import { FIELD_TYPES_ARR } from '../../constants/fieldTypes';

export const KEY_FORM_OPTIONS = {
  name: {
    label: 'Name',
    type: 'text',
    icon: 'app',
  },
  label: {
    label: 'Label',
    type: 'text',
    icon: 'collection',
    rows: 6,
  },
  type: {
    label: 'Type',
    type: 'select',
    noWrap: true,
    options: FIELD_TYPES_ARR,
  },
  lang: {
    label: 'Language',
    type: 'select',
    noWrap: true,
  },
  options: {
    label: 'Options',
    type: 'hidden',
    noWrap: true,
  },
};
