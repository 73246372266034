import React from 'react';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import { LANG } from '../../constants/lang';

const LocaleSelector = ({ list, lang, defaultLang, pathname }) => {
  const selected =
    (lang &&
      list.length &&
      list.find(({ identifier }) => lang === identifier)) ||
    list.find(({ identifier }) => defaultLang === identifier) ||
    {};

  const getPathByLang = langId => {
    if (pathname === '/') return `/${langId}/`;

    const probableLangFromPath = pathname.split('/')[1].toUpperCase();

    if (Object.values(LANG).includes(probableLangFromPath))
      return pathname.replace(`/${selected.identifier}/`, `/${langId}/`);

    return `/${langId}${pathname}`;
  };

  const handleChange = langId => {
    window.location.replace(getPathByLang(langId));
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle color="secondary" size="sm" className="m-0">
        {selected.label || <i className="fas fa-spinner fa-pulse" />}
      </DropdownToggle>
      <DropdownMenu right className="without-caret mt-1">
        {list.map(({ identifier, label }) => {
          return (
            <DropdownItem
              key={`locale-${identifier}`}
              onClick={() => handleChange(identifier)}
            >
              {label}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default LocaleSelector;
