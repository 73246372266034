import React from 'react';
import { Col, Row } from 'reactstrap';

import classes from './Hr.module.scss';

const Hr = ({
  color = 'default',
  margin = 'm-0',
  size = '3',
  justify = 'start',
}) => {
  return (
    <Row className={`justify-content-${justify}`}>
      <Col xs={12}>
        <div
          className={`mb-4 bg-${color} ${margin} ${classes.hr} ${justify ===
            'end' && 'ml-auto'} ${classes[`size-${size}`]}`}
        />
      </Col>
    </Row>
  );
};

export default Hr;
