import { prefix } from './actions';
import { hebcalCategories } from '../../constants/hebcal';

export const recent = state => state[prefix].recent;
export const list = state => state[prefix].list;
export const listCount = state => state[prefix].listCount;
export const item = state => state[prefix].item;
export const loading = state => state[prefix].loading;

export const listAdmin = state => state[prefix].listAdmin;
export const listAdminCount = state => state[prefix].listAdminCount;
export const itemAdmin = state => state[prefix].itemAdmin;
export const saving = state => state[prefix].saving;

export const hebcal = state => {
  const hebcalRes = state[prefix].hebcal;
  if (hebcalRes && hebcalRes.link) {
    return {
      items: addHebdateToParashat(state[prefix].hebcal.items),
      link: hebcalRes.link,
    };
  }
  return hebcalRes;
};
export const hebcalLoading = state => state[prefix].hebcalLoading;

function addHebdateToParashat(items) {
  return items.reduce((acc, item) => {
    if (item.category === hebcalCategories.parashat) {
      const hevdateItem = items.find(
        hItem =>
          hItem.date === item.date &&
          hItem.category === hebcalCategories.hebdate,
      );
      item.hDate = hevdateItem.title;
      acc.push(item);
    }

    return acc;
  }, []);
}
