import React, { Component } from 'react';
import MaterialsCategoryTypeForm from '../../../../../components/MaterialsCategoryTypeForm';
import { Card, CardHeader, Modal, CardBody } from 'reactstrap';

class RelationModal extends Component {
  constructor(props) {
    super(props);

    this.save = this.save.bind(this);
  }

  save({ id, doctype, categoryTypeId }) {
    const { add, update } = this.props;
    if (id) {
      update({ id, doctype, categoryTypeId });
    } else {
      add({ doctype, categoryTypeId });
    }
  }

  render() {
    const {
      isOpened,
      defaultLang,
      close,
      remove,
      initialValues = {},
    } = this.props;

    return (
      <Modal
        zIndex={1000}
        size="lg"
        className="modal-dialog-centered"
        isOpen={isOpened}
        toggle={() => close()}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0 mb-0">
            <CardHeader className="bg-transparent position-relative">
              <div className="text-muted text-center mt-2 mb-2">
                <small>Assign Category Type</small>
              </div>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  close();
                }}
                className="position-absolute right-4 top-4 display-4"
              >
                <i className="ni ni-fat-remove text-default" />
              </a>
            </CardHeader>
            <CardBody className="px-lg-5">
              <MaterialsCategoryTypeForm
                isNew={!initialValues.id}
                onSubmit={this.save}
                onDelete={() => remove(initialValues)}
                defaultLang={defaultLang}
                initialValues={initialValues}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default RelationModal;
