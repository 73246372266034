import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import Editor from '../Editor/Editor';

const customSelectStyles = {
  container: provided => ({
    ...provided,
    flexGrow: '1',
  }),
  control: provided => ({
    ...provided,
    border: 'none',
    padding: 5,
  }),
};

class InputRedux extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  componentWillUnmount() {
    const {
      input: { onChange },
      type,
      allowMulti,
    } = this.props;
    if (type === 'multiSelect' && !allowMulti) {
      onChange(null);
    }
  }

  onChange(e) {
    const {
      input: { onChange },
      type,
    } = this.props;

    if (type === 'checkbox') {
      return onChange(e.target.checked);
    }

    if (type === 'file') {
      if (this.props.filechanged && e.target.files && e.target.files.length) {
        this.props.filechanged(e.target.files[0].name.split('.').shift());
      }

      return onChange(e.target.files);
    }

    if (type === 'date') {
      return onChange(e._isAMomentObject ? e.format('MM/DD/YYYY') : e);
    }

    if (type === 'time') {
      return onChange(e._isAMomentObject ? e.format('h:mm a') : e);
    }

    // if (type === 'quill') {
    //   return onChange(e);
    // }

    return onChange(e.target.value);
  }

  handleMultiSelectChange = value => {
    const {
      input: { onChange },
      allowMulti,
      childTypes,
      change,
    } = this.props;
    if (!allowMulti) {
      const newValue = value ? [value.pop()] : value;
      childTypes &&
        childTypes.forEach(item => change(`categories.${item}`, null));
      return onChange(newValue);
    }
    return onChange(value);
  };

  render() {
    const {
      input: { value, checked },
      type,
      placeholder = '',
      dir = 'ltr',
      ...rest
    } = this.props;

    if (type === 'checkbox') {
      return (
        <div>
          <label className="custom-toggle mt-2">
            <input
              type={type}
              checked={checked}
              onChange={this.onChange}
              {...rest}
            />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </div>
      );
    }

    if (type === 'select') {
      const { options } = this.props;
      return (
        <Input
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={this.onChange}
          {...rest}
        >
          {options &&
            options.map(option => {
              let val, label, prefix;
              if (typeof option === 'object') {
                val = option.value;
                label = option.label;
                prefix = option.prefix;
              } else {
                val = option;
                label = option;
                prefix = '';
              }
              return (
                <option key={`select-option-${val.toLowerCase()}`} value={val}>
                  {prefix}
                  {label}
                </option>
              );
            })}
        </Input>
      );
    }

    if (type === 'multiSelect') {
      const { options, ...rest } = this.props;
      return (
        <Select
          {...rest}
          value={value}
          styles={customSelectStyles}
          placeholder={placeholder}
          isMulti
          isClearable
          onChange={this.handleMultiSelectChange}
          options={options}
        />
      );
    }

    if (type === 'file') {
      const {
        fileid,
        accept,
        filechanged,
        multiple,
        ...fileTypeRest
      } = this.props;

      return (
        <div className="custom-file">
          <input
            className="custom-file-input"
            id={fileid}
            lang="en"
            type="file"
            onChange={this.onChange}
            accept={accept}
            multiple={multiple}
            {...fileTypeRest}
          />
          <label className="custom-file-label" htmlFor={fileid}>
            {multiple
              ? 'Select files'
              : value && value.length
              ? value[0].name
              : 'Select file'}
          </label>
        </div>
      );
    }

    if (type === 'quill') {
      const { input, index, dir } = this.props;

      return (
        <Editor
          value={input.value}
          onChange={input.onChange}
          dir={dir}
          rest={{ ...rest }}
          id={index}
        />
      );
    }

    if (type === 'date') {
      return (
        <ReactDatetime
          value={value}
          inputProps={{
            placeholder: 'Set your date',
          }}
          timeFormat={false}
          onChange={this.onChange}
        />
      );
    }

    if (type === 'time') {
      return (
        <ReactDatetime
          value={value}
          inputProps={{
            placeholder: 'Set your time',
          }}
          dateFormat={false}
          timeFormat={true}
          onChange={this.onChange}
        />
      );
    }

    return (
      <Input
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={this.onChange}
        dir={dir}
        {...rest}
      />
    );
  }
}

InputRedux.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputRedux;
