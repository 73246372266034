import { prefix } from './actions';

export const types = state => state[prefix].types;
export const recent = state => state[prefix].recent;
export const related = state => state[prefix].related;
export const all = state => state[prefix].all;
export const allCount = state => state[prefix].allCount;
export const audio = state => state[prefix].audio;
export const loading = state => state[prefix].loading;

export const listAdmin = state => state[prefix].listAdmin;
export const listAdminCount = state => state[prefix].listAdminCount;
export const itemAdmin = state => state[prefix].itemAdmin;
export const saving = state => state[prefix].saving;
export const fileToSave = state => state[prefix].fileToSave;
