import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Banner from './Banner';

import { lang, defaultLang } from '../../../../redux/lang/selectors';
import { banners } from '../../../../redux/banner/selectors';
import { getBanners } from '../../../../redux/banner/actions';

const selector = createStructuredSelector({
  lang,
  banners,
  defaultLang,
});

export default connect(
  selector,
  { getBanners },
)(Banner);
