import React, { Component } from 'react';

import LocalizedLink from '../../components/LocalizedLink';
import BusinessWeeklyItem from '../../components/BusinessWeeklyItem';
import Paginator from '../../components/Paginator';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Row,
  FormGroup,
  Input,
  Button,
  Form,
} from 'reactstrap';
import Select from '../../components/Select';
import Loader from '../../components/Loader';
import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import { PARSHAS } from '../../constants/hebcal';

const parshasOptions = PARSHAS.map(item => ({
  label: item,
  value: item,
}));

export default class BWArchive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterParsha: null,
      filterSearch: '',
      filterNumber: null,
    };
    this.getList = this.getList.bind(this);
  }

  componentDidMount() {
    const { selectedLang, getList } = this.props;
    getList({ lang: selectedLang });
  }

  getList({ page = 1 }) {
    const { selectedLang, getList } = this.props;
    const { filterSearch, filterParsha, filterNumber } = this.state;
    const parsha = filterParsha && filterParsha.value;
    getList({
      page,
      lang: selectedLang,
      filter: {
        filterSearch,
        filterParsha: parsha,
        filterNumber,
      },
    });
  }

  handleChange = e => {
    const value = e.target.value === '-' ? '' : e.target.value;
    this.setState({
      [e.target.id]: value,
    });
  };

  handleSelectChange = value => {
    this.setState(
      {
        filterParsha: value,
      },
      () => {
        this.handleSearch();
      },
    );
  };

  handleSearch = () => {
    const { filterSearch, filterParsha, filterNumber } = this.state;
    const { selectedLang, getList } = this.props;
    const parsha = filterParsha && filterParsha.value;
    getList({
      lang: selectedLang,
      filter: {
        filterSearch,
        filterParsha: parsha,
        filterNumber,
      },
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.handleSearch();
  };

  render() {
    const { list, listCount, loading } = this.props;
    const { filterSearch, filterParsha, filterNumber } = this.state;
    return (
      <>
        {loading && <Loader type="fixed" />}
        <section className="bg-gradient-info py-5">
          <Container>
            <Breadcrumb listClassName="breadcrumb-dark breadcrumb-links pb-2">
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.HOME}>
                  {LINK_LABELS.HOME.EN}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem aria-current="page" className="active">
                {LINK_LABELS.BUSINESS_WEEKLY.EN}
              </BreadcrumbItem>
            </Breadcrumb>
            <Row className="align-items-stretch">
              <Col lg={4} xs={12}>
                <h1 className="display-2 text-white mb-0">
                  {LINK_LABELS.ARCHIVE.EN}
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container className="py-6">
            <Form onSubmit={this.handleSubmit}>
              <Row className="align-items-center">
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="filterParsha"
                    >
                      Parsha
                    </label>
                    <Select
                      id="filterParsha"
                      type="select"
                      value={filterParsha}
                      onChange={this.handleSelectChange}
                      options={parshasOptions}
                      placeholder="Select parsha"
                      isClearable
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="filterSearch"
                    >
                      Issue number
                    </label>
                    <Input
                      id="filterNumber"
                      placeholder="Issue number"
                      type="number"
                      value={filterNumber}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="filterSearch"
                    >
                      Narrow by word
                    </label>
                    <Input
                      id="filterSearch"
                      placeholder="Any"
                      type="text"
                      value={filterSearch}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={1}>
                  <Button
                    className="btn-icon btn-2 btn-block mt-2"
                    color="primary"
                    type="submit"
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-search" />
                    </span>
                  </Button>
                </Col>
              </Row>
            </Form>
            <Row className="py-5">
              {!list ||
                (!list.length && (
                  <h3 className="mx-3 py-6">No items founded</h3>
                ))}
              {list &&
                list.map(item => (
                  <Col key={`bw-item-${item.id}`} md={6}>
                    <BusinessWeeklyItem item={item} />
                  </Col>
                ))}
            </Row>
            <Paginator getItems={this.getList} amount={listCount} />
          </Container>
        </section>
      </>
    );
  }
}
