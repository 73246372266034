export const htmlTemplate = `<style type="text/css">
    #outlook a {
        padding: 0;
    }

    .ReadMsgBody {
        width: 100%;
    }

    .ExternalClass {
        width: 100%;
    }

    .ExternalClass * {
        line-height: 100%;
    }

    body {
        margin: 0;
        padding: 0;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    }

    table,
    td {
        border-collapse: collapse;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
    }

    img {
        border: 0;
        height: auto;
        line-height: 100%;
        outline: none;
        text-decoration: none;
        -ms-interpolation-mode: bicubic;
        max-width: 100%;
    }

    p {
        display: block;
        margin: 13px 0;
    }
</style>
<!--[if !mso]><!-->
<style type="text/css">
    @media only screen and (max-width: 480px) {
        @-ms-viewport {
            width: 320px;
        }

        @viewport {
            width: 320px;
        }
    }
</style>

<style type="text/css">
    @media only screen and (min-width: 480px) {
        .mj-column-per-100 {
            width: 100% !important;
            max-width: 100%;
        }

        .mj-column-per-50 {
            width: 50% !important;
            max-width: 50%;
        }
    }
</style>
<style type="text/css">
    @media only screen and (max-width: 480px) {
        table.full-width-mobile {
            width: 100% !important;
        }

        td.full-width-mobile {
            width: auto !important;
        }
    }
</style>

<div style="background-color:#ECEDEF;">
    <div style="display:none;font-size:1px;color:#ffffff;line-height:1px;max-height:0px;max-width:0px;opacity:0;overflow:hidden;">
        Pre-header Text
    </div>
    <div style="background-color:#ECEDEF;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
               style="background:#172B4D;background-color:#172B4D;width:100%;">
            <tbody>
            <tr>
                <td>
                    <div style="margin:0px auto;max-width:600px;">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                               style="width:100%;">
                            <tbody>
                            <tr>
                                <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;text-align:center;vertical-align:top;">
                                    <div class="mj-column-per-100 outlook-group-fix"
                                         style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                        <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                               style="vertical-align:top;" width="100%">
                                            <tr>
                                                <td align="center"
                                                    style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                           role="presentation"
                                                           style="border-collapse:collapse;border-spacing:0px;">
                                                        <tbody>
                                                        <tr>
                                                            <td style="width:150px;"><img alt="" height="auto"
                                                                                          src="{{logoHBoldImg}}"
                                                                                          style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;"
                                                                                          width="150"></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center"
                                                    style="font-size:0px;padding:10px 25px;padding-top:30px;padding-bottom:0px;word-break:break-word;">
                                                    <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:16px;font-weight:bold;letter-spacing:1px;line-height:24px;text-align:center;text-transform:uppercase;color:#ffffff;">
                                                        {{headerTitle}}<br><span
                                                            style="color: #979797; font-weight: normal">-</span></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center"
                                                    style="font-size:0px;padding:10px 25px;padding-top:0;padding-bottom:20px;word-break:break-word;">
                                                    <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:13px;font-weight:bold;letter-spacing:1px;line-height:20px;text-align:center;text-transform:uppercase;color:#17CBC4;">{{hDate}}
                                                        <br><span
                                                                style="color: #139994; font-weight: normal">{{date}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr style="direction:{{direction}}">
                                                <td align="center"
                                                    style="font-size:0px;padding:32px 25px;padding-bottom:0px;word-break:break-word;color:#4172C6;background-color:#4172C6;border-radius:6px 6px 0px 0px;border-top-left-radius:6px;border-top-right-radius:6px;border-bottom-left-radius:0px;border-bottom-right-radius:0px;-webkit-box-shadow: 0px -8px 11px 0px rgba(0, 0, 0, 0.05); -moz-box-shadow: 0px -8px 11px 0px rgba(0, 0, 0, 0.05); box-shadow: 0px -8px 11px 0px rgba(0, 0, 0, 0.05);">
                                                    <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:24px;font-weight:bold;letter-spacing:1px;line-height:41px;text-align:center;text-transform:uppercase;color:#FFFFFF;">
                                                        "{{headline}}"
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <div style="-webkit-box-shadow: 1px 4px 11px 0px rgba(0, 0, 0, 0.15); -moz-box-shadow: 1px 4px 11px 0px rgba(0, 0, 0, 0.15); box-shadow: 1px 4px 11px 0px rgba(0, 0, 0, 0.15);background:#4172C6;background-color:#4172C6;margin:0px auto;max-width:600px;">
            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                   style="background:#4172C6;background-color:#4172C6;width:100%;">
                <tbody>
                <tr>
                    <td style="direction:ltr;font-size:0px;padding:16px 0;text-align:center;vertical-align:top;">

                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="body-section"
             style="-webkit-box-shadow: 1px 4px 11px 0px rgba(0, 0, 0, 0.15); -moz-box-shadow: 1px 4px 11px 0px rgba(0, 0, 0, 0.15); box-shadow: 1px 4px 11px 0px rgba(0, 0, 0, 0.15); margin: 0px auto; max-width: 600px;">
            {{#unless sponsor}}
            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
                <tbody>
                <tr>
                    <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0;padding-top:0;text-align:center;vertical-align:top;">
                        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
                            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                                   style="background:#ffffff;background-color:#ffffff;width:100%;">
                                <tbody>
                                <tr>
                                    <td style="direction:ltr;font-size:0px;padding:10px 0;padding-left:15px;padding-right:15px;text-align:center;vertical-align:top;">
                                        <div class="mj-column-per-100 outlook-group-fix"
                                             style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                   style="vertical-align:top;" width="100%">
                                                <tr>
                                                    <td style="font-size:0px;padding:0px 25px;word-break:break-word;">
                                                        <div class="mj-column-per-50 outlook-group-fix"
                                                             style="text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                                            <table border="0" cellpadding="0" cellspacing="0"
                                                                   role="presentation" style="vertical-align:top;"
                                                                   width="100%">
                                                                <tbody>
                                                                <tr>
                                                                    <td align="left"
                                                                        style="font-size:0px;padding:0;word-break:break-word;">
                                                                        <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:16px;font-weight:bold;height:32px;line-height:38px;text-align:left;color:#657382;">
                                                                            {{sponsorText}}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="mj-column-per-50 outlook-group-fix"
                                                             style="text-align:right;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                                            <table border="0"
                                                                   cellpadding="0"
                                                                   cellspacing="0"
                                                                   role="presentation"
                                                                   style="vertical-align:top;"
                                                                   align="right"
                                                                >
                                                                <tbody>
                                                                <tr>
                                                                    <td
                                                                        align="center"
                                                                        role="presentation"
                                                                        style="border:none;border-radius:3px;cursor:auto;padding:10px;"
                                                                        valign="middle">
                                                                        <a href="{{sponsorLink}}" style="background:#003056;color:#ffffff;padding:8px 12px;border-radius:3px;font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:13px;font-weight:bold;line-height:120%;margin:0;text-decoration:none;text-transform:none;" target="_blank">
                                                                            {{sponsorButton}}
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
                            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;">
                                <tbody>
                                <tr>
                                    <td style="direction:ltr;font-size:0px;padding:0;padding-left:0;padding-right:0;padding-top:0;text-align:center;vertical-align:top;">
                                        <div class="mj-column-per-100 outlook-group-fix" style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                                                <tbody>
                                                    <tr>
                                                        <td style="font-size:0px;padding:0;word-break:break-word;">
                                                            <p style="border-top:solid 1px #DFE3E8;font-size:1;margin:0px auto;width:100%;"></p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            {{/unless}}
            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
                <tbody>
                <tr>
                    <td style="direction:{{direction}};font-size:0px;padding:20px 0;padding-bottom:0;padding-top:0;text-align:center;vertical-align:top;">
                        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
                            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                                   style="background:#ffffff;background-color:#ffffff;width:100%;">
                                <tbody>
                                <tr>
                                    <td style="direction:{{direction}};font-size:0px;padding:20px 0;padding-left:15px;padding-right:15px;text-align:center;vertical-align:top;">
                                        <div class="mj-column-per-100 outlook-group-fix"
                                             style="font-size:13px;text-align:{{align}};direction:{{direction}};display:inline-block;vertical-align:top;width:100%;">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                   style="vertical-align:top;" width="100%">
                                                <tr>
                                                    <td style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                        <div class="mj-column-per-50 outlook-group-fix"
                                                             style="text-align:{{align}};direction:{{direction}};display:inline-block;vertical-align:top;width:100%;">
                                                            <table border="0" cellpadding="0" cellspacing="0"
                                                                   role="presentation" style="vertical-align:top;"
                                                                   width="100%">
                                                                <tbody>
                                                                <tr>
                                                                    <td align="left"
                                                                        style="font-size:0px;padding:0;word-break:break-word;">
                                                                        <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:20px;font-weight:bold;line-height:24px;text-align:{{align}};color:#212b35;">{{title}}</div>
                                                                        <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:16px;font-weight:normal;line-height:24px;text-align:{{align}};color:#637381;">
                                                                            {{author}}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <!-- <div class="mj-column-per-50 outlook-group-fix" style="text-align:right;direction:rtl;display:inline-block;vertical-align:top;width:100%;">
                                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="75%">
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="center" bgcolor="#64b5f6" role="presentation" style="border:none;border-radius:3px;cursor:auto;padding:10px 25px;background:#64b5f6;" valign="middle">
                                                                            <a href="https://google.com" style="background:#64b5f6;color:#ffffff;font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:17px;font-weight:bold;line-height:120%;margin:0;text-decoration:none;text-transform:none;" target="_blank">
                                                                                Read Online
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div> -->
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="left"
                                                        style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                        <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:16px;font-weight:400;line-height:24px;text-align:{{align}};color:#29343f;">
                                                            {{{text}}}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" vertical-align="middle"
                                                        style="font-size:0px;padding:20px 25px;word-break:break-word;">
                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                               role="presentation"
                                                               style="border-collapse:separate;line-height:100%;">
                                                            <tr>
                                                                <td align="center" bgcolor="#4172C6" role="presentation"
                                                                    style="border:none;border-radius:3px;cursor:auto;padding:10px 25px;background:#4172C6;"
                                                                    valign="middle">
                                                                    <a href="{{bwLink}}"
                                                                       style="background:#4172C6;color:#ffffff;font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:17px;font-weight:bold;line-height:120%;margin:0;text-decoration:none;text-transform:none;"
                                                                       target="_blank">
                                                                        {{btnLabel}}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        {{#unless footer}}
                        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
                            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                                   style="background:#ffffff;background-color:#ffffff;width:100%;">
                                <tbody>
                                <tr>
                                    <td style="direction:ltr;font-size:0px;padding:0;padding-left:15px;padding-right:15px;padding-top:0;text-align:center;vertical-align:top;">
                                        <div class="mj-column-per-100 outlook-group-fix"
                                             style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                   style="vertical-align:top;" width="100%">
                                                <tr>
                                                    <td style="font-size:0px;padding:0px 25px;word-break:break-word;">
                                                        <p style="border-top:solid 1px #DFE3E8;font-size:1;margin:0px auto;width:100%;"></p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
                            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                                   style="background:#ffffff;background-color:#ffffff;width:100%;">
                                <tbody>
                                <tr>
                                    <td style="direction:ltr;font-size:0px;padding:20px 0;padding-left:15px;padding-right:15px;text-align:center;vertical-align:top;">
                                        <div class="mj-column-per-100 outlook-group-fix"
                                             style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                   style="vertical-align:top;" width="100%">
                                                <tr>
                                                    <td align="left"
                                                        style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                        <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:16px;font-weight:500;line-height:24px;text-align:left;color:#657382;">
                                                            {{footerTitle}}
                                                        </div>
                                                        <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:16px;font-weight:400;line-height:24px;text-align:left;color:#657382;">
                                                            {{{footerText}}}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        {{/unless}}
                        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
                            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                                   style="background:#ffffff;background-color:#ffffff;width:100%;">
                                <tbody>
                                <tr>
                                    <td style="direction:ltr;font-size:0px;padding:20px 0;padding-left:15px;padding-right:15px;padding-top:0;text-align:center;vertical-align:top;">
                                        <div class="mj-column-per-100 outlook-group-fix"
                                             style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                   style="vertical-align:top;" width="100%">
                                                <tr>
                                                    <td style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                                                        <p style="border-top:solid 1px #DFE3E8;font-size:1;margin:0px auto;width:100%;"></p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
                            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                                   style="background:#ffffff;background-color:#ffffff;width:100%;">
                                <tbody>
                                <tr>
                                    <td style="direction:ltr;font-size:0px;padding:20px 0;padding-left:15px;padding-right:15px;padding-top:0;text-align:center;vertical-align:top;">
                                        <div class="mj-column-per-50 outlook-group-fix"
                                             style="text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:50%;">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                   style="vertical-align:top;" width="100%">
                                                <tbody>
                                                <tr>
                                                    <td align="right"
                                                        style="font-size:0px;padding:5px 25px;word-break:break-word;">
                                                        <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:20px;font-weight:bold;line-height:24px;text-align:right;color:#212b35;padding:0;padding-top:5px;">
                                                            Business Halacha Institute
                                                        </div>
                                                        <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:16px;font-weight:normal;line-height:24px;text-align:right;color:#637381;padding:10px 0;padding-bottom:5px;">
                                                            Harav Chaim Kohn
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="mj-column-per-50 outlook-group-fix"
                                             style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:50%;">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                   style="vertical-align:top;" width="100%">
                                                <tr>
                                                    <td align="center"
                                                        style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                               role="presentation"
                                                               style="border-collapse:collapse;border-spacing:0px;">
                                                            <tbody>
                                                            <tr>
                                                                <td style="width:235px;">
                                                                    <img alt="" height="auto"
                                                                         src="{{logoLImg}}"
                                                                         style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:90px;">
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
                            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                                   style="background:#ffffff;background-color:#ffffff;width:100%;">
                                <tbody>
                                <tr>
                                    <td style="direction:ltr;font-size:0px;padding:0 15px;padding-top:0px;padding-bottom:0px;text-align:center;vertical-align:top;">
                                        <div class="mj-column-per-50 outlook-group-fix"
                                             style="font-size:13px;text-align:right;direction:ltr;display:inline-block;vertical-align:top;width:50%;">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                   style="vertical-align:top;" width="100%">
                                                <tr>
                                                    <td align="right"
                                                        style="font-size:0px;padding:10px 25px;padding-bottom:0;word-break:break-word;">
                                                        <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:12px;font-weight:bold;line-height:24px;text-align:right;text-transform:uppercase;color:#212b35;">
                                                            ADDRESS
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="right"
                                                        style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                                                        <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:24px;text-align:right;color:#637381;">
                                                            1937 Ocean Ave<br>Brooklyn, NY 11230
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="mj-column-per-50 outlook-group-fix"
                                             style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:50%;">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                   style="vertical-align:top;" width="100%">
                                                <tr>
                                                    <td align="left"
                                                        style="font-size:0px;padding:10px 25px;padding-bottom:0;word-break:break-word;">
                                                        <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:12px;font-weight:bold;line-height:24px;text-align:left;text-transform:uppercase;color:#212b35;">
                                                            HOURS
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="left"
                                                        style="font-size:0px;padding:10px 25px;padding-top:0;word-break:break-word;">
                                                        <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:14px;font-weight:400;line-height:24px;text-align:left;color:#637381;">
                                                            Sunday - Thursday:<br>10:00AM - 3:00PM
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
                            <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                                   style="background:#ffffff;background-color:#ffffff;width:100%;">
                                <tbody>
                                <tr>
                                    <td style="direction:ltr;font-size:0px;padding:20px 0;padding-left:15px;padding-right:15px;text-align:center;vertical-align:top;">
                                        <div class="mj-column-per-100 outlook-group-fix"
                                             style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                   style="vertical-align:top;" width="100%">
                                                <tr>
                                                    <td align="center"
                                                        style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                               role="presentation"
                                                               style="border-collapse:collapse;border-spacing:0px;">
                                                            <tbody>
                                                            <tr>
                                                                <td style="width:520px;">
                                                                    <a href="https://goo.gl/maps/CnppfZuAu4Yu3kJj6">
                                                                        <img alt="" height="auto"
                                                                             src="{{mapImg}}"
                                                                             style="-webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15); -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15); box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;border-radius:6px;border-top-right-radius:6px;border-top-left-radius:6px;border-bottom-right-radius:6px;border-bottom-right-radius:6px;"
                                                                             width="520">
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
            <tbody>
            <tr>
                <td>
                    <div style="margin:0px auto;max-width:600px;">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                               style="width:100%;">
                            <tbody>
                            <tr>
                                <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;vertical-align:top;">
                                    <div style="margin:0px auto;max-width:600px;">
                                        <table align="center" border="0" cellpadding="0" cellspacing="0"
                                               role="presentation" style="width:100%;">
                                            <tbody>
                                            <tr>
                                                <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;vertical-align:top;">
                                                    <div class="mj-column-per-100 outlook-group-fix"
                                                         style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                               role="presentation" width="100%">
                                                            <tbody>
                                                            <tr>
                                                                <td style="vertical-align:top;padding:0;">
                                                                    <table border="0" cellpadding="0" cellspacing="0"
                                                                           role="presentation" style="" width="100%">
                                                                        <tr>
                                                                            <td align="center"
                                                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                                                <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:11px;font-weight:bold;line-height:24px;text-align:center;color:#445566;">
                                                                                    <a class="text-link"
                                                                                       href="https://google.com"
                                                                                       style="color: #5e6ebf;">View this
                                                                                        email in your browser</a>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="center"
                                                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                                                <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:11px;font-weight:400;line-height:16px;text-align:center;color:#445566;">
                                                                                    The &copy; Business Halacha
                                                                                    Institute is committed to restoring
                                                                                    the primacy of Choshen Mishpat.
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="center"
                                                                                style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                                                <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:11px;font-weight:400;line-height:16px;text-align:center;color:#445566;">
                                                                                    Choshen Mishpat is applicable to
                                                                                    every aspect of contemporary
                                                                                    transactions. BHI is committed to
                                                                                    providing you with the awareness and
                                                                                    knowledge you need to navigate
                                                                                    today’s business world according to
                                                                                    halacha.
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style="margin:0px auto;max-width:600px;">
                                        <table align="center" border="0" cellpadding="0" cellspacing="0"
                                               role="presentation" style="width:100%;">
                                            <tbody>
                                            <tr>
                                                <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:0;text-align:center;vertical-align:top;">
                                                    <div class="mj-column-per-100 outlook-group-fix"
                                                         style="font-size:0;line-height:0;text-align:left;display:inline-block;width:100%;direction:ltr;">
                                                        <div class="mj-column-per-100 outlook-group-fix"
                                                             style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                                                            <table border="0" cellpadding="0" cellspacing="0"
                                                                   role="presentation" width="100%">
                                                                <tbody>
                                                                <tr>
                                                                    <td style="vertical-align:top;padding-right:0;">
                                                                        <table border="0" cellpadding="0"
                                                                               cellspacing="0" role="presentation"
                                                                               style="" width="100%">
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="font-size:0px;padding:10px 25px;word-break:break-word;">
                                                                                    <div style="font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;font-size:11px;font-weight:bold;line-height:16px;text-align:center;color:#445566;">
                                                                                        <a class="footer-link"
                                                                                           href="https://www.google.com"
                                                                                           style="color: #888888;">Privacy</a>&#xA0;&#xA0;&#xA0;&#xA0;&#xA0;&#xA0;&#xA0;&#xA0;<a
                                                                                            href="%mailing_list_unsubscribe_url%"
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer">Unsubscribe</a>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>`;
