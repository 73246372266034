import { LANG } from './lang';

export const TRANSLATIONS = {
  SEARCH_PLACEHOLDER: {
    [LANG.EN]: 'Search for an article, shiur, or shtar',
    [LANG.HE]: 'חיפוש מאמר,שיעור או שטר',
    [LANG.FR]: 'Recherchez un article, shiur ou shtar',
  },
  DISABLED_SEARCH_PLACEHOLDER: {
    [LANG.EN]: 'General search disabled while in advanced mode',
  },
  SEARCH_TITLE: {
    [LANG.EN]: 'Search',
    [LANG.HE]: 'חיפוש',
    [LANG.FR]: 'Recherchez',
  },
  ADVANCED_SEARCH: {
    [LANG.EN]: 'Advanced search',
    [LANG.HE]: 'חיפוש מתקדם',
    [LANG.FR]: 'Recherche avancée',
  },
  SUBSCRIBE: {
    [LANG.EN]: 'Subscribe',
    [LANG.HE]: 'הירשם כמנוי',
    [LANG.FR]: 'Abonnez vous',
  },
  ALL: {
    [LANG.EN]: 'All',
    [LANG.HE]: 'הכל',
    [LANG.FR]: 'Tout',
  },
  LOAD_MORE: {
    [LANG.EN]: 'Load more',
    [LANG.HE]: 'פתחעוד',
    [LANG.FR]: 'Telecharger',
  },
  NO_RESULTS: {
    [LANG.EN]: 'No results',
    [LANG.HE]: 'אין תוצאות',
    [LANG.FR]: 'Aucun resultat',
  },
  SEARCHING: {
    [LANG.EN]: 'Searching',
    [LANG.HE]: 'מחפש',
    [LANG.FR]: 'Scrutateur',
  },
  SEE_ALL: {
    [LANG.EN]: 'See all',
    [LANG.HE]: 'ראה הכל',
    [LANG.FR]: 'Voir tous les resultats,',
  },
  OUR_SERVICES: {
    [LANG.EN]: 'Our Services',
    [LANG.HE]: 'השירותים הניתנים על ידי המכון',
  },
  ALL_ARCHIVE: {
    [LANG.EN]: 'All archive',
    [LANG.HE]: 'כל המאמרים',
    [LANG.FR]: 'Toutes les archives',
  },
  RELATED: {
    [LANG.EN]: 'Related',
    [LANG.HE]: 'נושאים קשורים',
    [LANG.FR]: 'Connecte',
  },
  SEE_ARCHIVE: {
    [LANG.EN]: 'See archive',
    [LANG.HE]: 'ראה בארכיון',
    [LANG.FR]: 'Voir archive',
  },
  DOWNLOAD: {
    [LANG.EN]: 'Download',
    [LANG.HE]: 'הורד',
    [LANG.FR]: 'Telecharger',
  },
  VIEW: {
    [LANG.EN]: 'View',
  },
  PUBLICATIONS: {
    [LANG.EN]: 'Publications',
    [LANG.HE]: 'חומר שפורסם',
    [LANG.FR]: 'Publications',
  },
  DOCUMENTS: {
    [LANG.EN]: 'documents',
    [LANG.HE]: 'מסמכים',
    [LANG.FR]: 'Documents',
  },
  ARTICLES: {
    [LANG.EN]: 'Articles',
    [LANG.HE]: 'מאמרים',
    [LANG.FR]: 'Article',
  },
  AUDIO: {
    [LANG.EN]: 'Audio',
    [LANG.HE]: 'לשמיעה',
    [LANG.FR]: 'Audio',
  },
  AUDIOS: {
    [LANG.EN]: 'Audios',
    [LANG.HE]: 'שיעורים ודרשות',
    [LANG.FR]: 'Audios',
  },
  AUDIO_CLASSES: {
    [LANG.EN]: 'Audio Classes',
    [LANG.HE]: 'לשמיעת הרצאות',
    [LANG.FR]: 'Cours en audio',
  },
  SHTAROS: {
    [LANG.EN]: 'Shtaros',
    [LANG.HE]: 'שטרות',
    [LANG.FR]: 'Shtaros',
  },
  AUDIO_SEARCH_PH: {
    [LANG.EN]: 'Find audio classes',
    [LANG.HE]: 'חיפוש הרצאות',
    [LANG.FR]: 'Trouver des cours en audio',
  },
  SHTAROS_SEARCH_PH: {
    [LANG.EN]: 'Find document',
    [LANG.HE]: 'חיפוש מסמך',
    [LANG.FR]: 'Trouver un document',
  },
  CALL_US: {
    [LANG.EN]: 'Call us',
    [LANG.HE]: 'התקשר',
    [LANG.FR]: 'Appelez-nous',
  },
  CONTACT_US: {
    [LANG.EN]: 'Contact us',
    [LANG.HE]: 'צור קשר',
    [LANG.FR]: 'Contactez nous',
  },
  CONTACT_US_TEXT: {
    [LANG.EN]:
      'We work very quickly to get you the answer and guidance you need so you can operate your business efficiently and with Halacha',
    [LANG.HE]:
      'מטרתינו לתת מענה במהירות וביעילות על מנת שהעסק שלך יתנהל כנדרש על פי ההלכה',
    [LANG.FR]:
      'Nous travaillons rapidement after de fournir la reponse et la ligne de conduite a suivre pour que vous puissiez gerer vos affaires avec efficacite tout en respectant la halacha',
  },
  JOIN_NL: {
    [LANG.EN]: 'Join our newsletter',
    [LANG.HE]: 'הצטרף לקבלת הגליונות',
    [LANG.FR]: 'Souscrivez a notre Newsletter',
  },
  SEND: {
    [LANG.EN]: 'Send',
    [LANG.HE]: 'שלח',
    [LANG.FR]: 'Envoyez',
  },
  ENTER_EMAIL: {
    [LANG.EN]: 'Enter your e-mail address',
    [LANG.HE]: 'כתובת אימייל',
    [LANG.FR]: 'Inserez votre adresse email',
  },
  DONATE: {
    [LANG.EN]: 'Donate',
    [LANG.HE]: 'היה שותף',
    [LANG.FR]: 'Donnez',
  },
  PAYMENT_DETAILS: {
    [LANG.EN]: 'Payment Details',
    [LANG.HE]: 'פרטי התשלום',
    [LANG.FR]: 'Coordonnees',
  },
  BILLING_INFORMATION: {
    [LANG.EN]: 'Billing information',
    [LANG.HE]: 'מלא את הפרטים',
    [LANG.FR]: 'Coordonnees pour la facturation',
  },
  DONATE_AMOUNT_TITLE: {
    [LANG.EN]: 'Donation To Business Halacha',
    [LANG.HE]: 'תרום למכו',
    [LANG.FR]: 'Don au Business Halacha',
  },
  NO_CONTENT_MSG: {
    [LANG.EN]:
      'There are no publications in the current language. Please try Hebrew',
    [LANG.HE]: 'אין תוצאות בשפה המבוקשת. נסה באנגלית',
    [LANG.FR]:
      "Il n'y a pas de publication dans la langue souhaitee. Veuillez rechercher en Anglais",
  },
  NO_TITLE_DEFAULT: {
    [LANG.EN]: 'No title',
    [LANG.HE]: 'אין כותרת',
  },
  NO_DESC_DEFAULT: {
    [LANG.EN]: 'No description',
    [LANG.HE]: 'אין תיאור',
  },
  AUTHOR_DEFAULT: {
    [LANG.EN]: 'Business Halacha Institute',
    [LANG.HE]: 'מכון לחושן משפט',
  },
  AUTHOR_BW: {
    [LANG.EN]: {
      STORY_LINE: 'Rabbi Meir Orlian',
      HOTLINE: "Submitted by the Beis Hora'ah",
      MONEY_MATTERS: 'Rabbi Meir Orlian',
    },
    [LANG.HE]: {
      STORY_LINE: 'הרב אריה ליכטנשטיין',
      HOTLINE: 'בית הוראה',
      MONEY_MATTERS: "הרב חיים כהן, שליט''א",
    },
    [LANG.FR]: {
      STORY_LINE: 'Rabbi Meir Orlian, traduit par André Kahn',
      HOTLINE: "Submitted by the Beis Hora'ah, traduit par André Kahn",
      MONEY_MATTERS: 'Rabbi Meir Orlian, traduit par André Kahn',
    },
  },
};
