import React from 'react';

import LocalizedLink from '../LocalizedLink';

import { Row, Col, Card, CardBody } from 'reactstrap';
import { ROUTES } from '../../constants/routes';
import { Link } from 'react-router-dom';

class FormItem extends React.Component {
  render() {
    const { item, isAdmin, lang, selectedLang, onRemove } = this.props;
    return (
      <Card className="bg-primary">
        <Row noGutters className="align-items-center justify-content-between">
          <Col
            xs={2}
            className="p-4 mb-4 mb-sm-0 text-center d-none d-sm-block"
          >
            {isAdmin ? (
              <Link
                className="display-4 btn-inner--icon text-white mb-0 pt-1"
                to={`${ROUTES.ADMIN_FORMS_ITEM_PARAM}${item.id}`}
              >
                <i className="ni ni-single-copy-04" />
              </Link>
            ) : (
              <span className="display-4 btn-inner--icon text-white mb-0 pt-1">
                <i className="ni ni-single-copy-04" />
              </span>
            )}
          </Col>
          <Col xs={12} sm={10} className="rounded-right bg-white p-0">
            <CardBody>
              <Row>
                <Col
                  sm={8}
                  xl={8}
                  lg={8}
                  className="mb-3 mb-sm-0 text-center text-sm-left"
                >
                  {isAdmin ? (
                    <Link to={`${ROUTES.ADMIN_FORMS_ITEM_PARAM}${item.id}`}>
                      <h3 className="h3 lh-120">{item.lang[lang].name}</h3>
                    </Link>
                  ) : (
                    <h3 className="h3 lh-120">
                      {item.lang[selectedLang].name}
                    </h3>
                  )}

                  {isAdmin && (
                    <p className="text-muted lh-120">
                      {item.active ? 'PUBLISHED' : 'DRAFT'}
                    </p>
                  )}
                </Col>
                <Col
                  sm={4}
                  className="d-flex align-items-center justify-content-around"
                >
                  {isAdmin ? (
                    <>
                      <Link
                        to={`${ROUTES.ADMIN_FORMS_ITEM_PARAM}${item.id}`}
                        className="h2 btn-inner--icon text-primary mb-0 pt-1"
                      >
                        <i className="ni ni-settings-gear-65" />
                      </Link>
                      <a
                        href="/"
                        onClick={e => {
                          e.preventDefault();
                          onRemove(item.id);
                        }}
                        className="h1 btn-inner--icon text-danger mb-0 pt-1"
                      >
                        <i className="ni ni-fat-remove" />
                      </a>
                    </>
                  ) : (
                    <>
                      <LocalizedLink
                        to={`${ROUTES.FORMS_APPLY_PARAM}${item.id}`}
                        className="h2 btn-inner--icon text-primary mb-0 pt-1"
                      >
                        <i className="ni ni-zoom-split-in" />
                      </LocalizedLink>
                    </>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default FormItem;
