import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row, FormGroup, Button } from 'reactstrap';
import { typeOptions } from '../../constants/document';
import SearchGeneral from '../SearchGeneral';
import SearchInput from '../SearchInput';
import Select from '../Select';
import styles from './SearchFilters.module.scss';

class SearchFilters extends React.Component {
  handleChangeSelect = (selectedOption, event) => {
    const { onChange } = this.props;

    if (event.action === 'clear') return onChange(event.name, null);

    onChange(event.name, selectedOption.value);
  };

  handleChangeInput = event => {
    const { onChange } = this.props;
    onChange(event.target.name, event.target.value);
  };

  render() {
    const {
      isAdvancedSearch,
      values,
      setIsAdvancedSearch,
      onSearch,
    } = this.props;

    return (
      <section className="pt-4 pb-4">
        <Container className={styles.container}>
          <Row>
            <Col md="8">
              <SearchGeneral disabled={isAdvancedSearch} onSearch={onSearch} />
            </Col>
            <Col md="4">
              <FormGroup className="mb-md-0 mb-4">
                <label className="form-control-label" htmlFor="filterType">
                  Content type
                </label>
                <Select
                  id="filterType"
                  type="select"
                  name="type"
                  value={typeOptions.find(
                    option => option.value === values.type,
                  )}
                  onChange={this.handleChangeSelect}
                  options={typeOptions}
                  placeholder="Select type"
                  isClearable={false}
                />
              </FormGroup>
            </Col>
          </Row>

          {!isAdvancedSearch && (
            <Button
              className="pl-0 mt-3"
              color="link"
              onClick={() => setIsAdvancedSearch(true)}
            >
              Show advanced
            </Button>
          )}

          {isAdvancedSearch && (
            <Fragment>
              <Row className="align-items-center mt-4">
                <Col>
                  <SearchInput
                    name="title"
                    value={values.title}
                    label="Title"
                    placeholder="Search by title"
                    onChange={this.handleChangeInput}
                    onSearch={onSearch}
                  />
                </Col>
                <Col>
                  <SearchInput
                    name="category"
                    value={values.category}
                    label="Tag/category"
                    placeholder="Search by tag/category"
                    onChange={this.handleChangeInput}
                    onSearch={onSearch}
                  />
                </Col>
                <Col>
                  <SearchInput
                    name="content"
                    value={values.content}
                    label="Text content"
                    placeholder="Search full text content"
                    onChange={this.handleChangeInput}
                    onSearch={onSearch}
                  />
                </Col>
              </Row>
              <Button
                className="pl-0 mt-3"
                color="link"
                onClick={() => setIsAdvancedSearch(false)}
              >
                Hide advanced
              </Button>
            </Fragment>
          )}
        </Container>
      </section>
    );
  }
}

export default withRouter(SearchFilters);
