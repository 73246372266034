import React, { Component } from 'react';

import { Table, Button, Card, CardHeader, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import LanguageTabs from '../LanguageTabs';

import styles from './AdminTable.module.scss';

const defaultDirection = {
  '--fa-secondary-opacity': 0.5,
  '--fa-primary-opacity': 0.5,
};

const toLess = {
  '--fa-primary-opacity': 1,
  '--fa-secondary-opacity': 0.1,
};

const toHigh = {
  '--fa-primary-opacity': 0.1,
  '--fa-secondary-opacity': 1,
};

class AdminTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.colSort = this.colSort.bind(this);
    this.trSort = this.trSort.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  componentDidMount() {
    const { headerList } = this.props;

    const initState = {};

    headerList.forEach(head => {
      const { title, isSortable, dbName, isAssociated } = head;
      const name = dbName ? dbName : title;

      if (isSortable) {
        initState[name] = {
          isActive: false,
          direction: 'DESC',
          isAssociated,
        };
      }
    });

    this.setState(initState);
  }

  trSort(cb) {
    const state = this.state;

    const retObj = {};
    Object.keys(state)
      .filter(item => state[item].isActive)
      .forEach(item => {
        retObj[item] = { ...state[item] };
        delete retObj[item].isActive;
      });

    cb(retObj);
  }

  colSort(name, cb) {
    const state = this.state;

    if (!state[name].isActive) {
      return this.setState(
        {
          ...state,
          [name]: { ...state[name], isActive: true },
        },
        () => this.trSort(cb),
      );
    } else if (state[name].isActive && state[name].direction === 'DESC') {
      return this.setState(
        {
          ...state,
          [name]: { ...state[name], direction: 'ASC' },
        },
        () => this.trSort(cb),
      );
    } else {
      return this.setState(
        {
          ...state,
          [name]: { ...state[name], isActive: false, direction: 'DESC' },
        },
        () => this.trSort(cb),
      );
    }
  }

  resetFilter(cb) {
    const state = JSON.parse(JSON.stringify(this.state));

    Object.keys(state).forEach(name => {
      state[name].isActive = false;
      state[name].direction = 'DESC';
    });

    this.setState(state);
    cb({});
  }

  render() {
    const {
      headerList = [],
      rowsList = [],
      sortFn,
      selectedLang,
      onLangChange,
    } = this.props;

    const tableHead = headerList.map(head => {
      const { title, isSortable, dbName, centered, colStyle } = head;
      const name = dbName ? dbName : title;

      const direction = this.state[name] && this.state[name].direction;
      const isActive = this.state[name] && this.state[name].isActive;

      const isToHigh = direction === 'DESC';

      return (
        <th
          key={title}
          className={centered && 'text-center'}
          style={{ ...colStyle }}
          onClick={e => {
            e.preventDefault();
            if (isSortable && sortFn) {
              this.colSort(name, sortFn);
            }
          }}
        >
          <div className={isSortable && sortFn && styles.tableHeader}>
            {title}
            {isSortable && sortFn ? (
              <Button
                href="#"
                className={styles.sort}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Sort"
              >
                <span className="btn-inner--icon">
                  <FontAwesomeIcon
                    icon={['fad', 'sort-alt']}
                    style={
                      isActive ? (isToHigh ? toHigh : toLess) : defaultDirection
                    }
                  />
                </span>
              </Button>
            ) : null}
          </div>
        </th>
      );
    });
    return (
      <Card>
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <Col xs="6" md="2">
              <h3 className="mb-0">List</h3>
            </Col>
            <Col xs="6" md="4">
              <LanguageTabs
                fullList
                smLinks
                forceRow
                lang={selectedLang}
                onChange={onLangChange}
              />
            </Col>
            <Col className="text-right" xs="12" md="6">
              <Button
                className="btn-round btn-icon"
                color="secondary"
                onClick={e => {
                  e.preventDefault();
                  this.resetFilter(sortFn);
                }}
                size="sm"
              >
                <span className="btn-inner--icon mr-1">
                  <FontAwesomeIcon icon={['fad', 'sort-alt']} />
                </span>
                <span className="btn-inner--text">Reset sorting</span>
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <Table
          className={classnames(
            'align-items-center table-flush',
            styles.tableStyle,
          )}
          hover
        >
          <thead className="thead-light">
            <tr>{tableHead}</tr>
          </thead>
          <tbody>{rowsList}</tbody>
        </Table>
      </Card>
    );
  }
}

export default AdminTable;
