import React, { Component } from 'react';
import CustomForm from '../../../components/CustomForm';
import { Card, CardHeader, Modal, CardBody } from 'reactstrap';

class EditFormModal extends Component {
  render() {
    const {
      documentList,
      isOpened,
      item,
      save,
      remove,
      close,
      getDocumentList,
    } = this.props;

    return (
      <Modal
        zIndex={1000}
        size="lg"
        className="modal-dialog-centered"
        isOpen={isOpened}
        toggle={() => close()}
        onOpened={getDocumentList}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0 mb-0">
            <CardHeader className="bg-transparent position-relative">
              <div className="text-muted text-center mt-2 mb-2">
                <small>Edit form data</small>
              </div>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  close();
                }}
                className="position-absolute right-4 top-4 display-4"
              >
                <i className="ni ni-fat-remove text-default" />
              </a>
            </CardHeader>
            <CardBody className="px-lg-5 pt-0">
              <CustomForm
                documents={documentList}
                onSubmit={save}
                onDelete={remove}
                initialValues={item}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default EditFormModal;
