import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { list, listAdmin, defaultLang } from '../../redux/lang/selectors';

import LanguageTabs from './LanguageTabs';

export default connect(
  createStructuredSelector({
    langList: list,
    langFullList: listAdmin,
    defaultLang,
  }),
  null,
)(LanguageTabs);
