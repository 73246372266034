import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getItemAdmin,
  getTypes,
  add,
  update,
  remove,
  setFile,
} from '../../../redux/article/actions';
import {
  loading,
  saving,
  itemAdmin,
  types,
} from '../../../redux/article/selectors';
import { list, defaultLang } from '../../../redux/lang/selectors';
import ArticleItemPage from './ArticleItemPage';

const selector = createStructuredSelector({
  loading,
  saving,
  articleItem: itemAdmin,
  langList: list,
  types,
  defaultLang,
});

const actions = {
  fetch: getItemAdmin,
  add,
  update,
  remove,
  setFile,
  getTypes,
};

export { ArticleItemPage };

export default connect(
  selector,
  actions,
)(ArticleItemPage);
