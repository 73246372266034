import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import BusinessWeeklyPage from './BusinessWeeklyPage';

import { lang, defaultLang } from '../../redux/lang/selectors';
import { recent, item, loading } from '../../redux/business-weekly/selectors';
import { getRecent, getItem } from '../../redux/business-weekly/actions';

const selector = createStructuredSelector({
  selectedLang: lang,
  defaultLang,
  recent,
  item,
  loading,
});

const actions = { getRecent, getItem };

export { BusinessWeeklyPage };

export default connect(
  selector,
  actions,
)(BusinessWeeklyPage);
