import React from 'react';
import { find } from 'lodash';

export const missionStatement = {
  title: 'Restoring the primacy of Choshen Mishpat',
  text:
    'Choshen Mishpat is applicable to every aspect of contemporary transactions. BHI is committed to providing you with the awareness and knowledge you need to navigate today’s business world according to halacha.',
};

export const services = [
  {
    id: 'halachic_guidance',
    title: 'Halachic Guidance',
    text:
      'BHI’s team of rabanim provide Rabbinic consultation and guidance to help you ensure that your business is halachically compliant in all areas of business halacha, including Shabbos, ribbis, and halachically valid contracts.',
    link: {
      text: 'Get in touch',
      path: '/contact',
    },
  },
  {
    id: 'beis_din',
    title: 'Beis Din',
    text:
      'BHI maintains a beis din of professional dayanim with a competent understanding of business. This Beis Din has successfully arbitrated complex monetary disputes worth millions of dollars. This effective & efficient arbitration has helped parties avoid costly and time-consuming litigation while ensuring halachic compliance. All Beis Din proceedings are confidential.',
    link: {
      text: 'Reach out to learn more',
      path: '/contact',
    },
  },
  {
    id: 'halacha_hotline',
    title: 'Halacha Hotline',
    text:
      'BHI’s hotline answers short halacha questions in all areas of business halacha and is a great resource to easily resolve simple queries free of charge. The hotline is available by phone, email, or directly from the website.',
    link: {
      text: 'Call us now',
      path: 'tel:877-845-8455',
      isDirectLink: true,
    },
  },
  {
    id: 'shiurim_and_seminars',
    title: 'Shiurim and seminars',
    text:
      'BHI’s rabanim give shiurim and seminars in contemporary applications of halacha to educate and raise awareness of business halacha concepts.',
    link: {
      text: 'Check out the shiurim archive',
      path: '/materials/audio',
    },
  },
  {
    id: 'newsletters',
    title: 'Newsletters',
    text:
      'BHI’s popular newsletter is published in three languages and is frequently read by scholars, laymen and educators. The newsletter discusses real-life scenarios in an easily accessible format that all can learn from & enjoy.',
    link: {
      text: 'Check out the newsletter archive',
      path: '/business-weekly/archive',
    },
  },
];

export const profiles = [
  {
    id: 1,
    img: encodeURI(
      'https://s3.wasabisys.com/businesshalacha/other/media/Rabbi Kohn.jpg',
    ),
    name: 'Harav Chaim Kohn',
    position: '-',
    text: (
      <>
        <p className="font-weight-normal">
          Harav Chaim Kohn is the founder of the Mechon L’Choshen
          Mishpat/Business Halacha Institute, Dean of Bais Hora’ah Eitz Chaim
          and the Av Bais Din of its affiliated Bais Din. Rav Kohn authored
          Hilchos Mishpat, a comprehensive commentary on Choshen Mishpat. His
          halachic guidance is frequently sought throughout the world.
        </p>
      </>
    ),
  },
  {
    id: 2,
    img: encodeURI(
      'https://s3.wasabisys.com/businesshalacha/other/media/Harav Zez Kohn.jpg',
    ),
    name: 'Rabbi Zev Cohen',
    position: '-',
    text: (
      <>
        <p className="font-weight-normal">
          Rabbi Zev Cohen is Rav of Congregation Adas Yeshurun of Chicago and is
          a renowned lecturer on a wide range of topics for over a quarter of a
          century. He serves as the Rosh Kollel for the Choshen Mishpat Kollel
          of Chicago.
        </p>
      </>
    ),
  },
  {
    id: 3,
    img: encodeURI(
      'https://s3.wasabisys.com/businesshalacha/other/media/rabbi_schoen.png',
    ),
    name: 'Rabbi Dovid Schoen',
    position: '-',
    text: (
      <>
        <p className="font-weight-normal">
          Rabbi Dovid Schoen is Rosh HaKollel of The Choshen Mishpat Kollel of
          Miami, Dean of The Florida Center for Jewish Ethics, and Av Bais Din
          for The Bais Din of Florida. He frequently lectures to a wide range of
          audiences in South Florida and around the world, including forums for
          lawyers, business owners, doctors and university students.
        </p>
      </>
    ),
  },
];

export const branches = [
  {
    id: 'brooklyn',
    title: 'Brooklyn',
    employees: [
      {
        id: 'chaim_kohn',
        name: 'Rabbi Kohn, Rosh Av Beis Din',
      },
      {
        id: 'shlomo_fuchs',
        name: 'Rav Shlomo Fuchs',
      },
      {
        id: 'shaul_neuman',
        name: 'Rav Shaul Neuman',
      },
      {
        id: 'yisroel_rubinfeld',
        name: 'Rav Yisroel Rubinfeld',
      },
      {
        id: 'ben-tzion_meisels',
        name: 'Rav Ben-Tzion Meisels',
      },
      {
        id: 'moshe_y_bochner',
        name: 'Rav Moshe Y. Bochner',
      },
      {
        id: 'yitzchok_meir_pesach',
        name: 'Rav Yitzchok Meir Pesach',
      },
      {
        id: 'chaim_m_meisels',
        name: 'Rav Chaim M. Meisels',
      },
      {
        id: 'laibish_lemel',
        name: 'Rav Laibish Lemel',
      },
      {
        id: 'yochanan_scherman',
        name: 'Rabbi Yochanan Scherman, Administrator',
      },
    ],
    contact: {
      phoneNum: '7182333845x201',
      phoneDisplay: '718-233-3845 ext. 201',
      email: 'beisdin@businesshalacha.com',
    },
  },
  {
    id: 'chicago',
    title: 'Chicago',
    employees: [
      {
        id: 'zev_cohen',
        name: 'Rabbi Zev Cohen, Av Beis Din',
      },
      {
        id: 'yosef_wainkrantz',
        name: 'Rabbi Yosef Wainkrantz',
      },
      {
        id: 'yerachmiel_pickholtz',
        name: 'Rabbi Yerachmiel Pickholtz',
      },
      {
        id: 'gershon_schaffel',
        name: 'Rabbi Gershon Schaffel',
      },
      {
        id: 'shmuel_cohen',
        name: 'Rabbi Shmuel Cohen, Administrator',
      },
    ],
    contact: {
      phoneNum: '7738979160',
      phoneDisplay: '773-897-9160',
      email: 'scohenchmk@gmail.com',
    },
  },
  {
    id: 'florida',
    title: 'Florida',
    employees: [
      {
        id: 'dovid_schoen',
        name: 'Rav Dovid Schoen, Av Beis Din',
      },
      {
        id: 'yaacov_wincelberg',
        name: 'Rabbi Yaacov Wincelberg',
      },
      {
        id: 'yisroel_moshe_janowski',
        name: 'Rabbi Yisroel Moshe Janowski',
      },
      {
        id: 'yisrael_weberman',
        name: 'Rabbi Yisrael Weberman',
      },
      {
        id: 'zecharia_zweig',
        name: 'Rabbi Zecharia Zweig, Administrator',
      },
    ],
    contact: {
      phoneNum: '3059853461',
      phoneDisplay: '305-985-3461',
      email: 'thebaisdinofflorida@gmail.com',
    },
  },
];

export const sections = [
  {
    section: 'mission',
    label: 'MISSION STATEMENT',
  },
  {
    section: 'services',
    label: 'SERVICES',
  },
  {
    section: 'profiles',
    label: 'ROSHEI BEIS DIN',
  },
  /*{
    section: 'case-studies',
    label: 'CASE STUDIES',
  },*/
  {
    section: 'branches',
    label: 'BRANCHES',
  },
];

export const getSectionLabel = section =>
  (find(sections, { section }) || {}).label;
