import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { library } from '@fortawesome/fontawesome-svg-core';
import { addLocationQuery } from './helpers/routeParams';

import { faSortAlt } from '@fortawesome/pro-duotone-svg-icons';

import api from './api';
import configureStore from './redux/store';

import Routes from './routes';

const history = createBrowserHistory();

addLocationQuery(history);

history.listen(() => addLocationQuery(history));

library.add(faSortAlt);

const store = configureStore(api, history);

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </Provider>
    );
  }
}
