import React, { Component } from 'react';
import ReactDatetime from 'react-datetime';
import moment from 'moment';

export default class DatePicker extends Component {
  render() {
    const { onChange, value } = this.props;
    return (
      <ReactDatetime
        inputProps={{
          placeholder: 'Select send email date and time here',
        }}
        value={moment(value)}
        onChange={onChange}
        timeFormat="h A"
      />
    );
  }
}
