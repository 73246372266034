import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { listUsers, showUser } from '../../../redux/users/actions';
import { loading, list } from '../../../redux/users/selectors';
import DashboardPage from './DashboardPage';

const selector = createStructuredSelector({
  loading,
  list,
});

const actions = {
  listUsers,
  showUser,
};

export { DashboardPage };

export default connect(
  selector,
  actions,
)(DashboardPage);
