import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DocumentItem from './DocumentItem';

import { lang, defaultLang } from '../../redux/lang/selectors';

const selector = createStructuredSelector({
  selectedLang: lang,
  defaultLang,
});

export default connect(selector)(DocumentItem);
