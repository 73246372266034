import React from 'react';
import { Field } from 'redux-form';

import { Row, Col, Button } from 'reactstrap';
import FieldFormGroup from '../FieldFormGroup';
import Input from '../Input/Input';
import InputRedux from '../Input/InputRedux';

class FormFieldItem extends React.Component {
  render() {
    const {
      item: { id, icon, name, label, type, noWrap, ...rest },
      saving,
      isAdmin,
      lang,
      onEdit,
      onRemove,
      inActive,
    } = this.props;

    if (isAdmin) {
      if (type === 'select' || type === 'options') {
        rest.options = rest.options.options.slice();
      }
      return (
        <Row>
          <Col>
            <FieldFormGroup
              icon={icon}
              label={label}
              lang={lang}
              wrap={
                type !== 'select' &&
                type !== 'quill' &&
                type !== 'checkbox' &&
                type !== 'file' &&
                type !== 'options'
              }
            >
              <Input
                name={name}
                type={type}
                disabled={saving || inActive}
                {...rest}
              />
            </FieldFormGroup>
          </Col>
          <Col>
            <label>&nbsp;</label>
            <div>
              <Button
                color="primary"
                onClick={onEdit}
                className="h2 btn-icon-only mb-0"
                disabled={inActive}
              >
                <i className="ni ni-settings-gear-65" />
              </Button>
              <Button
                color="danger"
                onClick={e => {
                  e.preventDefault();
                  onRemove(id);
                }}
                className="btn-icon-only mb-0"
                disabled={inActive}
              >
                <i className="ni ni-fat-remove" />
              </Button>
            </div>
          </Col>
        </Row>
      );
    }
    return (
      <FieldFormGroup icon={icon} label={label} lang={lang} wrap={!noWrap}>
        <Field
          name={name}
          type={type}
          disabled={saving}
          component={InputRedux}
          {...rest}
        />
      </FieldFormGroup>
    );
  }
}

export default FormFieldItem;
