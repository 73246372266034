import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import InputRedux from '../../components/Input/InputRedux';
import {
  Form,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import classnames from 'classnames';

export default class LoginForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };
  state = {};

  renderRmemberMe() {
    return (
      <div className="custom-control custom-control-alternative custom-checkbox">
        <input
          className="custom-control-input"
          id="customCheckLogin"
          type="checkbox"
        />
        <label className="custom-control-label" htmlFor="customCheckLogin">
          <span className="text-muted">Remember me</span>
        </label>
      </div>
    );
  }

  render() {
    const { loading } = this.props;
    return (
      <Form onSubmit={this.props.handleSubmit} role="form">
        <FormGroup
          className={classnames('mb-3', {
            focused: this.state.focusedEmail,
          })}
        >
          <InputGroup className="input-group-merge input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-email-83" />
              </InputGroupText>
            </InputGroupAddon>
            <Field
              name="email"
              type="email"
              placeholder="Email"
              disabled={loading}
              onFocus={() => this.setState({ focusedEmail: true })}
              onBlur={() => this.setState({ focusedEmail: false })}
              component={InputRedux}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup
          className={classnames({
            focused: this.state.focusedPassword,
          })}
        >
          <InputGroup className="input-group-merge input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-lock-circle-open" />
              </InputGroupText>
            </InputGroupAddon>
            <Field
              name="password"
              type="password"
              placeholder="Password"
              disabled={loading}
              onFocus={() => this.setState({ focusedPassword: true })}
              onBlur={() => this.setState({ focusedPassword: false })}
              component={InputRedux}
            />
          </InputGroup>
        </FormGroup>
        <div className="text-center">
          <Button
            className="my-4"
            color="info"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <i className="text-white fas fa-spinner fa-pulse" />
            ) : (
              'Sign In'
            )}
          </Button>
        </div>
      </Form>
    );
  }
}
