import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Table,
  Card,
  CardBody,
  Button,
  Badge,
} from 'reactstrap';
import UserInfo from '../../../components/UserInfo';

import styles from './AdminUsersPage.module.scss';

class AdminUsersPage extends Component {
  componentWillMount() {
    this.props.listUsers();
  }

  renderRole = role => (
    <span>
      <Badge color={role === 'USER' ? 'info' : 'danger'}>{role}</Badge>
    </span>
  );

  renderStatus = status => (
    <span>
      <Badge color={status === 'ACTIVE' ? 'success' : 'warning'}>
        {status}
      </Badge>
    </span>
  );

  renderActions = id => (
    <span>
      <Button size="small" onClick={() => this.props.showUser(id)}>
        INFO
      </Button>
    </span>
  );

  render() {
    const { loading, list } = this.props;
    return (
      <div className={styles.table}>
        <UserInfo />
        <Container fluid className="py-2 border-bottom">
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="display-2 mb-0">Admin Users</h2>
            </Col>
            <Col className="text-right">
              <Button color="primary" className="btn btn-icon">
                <span className="btn-inner--icon">
                  <i className="fas fa-plus" />
                </span>
                <span className="btn-inner--text">New user</span>
              </Button>
            </Col>
          </Row>
        </Container>
        <Container className="py-4">
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Table responsive className={loading ? 'loading' : ''}>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Email</th>
                        <th scope="col">Role</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map(user => (
                        <tr key={user.uid}>
                          <td>{user.email}</td>
                          <td>{this.renderRole(user.role)}</td>
                          <td>{this.renderStatus(user.status)}</td>
                          <td>{this.renderActions(user.uid)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AdminUsersPage;
