import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.RECENT_REQUEST:
      case actions.LIST_REQUEST:
      case actions.LIST_ADMIN_REQUEST:
      case actions.GET_ITEM_REQUEST:
      case actions.ITEM_ADMIN_REQUEST:
        return true;
      case actions.RECENT_SUCCESS:
      case actions.RECENT_FAILURE:
      case actions.LIST_SUCCESS:
      case actions.LIST_FAILURE:
      case actions.GET_ITEM_SUCCESS:
      case actions.GET_ITEM_FAILURE:
      case actions.LIST_ADMIN_FAILURE:
      case actions.LIST_ADMIN_SUCCESS:
      case actions.ITEM_ADMIN_FAILURE:
      case actions.ITEM_ADMIN_SUCCESS:
        return false;
      default:
        return state;
    }
  },
  recent(state = { publications: [] }, action) {
    switch (action.type) {
      case actions.RECENT_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  list(state = [], action) {
    switch (action.type) {
      case actions.LIST_SUCCESS:
        return action.data.list;
      default:
        return state;
    }
  },
  listCount(state = 0, action) {
    switch (action.type) {
      case actions.LIST_SUCCESS:
        return action.data.count;
      default:
        return state;
    }
  },
  item(state = {}, action) {
    switch (action.type) {
      case actions.GET_ITEM_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  saving(state = false, action) {
    switch (action.type) {
      case actions.ADD_REQUEST:
      case actions.UPDATE_REQUEST:
      case actions.SAVE_FILE_REQUEST:
        return true;
      case actions.ADD_FAILURE:
      case actions.ADD_SUCCESS:
      case actions.UPDATE_FAILURE:
      case actions.UPDATE_SUCCESS:
      case actions.SAVE_FILE_FAILURE:
      case actions.SAVE_FILE_SUCCESS:
        return false;
      default:
        return state;
    }
  },
  listAdmin(state = [], action) {
    switch (action.type) {
      case actions.LIST_ADMIN_SUCCESS:
        return action.data.list;
      default:
        return state;
    }
  },
  listAdminCount(state = 0, action) {
    switch (action.type) {
      case actions.LIST_ADMIN_SUCCESS:
        return action.data.count;
      default:
        return state;
    }
  },
  itemAdmin(state = {}, action) {
    switch (action.type) {
      case actions.ITEM_ADMIN_SUCCESS:
        return action.data;
      case actions.ITEM_ADMIN_REQUEST:
      case actions.REMOVE_SUCCESS:
      case actions.ADD_SUCCESS:
        return {};
      default:
        return state;
    }
  },
  fileToSave(state = [], action) {
    switch (action.type) {
      case actions.SET_FILE:
        return action.data;
      case actions.SAVE_FILE_SUCCESS:
        return [];
      default:
        return state;
    }
  },
  hebcal(state = { items: [] }, action) {
    switch (action.type) {
      case actions.HEBCAL_SUCCESS:
        return action.data;
      case actions.HEBCAL_RESET:
        return { items: [] };
      default:
        return state;
    }
  },
  hebcalLoading(state = false, action) {
    switch (action.type) {
      case actions.HEBCAL_REQUEST:
        return true;
      case actions.HEBCAL_SUCCESS:
      case actions.HEBCAL_FAILURE:
        return false;
      default:
        return state;
    }
  },
});
