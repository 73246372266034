export const prefix = 'submission';

export const LIST_ADMIN_REQUEST = `${prefix}/LIST_ADMIN_REQUEST`;
export const LIST_ADMIN_SUCCESS = `${prefix}/LIST_ADMIN_SUCCESS`;
export const LIST_ADMIN_FAILURE = `${prefix}/LIST_ADMIN_FAILURE`;

export const getListAdmin = query => ({
  types: [LIST_ADMIN_REQUEST, LIST_ADMIN_SUCCESS, LIST_ADMIN_FAILURE],
  promise: api => api.submission.listAdmin(query),
});

export const ITEM_ADMIN_REQUEST = `${prefix}/ITEM_ADMIN_REQUEST`;
export const ITEM_ADMIN_SUCCESS = `${prefix}/ITEM_ADMIN_SUCCESS`;
export const ITEM_ADMIN_FAILURE = `${prefix}/ITEM_ADMIN_FAILURE`;

export const getItemAdmin = id => ({
  types: [ITEM_ADMIN_REQUEST, ITEM_ADMIN_SUCCESS, ITEM_ADMIN_FAILURE],
  promise: api => api.submission.itemAdmin(id),
});

export const ADD_REQUEST = `${prefix}/ADD_REQUEST`;
export const ADD_SUCCESS = `${prefix}/ADD_SUCCESS`;
export const ADD_FAILURE = `${prefix}/ADD_FAILURE`;

export const add = submission => ({
  types: [ADD_REQUEST, ADD_SUCCESS, ADD_FAILURE],
  promise: api => api.submission.add(submission),
});

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`;

export const update = submission => ({
  types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: api => api.submission.update(submission),
});

export const CHANGE_STATUS_REQUEST = `${prefix}/CHANGE_STATUS_REQUEST`;
export const CHANGE_STATUS_SUCCESS = `${prefix}/CHANGE_STATUS_SUCCESS`;
export const CHANGE_STATUS_FAILURE = `${prefix}/CHANGE_STATUS_FAILURE`;

export const changeStatus = (id, status) => ({
  types: [CHANGE_STATUS_REQUEST, CHANGE_STATUS_SUCCESS, CHANGE_STATUS_FAILURE],
  promise: api => api.submission.update({ id, status }),
});

export const REMOVE_REQUEST = `${prefix}/REMOVE_REQUEST`;
export const REMOVE_SUCCESS = `${prefix}/REMOVE_SUCCESS`;
export const REMOVE_FAILURE = `${prefix}/REMOVE_FAILURE`;

export const remove = id => ({
  types: [REMOVE_REQUEST, REMOVE_SUCCESS, REMOVE_FAILURE],
  promise: api => api.submission.remove(id),
});
