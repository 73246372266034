export const ERROR_MESSAGES = {
  EMAIL: {
    NOT_UNIQUE: 'This email already exists',
    NOT_FOUND: 'Invalid email',
  },
  CONFIRMPASSWORD: {
    FIELDS_NOT_EQUAL: 'Passwords are different',
  },
  'DATA/PASSWORD': {
    REQUIRED: 'Password is required',
  },
  'DATA/EMAIL': {
    REQUIRED: 'Email is required',
    WRONG_EMAIL: 'Email is invalid',
  },
  LANG: {
    CANNOT_BE_EMPTY: 'Choose at least one option',
  },
};

export const ERROR_MESSAGE_CODES = {
  NOT_UNIQUE: 'Field should be unique',
  NOT_FOUND: 'Invalid data',
  AUTHENTICATION_FAILED: 'Login error',
  NOT_ACTIVE_USER: 'Forbidden',
  FORMAT_ERROR: 'Invalid input data',
  CANNOT_BE_EMPTY: 'Choose at least one option',
};
