import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from '../../../utils';
import { createStructuredSelector } from 'reselect';
import { list, defaultLang } from '../../../redux/lang/selectors';
import { saving } from '../../../redux/banner/selectors';

import BannerEditForm from './BannerEditForm';

const selector = createStructuredSelector({
  langList: list,
  defaultLang,
  saving,
});

const reduxConnect = connect(
  selector,
  null,
);

const formConnect = reduxForm({
  form: 'banner',
});

export default compose(
  reduxConnect,
  formConnect,
)(BannerEditForm);
