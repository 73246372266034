import React, { Component } from 'react';

import ArticleItem from '../../components/ArticleItem';
import Paginator from '../../components/Paginator';
import LocalizedLink from '../../components/LocalizedLink';
import { Container, Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import { TRANSLATIONS } from '../../constants/translations';
import { getLabelFromLang } from '../../utils';
import TableFilter from '../../components/TableFilter';
import withTableFilter from '../../HOC/withTableFilter';
import { articleTypeOptions } from '../../constants/article';
import Loader from '../../components/Loader';

const FILTER_SHAPE = {
  type: {
    label: 'Type',
    type: 'react-select',
    initialValue: '',
    options: articleTypeOptions,
  },
  search: {
    label: 'Narrow by word',
    placeholder: 'Any',
    type: 'text',
    initialValue: '',
  },
};

class ArticlesPage extends Component {
  componentDidMount() {
    const { lang, getList } = this.props;
    getList({ lang });
  }

  getList = ({ page = 1 }) => {
    const { lang, filter, getList } = this.props;
    getList({ page, lang, filter });
  };

  render() {
    const {
      list,
      loading,
      allCount,
      lang,
      filter,
      onFilterChange,
    } = this.props;

    return (
      <>
        {loading && <Loader type="fixed" />}
        <section className="bg-gradient-info py-5">
          <Container>
            <Breadcrumb listClassName="breadcrumb-dark breadcrumb-links pb-2">
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.HOME}>
                  {getLabelFromLang(LINK_LABELS.HOME, lang)}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.MATERIALS}>
                  {getLabelFromLang(LINK_LABELS.MATERIALS, lang)}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem aria-current="page" className="active">
                {getLabelFromLang(LINK_LABELS.ARTICLES, lang)}
              </BreadcrumbItem>
            </Breadcrumb>
            <Row className="align-items-stretch">
              <Col lg={4} xs={12}>
                <h1 className="display-2 text-white mb-0">
                  {getLabelFromLang(LINK_LABELS.ARTICLES, lang)}
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-6">
          <Container className="no-overflow pb-5">
            <TableFilter
              filterValues={filter}
              filterShape={FILTER_SHAPE}
              onChange={onFilterChange}
              onSearch={this.getList}
            />
          </Container>

          <Container className="pb-6">
            <Row>
              {!loading && list && !list.length && (
                <div className="h2">
                  {getLabelFromLang(TRANSLATIONS.NO_CONTENT_MSG, lang)}
                </div>
              )}
              {list &&
                list.map(item => (
                  <Col key={`article-item-${item.id}`} lg={4} md={6} xs={12}>
                    <ArticleItem item={item} />
                  </Col>
                ))}
            </Row>
            <Paginator
              getItems={this.getList}
              amount={allCount}
              pageSize={12}
            />
          </Container>
        </section>
      </>
    );
  }
}

export default withTableFilter({
  shape: FILTER_SHAPE,
})(ArticlesPage);
