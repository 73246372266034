import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formValueSelector, reduxForm } from 'redux-form';

import { compose } from '../../utils';
import { defaultLang } from '../../redux/lang/selectors';
import { saving } from '../../redux/users/selectors';
import { loading } from '../../redux/customEmail/selectors';

import { alert } from '../../redux/notify/actions';

import CustomEmailForm from './CustomEmailForm';

const formConect = reduxForm({
  form: 'customEmail',
  enableReinitialize: true,
});

const customEmailValues = formValueSelector('customEmail');

const reduxConect = connect(
  createStructuredSelector({
    sending: saving,
    defaultLang,
    isSponsor: state => customEmailValues(state, 'sponsor'),
    isFooter: state => customEmailValues(state, 'footer'),
    selectedLang: state => customEmailValues(state, 'lang'),
    emailDate: state => customEmailValues(state, 'emailDate'),
    filesToSend: state => customEmailValues(state, 'filesToSend'),
    loading,
    sendObj: state =>
      customEmailValues(
        state,
        'ceId',
        'subject',
        'headerTitle',
        'date',
        'hDate',
        'sponsor',
        'sponsorText',
        'sponsorButton',
        'sponsorLink',
        'headline',
        'title',
        'author',
        'text',
        'lang',
        'ctaButton',
        'ctaLink',
        'footer',
        'footerTitle',
        'footerText',
        'mailingList',
        'mailingName',
        'emailDate',
        'filesToSend',
      ),
  }),
  { alert },
);

export default compose(
  reduxConect,
  formConect,
)(CustomEmailForm);
