import React from 'react';

import LocalizedLink from '../LocalizedLink';

import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { ROUTES } from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import { FILE_URL } from '../../constants/files';
import { statuses } from '../../constants/document';
import { tableTime } from '../../utils';

const BusinessWeeklyItem = withRouter(props => {
  const {
    item,
    isAdmin,
    selectedLang,
    defaultLang,
    onRemove,
    index,
    history,
    lang,
  } = props;

  const langData = item.lang[selectedLang] || {};
  const defaultLangData = item.lang[defaultLang] || {};
  const adminLangData = item.lang[lang] || {};

  const curDate = new Date(item.publishedAt).toDateString();

  const userSide = (
    <Card className="bg-default">
      <Row noGutters className="align-items-center justify-content-between">
        <Col xs={2} className="p-4 mb-4 mb-sm-0 text-center d-none d-sm-block">
          {item.files[selectedLang] && item.files[selectedLang].url && (
            <a
              href={`${FILE_URL}${item.files[selectedLang] &&
                item.files[selectedLang].url}`}
              target="_blank"
              rel="noopener noreferrer"
              className="display-4 btn-inner--icon text-white mb-0 pt-1"
            >
              <i className="ni ni-single-copy-04" />
            </a>
          )}
        </Col>
        <Col xs={12} sm={10} className="rounded-right bg-white p-0">
          <CardBody>
            <Row>
              <Col
                sm={8}
                xl={8}
                lg={8}
                className="mb-3 mb-sm-0 text-center text-sm-left"
              >
                <LocalizedLink
                  to={`${ROUTES.BUSINESS_WEEKLY_ITEM_PARAM}${item.id}`}
                >
                  <h3 className="h3 lh-120">
                    {langData.title || defaultLangData.title}
                  </h3>
                </LocalizedLink>
                <p className="text-muted h5 lh-120">
                  {langData.number && <span># {langData.number}</span>}
                </p>
                <p className="text-muted h6 lh-120">
                  {item.author && <span>{item.author} | </span>}
                  <span>{curDate}</span>
                </p>
              </Col>
            </Row>
          </CardBody>
        </Col>
      </Row>
    </Card>
  );

  const adminSide = (
    <tr
      onClick={e => {
        e.preventDefault();
        history.push(`${ROUTES.ADMIN_BUSINESS_WEEKLY_ITEM_PARAM}${item.id}`);
      }}
      style={{ cursor: 'pointer' }}
    >
      <th scope="row">{index}</th>
      <td># {adminLangData.number || 'N/A'}</td>
      <th className="truncatedText">
        <span>{item.mainTitle || 'N/A'}</span>
      </th>
      <td>
        <span
          className={
            item.status === statuses.PUBLISHED ? 'text-success' : 'text-warning'
          }
        >
          {item.status}
        </span>
      </td>
      <td>{tableTime(item.publishedAt)}</td>
      <td className="text-center">
        <Button
          className="btn btn-icon btn-danger btn-sm"
          type="button"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Delete"
          onClick={e => {
            e.stopPropagation();
            onRemove(item.id);
          }}
        >
          <span className="btn-inner--icon">
            <i className="ni ni-fat-remove" />
          </span>
        </Button>
      </td>
    </tr>
  );

  return isAdmin ? adminSide : userSide;
});

export default BusinessWeeklyItem;
