import React from 'react';

import BannerItem from './BannerItem';
import BannerControl from './BannerControl';
import BannerEditModal from './BannerEditModal';
import BannerOrderPanel from './BannerOrderPanel';
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  Button,
} from 'reactstrap';

import classes from './BannerBuilder.module.scss';

const slide = {
  link: '/',
  active: false,
  order: 0,
};

const langKeys = {
  img: '',
  caption: '',
  title: '',
  description: '',
  btnLabel: '',
};

class BannerBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.animating = false;
    this.state = {
      activeIndex: 0,
      lang: props.defaultLang,
      banners: props.banners.slice(),
      modalIsOpen: false,
      orderIsShown: false,
      bannerEdit: {},
      needUpdate: false,
      needRemove: false,
      fromProps: { defaultLang: '' },
    };

    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);

    this.renderItem = this.renderItem.bind(this);
    this.add = this.add.bind(this);
    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.edit = this.edit.bind(this);
    this.toggleOrderPanel = this.toggleOrderPanel.bind(this);
    this.updateBannersList = this.updateBannersList.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { defaultLang, banners, savedBanner, loading } = nextProps;
    const newState = { fromProps: state.fromProps };
    if (state.fromProps.defaultLang !== defaultLang) {
      newState.lang = defaultLang;
      newState.fromProps.defaultLang = defaultLang;
    }

    if (
      !loading &&
      state.fromProps.loading &&
      state.updatingList &&
      banners &&
      banners.length
    ) {
      newState.banners = banners;
      newState.updatingList = false;
      newState.activeIndex = 0;
      newState.fromProps.banners = banners;
    }

    if (!state.fromProps.banners && banners.length) {
      newState.banners = banners;
      newState.fromProps.banners = banners;
    }

    if (savedBanner && state.needUpdate) {
      const { banners, activeIndex } = state;
      if (!savedBanner.deleted && savedBanner.id) {
        banners[activeIndex] = { ...banners[activeIndex], ...savedBanner };
      }
      newState.banners = banners;
      newState.modalIsOpen = false;
      newState.needUpdate = false;
    }

    if (loading !== state.fromProps.loading) {
      newState.fromProps.loading = loading;
    }

    return newState;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.needUpdate && !this.state.needUpdate) {
      this.props.resetSavedBanner();
      if (this.state.needRemove) {
        this.removeActiveItemFromList();
      }
    }
  }

  toggleModal(open = false) {
    this.setState({ modalIsOpen: open });
  }

  toggleOrderPanel(open = false) {
    if (open) {
      this.props.getListAdmin();
    }
    this.setState({ orderIsShown: open });
  }

  updateBannersList() {
    this.setState({ updatingList: true });
    this.props.getListAdmin();
  }

  add() {
    const { langList } = this.props;
    const { banners } = this.state;
    const newSlide = { ...slide, order: banners.length + 1, lang: {} };
    langList.map(lang => (newSlide.lang[lang.identifier] = { ...langKeys }));
    banners.push(newSlide);
    this.setState({ banners }, () => {
      this.setState({ activeIndex: banners.length - 1 });
    });
  }

  save({ img, ...banner }) {
    const { add, update } = this.props;

    const formData = new FormData();
    formData.append('img', img);
    formData.append('data', JSON.stringify(banner));

    this.setState({ needUpdate: true });
    if (!banner.id) {
      add(formData);
    } else {
      update(formData);
    }
  }

  remove() {
    const yes = window.confirm('Are you sure you want delete this slide?');
    if (!yes) {
      return;
    }
    const { banners, activeIndex } = this.state;
    if (banners[activeIndex].id) {
      this.setState({ needUpdate: true, needRemove: true });
      this.props.remove(banners[activeIndex].id);
    } else {
      this.removeActiveItemFromList();
    }
  }

  edit() {
    const { banners, activeIndex } = this.state;
    const banner = banners[activeIndex];
    this.setState({
      bannerEdit: banner,
    });
    this.toggleModal(true);
  }

  removeActiveItemFromList() {
    const { banners, activeIndex } = this.state;
    let index = activeIndex;
    if (activeIndex + 1 === banners.length) {
      index = activeIndex - 1;
      this.setState({ activeIndex: index }, () => {
        setTimeout(() => {
          banners.splice(activeIndex, 1);
          this.setState({ banners, needRemove: false });
        }, 750);
      });
    } else {
      banners.splice(activeIndex, 1);
      this.setState({ activeIndex: index, banners, needRemove: false });
    }
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const { banners } = this.state;
    let { activeIndex } = this.state;
    this.setState({
      activeIndex: banners.length < ++activeIndex + 1 ? 0 : activeIndex,
    });
  }

  prev(e) {
    if (this.animating) return;
    const { banners } = this.state;
    let { activeIndex } = this.state;
    this.setState({
      activeIndex: 0 > --activeIndex ? banners.length - 1 : activeIndex,
    });
  }

  goToIndex(activeIndex) {
    if (this.animating) return;
    this.setState({ activeIndex });
  }

  renderItem(item, i) {
    const { lang } = this.state;
    const langKeys = item.lang[lang];

    return (
      <CarouselItem
        key={`banner-${item.id || `new-${i}`}`}
        onExiting={this.onExiting}
        onExited={this.onExited}
        className={classes.bannerItem}
      >
        <BannerItem
          id={item.id || `new-${i}`}
          item={item}
          langKeys={langKeys}
          edit={this.edit}
          lang={lang}
        />
      </CarouselItem>
    );
  }

  render() {
    const {
      banners,
      activeIndex,
      modalIsOpen,
      bannerEdit,
      saving,
      orderIsShown,
      lang,
    } = this.state;
    return (
      <div className="header-body position-relative pb-7">
        <Container fluid className="bg-translucent-info mb-4">
          <Row className="py-3 justify-content-end">
            <Col className="text-right">
              <Button
                disabled={!banners.length}
                color="success"
                onClick={() => this.toggleOrderPanel(true)}
              >
                Change order
              </Button>
              <Button color="default" onClick={this.add}>
                Add new
              </Button>
              <Button
                disabled={!banners.length}
                color="secondary"
                onClick={this.edit}
              >
                Edit
              </Button>
              <Button
                disabled={!banners.length}
                color="danger"
                onClick={this.remove}
              >
                Remove
              </Button>
            </Col>
          </Row>
        </Container>
        {banners.length ? (
          <Carousel
            next={this.next}
            previous={this.prev}
            interval={false}
            activeIndex={activeIndex}
            keyboard={false}
          >
            {banners.map(this.renderItem)}
            <BannerControl
              prev={this.prev}
              next={this.next}
              goToIndex={this.goToIndex}
              activeIndex={activeIndex}
              banners={banners}
            />
          </Carousel>
        ) : (
          <Container className="bg-info">
            <Row className="py-9 text-center">
              <Col>
                <Button outline onClick={this.add} color="default" size="lg">
                  Add first slide
                </Button>
              </Col>
            </Row>
          </Container>
        )}

        <BannerEditModal
          banner={bannerEdit}
          isOpen={modalIsOpen}
          save={this.save}
          cancel={this.toggleModal}
          index={activeIndex + 1}
          disabled={saving}
        />

        <BannerOrderPanel
          updateBannersList={this.updateBannersList}
          isOpen={orderIsShown}
          lang={lang}
          close={this.toggleOrderPanel}
        />
      </div>
    );
  }
}

export default BannerBuilder;
