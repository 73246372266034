import React from 'react';
import { Button } from 'reactstrap';

const FormActions = ({ saving, onDelete, isNew, disabled }) => {
  return (
    <>
      {!isNew && (
        <Button
          className="btn-icon"
          disabled={saving || disabled}
          color="danger"
          type="button"
          onClick={onDelete}
        >
          <span className="btn-inner--icon">
            {saving ? (
              <i className="text-white fas fa-spinner fa-pulse" />
            ) : (
              <i className="text-white ni ni-fat-delete" />
            )}
          </span>
          <span className="btn-inner--text">Remove</span>
        </Button>
      )}
      <Button
        className="btn-icon"
        disabled={saving}
        color="success"
        type="submit"
      >
        <span className="btn-inner--icon">
          {saving ? (
            <i className="text-white fas fa-spinner fa-pulse" />
          ) : (
            <i className="text-white ni ni-check-bold" />
          )}
        </span>
        <span className="btn-inner--text">
          {isNew ? 'Save' : 'Save changes'}
        </span>
      </Button>
    </>
  );
};

export default FormActions;
