import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Autosuggest from 'react-autosuggest';

export default class LinkAutoComplete extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
      lang: props.defaultLang,
      fromProps: {
        defaultLang: '',
      },
    };
  }

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.onChange(suggestion.link);
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestions = value => {
    const { links } = this.props;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : links.filter(link => link.label.toLowerCase().indexOf(inputValue) > -1);
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: 'Type to search',
      value,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={this.onSuggestionSelected}
        renderSuggestion={s => (
          <div>
            <div>{s.label}</div>
            <small className="text-muted">{s.link}</small>
          </div>
        )}
        inputProps={inputProps}
      />
    );
  }
}

const getSuggestionValue = suggestion => suggestion.label;
