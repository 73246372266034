import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import {
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';

import { getLabelFromLang } from '../../utils';
import { TRANSLATIONS } from '../../constants/translations';

const DEBOUNCE_TIME = 1000;

class SearchGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.searchedStr || '',
    };

    this.onSearch = _.debounce(e => this.search(e), DEBOUNCE_TIME);
  }

  search() {
    const { onSearch } = this.props;
    const { value } = this.state;

    const searching = value.trim().toLowerCase();
    const inputLength = searching.length;

    if (inputLength && inputLength > 1) onSearch({ searching, reset: true });
  }

  onChange = e => {
    this.setState({
      value: e.target.value,
    });
  };

  onSubmit = e => {
    e.preventDefault();
    this.onSearch.cancel();
    this.search();
  };

  render() {
    const { disabled, lang } = this.props;
    const { value } = this.state;

    return (
      <Form onSubmit={this.onSubmit}>
        <FormGroup className="mb-md-0 mb-4">
          <label className="form-control-label">General search</label>
          <InputGroup className="input-group-alternative input-group-merge">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder={getLabelFromLang(
                disabled
                  ? TRANSLATIONS.DISABLED_SEARCH_PLACEHOLDER
                  : TRANSLATIONS.SEARCH_PLACEHOLDER,
                lang,
              )}
              onChange={this.onChange}
              onKeyUp={this.onSearch}
              disabled={disabled}
              value={value}
            />
          </InputGroup>
        </FormGroup>
      </Form>
    );
  }
}

export default withRouter(SearchGeneral);
