import { takeEvery, put } from 'redux-saga/effects';
import { REMOVE_SUCCESS, getListAdmin } from './actions';

import { ROUTES } from '../../constants/routes';

export function* submissionRemovedSuccess(history) {
  yield takeEvery(REMOVE_SUCCESS, function*() {
    yield put(getListAdmin());
    yield history.push(`${ROUTES.ADMIN_SUBMISSIONS}`);
  });
}
