import React from 'react';
import ReactSelect from 'react-select';

import styles from './styles';

const Select = props => {
  return <ReactSelect styles={styles} {...props} />;
};

export default Select;
