import Base from './base';

export default class Banner extends Base {
  list(query) {
    return this.apiClient.get('banners', query);
  }
  listAdmin(query) {
    return this.apiClient.get('banners/admin', query);
  }
  add(data) {
    return this.apiClient.post('banners/new', data);
  }
  update(data) {
    return this.apiClient.post('banners', data);
  }
  remove(id) {
    return this.apiClient.delete(`banners/${id}`);
  }
  saveOrder(banners) {
    return this.apiClient.post(`banners/order`, { banners });
  }
}
