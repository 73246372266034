import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

import IndexNavbar from '../../components/Navbars/IndexNavbar';
import AuthFooter from '../../components/Footers/MainFooter';
import WelcomeModal from '../../components/WelcomeModal';

import HomePage from '../../pages/HomePage';
import DonatePage from '../../pages/DonatePage';
import ContactPage from '../../pages/ContactPage';
import AboutPage from '../../pages/AboutPage';
import BusinessWeeklyPage from '../../pages/BusinessWeeklyPage';
import MaterialsPage from '../../pages/MaterialsPage';
import AudiosPage from '../../pages/AudiosPage';
import ArticlesPage from '../../pages/ArticlesPage';
import ArticlePage from '../../pages/ArticlePage';
import HalachicGuidancePage from '../../pages/HalachicGuidancePage';
import HotlineFeedbackPage from '../../pages/HotlineFeedbackPage';
import ShulchanAruchPage from '../../pages/ShulchanAruchPage';
import AudioPage from '../../pages/AudioPage';
import DocumentPage from '../../pages/DocumentPage';
import BWArchivePage from '../../pages/BWArchivePage';
import SubscribePage from '../../pages/SubscribePage';
import SearchPage from '../../pages/SearchPage';
import KuntraisimPage from '../../pages/KuntraisimPage';
import ArbitrationPage from '../../pages/ArbitrationPage';
import ServicesPage from '../../pages/ServicesPage';
import ConsultationPage from '../../pages/ConsultationPage';
import DocumentsPage from '../../pages/DocumentsPage';

import { ROUTES } from '../../constants/routes';
import { getPath } from '../../constants/lang';

import { getLangFromPath } from '../../utils';
import './Main.module.scss';
import smartlookClient from 'smartlook-client';

class Main extends React.Component {
  componentWillMount() {
    const {
      setLang,
      getGlobalSettings,
      location: { pathname },
    } = this.props;
    setLang(getLangFromPath(pathname));
    getGlobalSettings();

    if (process.env.REACT_APP_SMARTLOOK_KEY) {
      smartlookClient.init(process.env.REACT_APP_SMARTLOOK_KEY);
    }
  }
  componentDidMount() {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
    }
    if (document.body.classList.contains('g-sidenav-show')) {
      document.body.classList.remove('g-sidenav-show');
    }

    this.hashLinkScroll();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.history.location.pathname !== prevProps.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
      window.analytics.page();
    }
    this.hashLinkScroll();
  }

  hashLinkScroll() {
    const { hash } = window.location;

    if (hash !== '') {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        scroll.scrollTo(element.offsetTop, { duration: 400 });
      }
    }
  }

  render() {
    const {
      location: { pathname },
    } = this.props;

    const lang = getLangFromPath(pathname);

    const readerPages = [
      /business-weekly$/,
      /business-weekly\/archive\/\d$/,
      /documents\/\d$/,
      /audio\/\d$/,
      /articles\/\d$/,
    ];

    const isReaderPage = readerPages.some(o => o.test(pathname));

    return (
      <>
        <IndexNavbar pathname={pathname} />
        <div
          className={`main-content ${isReaderPage ? 'readerPage' : ''}`.trim()}
          ref="mainContent"
          lang={lang}
        >
          <Switch>
            <Route path={getPath(ROUTES.HOME)} component={HomePage} exact />
            <Route path={getPath(ROUTES.DONATE)} component={DonatePage} exact />
            <Route
              path={getPath(ROUTES.CONTACT_US)}
              component={ContactPage}
              exact
            />
            <Route
              path={getPath(ROUTES.ABOUT_US)}
              component={AboutPage}
              exact
            />
            <Route
              path={getPath(ROUTES.SHULCHAN)}
              component={ShulchanAruchPage}
              exact
            />
            <Route
              path={getPath(ROUTES.KUNTRAISIM)}
              component={KuntraisimPage}
              exact
            />
            <Route
              path={getPath(ROUTES.BUSINESS_WEEKLY)}
              component={BusinessWeeklyPage}
              exact
            />
            <Route
              path={getPath(ROUTES.BUSINESS_WEEKLY_ARCHIVE)}
              component={BWArchivePage}
              exact
            />
            <Route
              path={getPath(ROUTES.BUSINESS_WEEKLY_ITEM)}
              component={BusinessWeeklyPage}
              exact
            />
            <Route
              path={getPath(ROUTES.ARBITRATION)}
              component={ArbitrationPage}
              exact
            />
            <Route
              path={getPath(ROUTES.SERVICES)}
              component={ServicesPage}
              exact
            />
            <Route
              path={getPath(ROUTES.CONSULTATION)}
              component={ConsultationPage}
              exact
            />
            <Route
              path={getPath(ROUTES.DOCUMENTS)}
              component={DocumentsPage}
              exact
            />
            <Route
              path={getPath(ROUTES.DOCUMENT)}
              component={DocumentPage}
              exact
            />
            <Route
              path={getPath(ROUTES.MATERIALS)}
              component={MaterialsPage}
              exact
            />
            <Route path={getPath(ROUTES.AUDIO)} component={AudiosPage} exact />
            <Route path={getPath(ROUTES.AUDIO_ITEM)} component={AudioPage} />
            <Route
              path={getPath(ROUTES.ARTICLES)}
              component={ArticlesPage}
              exact
            />
            <Route
              path={getPath(ROUTES.ARTICLE)}
              component={ArticlePage}
              exact
            />
            <Route
              path={getPath(ROUTES.KUNTRAISIM)}
              component={KuntraisimPage}
              exact
            />
            <Route
              path={getPath(ROUTES.HALACHIC_GUIDANCE)}
              component={HalachicGuidancePage}
              exact
            />
            <Route
              path={getPath(ROUTES.HOTLINE_FEEDBACK)}
              component={HotlineFeedbackPage}
              exact
            />
            <Route
              path={getPath(ROUTES.SUBSCRIBE)}
              component={SubscribePage}
              exact
            />
            <Route
              path={getPath(`${ROUTES.SEARCH_PAGE}/:type?`)}
              component={SearchPage}
              exact
            />
          </Switch>
        </div>
        <AuthFooter />

        <WelcomeModal />
      </>
    );
  }
}

export default Main;
