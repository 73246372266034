import { all } from 'redux-saga/effects';

import {
  loginSuccess,
  signupSuccess,
  logoutSuccess,
  profileSuccess,
  profileFailure,
} from './profile/sagas';
import {
  listFailure,
  subscriberAdded,
  subscribersImported,
} from './users/sagas';
import {
  createAudioSuccess,
  updateAudioSuccess,
  audioSaveFileSuccess,
  audioRemovedeSuccess,
} from './audio/sagas';
import {
  createDocumentSuccess,
  updateDocumentSuccess,
  documentSaveFileSuccess,
  documentRemovedSuccess,
} from './document/sagas';
import {
  createArticleSuccess,
  updateArticleSuccess,
  articleSaveFileSuccess,
  articleRemovedSuccess,
} from './article/sagas';
import {
  createBWSuccess,
  updateBWSuccess,
  bwSaveFileSuccess,
  bwRemovedSuccess,
} from './business-weekly/sagas';
import {
  createFormSuccess,
  formRemovedSuccess,
  removeFieldSuccess,
} from './form/sagas';
import { submissionRemovedSuccess } from './submission/sagas';
import { categoryRemovedSuccess, categorySaveSuccess } from './category/sagas';
import {
  categoryTypeRemovedSuccess,
  categoryTypeSaveSuccess,
} from './category-type/sagas';
import {
  materialsRemovedSuccess,
  materialsSaveSuccess,
} from './materials-category-type/sagas';
import {
  customEmailSent,
  customEmailScheduled,
  customEmailRemoved,
  customEmailPrepared,
} from './customEmail/sagas';

import { notify } from './notify/sagas';

export default function* rootSaga(history, api) {
  yield all([
    loginSuccess(history, api),
    logoutSuccess(history, api),
    profileSuccess(history),
    listFailure(history),
    signupSuccess(history),
    profileFailure(history),
    createAudioSuccess(history),
    audioRemovedeSuccess(history),
    updateAudioSuccess(),
    audioSaveFileSuccess(),
    createDocumentSuccess(history),
    documentRemovedSuccess(history),
    updateDocumentSuccess(),
    documentSaveFileSuccess(),
    createArticleSuccess(history),
    articleRemovedSuccess(history),
    updateArticleSuccess(),
    articleSaveFileSuccess(),
    createFormSuccess(history),
    formRemovedSuccess(history),
    removeFieldSuccess(history),
    createBWSuccess(history),
    bwRemovedSuccess(history),
    updateBWSuccess(),
    bwSaveFileSuccess(),
    subscriberAdded(),
    subscribersImported(),
    submissionRemovedSuccess(history),
    categoryTypeRemovedSuccess(),
    categoryTypeSaveSuccess(),
    categoryRemovedSuccess(history),
    categorySaveSuccess(history),
    materialsRemovedSuccess(),
    materialsSaveSuccess(),
    notify(),
    customEmailSent(),
    customEmailScheduled(),
    customEmailRemoved(),
    customEmailPrepared(),
  ]);
}
