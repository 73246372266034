import React, { Component } from 'react';

import handlebars from 'handlebars';
import { htmlTemplate } from './customEmail';

class EmailPreview extends Component {
  getHtml() {
    const {
      values: {
        subject,
        headerTitle,
        date,
        hDate,
        sponsor,
        sponsorText,
        sponsorButton,
        sponsorLink,
        headline,
        title,
        author,
        text,
        lang,
        ctaButton,
        ctaLink,
        footer,
        footerTitle,
        footerText,
      },
    } = this.props;

    // if (!subject) return '<div />';

    const template = handlebars.compile(htmlTemplate);
    const isHE = lang === 'he';
    return template({
      name: subject || '',
      headerTitle: headerTitle || '',
      date: date || '',
      hDate: hDate || '',
      headline: headline || '',
      title: title || '',
      author: author || '',
      text: text || '',
      sponsor: !sponsor,
      sponsorText: sponsorText || '',
      sponsorButton: sponsorButton || '',
      // lang,
      footer: !footer,
      footerTitle: footerTitle || '',
      footerText: footerText || '',
      bwLink: ctaLink || '',
      btnLabel: ctaButton || '',
      sponsorLink: sponsorLink || '',
      logoHBoldImg: require('../../../../assets/images/bw/logo-h-bold.png'),
      logoLImg: require('../../../../assets/images/color-logo.svg'),
      mapImg: require('../../../../assets/images/bw/map.png'),
      direction: isHE ? 'rtl' : 'ltr', //todo move the direction to db
      align: isHE ? 'right' : 'left', //todo move the align to db
    });
  }
  render() {
    return (
      <div className="h-100 overflow-visible">
        <div dangerouslySetInnerHTML={{ __html: this.getHtml() }} />
      </div>
    );
  }
}

export default EmailPreview;
