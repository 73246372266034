import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getListAdmin, getTypes, remove } from '../../../redux/audio/actions';
import {
  loading,
  listAdmin,
  listAdminCount,
} from '../../../redux/audio/selectors';
import { list, defaultLang } from '../../../redux/lang/selectors';
import AudioListPage from './AudioListPage';

const selector = createStructuredSelector({
  loading,
  list: listAdmin,
  allCount: listAdminCount,
  langList: list,
  defaultLang,
});

const actions = {
  getListAdmin,
  getTypes,
  remove,
};

export { AudioListPage };

export default connect(
  selector,
  actions,
)(AudioListPage);
