import { combineReducers } from 'redux';
import * as actions from './actions';
import { FILE_URL } from '../../constants/files';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.LIST_REQUEST:
      case actions.SUBSCRIBERS_REQUEST:
      case actions.SUBSCRIBE_REQUEST:
      case actions.EXPORT_SUBSCRIBERS_REQUEST:
      case actions.IMPORT_SUBSCRIBERS_REQUEST:
        return true;
      case actions.LIST_SUCCESS:
      case actions.LIST_FAILURE:
      case actions.SUBSCRIBERS_SUCCESS:
      case actions.SUBSCRIBERS_FAILURE:
      case actions.SUBSCRIBE_SUCCESS:
      case actions.SUBSCRIBE_FAILURE:
      case actions.EXPORT_SUBSCRIBERS_FAILURE:
      case actions.EXPORT_SUBSCRIBERS_SUCCESS:
      case actions.IMPORT_SUBSCRIBERS_FAILURE:
      case actions.IMPORT_SUBSCRIBERS_SUCCESS:
        return false;
      default:
        return state;
    }
  },
  saving(state = false, action) {
    switch (action.type) {
      case actions.SEND_WEEKLY_REQUEST:
      case actions.SCHEDULE_WEEKLY_REQUEST:
      case actions.ADD_SUBSCRIBER_REQUEST:
        return true;
      case actions.SEND_WEEKLY_FAILURE:
      case actions.SEND_WEEKLY_SUCCESS:
      case actions.SCHEDULE_WEEKLY_FAILURE:
      case actions.SCHEDULE_WEEKLY_SUCCESS:
      case actions.ADD_SUBSCRIBER_FAILURE:
      case actions.ADD_SUBSCRIBER_SUCCESS:
        return false;
      default:
        return state;
    }
  },
  savingSubscriber(state = {}, action) {
    switch (action.type) {
      case actions.SET_SUBSCRIBED_REQUEST: {
        state[action.data.address] = true;
        return state;
      }
      case actions.SET_SUBSCRIBED_FAILURE:
      case actions.SET_SUBSCRIBED_SUCCESS: {
        state[action.data.address] = false;
        return state;
      }
      default:
        return state;
    }
  },
  selected(state = null, action) {
    switch (action.type) {
      case actions.SHOW_SUCCESS:
        return action.id;
      case actions.HIDE:
        return null;
      default:
        return state;
    }
  },
  subscribed(state = false, action) {
    switch (action.type) {
      case actions.SUBSCRIBE_REQUEST:
        return false;
      case actions.SUBSCRIBE_SUCCESS:
        return true;
      default:
        return state;
    }
  },
  subscribedError(state = {}, action) {
    switch (action.type) {
      case actions.SUBSCRIBE_REQUEST:
        return {};
      case actions.SUBSCRIBE_FAILURE:
        return action.error;
      default:
        return state;
    }
  },
  list(state = [], action) {
    switch (action.type) {
      case actions.LIST_SUCCESS:
        return action.data;
      case actions.SHOW_REQUEST:
        return state.map(user =>
          user.id === action.id ? { ...user, loading: true } : user,
        );
      case actions.SHOW_SUCCESS:
        return state.map(user => (user.id === action.id ? action.data : user));
      case actions.SHOW_FAILURE:
        return state.map(user =>
          user.id === action.id ? { ...user, loading: false } : user,
        );
      default:
        return state;
    }
  },
  subscribersList(state = [], action) {
    switch (action.type) {
      case actions.SUBSCRIBERS_SUCCESS:
        return action.data.list;
      case actions.SUBSCRIBERS_REQUEST:
        return [];
      default:
        return state;
    }
  },
  subscribersCount(state = 0, action) {
    switch (action.type) {
      case actions.SUBSCRIBERS_SUCCESS:
        return action.data.count;
      default:
        return state;
    }
  },
  donationLoading(state = false, action) {
    switch (action.type) {
      case actions.DONATE_REQUEST:
        return true;
      case actions.DONATE_SUCCESS:
      case actions.DONATE_FAILURE:
        return false;
      default:
        return state;
    }
  },
  subscribersExport(state = false, action) {
    switch (action.type) {
      case actions.EXPORT_SUBSCRIBERS_SUCCESS:
        return window.open(`${FILE_URL}${action.data.fileKey}`, '_blank');
      default:
        return state;
    }
  },
  contactUsSent(state = false, action) {
    switch (action.type) {
      case actions.CONTACT_US_REQUEST:
        return false;
      case actions.CONTACT_US_SUCCESS:
        return true;
      default:
        return state;
    }
  },
  consultationSent(state = false, action) {
    switch (action.type) {
      case actions.CONSULTATION_REQUEST:
        return false;
      case actions.CONSULTATION_SUCCESS:
        return true;
      default:
        return state;
    }
  },
  sending(state = false, action) {
    switch (action.type) {
      case actions.CONTACT_US_REQUEST:
      case actions.CONSULTATION_REQUEST:
        return true;
      case actions.CONTACT_US_SUCCESS:
      case actions.CONTACT_US_FAILURE:
      case actions.CONSULTATION_SUCCESS:
      case actions.CONSULTATION_FAILURE:
        return false;
      default:
        return state;
    }
  },
});
