import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.LISTS_PAGE_REQUEST:
      case actions.SEND_CUSTOM_EMAIL_REQUEST:
      case actions.SCHEDULE_CUSTOM_EMAIL_REQUEST:
      case actions.GET_CUSTOM_EMAILS_LIST_REQUEST:
      case actions.REMOVE_CUSTOM_EMAIL_REQUEST:
      case actions.GET_CUSTOM_EMAIL_LIST_LIMIT_REQUEST:
      case actions.PREPARE_CUSTOM_EMAIL_REQUEST:
        return true;
      case actions.LISTS_PAGE_SUCCESS:
      case actions.LISTS_PAGE_FAILURE:
      case actions.SEND_CUSTOM_EMAIL_SUCCESS:
      case actions.SEND_CUSTOM_EMAIL_FAILURE:
      case actions.SCHEDULE_CUSTOM_EMAIL_SUCCESS:
      case actions.SCHEDULE_CUSTOM_EMAIL_FAILURE:
      case actions.GET_CUSTOM_EMAILS_LIST_SUCCESS:
      case actions.GET_CUSTOM_EMAILS_LIST_FAILURE:
      case actions.REMOVE_CUSTOM_EMAIL_SUCCESS:
      case actions.REMOVE_CUSTOM_EMAIL_FAILURE:
      case actions.GET_CUSTOM_EMAIL_LIST_LIMIT_SUCCESS:
      case actions.GET_CUSTOM_EMAIL_LIST_LIMIT_FAILURE:
      case actions.PREPARE_CUSTOM_EMAIL_SUCCESS:
      case actions.PREPARE_CUSTOM_EMAIL_FAILURE:
        return false;
      default:
        return state;
    }
  },
  listsPage(state = [], action) {
    switch (action.type) {
      case actions.LISTS_PAGE_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  sendEmailStatus(state = {}, action) {
    switch (action.type) {
      case actions.SEND_CUSTOM_EMAIL_SUCCESS:
      case actions.SEND_CUSTOM_EMAIL_FAILURE:
        return action.data;
      default:
        return state;
    }
  },
  current(state = {}, action) {
    switch (action.type) {
      case actions.SCHEDULE_CUSTOM_EMAIL_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  customEmailsList(state = [], action) {
    switch (action.type) {
      case actions.GET_CUSTOM_EMAILS_LIST_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  listLimit(state = [], action) {
    switch (action.type) {
      case actions.GET_CUSTOM_EMAIL_LIST_LIMIT_SUCCESS:
        return action.data.list;
      default:
        return state;
    }
  },
  listCount(state = 0, action) {
    switch (action.type) {
      case actions.GET_CUSTOM_EMAIL_LIST_LIMIT_SUCCESS:
        return action.data.count;
      default:
        return state;
    }
  },
});
