import React, { Component } from 'react';
import Select from 'react-select';
import Cookies from 'js-cookie';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';
import LanguageTab from '../LanguageTabs';
import CategoryModal from '../../pages/admin/CategoryListPage/components/CategoryModal';

const customSelectStyles = {
  container: provided => ({
    ...provided,
    flexGrow: '1',
  }),
  control: provided => ({
    ...provided,
    border: 'none',
    padding: 5,
  }),
  option: (provided, { data }) => {
    const { value } = data;
    const stylesAddNewTag =
      value === 'addNewTag'
        ? { color: 'grey', fontStyle: 'italic', borderBottom: '1px solid grey' }
        : {};
    return {
      ...provided,
      ...stylesAddNewTag,
    };
  },
};

export default class CategoryField extends Component {
  state = {
    values: {},
    lang: '',
    modalIsOpen: false,
    modalData: {},
  };

  static getDerivedStateFromProps(nextProps, state) {
    const {
      data: { categoryTypeId },
    } = nextProps;
    const lang = Cookies.get(`bhi_tag_lang_${categoryTypeId}`) || 'en';
    const newState = { lang: state.lang };

    if (state.lang !== lang) {
      newState.lang = lang;
    }

    return newState;
  }

  renderCategories = (categoryTypes, categoryTypeId) => {
    const categoryType = categoryTypes.find(item => item.id === categoryTypeId);
    const { values, lang } = this.state;
    const { categories } = this.props;
    const { parentId, label } = categoryType;

    const selectedParentCat = values[parentId];

    let options = [];
    const addNewTag = {
      label: `+ Add new ${categoryType.label} tag...`,
      value: 'addNewTag',
      data: {
        typeId: categoryTypeId,
      },
    };

    if (selectedParentCat) {
      addNewTag.data['parentId'] = selectedParentCat.value;
    }
    if (!selectedParentCat && categoryType.hierarchyLevel === 1) {
      options = categories
        .filter(
          item =>
            item.hierarchyLevel === categoryType.hierarchyLevel &&
            item.typeId === categoryTypeId,
        )
        .map(item => ({
          label: item.title
            ? typeof item.title === 'string'
              ? item.title || ''
              : item.title[lang] ||
                item.title['en'] ||
                item.title['he'] ||
                item.title['fr'] ||
                ''
            : '',
          value: item.id,
          data: item,
        }));
      options.unshift(addNewTag);
    } else if (selectedParentCat) {
      options = categories
        .filter(
          item =>
            item.parentId === selectedParentCat.value &&
            item.typeId === categoryTypeId,
        )
        .map(item => ({
          label: item.title
            ? typeof item.title === 'string'
              ? item.title || ''
              : item.title[lang] ||
                item.title['en'] ||
                item.title['he'] ||
                item.title['fr'] ||
                ''
            : '',
          value: item.id,
          data: item,
        }));
      options.unshift(addNewTag);
    }

    if (!options.length && categoryType.hierarchyLevel !== 1) return null;

    return (
      <div
        key={categoryType.id}
        className={`flex-grow-1 ml-${categoryType.hierarchyLevel}`}
      >
        <h4 className="m-0 mt-2">{label}</h4>
        <Select
          id={categoryType.id}
          className="w-100"
          options={options}
          styles={customSelectStyles}
          value={values[categoryTypeId] ? { ...values[categoryTypeId] } : null}
          onChange={this.handleChange}
        />
        {categoryType.categoryTypes &&
          categoryType.categoryTypes.map(item =>
            this.renderCategories(categoryType.categoryTypes, item.id),
          )}
      </div>
    );
  };

  renderTypes = (categoryTypes, categoryTypeId) => {
    const {
      selectedCategories,
      removeCategory,
      docType = null,
      docTypeLang,
    } = this.props;

    const { lang } = this.state;
    const categoryType = categoryTypes.find(item => item.id === categoryTypeId);
    const { label: typeLabel } = categoryType;

    return (
      <div
        key={categoryType.id}
        className={`ml-${categoryType.hierarchyLevel}`}
      >
        <h4 className="m-0 mt-2">{typeLabel}:</h4>
        <div className="d-flex align-items-start flex-wrap">
          {selectedCategories &&
            selectedCategories
              .filter(item => item.typeId === categoryTypeId)
              .map(item => {
                const label = item.title
                  ? typeof item.title === 'string'
                    ? item.title || ''
                    : item.title[lang] ||
                      item.title['en'] ||
                      item.title['he'] ||
                      item.title['fr'] ||
                      ''
                  : item.lang[lang].label;
                return (
                  <Card
                    key={item.id}
                    className="flex-row align-items-center m-1 p-2"
                  >
                    <span className="h5 m-0">{label}</span>
                    <i
                      className="ni ni-fat-remove ml-2 text-danger pointer"
                      onClick={removeCategory(item.id, docType, docTypeLang)}
                    />
                  </Card>
                );
              })}
        </div>

        {categoryType.categoryTypes &&
          categoryType.categoryTypes.map(item =>
            this.renderTypes(categoryType.categoryTypes, item.id),
          )}
      </div>
    );
  };

  toggleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };

  handleChange = item => {
    const { data, value } = item;
    const { lang } = this.state;

    if (value === 'addNewTag') {
      return this.setState({ modalIsOpen: true, modalData: data });
    }
    const { typeId, hierarchyLevel, id } = data;
    const { getChildrenById } = this.props;
    getChildrenById({ id, lang });

    this.setState(({ values }) => {
      const topValues = Object.entries(values).filter(
        ([key, value]) => value.data.hierarchyLevel <= hierarchyLevel,
      );
      const newValues = topValues.reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

      return {
        values: {
          ...newValues,
          [typeId]: item,
        },
      };
    });
  };

  handleAdd = e => {
    const { values } = this.state;
    const {
      change,
      selectedCategories = [],
      docType,
      docTypeLang,
    } = this.props;
    const valuesList = Object.values(values).map(item => item.data);
    const filteredValues = [...selectedCategories, ...valuesList].reduce(
      (acc, item) => {
        acc[item.id] = item;
        return acc;
      },
      {},
    );

    if (docType) {
      change(
        `${docType}.lang.${docTypeLang}.categories`,
        Object.values(filteredValues),
      );
    } else {
      change(`lang.${docTypeLang}.categories`, Object.values(filteredValues));
    }

    this.setState({
      values: {},
    });
  };

  langChanged = lang => {
    const {
      categoryTypes,
      data: { categoryTypeId },
    } = this.props;
    this.setState({ lang, values: {} }, () => {
      this.renderTypes(categoryTypes, categoryTypeId);
      this.renderCategories(categoryTypes, categoryTypeId);
    });

    Cookies.set(`bhi_tag_lang_${categoryTypeId}`, lang, {
      expires: 365,
    });
  };

  render() {
    const {
      categoryTypes,
      data: { categoryTypeId },
    } = this.props;

    const { lang, modalIsOpen, modalData } = this.state;
    const categoryLang = Cookies.get(`bhi_tag_lang_${categoryTypeId}`);
    if (!categoryTypes.length) return null;

    return (
      <Card className="shadow">
        <CardBody className="bg-secondary">
          <LanguageTab
            onChange={this.langChanged}
            smLinks
            dafalutCatLang={lang}
            isCategory
          />
          <Row>
            <Col>
              {this.renderCategories(categoryTypes, categoryTypeId)}
              <Button
                className="btn-sm btn-success mt-2 ml-1 align-self-start"
                type="button"
                onClick={this.handleAdd}
              >
                Add category
              </Button>
            </Col>
            <Col>{this.renderTypes(categoryTypes, categoryTypeId)}</Col>
          </Row>
        </CardBody>
        <CategoryModal
          isOpened={modalIsOpen}
          defaultLang={categoryLang}
          initialValues={modalData}
          close={this.toggleModal}
        />
      </Card>
    );
  }
}
