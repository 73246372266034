import Base from './base';
import { ANALYTICS_EVENTS } from '../constants/analyticsEvents';

export default class Submission extends Base {
  listAdmin(query) {
    return this.apiClient.get('submission/admin', query);
  }

  itemAdmin(id) {
    return this.apiClient.get(`submission/admin/${id}`);
  }

  add(data) {
    window.analytics.track(ANALYTICS_EVENTS.SUBMISSION, data);
    return this.apiClient.post('submission/new', data);
  }

  update(data) {
    return this.apiClient.post('submission', { data });
  }

  remove(id) {
    return this.apiClient.delete(`submission/${id}`);
  }
}
