import React, { Component } from 'react';

import handlebars from 'handlebars';
import { htmlTemplate } from './EmailTemplate/businessWeekly';

const btnLabels = {
  en: 'Continue Reading',
  he: 'קרא עוד',
  fr: 'Pour plus d’information',
};

class EmailPreview extends Component {
  getHtml() {
    const {
      values: {
        bwId,
        subject,
        subjectCustom,
        date,
        hDate,
        headline,
        title,
        author,
        text,
        lang,
      },
    } = this.props;

    if (!subject) return '<div />';

    const template = handlebars.compile(htmlTemplate);
    const isCustomSubject = subject[lang] === 'other';
    const isHE = lang === 'he';
    return template({
      name: isCustomSubject ? subjectCustom[lang] : subject[lang],
      bwText: isHE ? 'משא ומתן' : 'Business Weekly',
      date: isCustomSubject ? '' : date,
      hDate: isCustomSubject ? '' : hDate,
      headline: headline ? headline[lang] : '',
      title: title ? title[lang] : '',
      author: author ? author[lang] : '',
      text: text ? text[lang] : '',
      lang,
      bwLink: `/${lang}/business-weekly/archive/${bwId}`,
      btnLabel: btnLabels[lang] || btnLabels.en,
      // sponsorLink: `/en/donate`,
      sponsorLink: 'https://donate.businesshalacha.com/donate',
      sponsorSite: 'http://dmtnyc.com/index.html?ref=businesshalacha.com',
      logoHBoldImg: require('../../../../assets/images/bw/logo-h-bold.png'),
      logoLImg: require('../../../../assets/images/color-logo.svg'),
      mapImg: require('../../../../assets/images/bw/map.png'),
      sponsorImg: require('../../../../assets/images/sponsor/dmt-plumbing.png'),
      direction: isHE ? 'rtl' : 'ltr', //todo move the direction to db
      align: isHE ? 'right' : 'left', //todo move the align to db
    });
  }
  render() {
    return (
      <div className="h-100 overflow-visible">
        <div dangerouslySetInnerHTML={{ __html: this.getHtml() }} />
      </div>
    );
  }
}

export default EmailPreview;
