import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import { reduxForm, formValueSelector } from 'redux-form';

import { compose } from '../../utils';
import { getParentsByType } from '../../redux/category/actions';
import {
  saving,
  listAdmin as categoriesList,
} from '../../redux/category/selectors';
import { list, defaultLang } from '../../redux/lang/selectors';

import CategoryForm from './CategoryForm';
import { listAdmin as categoryTypes } from '../../redux/category-type/selectors';

const formConnect = reduxForm({
  form: 'category',
  enableReinitialize: true,
});

const values = formValueSelector('category');

const reduxConnect = connect(
  createStructuredSelector({
    saving,
    langList: list,
    defaultLang,
    categoryTypes,
    categoryOptions: createSelector(
      categoriesList,
      defaultLang,
      categories => {
        const options = categories.map(c => ({
          label: c.title,
          value: c.id,
        }));
        return [{ label: 'None', value: '' }, ...options];
      },
    ),
    langValues: state => values(state, 'lang'),
  }),
  { getParentsByType },
);

export default compose(
  reduxConnect,
  formConnect,
)(CategoryForm);
