import React from 'react';
import PDFViewer from 'mgr-pdf-viewer-react';
import { Button } from 'reactstrap';

export default class PdfViewer extends React.Component {
  renderPDFArrow({ page, pages, cb }, isNext = false) {
    return (
      <Button
        className="btn-icon btn-sm m-0"
        color="default"
        type="button"
        onClick={cb}
      >
        <span className="btn-inner--icon">
          <i className={`ni ni-bold-${isNext ? 'right' : 'left'}`} />
        </span>
      </Button>
    );
  }

  render() {
    const { url, scale = 1, classes = '', newTabLink = false } = this.props;

    if (!url) return null;

    return (
      <div className="position-relative">
        {newTabLink && (
          <a
            href={url}
            className="btn btn-default btn-sm position-absolute top-3 left-0"
            target="_blank"
            rel="noreferrer noopener"
          >
            Open pdf in new tab
          </a>
        )}

        <PDFViewer
          document={{ url }}
          scale={scale}
          css={`text-left w-100 ${classes}`}
          navigation={{
            css: {
              wrapper: 'bg-transparent py-3 h3',
              pages: 'd-inline px-4',
            },
            elements: {
              previousPageBtn: ({ handlePrevClick, ...rest }) =>
                this.renderPDFArrow({ cb: handlePrevClick, ...rest }),
              nextPageBtn: ({ handleNextClick, ...rest }) =>
                this.renderPDFArrow({ cb: handleNextClick, ...rest }, true),
            },
          }}
        />
      </div>
    );
  }
}
