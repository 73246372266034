import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getListAdmin,
  remove,
  getItemAdmin,
  getChildrenById,
} from '../../../../../redux/category/actions';
import {
  loading,
  listAdmin,
  categoryModalOpened,
  itemAdmin,
  childrenById,
  loadingChildren,
} from '../../../../../redux/category/selectors';
import {
  listAdmin as categoryTypes,
  loading as loadingTypes,
} from '../../../../../redux/category-type/selectors';
import { getListAdmin as getTypeListAdmin } from '../../../../../redux/category-type/actions';
import { list, defaultLang } from '../../../../../redux/lang/selectors';
import CategoryList from './CategoryList';

const selector = createStructuredSelector({
  loading,
  loadingTypes,
  loadingChildren,
  categoryModalOpened,
  list: listAdmin,
  categoryTypes,
  langList: list,
  defaultLang,
  itemAdmin,
  childrenById,
});

const actions = {
  getListAdmin,
  remove,
  getItemAdmin,
  getChildrenById,
  getTypeListAdmin,
};

export default connect(
  selector,
  actions,
)(CategoryList);
