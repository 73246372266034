import React from 'react';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import _ from 'lodash';
import mammoth from 'mammoth';

import classes from './Editor.module.scss';

const Embed = Quill.import('blots/block/embed');
const Block = Quill.import('blots/block');
const EmbedBlot = Quill.import('blots/embed');

const reCount = ({ array, currentDataId, hasText }) => {
  const sortedArr = _.sortBy(array, o => o.getAttribute('data-id'));
  const hasDuplicate = sortedArr.find(
    o => o.getAttribute('data-id') === currentDataId,
  );

  !hasDuplicate &&
    sortedArr.forEach((item, i) => {
      const currentPosition = parseInt(item.getAttribute('data-id'));
      const count = currentPosition - 1;

      if (currentPosition > parseInt(currentDataId)) {
        item.setAttribute('data-id', count);
        if (hasText) {
          item.setAttribute('id', `footnoteBottom-${count}`);

          const currentHtml = item.innerHTML;
          item.innerHTML =
            currentHtml && currentHtml.length > 0
              ? currentHtml.replace(/\d{1,}. /, `${count}. `, 1)
              : `${count}. `;
        } else {
          const currentId = item.getAttribute('id');
          const newId = currentId.replace(/-\d{1,}-/, `-${count}-`);
          item.setAttribute('id', newId);
          item.setAttribute('data-ref', `#footnoteBottom-${count}`);
          item.innerHTML = count;
        }
      }
    });
};

// LOGIC FOR RECALCULATING OF FOOTNOTES
// const sortFootnotes = (footnotesArr, currentDataId) => {
//   const updatedFootnotes = footnotesArr.map((item, i) => {
//     // item.setAttribute('data-id', i + 1);
//     // const currentId = item.getAttribute('id');
//     // const newId = currentId.replace(/-\d{1,}-/, `-${i + 1}-`);
//     // item.setAttribute('id', newId);
//     // item.setAttribute('data-ref', `#footnoteBottom-${i + 1}`);
//     item.innerHTML = i + 1;
//     return item;
//   });

//   return updatedFootnotes;
// };

// const sortFtn = (footnotesArr, currentDataId) => {
//   const updatedFtn = footnotesArr.map((item, i) => {
//     const currentHtml = item.innerHTML;

//     if (currentHtml === '<br>') {
//       // item.setAttribute('data-id', i - 1);
//       // item.setAttribute('id', `footnoteBottom-${i - 1}`);
//     } else {
//       // item.setAttribute('data-id', i + 1);
//       // item.setAttribute('id', `footnoteBottom-${i + 1}`);

//       item.innerHTML =
//         currentHtml && currentHtml.length > 0
//           ? currentHtml.replace(/\d{1,}. /, `${i + 1}. `, 1)
//           : `${i + 1}. `;
//     }
//     return item;
//   });

//   return updatedFtn;
// };

class Hr extends Embed {}
Hr.blotName = 'hr';
Hr.className = 'my-hr';
Hr.tagName = 'hr';

class LinkBlot extends EmbedBlot {
  static create(data) {
    const { id, count, dataRef, dataId, dataType } = data;

    const node = super.create();
    node.setAttribute('id', id || `footnote-${count || 1}-${dataType}`);
    node.setAttribute('data-id', dataId || count || 1);
    node.setAttribute('data-ref', dataRef || `#footnoteBottom-${count || 1}`);
    // node.setAttribute('target', '_self');
    node.innerHTML = `${dataId || count || 1}`;

    return node;
  }

  static value(node) {
    return {
      id: node.getAttribute('id'),
      dataRef: node.getAttribute('data-ref'),
      dataId: node.getAttribute('data-id'),
    };
  }
  static formats(node) {
    return {
      id: node.getAttribute('id'),
      dataRef: node.getAttribute('data-ref'),
      dataId: node.getAttribute('data-id'),
    };
  }

  detach() {
    const currentDataId = this.domNode.getAttribute('data-id');

    const highFootnotes = Array.from(
      this.scroll.domNode.querySelectorAll('.footnote'),
    );
    const dependentBlot = Array.from(
      this.scroll.domNode.querySelectorAll(`[data-id="${currentDataId}"]`),
    );
    const line = this.scroll.domNode.querySelector('.my-hr');
    dependentBlot.length === 1 && dependentBlot[0].remove();
    if (highFootnotes.length === 0 && line) {
      line.remove();
    }
    const lowFootnotes = Array.from(
      this.scroll.domNode.querySelectorAll('.ftn'),
    );
    reCount({ array: highFootnotes, currentDataId });
    reCount({ array: lowFootnotes, currentDataId, hasText: true });

    super.detach();
  }
}
LinkBlot.blotName = 'fnLink';
LinkBlot.tagName = 'span';
LinkBlot.className = 'footnote';

class FootnoteBlot extends Block {
  static create(data) {
    const { id, count, dataId } = data;
    const node = super.create();

    node.setAttribute('id', `footnoteBottom-${count || 1}`);
    node.setAttribute('data-id', dataId || count || 1);

    if (id.split('')[0] === '1') {
      node.setAttribute('dir', 'rtl');
      node.setAttribute('align', 'right');
    }

    return node;
  }

  static formats(node) {
    return {
      id: node.getAttribute('id'),
      dataId: node.getAttribute('data-id'),
    };
  }
}
FootnoteBlot.blotName = 'ftn';
FootnoteBlot.tagName = 'p';
FootnoteBlot.className = 'ftn';

Quill.register(FootnoteBlot);
Quill.register(LinkBlot);
Quill.register({
  'formats/hr': Hr,
});

const CustomButton = () => <i className="ni ni-single-copy-04" />;

function insertFootnote(quillEditor, props) {
  const editor = quillEditor.getEditor();
  const length = editor.getLength();
  const index = editor.getSelection().index;

  if (!length || index === 0) return;
  const currentHtml = quillEditor.getEditorContents();

  const div = document.createElement('div');
  div.innerHTML = currentHtml;
  const hasLine = div.getElementsByTagName('hr').length;
  const footnotesArr = Array.from(div.getElementsByClassName('footnote'));
  const uniqFootnotesArr = _.uniqBy(footnotesArr, o =>
    o.getAttribute('data-id'),
  );
  const footnotesLength = uniqFootnotesArr.length;
  const numberLength =
    typeof footnotesLength === 'number' && footnotesLength.toString().length;
  const nextNumber = footnotesLength + 1;
  const nextNumberLength = nextNumber.toString().length;

  editor.insertEmbed(
    index,
    'fnLink',
    { count: footnotesLength + 1 || 1, dataType: props.id },
    'user',
  );

  let updlength = editor.getLength();

  if (footnotesLength === 0 && hasLine === 0) {
    editor.insertEmbed(updlength, 'hr', 'null', 'user');
  }

  updlength = editor.getLength();

  // if (footnotesLength === 0) {
  editor.insertEmbed(
    updlength,
    'ftn',
    { count: footnotesLength + 1, id: props.id },
    'user',
  );
  // } else {
  //   editor.insertEmbed(updlength - 4, 'ftn', { count: footnotesLength + 1 }, 'user');
  // }

  // const updatedfootnotesArr = Array.from(
  //   document.getElementsByClassName('footnote'),
  // );

  // const updatedFtnArr = Array.from(document.getElementsByClassName('ftn'));

  // const updatedFootnotes = sortFootnotes(
  //   updatedfootnotesArr,
  //   footnotesLength + 1,
  // );

  // const updatedFtn = updatedFtnArr.reverse();

  // updatedfootnotesArr.forEach((el, idx) => {
  //   el.parentNode.replaceChild(updatedFootnotes[idx], el);
  // });

  // updatedFtnArr.forEach((el, idx) => {
  //   el.parentNode.replaceChild(updatedFtn[idx], el);
  // });

  quillEditor.focus();

  editor.setSelection(
    index + (nextNumberLength > numberLength ? nextNumberLength : numberLength),
  );
}

const CustomToolbar = ({ id }) => (
  <div id={'toolbar' + id}>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-blockquote" />
      <button className="ql-code" />
      <button className="ql-image" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="ql-formats">
      <button className="ql-direction" value="rtl" />
      <button className="ql-align" value="center" />
      <button className="ql-align" value="right" />
      <button className="ql-align" value="justify" />
    </span>

    <button className="ql-insertFootnote">
      <CustomButton />
    </button>
  </div>
);

class Editor extends React.Component {
  constructor(props) {
    super(props);
    this.reactQuillRef = null;
    //* input onChange performance *//
    this.handleChange = _.debounce(this.handleChange, 500);
    this.handleUploadfFile = this.handleUploadfFile.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    //* render performance *//
    return nextProps.value !== this.props.value;
  }

  modules = {
    toolbar: {
      container: '#toolbar' + this.props.id,
      handlers: {
        insertFootnote: () => insertFootnote(this.reactQuillRef, this.props),
      },
    },
    keyboard: {
      bindings: {
        footnotesBottom: {
          key: 'backspace',
          format: ['ftn'],
          handler: (range, keycontext) => {
            const editor = this.reactQuillRef.getEditor();
            const format = editor.getFormat(range.index - 1);

            if (
              (!format.ftn && !keycontext.format.ftn) ||
              (format.ftn && !!keycontext.prefix)
            ) {
              // propogate to Quill's default
              return true;
            } // else do nothing to prevent deleting video
          },
        },
      },
    },
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  handleChange = newValue => {
    const { onChange } = this.props;
    onChange(newValue);
  };

  handleUploadfFile(e, props) {
    const editor = this.reactQuillRef.getEditor();

    const options = {
      styleMap: ['u => u'],
    };

    mammoth
      .convertToHtml({ arrayBuffer: e.target.files[0].arrayBuffer() }, options)
      .then(res => {
        const footnotesArr = [];
        const ftnRefsIdx = [];

        function getFootnotes(match, offset, input) {
          const footnote = match.match(/p>.*?<a/gi)[0];
          footnotesArr.push(footnote.slice(3, footnote.length - 3));
          return '';
        }

        function getFtnRefs(match) {
          ftnRefsIdx.push(match);

          const fnLink = LinkBlot.create({
            count: ftnRefsIdx.length || 1,
            dataType: props.id,
          });

          return fnLink.outerHTML;
        }

        const html = res.value.replace(
          /<ol>|<\/ol>|<li id="footnote-[0-9]+">|<\/li>/g,
          '',
        );

        const filterFootnotes = html.replace(
          /<p>(?:| <a id="[\s\S]*"><\/a>)[^<>;]+<a href="#footnote-ref-[0-9]+">↑<\/a><\/p>/gi,
          getFootnotes,
        );

        const finalHtml = filterFootnotes.replace(
          /<sup><a href="#footnote-[0-9]+" id="footnote-ref-[0-9]+">\[[0-9]+\]<\/a><\/sup>/g,
          getFtnRefs,
        );

        const delta = editor.clipboard.convert(finalHtml);

        if (props.id.split('')[0] === '1') {
          delta.ops.forEach(el => {
            el.attributes = {
              ...el.attributes,
              align: 'right',
            };
          });
        }

        editor.setContents(delta, 'silent');

        if (props.id.split('')[0] === '1') {
          editor.setSelection(editor.getLength());
          editor.format('align', 'right');
        }

        if (footnotesArr.length > 0) {
          let updlength = editor.getLength();
          editor.insertEmbed(updlength, 'hr', 'null', 'user');

          footnotesArr.forEach((el, idx) => {
            updlength = editor.getLength();
            editor.insertEmbed(
              updlength,
              'ftn',
              { count: idx + 1, id: props.id },
              'user',
            );
            editor.insertText(updlength, el, 'user');
          });
        }
      })
      .catch(err => {
        throw err;
      });
  }

  render() {
    const { value, rest } = this.props;
    return (
      <div className={classes.textEditor}>
        <input
          type="file"
          onChange={e => this.handleUploadfFile(e, this.props)}
        />

        <CustomToolbar id={this.props.id} />
        <div className={classes.editorScroll}>
          <ReactQuill
            value={value}
            {...rest}
            onChange={(newValue, delta, source) => {
              if (source === 'user') {
                this.handleChange(newValue);
              }
            }}
            modules={this.modules}
            formats={Editor.formats}
            ref={el => {
              this.reactQuillRef = el;
            }}
          />
        </div>
      </div>
    );
  }
}

Editor.formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'code',
  'direction',
  'hr',
  'ftn',
  'fnLink',
  'align',
];

export default Editor;
