export const prefix = 'lang';

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`;
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`;
export const LIST_FAILURE = `${prefix}/LIST_FAILURE`;

export const getList = () => ({
  types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: api => api.lang.list(),
});

export const LIST_ADMIN_REQUEST = `${prefix}/LIST_ADMIN_REQUEST`;
export const LIST_ADMIN_SUCCESS = `${prefix}/LIST_ADMIN_SUCCESS`;
export const LIST_ADMIN_FAILURE = `${prefix}/LIST_ADMIN_FAILURE`;

export const getListAdmin = () => ({
  types: [LIST_ADMIN_REQUEST, LIST_ADMIN_SUCCESS, LIST_ADMIN_FAILURE],
  promise: api => api.lang.listAdmin(),
});

export const LIST_SUBSCRIBE_REQUEST = `${prefix}/LIST_SUBSCRIBE_REQUEST`;
export const LIST_SUBSCRIBE_SUCCESS = `${prefix}/LIST_SUBSCRIBE_SUCCESS`;
export const LIST_SUBSCRIBE_FAILURE = `${prefix}/LIST_SUBSCRIBE_FAILURE`;

export const getListSubscribe = () => ({
  types: [
    LIST_SUBSCRIBE_REQUEST,
    LIST_SUBSCRIBE_SUCCESS,
    LIST_SUBSCRIBE_FAILURE,
  ],
  promise: api => api.lang.listSubscribe(),
});

export const SET = `${prefix}/SET`;

export const set = lang => ({
  type: SET,
  payload: { lang },
});
