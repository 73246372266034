import Base from './base';

export default class Audio extends Base {
  list(query) {
    return this.apiClient.get('audio', query);
  }

  recent() {
    return this.apiClient.get(`audio/recent`);
  }

  get(id) {
    return this.apiClient.get(`audio/${id}`);
  }

  listAdmin(params) {
    return this.apiClient.get('audio/admin', params);
  }

  itemAdmin(id) {
    return this.apiClient.get(`audio/admin/${id}`);
  }

  add(data) {
    return this.apiClient.post('audio/new', { data });
  }

  update(data) {
    return this.apiClient.post('audio', { data });
  }

  remove(id) {
    return this.apiClient.delete(`audio/${id}`);
  }

  getTypes() {
    return this.apiClient.get(`audio/types`);
  }

  saveFile(data) {
    return this.apiClient.post('audio/file/save', data);
  }
}
