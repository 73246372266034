import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import RelationModal from '../RelationModal';

import {
  types,
  typesLabels,
  labels,
  bwTypes,
  subTypes,
  bwTypesMap,
} from '../../../../../constants/document';
import { TRANSLATIONS } from '../../../../../constants/translations';

const materials = [
  {
    type: types.AUDIO,
    label: TRANSLATIONS.AUDIO.EN,
    subTypes: [
      {
        type: subTypes.AUDIO.CHABURA,
        label: subTypes.AUDIO.CHABURA.toLowerCase(),
      },
      {
        type: subTypes.AUDIO.SHIUR,
        label: subTypes.AUDIO.SHIUR.toLowerCase(),
      },
      {
        type: subTypes.AUDIO.SEMINAR,
        label: subTypes.AUDIO.SEMINAR.toLowerCase(),
      },
    ],
  },
  {
    type: types.DOCUMENT,
    label: TRANSLATIONS.DOCUMENTS.EN,
  },
  {
    type: types.ARTICLE,
    label: TRANSLATIONS.ARTICLES.EN,
    subTypes: [
      {
        type: subTypes.ARTICLE.PUBLICATION,
        label: subTypes.ARTICLE.PUBLICATION.toLowerCase(),
      },
      {
        type: subTypes.ARTICLE.ARTICLE,
        label: subTypes.ARTICLE.ARTICLE.toLowerCase(),
      },
    ],
  },
  {
    type: types.BUSINESS_WEEKLY,
    label: typesLabels.BUSINESS_WEEKLY,
    subTypes: [
      {
        type: bwTypes.STORY_LINE,
        label: labels[bwTypesMap.STORY_LINE].EN,
      },
      {
        type: bwTypes.HOTLINE,
        label: labels[bwTypesMap.HOTLINE].EN,
      },
      {
        type: bwTypes.MONEY_MATTERS,
        label: labels[bwTypesMap.MONEY_MATTERS].EN,
      },
    ],
  },
];

class Relations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      relationToEdit: {},
      fromProps: { saving: false },
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.editRelation = this.editRelation.bind(this);
    this.remove = this.remove.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { materialsModalOpened } = nextProps;
    const newState = {
      fromProps: {
        saving: state.fromProps.saving,
        materialsModalOpened: state.fromProps.materialsModalOpened,
      },
    };

    if (materialsModalOpened !== state.fromProps.materialsModalOpened) {
      newState.fromProps.materialsModalOpened = materialsModalOpened;
    }

    return newState;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fromProps: { materialsModalOpened },
    } = this.state;
    if (prevState.fromProps.materialsModalOpened && !materialsModalOpened) {
      this.toggleModal();
    }
  }

  componentDidMount() {
    this.props.getListAdmin();
  }

  toggleModal(open = false) {
    this.setState({ modalIsOpen: open });
    if (!open) {
      this.setState({ relationToEdit: {} });
    }
  }

  remove({ doctype, categoryTypeId }) {
    const yes = window.confirm('Are you sure you want delete this relation?');
    if (!yes) return;
    const { remove } = this.props;
    if (doctype && categoryTypeId) {
      remove({ doctype, categoryTypeId });
    }
  }

  editRelation(relationToEdit) {
    this.toggleModal(true);
    this.setState({ relationToEdit });
  }

  renderRelations(type) {
    const { relations, defaultLang } = this.props;
    const typeRelations = relations.filter(r => r.doctype === type);
    return (
      <Row className="py-3">
        <Col>
          {typeRelations.map(relation => (
            <Card
              key={`relation-${relation.id}`}
              className="d-inline-block mr-3"
            >
              <CardBody className="p-3">
                {relation.categoryType.lang[defaultLang].label}

                <Button
                  className="btn btn-icon btn-secondary btn-sm ml-4"
                  type="button"
                  onClick={() => this.editRelation(relation)}
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-settings-gear-65" />
                  </span>
                </Button>

                <Button
                  className="btn btn-icon btn-danger btn-sm"
                  type="button"
                  onClick={() => this.remove(relation)}
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-fat-remove" />
                  </span>
                </Button>
              </CardBody>
            </Card>
          ))}
        </Col>
      </Row>
    );
  }

  render() {
    const { defaultLang } = this.props;
    const { modalIsOpen, relationToEdit } = this.state;

    return (
      <>
        <Container fluid className="py-4">
          <Row className="mb-4">
            <Col>
              <p className="display-4">Materials Relations</p>
            </Col>
          </Row>
          {materials.map(m => (
            <Row key={m.type} className="border-bottom py-3">
              <Col>
                <h2 className="text-capitalize">
                  {m.label}
                  {!m.subTypes && (
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => this.editRelation({ doctype: m.type })}
                      className="btn btn-icon ml-3"
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-fat-add" />
                      </span>
                      <span className="btn-inner--text">
                        Assign category type
                      </span>
                    </Button>
                  )}
                </h2>
                {m.subTypes &&
                  m.subTypes.map(subType => (
                    <Row key={subType.type} className="pl-4">
                      <Col>
                        <h4 className="text-capitalize">
                          {subType.label}
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() =>
                              this.editRelation({ doctype: subType.type })
                            }
                            className="btn btn-icon ml-3"
                          >
                            <span className="btn-inner--icon">
                              <i className="ni ni-fat-add" />
                            </span>
                            <span className="btn-inner--text">
                              Assign category type
                            </span>
                          </Button>
                        </h4>
                        {this.renderRelations(subType.type)}
                      </Col>
                    </Row>
                  ))}
                {!m.subTypes && this.renderRelations(m.type)}
              </Col>
            </Row>
          ))}
        </Container>

        <RelationModal
          isOpened={modalIsOpen}
          defaultLang={defaultLang}
          initialValues={relationToEdit}
          close={this.toggleModal}
          remove={this.remove}
        />
      </>
    );
  }
}

export default Relations;
