import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import { ROUTES } from '../../../constants/routes';
import EditFormModal from './EditFormModal';
import EditFieldModal from './EditFieldModal';
import FieldItemCard from './FieldItemCard';
import FormFieldItem from '../../../components/FormFieldItem';
import LanguageTabs from '../../../components/LanguageTabs';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { FIELD_TYPES_ARR } from '../../../constants/fieldTypes';

class FormItemPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editFieldModalIsOpen: false,
      editModalIsOpen: false,
      fieldForEdit: {},
      fromProps: {},
      fields: [],
      lang: '',
    };

    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.back = this.back.bind(this);
    this.addField = this.addField.bind(this);
    this.saveField = this.saveField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.openFieldEdit = this.openFieldEdit.bind(this);
    this.langChanged = this.langChanged.bind(this);
    this.renderFieldOption = this.renderFieldOption.bind(this);
    this.moveCard = this.moveCard.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const {
      match: {
        params: { id },
      },
      saving,
      editModalOpened,
      defaultLang,
      formItem,
    } = nextProps;
    const newState = {
      fromProps: {
        saving: state.fromProps.saving,
        id: state.fromProps.id,
        editModalOpened: state.fromProps.editModalOpened,
        defaultLang: state.fromProps.defaultLang,
        formItemId: state.fromProps.formItemId,
      },
    };

    if (id !== state.fromProps.id) {
      newState.fromProps.id = id;
    }

    if (saving !== state.fromProps.saving) {
      newState.fromProps.saving = saving;
    }

    if (editModalOpened !== state.fromProps.editModalOpened) {
      newState.fromProps.editModalOpened = editModalOpened;
    }

    if (defaultLang !== state.fromProps.defaultLang) {
      newState.fromProps.defaultLang = defaultLang;
      newState.lang = defaultLang;
    }

    if (
      (!state.fromProps.formItemId && formItem && formItem.id) ||
      state.fromProps.formItemId !== formItem.id
    ) {
      newState.fields = formItem.fields.filter(f => f.lang === defaultLang);
      newState.fromProps.formItemId = formItem.id;
    }

    return newState;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fromProps: { id, saving, editModalOpened },
    } = this.state;
    if (prevState.fromProps.id !== id) {
      if (id && id !== 'new') {
        this.props.fetch(id);
      }
    }
    if (prevState.fromProps.saving && !saving) {
      this.props.fetch(id);
    }
    if (prevState.fromProps.editModalOpened && !editModalOpened) {
      this.toggleModal('editFieldModalIsOpen', false);
      this.toggleModal('editModalIsOpen', false);
    }
  }

  componentDidMount() {
    const {
      fetch,
      match: {
        params: { id },
      },
    } = this.props;
    if (id && id !== 'new') {
      fetch(id);
    }
  }

  toggleModal(key, open = false) {
    this.setState({ [key]: open });
  }

  langChanged(lang) {
    const {
      formItem: { fields },
    } = this.props;
    this.setState({
      lang,
      fields: fields
        .filter(f => f.lang === lang)
        .sort((f1, f2) => f1.order < f2.order),
    });
  }

  openFieldEdit(fieldForEdit) {
    this.setState({ fieldForEdit: JSON.parse(JSON.stringify(fieldForEdit)) });
    this.toggleModal('editFieldModalIsOpen', true);
  }

  addField({ label, value }) {
    const { fields } = this.state;
    this.setState({
      fieldForEdit: {
        label,
        type: value,
        name: label.toLowerCase().replace(' ', '_'),
        lang: this.props.defaultLang,
        order: fields.length ? fields[fields.length - 1].order + 1 : 1,
      },
    });
    this.toggleModal('editFieldModalIsOpen', true);
  }

  saveField(field) {
    const { saveField, formItem } = this.props;
    saveField({ field, formId: formItem.id });
  }

  removeField(id) {
    const yes = window.confirm('Are you sure you want delete this field?');
    if (!yes) return;
    const { removeField } = this.props;
    if (id) {
      removeField(id);
    }
  }

  save({ id, active, documentId, lang }) {
    const { add, update } = this.props;
    if (id) {
      update({ id, active, documentId, lang });
    } else {
      add({ active, documentId, lang });
    }
  }

  remove() {
    const yes = window.confirm('Are you sure you want delete this item?');
    if (!yes) return;
    const { formItem, remove } = this.props;
    if (formItem.id) {
      remove(formItem.id);
    }
  }

  back() {
    this.props.history.push(ROUTES.ADMIN_FORMS);
  }

  saveOrder() {
    const { fields } = this.state;
    const { saveOrder } = this.props;
    fields.forEach((f, i) => {
      f.order = i + 1;
    });

    saveOrder(fields);
  }

  moveCard(dragIndex, hoverIndex) {
    const { fields } = this.state;
    const dragCard = fields[dragIndex];
    fields.splice(dragIndex, 1);
    fields.splice(hoverIndex, 0, dragCard);
    this.setState({ fields }, () => {
      this.saveOrder();
    });
  }

  getItemForForm() {
    const { formItem } = this.props;

    if (formItem.id) {
      return formItem;
    }
    return {};
  }

  renderFieldOption(field) {
    return (
      <Card key={field.value} className="mb-2 ">
        <CardBody className="py-3">
          <Row className="justify-content-between">
            <Col>
              <p className="h4 mb-0">{field.label}</p>
            </Col>
            <Col className="text-right">
              <Button
                size="sm"
                color="primary"
                onClick={() => this.addField(field)}
              >
                Add
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderDefaultEmailField = () => (
    <Row noGutters className="align-items-center">
      <Col sm="1" className="pl-md-2 pr-md-0 pl-1 pr-1  text-center">
        <i className="far fa-envelope" />
      </Col>
      <Col className="border-left pl-4">
        <FormFieldItem
          isAdmin={true}
          inActive={true}
          item={{
            label: 'Email',
            name: 'email',
            type: 'text',
          }}
        />
      </Col>
    </Row>
  );

  render() {
    const {
      loading,
      saving,
      defaultLang,
      formItem,
      documentList,
      getDocumentList,
    } = this.props;
    const {
      editModalIsOpen,
      editFieldModalIsOpen,
      fieldForEdit,
      fields,
      lang,
    } = this.state;

    return (
      <>
        {(loading || saving) && <Loader type="fixed" />}
        <Container fluid className="py-2 border-bottom">
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="display-2 mb-0">
                {formItem.id && formItem.lang[lang].name}
              </h2>
            </Col>
            <Col className="text-right">
              <Button
                color="primary"
                onClick={() => this.toggleModal('editModalIsOpen', true)}
                className="btn btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-settings-gear-65" />
                </span>
                <span className="btn-inner--text">Edit form info</span>
              </Button>
              <Button
                color="info"
                onClick={this.back}
                className="btn  btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-long-arrow-alt-left" />
                </span>
                <span className="btn-inner--text">Back</span>
              </Button>
            </Col>
          </Row>
        </Container>
        <Container fluid className="pb-5">
          <Row>
            <Col sm={3} className="border-right pt-4">
              {FIELD_TYPES_ARR.map(this.renderFieldOption)}
            </Col>
            <Col className="pt-4">
              <h2 className="mb-4">{lang.toUpperCase()} fields:</h2>
              {this.renderDefaultEmailField()}
              {fields.map((field, i) => (
                <FieldItemCard
                  key={`field-${field.id}`}
                  order={field.order}
                  index={i}
                  moveCard={this.moveCard}
                >
                  <FormFieldItem
                    isAdmin={true}
                    item={field}
                    onEdit={() => this.openFieldEdit(field)}
                    onRemove={this.removeField}
                  />
                </FieldItemCard>
              ))}
            </Col>
            <Col className="pt-4 border-left" sm={1}>
              <LanguageTabs vertical onChange={this.langChanged} />
            </Col>
          </Row>
        </Container>

        <EditFieldModal
          isOpened={editFieldModalIsOpen}
          defaultLang={defaultLang}
          field={fieldForEdit}
          save={this.saveField}
          remove={this.removeField}
          close={() => this.toggleModal('editFieldModalIsOpen')}
        />

        <EditFormModal
          isOpened={editModalIsOpen}
          defaultLang={defaultLang}
          documentList={documentList}
          item={this.getItemForForm()}
          save={this.save}
          remove={this.remove}
          close={() => this.toggleModal('editModalIsOpen')}
          getDocumentList={getDocumentList}
        />
      </>
    );
  }
}

export default DragDropContext(HTML5Backend)(FormItemPage);
