import React from 'react';
import classnames from 'classnames';

import LocalizedLink from '../LocalizedLink';
import AuthorAndDateLabel from '../AuthorAndDateLabel';

import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { ROUTES } from '../../constants/routes';
import { TRANSLATIONS } from '../../constants/translations';
import { withRouter } from 'react-router-dom';
import { FILE_URL } from '../../constants/files';
import { statuses } from '../../constants/document';
import { tableTime } from '../../utils';

class DocumentItem extends React.Component {
  render() {
    const {
      item,
      isAdmin,
      defaultLang,
      lang,
      selectedLang,
      onRemove,
      history,
      index,
    } = this.props;

    const isHE = selectedLang === 'he';
    const title = (lang && item.lang[lang] && item.lang[lang].title) || 'N/A';

    const userSide = (
      <Card className="bg-primary">
        <Row
          noGutters
          className={classnames('justify-content-between align-items-center', {
            'flex-row-reverse': isHE,
          })}
        >
          <Col
            xs={2}
            className="p-4 mb-4 mb-sm-0 text-center d-none d-sm-block"
          >
            <LocalizedLink
              className="display-4 btn-inner--icon text-white mb-0 pt-1"
              to={`${ROUTES.DOCUMENT_PARAM}${item.id}`}
            >
              <i className="ni ni-single-copy-04" />
            </LocalizedLink>
          </Col>
          <Col
            xs={12}
            sm={10}
            className={classnames('bg-white p-0', {
              'rounded-right': !isHE,
              'rounded-left': isHE,
            })}
          >
            <CardBody>
              <Row
                className={classnames({
                  'flex-row-reverse': isHE,
                })}
              >
                <Col
                  sm={8}
                  xl={8}
                  lg={8}
                  className={classnames('mb-3 mb-sm-0 text-center', {
                    'text-sm-left': !isHE,
                    'text-sm-right': isHE,
                  })}
                >
                  <LocalizedLink to={`${ROUTES.DOCUMENT_PARAM}${item.id}`}>
                    <h2 className="h2 lh-120 mb-1">
                      {item.lang[selectedLang]
                        ? item.lang[selectedLang].title
                        : item.lang[defaultLang]
                        ? item.lang[defaultLang].title
                        : TRANSLATIONS.NO_TITLE_DEFAULT[
                            selectedLang.toUpperCase()
                          ]}
                    </h2>
                  </LocalizedLink>

                  <AuthorAndDateLabel
                    selectedLang={selectedLang}
                    item={item}
                    createdAt={item.createdAt}
                  />

                  {isAdmin && (
                    <p className="text-muted lh-120">{item.status}</p>
                  )}
                </Col>
                <Col
                  sm={4}
                  className={classnames(
                    'd-flex align-items-center justify-content-around',
                    {
                      'flex-row-reverse': isHE,
                    },
                  )}
                >
                  <LocalizedLink
                    className="h2 btn-inner--icon text-primary mb-0 pt-1"
                    to={`${ROUTES.DOCUMENT_PARAM}${item.id}`}
                  >
                    <i className="fas fa-eye" />
                  </LocalizedLink>
                  <a
                    href={`${FILE_URL}${item.file.watermarkUrl ||
                      item.file.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h2 btn-inner--icon text-primary mb-0 pt-1"
                  >
                    <i className="ni ni-cloud-download-95" />
                  </a>
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>
      </Card>
    );

    const adminSide = (
      <tr
        onClick={e => {
          e.preventDefault();
          history.push(`${ROUTES.ADMIN_DOCUMENT_ITEM_PARAM}${item.id}`);
        }}
        style={{ cursor: 'pointer' }}
      >
        <th scope="row">{index}</th>
        <th className="truncatedText">
          <span>{title}</span>
        </th>
        <td>{item.type || 'N/A'}</td>
        <td>
          <span
            className={
              item.status === statuses.PUBLISHED
                ? 'text-success'
                : 'text-warning'
            }
          >
            {item.status}
          </span>
        </td>
        <td>{tableTime(item.publishedAt)}</td>
        <td className="text-center">
          <Button
            className="btn btn-icon btn-danger btn-sm"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Delete"
            onClick={e => {
              e.stopPropagation();
              onRemove(item.id);
            }}
          >
            <span className="btn-inner--icon">
              <i className="ni ni-fat-remove" />
            </span>
          </Button>
        </td>
      </tr>
    );

    return isAdmin ? adminSide : userSide;
  }
}

export default withRouter(DocumentItem);
