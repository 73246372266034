import React, { Component } from 'react';

import AdminTable from '../../../components/AdminTable';
import Paginator from '../../../components/Paginator';
import TableFilter from '../../../components/TableFilter';
import { TABLE_PER_PAGE } from '../../../constants/admin';

class AdminDocWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      sort: {},
      selectedLang: '',
      filter: this.getInitialFilter(),
    };

    this.sort = this.sort.bind(this);
    this.page = this.page.bind(this);
    this.remove = this.remove.bind(this);
    this.onLangChange = this.onLangChange.bind(this);
    this.langInit = this.langInit.bind(this);
  }

  langInit() {
    const { defaultLang } = this.props;

    if (defaultLang) {
      this.setState({ selectedLang: defaultLang });
    }
  }

  componentDidMount() {
    this.langInit();
  }

  getInitialFilter() {
    const { filterShape } = this.props;

    if (!filterShape) return {};

    const initialFilter = Object.keys(filterShape).reduce((acc, fieldName) => {
      acc[fieldName] = filterShape[fieldName].initialValue;
      return acc;
    }, {});

    return initialFilter;
  }

  remove(id) {
    const yes = window.confirm('Are you sure you want delete this item?');
    if (!yes) return;
    const { remove } = this.props;
    if (id) {
      remove(id);
    }
  }

  onLangChange(selectedLang) {
    this.setState({ selectedLang }, () => this.getList());
  }

  getList = () => {
    const { page, order, selectedLang, filter } = this.state;
    const { langList } = this.props;
    const langId = langList.find(lang => lang.identifier === selectedLang).id;

    this.props.getListAdmin({ page, order, langId, filter });
  };

  page({ page }) {
    this.setState({ page }, () => this.getList());
  }

  sort(order) {
    this.setState({ order }, () => this.getList());
  }

  onFilterChange = (name, value) => {
    return new Promise(resolve => {
      this.setState(
        state => ({
          filter: {
            ...state.filter,
            [name]: value,
          },
        }),
        () => {
          resolve();
        },
      );
    });
  };

  render() {
    const { filterShape, headerList, rowsList, allCount } = this.props;
    const { selectedLang, filter } = this.state;

    return (
      <>
        {filterShape && (
          <TableFilter
            filterValues={filter}
            filterShape={filterShape}
            onChange={this.onFilterChange}
            onSearch={this.getList}
          />
        )}
        <AdminTable
          headerList={headerList}
          sortFn={this.sort}
          selectedLang={selectedLang}
          onLangChange={this.onLangChange}
          rowsList={rowsList.map(item => ({
            ...item,
            props: { ...item.props, lang: selectedLang, onRemove: this.remove },
          }))}
        />
        <Paginator
          getItems={this.page}
          amount={allCount}
          pageSize={TABLE_PER_PAGE}
        />
      </>
    );
  }
}

export default AdminDocWrapper;
