import Base from './base';

export default class Form extends Base {
  list(query) {
    return this.apiClient.get('form', query);
  }

  get(id) {
    return this.apiClient.get(`form/${id}`);
  }

  listAdmin() {
    return this.apiClient.get('form/admin');
  }

  itemAdmin(id) {
    return this.apiClient.get(`form/admin/${id}`);
  }

  add(data) {
    return this.apiClient.post('form/new', { data });
  }

  update(data) {
    return this.apiClient.post('form', { data });
  }

  remove(id) {
    return this.apiClient.delete(`form/${id}`);
  }

  saveField(data) {
    return this.apiClient.post('form/field', { data });
  }

  removeField(id) {
    return this.apiClient.delete(`form/field/${id}`);
  }

  saveFieldsOrder(fields) {
    return this.apiClient.post(`/form/field/order`, { fields });
  }
}
