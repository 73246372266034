import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  Form,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from 'reactstrap';

import PdfViewer from '../PdfViewer';
import FormActions from '../FormActions';
import FieldFormGroup from '../FieldFormGroup';
import InputRedux from '../../components/Input/InputRedux';
import LanguageTabs from '../LanguageTabs';
import RelatedForm from '../RelatedForm';
import CategoryField from '../CategoryField';
import CategoryCopyButtons from '../CategoryCopyButtons';

import { statuses, types } from '../../constants/document';
import { FILE_URL, FILE_TYPES } from '../../constants/files';

const KEY_FORM_OPTIONS = {
  status: {
    label: 'Status',
    type: 'select',
    icon: 'button-power',
    options: [statuses.DRAFT, statuses.PUBLISHED],
  },
  title: {
    label: 'Name',
    type: 'text',
    icon: 'collection',
  },
  author: {
    label: 'Author',
    type: 'text',
    icon: 'circle-08',
  },
  location: {
    label: 'Location',
    type: 'text',
    icon: 'square-pin',
  },
  content: {
    label: 'Description',
    type: 'textarea',
    icon: 'align-left-2',
    rows: 8,
  },
  file: {
    label: 'Audio',
    type: 'file',
  },
  pdfFile: {
    label: 'PDF',
    type: 'file',
  },
  subType: {
    label: 'Type',
    type: 'select',
    icon: 'single-copy-04',
    options: [],
  },
  categories: {
    type: 'multiSelect',
    icon: 'tag',
    placeholder: 'Select category',
  },
};

export default class AudioForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tabs: props.defaultLang,
      fromProps: {},
    };

    this.langChanged = this.langChanged.bind(this);
    this.relatedChanged = this.relatedChanged.bind(this);
    this.onCategoryCopy = this.onCategoryCopy.bind(this);
  }

  componentDidMount() {
    const {
      getCategoriesRelations,
      getCategoryTypesList,
      getListByDoctype,
    } = this.props;
    getCategoriesRelations();
    getCategoryTypesList();
    getListByDoctype('AUDIO');
  }

  relatedChanged(related) {
    const { change } = this.props;
    change(`related`, related);
  }

  langChanged(lang) {
    this.setState({ tabs: lang });
  }

  onCategoryCopy(fromLang, toLang) {
    const { change, langData } = this.props;

    const categories = langData[fromLang].categories;

    change(`lang.${toLang}.categories`, categories);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { defaultLang, types } = nextProps;
    const newState = { fromProps: state.fromProps };
    if (state.fromProps.defaultLang !== defaultLang) {
      newState.tabs = defaultLang;
      newState.fromProps.defaultLang = defaultLang;
    }
    if (!state.fromProps.types && types.length) {
      newState.fromProps.types = types;
      KEY_FORM_OPTIONS.subType.options = types;
    }

    return newState;
  }

  renderField(key, lang = null) {
    const { saving } = this.props;
    const name = lang ? `lang.${lang}.` + key : key;
    const { icon, type, label, ...rest } = KEY_FORM_OPTIONS[key];
    const isHE = lang === 'he';

    return (
      <FieldFormGroup icon={icon} label={label} lang={lang}>
        <Field
          name={name}
          type={type}
          disabled={saving}
          onFocus={() => this.setState({ [`focused_${key}`]: true })}
          onBlur={() => this.setState({ [`focused_${key}`]: false })}
          component={InputRedux}
          dir={`${isHE ? 'rtl' : 'ltr'}`}
          {...rest}
        />
      </FieldFormGroup>
    );
  }

  removeCategory = (id, docType, docTypeLang) => e => {
    const { change, langData } = this.props;
    const filteredCategories = langData[docTypeLang].categories.filter(
      item => item.id !== id,
    );
    change(`lang.${docTypeLang}.categories`, filteredCategories);
  };

  renderFile() {
    const { file } = this.props;

    if (!file || !file.url) return null;

    if (file.type === FILE_TYPES.AUDIO)
      return (
        <Row className="mt-4">
          <Col>
            <audio id="audio-player" className="w-100" controls>
              <source
                src={`${FILE_URL}${file.url}`}
                type={file.meta.type || 'audio/mp3'}
              />
              <span>Your browser does not support the audio element.</span>
            </audio>
          </Col>
        </Row>
      );

    if (file.type === FILE_TYPES.VIDEO)
      return (
        <Row className="mt-4">
          <Col>
            <video width="320" height="240" controls>
              <source src={`${FILE_URL}${file.url}`} type={file.meta.type} />
              Your browser does not support the video tag.
            </video>
          </Col>
        </Row>
      );
  }

  renderPdf() {
    const { pdf } = this.props;

    if (!pdf || !pdf.url) return null;
    return (
      <PdfViewer
        classes="py-3"
        scale={0.7}
        url={`${FILE_URL}${pdf.url}`}
        newTabLink={true}
      />
    );
  }

  render() {
    const {
      saving,
      handleSubmit,
      onDelete,
      langList,
      isNew,
      relatedValues,
      relatedDocument,
      relatedArticle,
      relatedBW,
      categoriesRelations,
      selectedSubType,
      change,
      langData = [],
    } = this.props;

    const relatedCategories = categoriesRelations.filter(
      item => item.doctype === selectedSubType,
    );

    return (
      <Form onSubmit={handleSubmit} role="form">
        <Row className="justify-content-between py-4 mb-4 border-bottom">
          <Col sm={4}>
            <LanguageTabs onChange={this.langChanged} />
          </Col>
          <Col className="text-right">
            <FormActions saving={saving} isNew={isNew} onDelete={onDelete} />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Card className="shadow mb-6">
              <CardBody className="bg-secondary">
                <TabContent activeTab={'tabs-' + this.state.tabs}>
                  {langList.map(lang => {
                    return (
                      <TabPane
                        key={`tab-${lang.identifier}`}
                        tabId={`tabs-${lang.identifier}`}
                      >
                        <Row>
                          <Col>
                            {this.renderField('title', lang.identifier)}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {this.renderField('author', lang.identifier)}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {this.renderField('content', lang.identifier)}
                          </Col>
                        </Row>
                      </TabPane>
                    );
                  })}
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          <Col sm={6}>
            <Row>
              <Col md={6}>{this.renderField('subType')}</Col>
              <Col md={6}>{this.renderField('status')}</Col>
            </Row>
            <Row>
              <Col>{this.renderField('location')}</Col>
            </Row>
            <RelatedForm
              changeRelated={this.relatedChanged}
              relatedValues={relatedValues}
              relatedDefault={{
                [types.ARTICLE]: relatedArticle,
                [types.DOCUMENT]: relatedDocument,
                [types.BUSINESS_WEEKLY]: relatedBW,
              }}
              relatedFields={[
                types.ARTICLE,
                types.DOCUMENT,
                types.BUSINESS_WEEKLY,
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="form-control-label mt-3">
              {KEY_FORM_OPTIONS.file.label}
            </label>
            <Field
              name="file"
              type={KEY_FORM_OPTIONS.file.type}
              component={InputRedux}
              accept="audio/*,video/*"
              fileid="audio-file-input"
              disabled={saving}
            />
            {this.renderFile()}
          </Col>
          <Col>
            <label className="form-control-label mt-4">
              {KEY_FORM_OPTIONS.pdfFile.label}
            </label>
            <Field
              name="pdf"
              type={KEY_FORM_OPTIONS.pdfFile.type}
              component={InputRedux}
              accept="application/pdf"
              fileid="audio-pdf-file-input"
              disabled={saving}
            />
            {this.renderPdf()}
          </Col>
        </Row>
        {relatedCategories.length > 0 && (
          <Row>
            <Col>
              <Card className="shadow mb-6">
                <CardBody className="bg-secondary">
                  <TabContent activeTab={'tabs-' + this.state.tabs}>
                    {langList.map((lang, i) => {
                      return (
                        <TabPane
                          key={`tab-${lang.identifier}`}
                          tabId={`tabs-${lang.identifier}`}
                        >
                          <Row key={lang.identifier}>
                            <Col>
                              <Card className="shadow">
                                <CardBody className="bg-secondary">
                                  <div className="d-flex">
                                    <h3 className="text-muted mt-2 mr-4">
                                      Categories {lang.identifier.toUpperCase()}
                                    </h3>
                                    <CategoryCopyButtons
                                      langList={langList}
                                      lang={lang.identifier}
                                      onClick={this.onCategoryCopy}
                                    />
                                  </div>
                                  {relatedCategories.length > 0 &&
                                    relatedCategories.map(item => (
                                      <CategoryField
                                        key={item.id}
                                        data={item}
                                        change={change}
                                        docTypeLang={lang.identifier}
                                        selectedCategories={
                                          (langData &&
                                            langData[lang.identifier] &&
                                            langData[lang.identifier]
                                              .categories) ||
                                          []
                                        }
                                        removeCategory={this.removeCategory}
                                      />
                                    ))}
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </TabPane>
                      );
                    })}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Form>
    );
  }
}
