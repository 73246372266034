import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PasswordRecoveryForm from '../../components/PasswordRecoveryForm';
import AuthHeader from '../../components/Headers/AuthHeader';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

export default class PasswordRecoveryPage extends Component {
  static propTypes = {
    isRecoveryRequested: PropTypes.bool.isRequired,
  };

  render() {
    if (this.props.isRecoveryRequested) {
      return (
        <>
          <AuthHeader title="Welcome!" lead="-" />
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <h3>Success, check your email for next step.</h3>
                  </CardBody>
                </Card>
                <Row className="mt-3">
                  <Col xs="6">
                    <Link className="text-light" to={ROUTES.LOGIN}>
                      <small>Back to login</small>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
    return (
      <>
        <AuthHeader title="Welcome!" lead="-" />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Enter email to recover</small>
                  </div>
                  <PasswordRecoveryForm />
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <Link className="text-light" to={ROUTES.LOGIN}>
                    <small>Back to login</small>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
