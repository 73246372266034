import React, { Component } from 'react';
import LocalizedLink from '../../components/LocalizedLink';

import Articles from '../HomePage/components/Articles';
import Audios from '../HomePage/components/Audios';
import Documents from '../HomePage/components/Documents';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';

import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';

export default class MaterialsPage extends Component {
  render() {
    return (
      <>
        <section className="bg-gradient-info py-5">
          <Container>
            <Breadcrumb listClassName="breadcrumb-dark breadcrumb-links pb-2">
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.HOME}>
                  {LINK_LABELS.HOME.EN}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem aria-current="page" className="active">
                {LINK_LABELS.MATERIALS.EN}
              </BreadcrumbItem>
            </Breadcrumb>
            <Row className="align-items-stretch">
              <Col lg={4} xs={12}>
                <h1 className="display-2 text-white mb-0">
                  {LINK_LABELS.MATERIALS.EN}
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pt-6">
          <Container>
            <Articles darkTitle />
          </Container>
        </section>
        <section className="py-6">
          <Container>
            <Row>
              <Col lg={6}>
                <Audios />
              </Col>
              <Col lg={6}>
                <Documents />
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
