import React from 'react';

export const feedback = [
  {
    text: (
      <>
        I had a very positive experience using the Institute for business
        dealings. They provided the exact information and service I was looking
        for. Yasher Ko’ach!
      </>
    ),
    author: 'JW',
  },
  {
    text: (
      <>
        I was very impressed with the quick response to my halachic question.
        Being ’out-of-town‘, it is sometimes more difficult to ask a rav a
        shailah. The hotline is an easy way to get a true informative
        perspective. Yasher Koach to everyone who puts in the time and effort
        for this wonderful service.
      </>
    ),
    author: 'DB',
  },
  {
    text: (
      <>
        The hotline enabled me to clarify my thoughts in order to write a
        precise shaila regarding the ethical situation I found myself in. The
        written response then empowered me to carry out the required action that
        previously I had been struggling with. Thank you.
      </>
    ),
    author: 'DN, Baltimore, MD',
  },
  {
    text: (
      <>
        What a bracha to have this hotline. When I found myself in a situation
        that resulted in a potential chiyuv of thousands of dollars, it was a
        relief knowing that I could get in touch with top rabbonim who are
        experts in Choshen Mishpat to guide me in making the proper decisions.
        Yasher Koach on your great work.
      </>
    ),
    author: 'YP, Los Angeles, California',
  },
  {
    text: (
      <>
        Thank you very much for your response to my She'ila. Your response was
        short, to the point, and easy to understand. Thank you for your service.
      </>
    ),
    author: 'AF',
  },
  {
    text: (
      <>
        I recently purchased shares in a nursing home where very serious
        halachic issues including Shabbos, Yom Tov, Kashrus, and Ribbis had to
        be dealt with. BHI arranged for me to meet with an expert in this area
        who advised me on the different options. I was impressed with the
        professionalism and efficiency of the service, as well as their ability
        to match me with the best Rav to address my issue.
      </>
    ),
    author: 'Ari W.',
  },
  {
    text: (
      <>
        I found the Rabbi to be very personable. The questions were answered
        directly with clear direction. It took only a very small amount of time
        to get warmed up and comfortable.
      </>
    ),
    author: 'Norman K',
  },
  {
    text: (
      <>
        I have found the Business Halacha Institute to be a wonderful resource.
        Baruch Hashem that the frum world is zocheh to have such people among
        them.
      </>
    ),
    author: 'N.S.',
  },
  {
    text: (
      <>
        I contacted the hotline because I needed answers to important shailos.
        Baruch Hashem, I was quickly contacted, and received clear and
        understandable answers.
      </>
    ),
    author: 'T.G.',
  },
  {
    text: (
      <>
        I posed a complicated question regarding responsibility in a car
        accident to Business Halacha. They got back to me promptly with a
        general overview and answer to my question and a recommendation for both
        parties to take the issue to a competent Rov or Bais Din, which they
        offered to arrange. I encourage anyone with monetary disputes to use
        this amazing service.
      </>
    ),
    author: 'D.J.',
  },
  {
    text: (
      <>
        We appreciate the availability of the Halacha Institute. In my business
        experience, we are most often faced with "shades of gray" rather than
        clear "black and white" issues, and the Halacha Institute provides
        business owners with access to proper halacha in a timely manner.
      </>
    ),
    author: 'L.M.S.',
  },
];
