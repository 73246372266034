import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getListAdmin, remove, add } from '../../../redux/form/actions';
import { getListFullAdmin as getDocumentList } from '../../../redux/document/actions';
import { loading, listAdmin } from '../../../redux/form/selectors';
import { list, defaultLang } from '../../../redux/lang/selectors';
import { listFullAdmin as documentList } from '../../../redux/document/selectors';
import FormListPage from './FormListPage';

const selector = createStructuredSelector({
  loading,
  documentList,
  list: listAdmin,
  langList: list,
  defaultLang,
});

const actions = {
  getDocumentList,
  getListAdmin,
  remove,
  add,
};

export default connect(
  selector,
  actions,
)(FormListPage);
