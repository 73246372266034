import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import FormActions from '../FormActions';
import FieldFormGroup from '../FieldFormGroup';
import InputRedux from '../../components/Input/InputRedux';
import InputCustom from '../../components/Input/Input';
import { Form, Row, Col, Button } from 'reactstrap';

import { KEY_FORM_OPTIONS } from './formKeys';

export default class CustomFormFieldForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tabs: props.defaultLang,
      selectOption: '',
      selectedOption: '',
      fromProps: {},
    };
    this.labelOnChange = this.labelOnChange.bind(this);
    this.selectOptionOnChange = this.selectOptionOnChange.bind(this);
    this.selectOptionsOnChange = this.selectOptionsOnChange.bind(this);
    this.addSelectOption = this.addSelectOption.bind(this);
    this.removeSelectOption = this.removeSelectOption.bind(this);
    this.removeOption = this.removeOption.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { defaultLang } = nextProps;
    const newState = { fromProps: state.fromProps };
    if (state.fromProps.defaultLang !== defaultLang) {
      newState.tabs = defaultLang;
      newState.fromProps.defaultLang = defaultLang;
    }

    return newState;
  }

  labelOnChange(e, label) {
    const { change } = this.props;
    change('name', label.toLowerCase().replace(/ |'|"|,|\.|\(|\)|\?/g, '_'));
  }

  selectOptionOnChange(e) {
    this.setState({ selectOption: e.target.value });
  }

  selectOptionsOnChange(e) {
    this.setState({ selectedOption: e.target.value });
  }

  addSelectOption() {
    const { change, fieldOptions = {} } = this.props;
    const { selectOption } = this.state;

    if (!fieldOptions.options) fieldOptions.options = [];
    const newOption = {
      label: selectOption,
      value: selectOption.toLowerCase().replace(/ |'|"|,|\.|\(|\)|\?/g, '_'),
    };
    fieldOptions.options.push(newOption);

    change('options', fieldOptions);
    this.setState({ selectOption: '', selectedOption: newOption.value });
  }

  removeSelectOption() {
    const { change, fieldOptions } = this.props;
    const { selectedOption } = this.state;

    if (fieldOptions.options && fieldOptions.options.length) {
      const index = selectedOption
        ? fieldOptions.options.findIndex(o => o.value === selectedOption)
        : 0;
      fieldOptions.options.splice(index, 1);
    }

    change('options', fieldOptions);
    this.setState({
      selectOption: '',
      selectedOption: fieldOptions.options.length
        ? fieldOptions.options[0].value
        : '',
    });
  }

  removeOption(val) {
    const { change, fieldOptions } = this.props;
    if (fieldOptions.options && fieldOptions.options.length) {
      const index = val
        ? fieldOptions.options.findIndex(o => o.value === val)
        : 0;
      fieldOptions.options.splice(index, 1);
    }

    change('options', fieldOptions);
    this.setState({
      selectOption: '',
    });
  }

  renderField(key, isLang = false) {
    const { saving, langList } = this.props;
    const name = key;
    const { icon, type, label, noWrap, ...rest } = KEY_FORM_OPTIONS[key];
    if (isLang)
      rest.options = langList.map(l => ({
        label: l.label,
        value: l.identifier,
      }));
    if (key === 'label') rest.onChange = this.labelOnChange;
    return (
      <FieldFormGroup icon={icon} label={label} wrap={!noWrap}>
        <Field
          name={name}
          type={type}
          disabled={saving}
          component={InputRedux}
          {...rest}
        />
      </FieldFormGroup>
    );
  }

  renderOptions() {
    const { selectedType } = this.props;

    if (selectedType === 'select') {
      const { fieldOptions = { options: [] } } = this.props;
      const { selectOption, selectedOption } = this.state;

      return (
        <Col>
          <label className="form-control-label">New option</label>
          <div className="d-flex justify-content-between">
            <InputCustom
              value={selectOption}
              name="selectOption"
              type="text"
              onChange={this.selectOptionOnChange}
            />
            <Button color="primary" onClick={this.addSelectOption}>
              Add
            </Button>
          </div>

          <label className="form-control-label">Options</label>
          <div className="d-flex justify-content-between">
            <InputCustom
              value={selectedOption}
              name="selectOptions"
              type="select"
              options={fieldOptions.options}
              onChange={this.selectOptionsOnChange}
            />
            {fieldOptions.options && fieldOptions.options.length ? (
              <Button color="danger" onClick={this.removeSelectOption}>
                Remove
              </Button>
            ) : null}
          </div>
        </Col>
      );
    }

    if (selectedType === 'options') {
      const { fieldOptions = { options: [] } } = this.props;
      const { selectOption } = this.state;

      return (
        <Col>
          <label className="form-control-label">New option</label>
          <div className="d-flex justify-content-between">
            <InputCustom
              value={selectOption}
              name="selectOption"
              type="text"
              onChange={this.selectOptionOnChange}
            />
            <Button color="primary ml-1" onClick={this.addSelectOption}>
              Add
            </Button>
          </div>

          {fieldOptions.options && fieldOptions.options.length ? (
            <label className="form-control-label">Options</label>
          ) : null}
          <div>
            {fieldOptions.options &&
              fieldOptions.options.map(option => {
                let val, label;
                if (typeof option === 'object') {
                  val = option.value;
                  label = option.label;
                } else {
                  val = option;
                  label = option;
                }
                const key = `checkbox-option-${val.toLowerCase()}`;
                return (
                  <div
                    className="d-flex justify-content-between custom-control custom-checkbox my-1"
                    key={key}
                  >
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id={key}
                      name={val}
                    />
                    <label className="custom-control-label" htmlFor={key}>
                      {label}
                    </label>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => this.removeOption(val)}
                    >
                      Remove
                    </Button>
                  </div>
                );
              })}
          </div>
        </Col>
      );
    }

    return null;
  }

  render() {
    const { saving, handleSubmit, onDelete, isNew } = this.props;

    return (
      <Form onSubmit={handleSubmit} role="form">
        <Row className="py-4">
          <Col sm={6}>
            <Row>
              <Col sm={6}>{this.renderField('label')}</Col>
              <Col>{this.renderField('name')}</Col>
              <Col sm={6}>{this.renderField('lang', true)}</Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col sm={12}>{this.renderField('type')}</Col>
              {this.renderOptions()}
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-between pt-4 mb-4 border-top">
          <Col sm={4} />
          <Col className="text-right">
            <FormActions saving={saving} isNew={isNew} onDelete={onDelete} />
          </Col>
        </Row>
      </Form>
    );
  }
}
