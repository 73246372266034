import React, { Component } from 'react';
import {
  CardBody,
  CardTitle,
  Container,
  Col,
  Row,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import classnames from 'classnames';

import LocalizedLink from '../../components/LocalizedLink';
import RelatedDocuments from '../../components/RelatedDocuments';
import RelatedAudio from '../../components/RelatedAudio';
import SubscribeForm from '../../components/SubscribeForm';
import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import { TRANSLATIONS } from '../../constants/translations';
import { FILE_URL } from '../../constants/files';
import { OPTION_SEPARATOR } from '../../constants/fieldTypes';
import ShareBtn from '../../components/ShareBtn';
import { getLabelFromLang, tippyContent } from '../../utils';

export default class ArticlePage extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    this.props.getItem(id);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { id },
        url,
      },
    } = this.props;

    if (url !== prevProps.match.url) {
      this.props.getItem(id);
    }
  }

  renderBreadcrumbs() {
    const { lang: selectedLang, item: { lang } = {}, defaultLang } = this.props;
    const langData = (lang && lang[selectedLang]) || {};
    const defaultLangData = (lang && lang[defaultLang]) || {};
    const breadcrumbItems = [
      <BreadcrumbItem key="home">
        <LocalizedLink to={ROUTES.HOME}>
          {getLabelFromLang(LINK_LABELS.HOME, selectedLang)}
        </LocalizedLink>
      </BreadcrumbItem>,
      <BreadcrumbItem key="materials">
        <LocalizedLink to={ROUTES.MATERIALS}>
          {getLabelFromLang(LINK_LABELS.MATERIALS, selectedLang)}
        </LocalizedLink>
      </BreadcrumbItem>,
      <BreadcrumbItem key="articles">
        <LocalizedLink to={ROUTES.ARTICLES}>
          {getLabelFromLang(LINK_LABELS.ARTICLES, selectedLang)}
        </LocalizedLink>
      </BreadcrumbItem>,
      <BreadcrumbItem key="page" aria-current="page" className="active">
        {langData.title || defaultLangData.title}
      </BreadcrumbItem>,
    ];

    return selectedLang !== 'he' ? breadcrumbItems : breadcrumbItems.reverse();
  }

  renderItem() {
    const {
      lang: selectedLang,
      item: { lang, createdAt },
    } = this.props;

    const authorLabel =
      (lang && lang[selectedLang] && lang[selectedLang].author) ||
      TRANSLATIONS.AUTHOR_DEFAULT[selectedLang.toUpperCase()];

    const isHE = selectedLang === 'he';

    return (
      <CardBody className="pt-lg-0">
        <CardTitle
          className={classnames('h1 mb-0', {
            'text-right': isHE,
          })}
        >
          {lang[selectedLang].title}
        </CardTitle>

        {authorLabel && (
          <div
            className={classnames('h3 text-muted mb-0', {
              'text-right': isHE,
            })}
          >
            {authorLabel}
          </div>
        )}

        {createdAt && (
          <div
            className={classnames('h5 font-weight-normal text-muted', {
              'text-right': isHE,
            })}
          >
            {new Date(createdAt).toDateString()}
          </div>
        )}

        <div
          className={classnames('mt-4 card-text', {
            'text-left': !isHE,
            'text-right': isHE,
          })}
          dangerouslySetInnerHTML={{
            __html: tippyContent(lang[selectedLang].content),
          }}
        />
      </CardBody>
    );
  }

  renderActions() {
    const {
      lang: selectedLang,
      item: { pdf, lang, subType, type },
    } = this.props;

    return (
      <>
        {pdf && pdf.id && (
          <a
            href={`${FILE_URL}${pdf.watermarkUrl || pdf.url}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-block btn-lg btn-outline-primary btn-icon ml-0"
          >
            <span className="btn-inner--icon">
              <i className="ni ni-cloud-download-95" />
            </span>
            <span className="btn-inner--text">
              {getLabelFromLang(TRANSLATIONS.DOWNLOAD, selectedLang)}
            </span>
          </a>
        )}
        <ShareBtn
          contentType={subType || type}
          title={lang[selectedLang].title}
        />
      </>
    );
  }

  renderActionsSection() {
    const { lang: selectedLang } = this.props;

    return (
      <>
        {this.renderActions()}
        <div className="mt-5">
          <SubscribeForm
            initialValues={{
              lang: [selectedLang],
              [`lang${OPTION_SEPARATOR}${selectedLang}`]: true,
            }}
          />
        </div>
      </>
    );
  }

  render() {
    const {
      lang: selectedLang,
      defaultLang,
      showRelated,
      item: { id, lang, createdAt, related },
    } = this.props;
    const langData = (lang && lang[selectedLang]) || {};
    const defaultLangData = (lang && lang[defaultLang]) || {};
    const authorLabel =
      (langData && langData.author) ||
      TRANSLATIONS.AUTHOR_DEFAULT[selectedLang.toUpperCase()];

    const isHE = selectedLang === 'he';

    if (!id)
      return (
        <Container>
          <Row>
            <p className="display-2 pt-2">Article not found</p>
          </Row>
        </Container>
      );

    return (
      <>
        <section className="bg-gradient-info py-5">
          <Container>
            {/* Breadcrumbs */}
            <Breadcrumb
              listClassName={classnames(
                'breadcrumb-dark breadcrumb-links pb-2',
                {
                  'justify-content-start': !isHE,
                  'justify-content-end': isHE,
                },
              )}
            >
              {this.renderBreadcrumbs()}
            </Breadcrumb>
            {/* Title */}
            <Row
              className={classnames('align-items-stretch', {
                'flex-row-reverse': isHE,
              })}
            >
              <Col
                xs={12}
                className={classnames('mb-4 mb-lg-0', {
                  'text-left': !isHE,
                  'text-right': isHE,
                })}
              >
                <h2 className="display-2 mb-0 text-white font-weight-500">
                  {langData.title || defaultLangData.title}
                </h2>
                <p
                  className={classnames('h4 text-white d-flex', {
                    'flex-row-reverse': isHE,
                  })}
                >
                  <span>{authorLabel}</span>
                  <span>&nbsp;|&nbsp;</span>
                  <span>{createdAt && new Date(createdAt).toDateString()}</span>
                </p>
              </Col>
              <Col
                xs={12}
                className={classnames({
                  'text-left': !isHE,
                  'text-right': isHE,
                })}
              >
                <LocalizedLink
                  to={ROUTES.ARTICLES}
                  className="btn btn-outline-white btn-sm mt-4 mb-0"
                >
                  <span className="btn-inner--text mr-1">
                    {getLabelFromLang(TRANSLATIONS.ALL, selectedLang)}{' '}
                    {getLabelFromLang(TRANSLATIONS.ARTICLES, selectedLang)}
                  </span>
                  <span className="btn-inner--icon">
                    <i className="ni ni-bold-right" />
                  </span>
                </LocalizedLink>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Subscribe */}
        <section>
          <Container className="overflow-visible">
            <Row
              className={classnames('mt-5', {
                'flex-row-reverse': isHE,
              })}
            >
              {/* Subscribe section on sm and up */}
              <Col
                lg={4}
                xs={12}
                className="d-none d-sm-block position-sticky top-4 align-self-start"
              >
                {this.renderActionsSection()}
              </Col>
              {/* Actual content */}
              <Col
                lg={8}
                xs={12}
                className="mb-4 mb-sm-5 mb-lg-0 mt-2 mt-md-0"
                dir={`${isHE ? 'rtl' : 'ltr'}`}
              >
                {this.renderItem()}
              </Col>
              {/* Subscribe section on xs */}
              <Col xs={12} className="d-block d-sm-none">
                {this.renderActionsSection()}
              </Col>
            </Row>
          </Container>
        </section>
        {/* Related items */}
        {showRelated && (
          <section className="pt-2 pb-6">
            <Container>
              <hr />
              <Row>
                <Col lg={6}>
                  <RelatedAudio related={related} tags={[]} />
                </Col>
                <Col lg={6}>
                  <RelatedDocuments related={related} tags={[]} />
                </Col>
              </Row>
            </Container>
          </section>
        )}
      </>
    );
  }
}
