import React from 'react';

import { CarouselControl, CarouselIndicators } from 'reactstrap';

import classes from './BannerBuilder.module.scss';

class BannerControl extends React.Component {
  render() {
    const { prev, goToIndex, next, activeIndex, banners } = this.props;
    const current = activeIndex + 1;

    return (
      <div className={`ml-md-7 ${classes.controls}`}>
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={prev}
        />
        <CarouselIndicators
          items={banners}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
        <span className={`h3 text-white ${classes.numberIndicator}`}>
          {current > 9 ? current : `0${current}`}
        </span>
        <span className={`h3 text-white ${classes.numberIndicator}`}>
          {banners.length > 9 ? banners.length : `0${banners.length}`}
        </span>
      </div>
    );
  }
}

export default BannerControl;
