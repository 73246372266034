import { combineReducers } from 'redux';
import * as actions from './actions';
import { types } from '../../constants/document';

export default combineReducers({
  recent(state = [], action) {
    switch (action.type) {
      case actions.RECENT_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  related(state = [], action) {
    switch (action.type) {
      case actions.RELATED_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  list(state = [], action) {
    switch (action.type) {
      case actions.LIST_SUCCESS:
        return action.data.list;
      default:
        return state;
    }
  },
  allCount(state = 0, action) {
    switch (action.type) {
      case actions.LIST_SUCCESS:
        return action.data.count;
      default:
        return state;
    }
  },
  item(state = {}, action) {
    switch (action.type) {
      case actions.GET_ITEM_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  saving(state = false, action) {
    switch (action.type) {
      case actions.ADD_REQUEST:
      case actions.UPDATE_REQUEST:
      case actions.SAVE_FILE_REQUEST:
        return true;
      case actions.ADD_FAILURE:
      case actions.ADD_SUCCESS:
      case actions.UPDATE_FAILURE:
      case actions.UPDATE_SUCCESS:
      case actions.SAVE_FILE_FAILURE:
      case actions.SAVE_FILE_SUCCESS:
        return false;
      default:
        return state;
    }
  },
  listAdmin(state = [], action) {
    switch (action.type) {
      case actions.LIST_ADMIN_SUCCESS:
        return action.data.list;
      default:
        return state;
    }
  },
  listFullAdmin(state = [], action) {
    switch (action.type) {
      case actions.LIST_FULL_ADMIN_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  listAdminCount(state = 0, action) {
    switch (action.type) {
      case actions.LIST_ADMIN_SUCCESS:
        return action.data.count;
      default:
        return state;
    }
  },
  itemAdmin(state = {}, action) {
    switch (action.type) {
      case actions.ITEM_ADMIN_SUCCESS:
        return action.data;
      case actions.ITEM_ADMIN_REQUEST:
      case actions.REMOVE_SUCCESS:
      case actions.ADD_SUCCESS:
        return {};
      default:
        return state;
    }
  },
  fileToSave(state = [], action) {
    switch (action.type) {
      case actions.SET_FILE:
        return action.data;
      case actions.SAVE_FILE_SUCCESS:
        return [];
      default:
        return state;
    }
  },
  materials(state = [], action) {
    switch (action.type) {
      case actions.MATERIALS_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  autocompleteResult(
    state = {
      [types.ARTICLE]: [],
      [types.AUDIO]: [],
      [types.DOCUMENT]: [],
      [types.BUSINESS_WEEKLY]: [],
    },
    action,
  ) {
    switch (action.type) {
      case actions.SEARCH_AUTOCOMPLETE_SUCCESS: {
        const materials = { ...state };
        materials[action.docType] = action.data;
        return materials;
      }
      default:
        return state;
    }
  },
  autocompleteLoading(state = false, action) {
    switch (action.type) {
      case actions.SEARCH_AUTOCOMPLETE_REQUEST:
        return true;
      case actions.SEARCH_AUTOCOMPLETE_FAILURE:
      case actions.SEARCH_AUTOCOMPLETE_SUCCESS:
        return false;
      default:
        return state;
    }
  },
});
