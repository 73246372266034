import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { IMG_URL } from '../../constants/files';
import { Card, CardTitle, CardBody, CardImg, Row, Col } from 'reactstrap';
import PdfViewer from '../PdfViewer';

export default class ReactDropzone extends Component {
  state = {
    imagePreview: null,
  };

  componentDidMount() {
    const {
      input: { value },
    } = this.props;
    if (value) {
      this.setState({
        imagePreview: `${IMG_URL}${value}`,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      input: { value },
    } = this.props;
    if (value !== prevProps.input.value) {
      if (value && typeof value === 'string') {
        this.setState({
          imagePreview: `${IMG_URL}${value}`,
        });
      } else {
        this.setState({
          imagePreview: null,
        });
      }
    }
  }

  handelDrop = acceptedFiles => {
    const {
      input: { onChange },
    } = this.props;
    const file = acceptedFiles[0];
    onChange(file);

    const url = URL.createObjectURL(file);
    this.setState({
      imagePreview: url,
    });
  };

  handleClean = e => {
    e.stopPropagation();
    const {
      input: { onChange },
    } = this.props;
    onChange(null);
  };

  render() {
    const { imagePreview } = this.state;
    const { accept, type } = this.props;
    return (
      <Dropzone
        accept={accept}
        className="dropzone-custom"
        onDrop={this.handelDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <Card className="card-frame">
            <div {...getRootProps()}>
              <CardBody className="p-3">
                <input {...getInputProps()} />
                <Row>
                  <Col sm={10}>
                    <CardTitle className="h4 my-1 text-center pointer">
                      Drop file, or click to select
                    </CardTitle>
                  </Col>
                  <Col sm={2}>
                    <span
                      onClick={this.handleClean}
                      className="h1 btn-inner--icon text-danger"
                    >
                      <i className="ni ni-fat-remove" />
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </div>
            {imagePreview && (
              <Row>
                <Col className="justify-content-center">
                  {type === 'image' && <CardImg src={imagePreview} />}
                  {type === 'pdf' && (
                    <PdfViewer
                      newTabLink={true}
                      scale={0.2}
                      url={imagePreview}
                      classes="text-center"
                    />
                  )}
                </Col>
              </Row>
            )}
          </Card>
        )}
      </Dropzone>
    );
  }
}
