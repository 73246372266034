import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import AudioForm from '../../../components/AudioForm';
import { Container, Row, Col, Button } from 'reactstrap';
import { ROUTES } from '../../../constants/routes';
import { statuses, types } from '../../../constants/document';
import { Link } from 'react-router-dom';

class AudioItemPage extends Component {
  constructor(props) {
    super(props);

    this.state = { fromProps: {} };

    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const {
      match: {
        params: { id },
      },
    } = nextProps;
    const newState = { fromProps: state.fromProps };

    if (id !== state.fromProps.id) {
      newState.fromProps.id = id;
    }

    return newState;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fromProps: { id },
    } = this.state;
    if (prevState.fromProps.id !== id) {
      if (id && id !== 'new') {
        this.props.fetch(id);
      }
    }
  }

  componentDidMount() {
    const {
      fetch,
      getTypes,
      match: {
        params: { id },
      },
    } = this.props;
    getTypes();
    if (id && id !== 'new') {
      fetch(id);
    }
  }

  save({
    id,
    status,
    subType,
    lang,
    location,
    file,
    pdf,
    related,
    categories,
  }) {
    const { add, update, setFile } = this.props;
    const files = [];
    if (file && file.length) {
      file[0].fileKey = 'file';
      files.push(file[0]);
    }
    if (pdf && pdf.length) {
      pdf[0].fileKey = 'pdf';
      files.push(pdf[0]);
    }
    const categoriesIds = categories.map(item => item.id);

    setFile(files);
    if (id) {
      update({
        id,
        status,
        subType,
        lang,
        location,
        related,
        categories: categoriesIds,
      });
    } else {
      add({
        status,
        subType,
        lang,
        location,
        related,
        categories: categoriesIds,
      });
    }
  }

  remove() {
    const yes = window.confirm('Are you sure you want delete this item?');
    if (!yes) return;
    const { audioItem, remove } = this.props;
    if (audioItem.id) {
      remove(audioItem.id);
    }
  }

  cancel() {
    const yes = window.confirm('Are you sure?');
    if (!yes) return;
    this.props.history.push(ROUTES.ADMIN_AUDIO);
  }

  getDefaultValues() {
    const { types } = this.props;
    if (types.length) {
      return {
        status: statuses.DRAFT,
        subType: types[0],
        categories: [],
      };
    }
    return null;
  }

  getItemForForm() {
    const { audioItem } = this.props;

    if (audioItem.id) {
      if (!audioItem.categories) {
        audioItem.categories = [];
      }
      return audioItem;
    }
    return null;
  }

  render() {
    const {
      loading,
      saving,
      audioItem,
      match: {
        params: { id },
      },
    } = this.props;
    const isNew = id === 'new';

    return (
      <>
        {(loading || saving) && <Loader type="fixed" />}
        <Container fluid className="py-2 border-bottom">
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="display-2 mb-0">{isNew ? 'New' : 'Edit'} audio</h2>
            </Col>
            <Col className="text-right">
              <Link
                to={`${ROUTES.ADMIN_AUDIO_ITEM_PARAM}new`}
                className="btn btn-primary btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text">New</span>
              </Link>
              <Button
                onClick={this.cancel}
                className="btn btn-warning btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-remove" />
                </span>
                <span className="btn-inner--text">Cancel</span>
              </Button>
            </Col>
          </Row>
        </Container>
        <Container className="pb-9">
          <Row>
            <Col>
              {isNew || !audioItem || !audioItem.id ? (
                <AudioForm
                  isNew={isNew}
                  onSubmit={this.save}
                  onDelete={this.remove}
                  file={null}
                  pdf={null}
                  initialValues={this.getDefaultValues()}
                />
              ) : (
                <AudioForm
                  isNew={false}
                  onSubmit={this.save}
                  onDelete={this.remove}
                  file={audioItem.file}
                  pdf={audioItem.pdf}
                  initialValues={this.getItemForForm()}
                  relatedDocument={audioItem.related.find(
                    rItem => rItem.type === types.DOCUMENT,
                  )}
                  relatedArticle={audioItem.related.find(
                    rItem => rItem.type === types.ARTICLE,
                  )}
                  relatedBW={audioItem.related.find(
                    rItem => rItem.type === types.BUSINESS_WEEKLY,
                  )}
                />
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AudioItemPage;
