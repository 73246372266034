export const prefix = 'businessWeekly';

export const RECENT_REQUEST = `${prefix}/RECENT_REQUEST`;
export const RECENT_SUCCESS = `${prefix}/RECENT_SUCCESS`;
export const RECENT_FAILURE = `${prefix}/RECENT_FAILURE`;

export const getRecent = () => ({
  types: [RECENT_REQUEST, RECENT_SUCCESS, RECENT_FAILURE],
  promise: api => api.businessWeekly.recent(),
});

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`;
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`;
export const LIST_FAILURE = `${prefix}/LIST_FAILURE`;

export const getList = (params = {}) => ({
  types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: api => api.businessWeekly.list(params),
});

export const GET_ITEM_REQUEST = `${prefix}/GET_ITEM_REQUEST`;
export const GET_ITEM_SUCCESS = `${prefix}/GET_ITEM_SUCCESS`;
export const GET_ITEM_FAILURE = `${prefix}/GET_ITEM_FAILURE`;

export const getItem = id => ({
  types: [GET_ITEM_REQUEST, GET_ITEM_SUCCESS, GET_ITEM_FAILURE],
  promise: api => api.businessWeekly.get(id),
});

export const LIST_ADMIN_REQUEST = `${prefix}/LIST_ADMIN_REQUEST`;
export const LIST_ADMIN_SUCCESS = `${prefix}/LIST_ADMIN_SUCCESS`;
export const LIST_ADMIN_FAILURE = `${prefix}/LIST_ADMIN_FAILURE`;

export const getListAdmin = (params = {}) => ({
  types: [LIST_ADMIN_REQUEST, LIST_ADMIN_SUCCESS, LIST_ADMIN_FAILURE],
  promise: api => api.businessWeekly.listAdmin(params),
});

export const ITEM_ADMIN_REQUEST = `${prefix}/ITEM_ADMIN_REQUEST`;
export const ITEM_ADMIN_SUCCESS = `${prefix}/ITEM_ADMIN_SUCCESS`;
export const ITEM_ADMIN_FAILURE = `${prefix}/ITEM_ADMIN_FAILURE`;

export const getItemAdmin = id => ({
  types: [ITEM_ADMIN_REQUEST, ITEM_ADMIN_SUCCESS, ITEM_ADMIN_FAILURE],
  promise: api => api.businessWeekly.itemAdmin(id),
});

export const ADD_REQUEST = `${prefix}/ADD_REQUEST`;
export const ADD_SUCCESS = `${prefix}/ADD_SUCCESS`;
export const ADD_FAILURE = `${prefix}/ADD_FAILURE`;

export const add = item => ({
  types: [ADD_REQUEST, ADD_SUCCESS, ADD_FAILURE],
  promise: api => api.businessWeekly.add(item),
});

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`;

export const update = item => ({
  types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: api => api.businessWeekly.update(item),
});

export const REMOVE_REQUEST = `${prefix}/REMOVE_REQUEST`;
export const REMOVE_SUCCESS = `${prefix}/REMOVE_SUCCESS`;
export const REMOVE_FAILURE = `${prefix}/REMOVE_FAILURE`;

export const remove = id => ({
  types: [REMOVE_REQUEST, REMOVE_SUCCESS, REMOVE_FAILURE],
  promise: api => api.businessWeekly.remove(id),
});

export const SET_FILE = `${prefix}/SET_FILE`;

export const setFile = files => ({
  type: SET_FILE,
  data: files,
});

export const SAVE_FILE_REQUEST = `${prefix}/SAVE_FILE_REQUEST`;
export const SAVE_FILE_SUCCESS = `${prefix}/SAVE_FILE_SUCCESS`;
export const SAVE_FILE_FAILURE = `${prefix}/SAVE_FILE_FAILURE`;

export const saveFile = (id, exFiles = {}) => select => {
  const files = select(state => state[prefix].fileToSave);
  if (!Object.keys(files).length)
    return {
      type: SAVE_FILE_SUCCESS,
      data: { itemId: id },
    };
  const formData = new FormData();
  formData.append('id', id);
  Object.keys(files).forEach(lang => {
    if (exFiles[lang])
      formData.append(`exFiles.${lang}.fileId`, exFiles[lang].id);
    formData.append(`files.${lang}`, files[lang][0], files[lang][0].name);
  });
  return {
    types: [SAVE_FILE_REQUEST, SAVE_FILE_SUCCESS, SAVE_FILE_FAILURE],
    promise: api => api.businessWeekly.saveFile(formData),
  };
};

export const HEBCAL_REQUEST = `${prefix}/HEBCAL_REQUEST`;
export const HEBCAL_SUCCESS = `${prefix}/HEBCAL_SUCCESS`;
export const HEBCAL_FAILURE = `${prefix}/HEBCAL_FAILURE`;

export const getHebcal = params => ({
  types: [HEBCAL_REQUEST, HEBCAL_SUCCESS, HEBCAL_FAILURE],
  promise: api => api.hebcal.newsletter(params),
});

export const HEBCAL_RESET = `${prefix}/HEBCAL_RESET`;

export const resetHebcal = () => ({
  type: HEBCAL_RESET,
});
