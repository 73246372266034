import { connect } from 'react-redux';

import DonatePage from './DonatePage';

const selector = null;

const actions = {};

export { DonatePage };

export default connect(
  selector,
  actions,
)(DonatePage);
