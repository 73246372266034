import React from 'react';
import { Row, Col } from 'reactstrap';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';

import { lang } from '../../redux/lang/selectors';

import Banner from './components/Banner';
import SubscribeForm from '../SubscribeForm';
import { getLabelFromLang } from '../../utils';
import { TRANSLATIONS } from '../../constants/translations';
import Hr from '../Hr';

class IndexHeader extends React.Component {
  render() {
    const { lang } = this.props;

    const isHE = lang === 'he';

    return (
      <>
        <div className="header bg-info pt-5 pb-7">
          <Banner />
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>

          <Row>
            <Col lg="5" xs="10" className="pl-md-8 pl-6 pt-6 pt-lg-0">
              <Hr color="white" justify={isHE ? 'end' : 'start'} />
              <h2
                className={classNames('display-3 text-white', {
                  'text-right': isHE,
                })}
              >
                {getLabelFromLang(TRANSLATIONS.JOIN_NL, lang)}
              </h2>
              <SubscribeForm
                form="subscribeHorizontal"
                horizontal
                initialValues={{ lang: lang.toLowerCase() }}
                rowClassNames="mb-sm-0"
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const selector = createStructuredSelector({ lang });

export default connect(selector)(IndexHeader);
// export default IndexHeader;
