import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';

import { compose } from '../../utils';
import { lang, list } from '../../redux/lang/selectors';
import { loading, subscribed } from '../../redux/users/selectors';

import ImportSubscribersForm from './ImportSubscribersForm';

const reduxConect = connect(
  createStructuredSelector({
    loading,
    subscribed,
    lang,
    langList: list,
  }),
);

const formConect = reduxForm({
  form: 'importSubscribers',
});

export default compose(
  reduxConect,
  formConect,
)(ImportSubscribersForm);
