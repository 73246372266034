import React from 'react';

import { Modal, Card, CardHeader, CardBody } from 'reactstrap';
import SubmissionForm from '../../components/SubmissionForm';
import { FIELD_TYPES } from '../../constants/fieldTypes';

export default class SubmissionModal extends React.Component {
  submitForm(data) {
    const {
      save,
      lang,
      form: { id, fields },
    } = this.props;

    const filesFieldNames = fields
      .filter(item => item.type === 'file' && item.lang === lang)
      .map(item => item.name);
    const textFieldNames = fields
      .filter(item => item.type !== 'file' && item.lang === lang)
      .map(item => item.name);

    const files = filesFieldNames.reduce((acc, item) => {
      if (data[item] && data[item][0]) {
        acc[item] = data[item][0];
      }
      return acc;
    }, {});
    const textFields = textFieldNames.reduce((acc, item) => {
      acc[item] = data[item];
      return acc;
    }, {});

    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({
        email: data.email,
        formId: id,
        submittedLang: lang,
        fields: textFields,
      }),
    );
    Object.entries(files).map(([name, file]) => formData.append(name, file));

    save(formData);
  }

  render() {
    const { isOpened, close, form, lang, defaultLang } = this.props;
    const formLangData = (form && form.lang[lang]) || {};
    const formDefaultLangData = (form && form.lang[defaultLang]) || {};
    return (
      <Modal
        zIndex={1000}
        size="lg"
        className="modal-dialog-centered"
        isOpen={isOpened}
        toggle={() => close()}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0 mb-0">
            <CardHeader className="bg-transparent p-1">
              <div className="text-muted text-center mt-2 mb-2 display-4">
                {formLangData.name || formDefaultLangData.name}
              </div>
            </CardHeader>
            <CardBody className="px-lg-5">
              <p className="h4">{formLangData.description}</p>
              <p dangerouslySetInnerHTML={{ __html: formLangData.text }} />
              <SubmissionForm
                onSubmit={data => this.submitForm(data)}
                formFields={form.fields}
                initialValues={form.fields.reduce((acc, field) => {
                  if (field.type === FIELD_TYPES.select.value) {
                    acc[field.name] = field.options.options[0].value;
                  }
                  if (field.type === FIELD_TYPES.checkbox.value) {
                    acc[field.name] = false;
                  }

                  return acc;
                }, {})}
                cancel={close}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    );
  }
}
