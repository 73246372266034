import React from 'react';
import PdfViewer from '../PdfViewer';
import { FILE_URL } from '../../constants/files';

const FormFileRender = ({ file }) => {
  if (!file || !file.url) return null;

  return (
    <PdfViewer
      classes="py-3"
      scale={0.7}
      url={`${FILE_URL}${file.url}`}
      newTabLink
    />
  );
};

export default FormFileRender;
