import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { compose } from '../../../utils';
import { DragSource, DropTarget } from 'react-dnd';
import { Col, Row } from 'reactstrap';
import classes from './fieldItem.module.scss';

const ItemTypes = {
  FieldItemCard: 'fieldItemCard',
};
const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      order: props.order,
      index: props.index,
    };
  },
};

const cardTarget = {
  hover: (props, monitor, component) => {
    if (!component) {
      return null;
    }
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    const hoverMiddleY = (hoverBoundingRect.top - hoverBoundingRect.bottom) / 2;

    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.bottom;
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    props.moveCard(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

const DropTargetConnect = DropTarget(
  ItemTypes.FieldItemCard,
  cardTarget,
  connect => ({
    connectDropTarget: connect.dropTarget(),
  }),
);

const DrugSourceConnect = DragSource(
  ItemTypes.FieldItemCard,
  cardSource,
  collect,
);

class FieldItemCard extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
  };
  render() {
    const {
      children,
      key,
      isDragging,
      connectDragSource,
      connectDropTarget,
    } = this.props;
    const opacity = isDragging ? 0.3 : 1;

    return (
      connectDragSource &&
      connectDropTarget &&
      connectDragSource(
        connectDropTarget(
          <div
            key={key}
            className={classnames(classes.fieldItemCard, {
              [classes.dragging]: isDragging,
            })}
            style={{ opacity }}
          >
            {isDragging ? (
              <div className={classes.dropHere}>Drop here</div>
            ) : (
              <Row noGutters className="align-items-center">
                <Col sm="1" className="pl-md-2 pr-md-0 pl-1 pr-1 text-center">
                  <i className="fas fa-arrows-alt" />
                </Col>
                <Col className="border-left pl-4">{children}</Col>
              </Row>
            )}
          </div>,
        ),
      )
    );
  }
}
export default compose(
  DropTargetConnect,
  DrugSourceConnect,
)(FieldItemCard);
