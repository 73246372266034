import Base from './base';

export default class Article extends Base {
  list(query) {
    return this.apiClient.get('article', query);
  }

  recent() {
    return this.apiClient.get(`article/recent`);
  }

  get(id) {
    return this.apiClient.get(`article/${id}`);
  }

  listAdmin(params) {
    return this.apiClient.get('article/admin', params);
  }

  itemAdmin(id) {
    return this.apiClient.get(`article/admin/${id}`);
  }

  add(data) {
    return this.apiClient.post('article/new', { data });
  }

  update(data) {
    return this.apiClient.post('article', { data });
  }

  remove(id) {
    return this.apiClient.delete(`article/${id}`);
  }

  getTypes() {
    return this.apiClient.get(`article/types`);
  }

  saveFile(data) {
    return this.apiClient.post('article/file/save', data);
  }
}
