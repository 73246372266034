import React from 'react';
import { LANG } from './lang';

export const statuses = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
};

export const statusOptions = [
  { value: '', label: 'All' },
  { value: statuses.PUBLISHED, label: 'Published' },
  { value: statuses.DRAFT, label: 'Draft' },
];

export const types = {
  AUDIO: 'AUDIO',
  ARTICLE: 'ARTICLE',
  DOCUMENT: 'DOCUMENT',
  BUSINESS_WEEKLY: 'BUSINESS_WEEKLY',
};

export const typesLabels = {
  [types.AUDIO]: 'Audio',
  [types.ARTICLE]: 'Articles',
  [types.DOCUMENT]: 'Documents',
  [types.BUSINESS_WEEKLY]: 'Business Weekly',
};

export const typeOptions = [
  { value: '', label: 'All' },
  { value: types.BUSINESS_WEEKLY, label: typesLabels.BUSINESS_WEEKLY },
  { value: types.ARTICLE, label: typesLabels.ARTICLE },
  { value: types.AUDIO, label: 'Shiurim' },
  { value: types.DOCUMENT, label: 'Shtaros' },
];

export const typesIcons = {
  [types.AUDIO]: <i className="fas fa-music" />,
  [types.ARTICLE]: <i className="fas fa-newspaper" />,
  [types.DOCUMENT]: <i className="fas fa-file-alt" />,
  [types.BUSINESS_WEEKLY]: <i className="fas fa-rss" />,
};

export const bwLabels = {
  en: 'Business Weekly',
  he: 'Masa Umatan',
  fr: 'Business Weekly',
};

export const subTypes = {
  [types.BUSINESS_WEEKLY]: {
    storyLine: 'storyLine',
    hotline: 'hotline',
    moneyMatters: 'moneyMatters',
  },
  [types.AUDIO]: {
    SEMINAR: 'SEMINAR',
    CHABURA: 'CHABURA',
    SHIUR: 'SHIUR',
  },
  [types.ARTICLE]: {
    PUBLICATION: 'PUBLICATION',
    ARTICLE: 'ARTICLE',
  },
};

export const labels = {
  storyLine: {
    [LANG.EN]: 'Case File',
    [LANG.HE]: 'מעשה שהיה',
  },
  hotline: {
    [LANG.EN]: 'BHI Hotline',
    [LANG.HE]: 'קו ההלכה',
  },
  moneyMatters: {
    [LANG.EN]: 'Money Matters',
    [LANG.HE]: 'פסקי דינים',
  },
};

export const bwTypesMap = {
  STORY_LINE: 'storyLine',
  HOTLINE: 'hotline',
  MONEY_MATTERS: 'moneyMatters',
};

export const bwTypesMapReverse = {
  storyLine: 'STORY_LINE',
  hotline: 'HOTLINE',
  moneyMatters: 'MONEY_MATTERS',
};

export const bwTypes = {
  STORY_LINE: 'STORY_LINE',
  HOTLINE: 'HOTLINE',
  MONEY_MATTERS: 'MONEY_MATTERS',
};

export const searchBy = {
  CONTENT: 'CONTENT',
  CATEGORY: 'CATEGORY',
  TITLE: 'TITLE',
};

export const searchByLabels = {
  [searchBy.TITLE]: 'By title',
  [searchBy.CONTENT]: 'By content',
  [searchBy.CATEGORY]: 'By category',
};
