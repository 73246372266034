import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formValueSelector, reduxForm } from 'redux-form';

import { compose } from '../../utils';
import { saving, types } from '../../redux/audio/selectors';
import { list, defaultLang } from '../../redux/lang/selectors';
import { getListAdmin as getCategoriesRelations } from '../../redux/materials-category-type/actions';
import { listAdmin as categoriesRelations } from '../../redux/materials-category-type/selectors';
import { getListAdmin as getCategoryTypesList } from '../../redux/category-type/actions';
import { getListByDoctype } from '../../redux/category/actions';
import { listAdmin as categoryTypes } from '../../redux/category-type/selectors';

import AudioForm from './AudioForm';

const formConect = reduxForm({
  form: 'audio',
  enableReinitialize: true,
});

const values = formValueSelector('audio');

const reduxConect = connect(
  createStructuredSelector({
    saving,
    langList: list,
    defaultLang,
    types,
    relatedValues: state => values(state, 'related'),
    categoriesRelations,
    categoryTypes,
    selectedSubType: state => values(state, 'subType'),
    langData: state => values(state, 'lang'),
  }),
  {
    getCategoriesRelations,
    getCategoryTypesList,
    getListByDoctype,
  },
);

export { AudioForm };

export default compose(
  reduxConect,
  formConect,
)(AudioForm);
