import Base from './base';

export default class Document extends Base {
  list(query) {
    return this.apiClient.get('document', query);
  }

  recent() {
    return this.apiClient.get(`document/recent`);
  }

  get(id) {
    return this.apiClient.get(`document/${id}`);
  }

  listAdmin(params) {
    return this.apiClient.get('document/admin', params);
  }

  listFullAdmin(params) {
    return this.apiClient.get('document/admin/full-list', params);
  }

  itemAdmin(id) {
    return this.apiClient.get(`document/admin/${id}`);
  }

  add(data) {
    return this.apiClient.post('document/new', { data });
  }

  update(data) {
    return this.apiClient.post('document', { data });
  }

  remove(id) {
    return this.apiClient.delete(`document/${id}`);
  }

  saveFile(data) {
    return this.apiClient.post('document/file/save', data);
  }

  fetchAll() {
    return this.apiClient.get('materials');
  }

  autocompleteSearch(q) {
    return this.apiClient.get('materials/search/autocomplete', q);
  }
}
