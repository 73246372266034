export const LANG = {
  EN: 'EN',
  HE: 'HE',
  FR: 'FR',
};

export const DEFAULT_LANG = LANG.EN;

export const LANG_PATH = '/:lng([a-z]{2})?';

export const getPath = route => `${LANG_PATH}${route}`;
