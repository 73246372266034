export const prefix = 'settings';

export const LAUNCH_DATA_MIGRATION_REQUEST = `${prefix}/LAUNCH_DATA_MIGRATION_REQUEST`;
export const LAUNCH_DATA_MIGRATION_SUCCESS = `${prefix}/LAUNCH_DATA_MIGRATION_SUCCESS`;
export const LAUNCH_DATA_MIGRATION_FAILURE = `${prefix}/LAUNCH_DATA_MIGRATION_FAILURE`;

export const launchDataMigration = params => ({
  types: [
    LAUNCH_DATA_MIGRATION_REQUEST,
    LAUNCH_DATA_MIGRATION_SUCCESS,
    LAUNCH_DATA_MIGRATION_FAILURE,
  ],
  promise: api => api.settings.launchDataMigration(params),
});

export const LAUNCH_AUDIO_HIDE_REQUEST = `${prefix}/LAUNCH_AUDIO_HIDE_REQUEST`;
export const LAUNCH_AUDIO_HIDE_SUCCESS = `${prefix}/LAUNCH_AUDIO_HIDE_SUCCESS`;
export const LAUNCH_AUDIO_HIDE_FAILURE = `${prefix}/LAUNCH_AUDIO_HIDE_FAILURE`;

export const launchAudioHide = params => ({
  types: [
    LAUNCH_AUDIO_HIDE_REQUEST,
    LAUNCH_AUDIO_HIDE_SUCCESS,
    LAUNCH_AUDIO_HIDE_FAILURE,
  ],
  promise: api => api.settings.launchAudioHide(params),
});

export const LAUNCH_SEFARIA_MIGRATION_REQUEST = `${prefix}/LAUNCH_SEFARIA_MIGRATION_REQUEST`;
export const LAUNCH_SEFARIA_MIGRATION_SUCCESS = `${prefix}/LAUNCH_SEFARIA_MIGRATION_SUCCESS`;
export const LAUNCH_SEFARIA_MIGRATION_FAILURE = `${prefix}/LAUNCH_SEFARIA_MIGRATION_FAILURE`;

export const launchSefariaMigration = params => ({
  types: [
    LAUNCH_SEFARIA_MIGRATION_REQUEST,
    LAUNCH_SEFARIA_MIGRATION_SUCCESS,
    LAUNCH_SEFARIA_MIGRATION_FAILURE,
  ],
  promise: api => api.settings.launchSefariaMigration(params),
});

export const LAUNCH_TAGS_MIGRATION_REQUEST = `${prefix}/LAUNCH_TAGS_MIGRATION_REQUEST`;
export const LAUNCH_TAGS_MIGRATION_SUCCESS = `${prefix}/LAUNCH_TAGS_MIGRATION_SUCCESS`;
export const LAUNCH_TAGS_MIGRATION_FAILURE = `${prefix}/LAUNCH_TAGS_MIGRATION_FAILURE`;

export const launchTagsMigration = params => ({
  types: [
    LAUNCH_TAGS_MIGRATION_REQUEST,
    LAUNCH_TAGS_MIGRATION_SUCCESS,
    LAUNCH_TAGS_MIGRATION_FAILURE,
  ],
  promise: api => api.settings.launchTagsMigration(params),
});

export const LAUNCH_TORAH_MIGRATION_REQUEST = `${prefix}/LAUNCH_TORAH_MIGRATION_REQUEST`;
export const LAUNCH_TORAH_MIGRATION_SUCCESS = `${prefix}/LAUNCH_TORAH_MIGRATION_SUCCESS`;
export const LAUNCH_TORAH_MIGRATION_FAILURE = `${prefix}/LAUNCH_TORAH_MIGRATION_FAILURE`;

export const launchSefariaTorahMigration = params => ({
  types: [
    LAUNCH_TORAH_MIGRATION_REQUEST,
    LAUNCH_TORAH_MIGRATION_SUCCESS,
    LAUNCH_TORAH_MIGRATION_FAILURE,
  ],
  promise: api => api.settings.launchSefariaTorahMigration(params),
});

export const LAUNCH_PROPHETS_MIGRATION_REQUEST = `${prefix}/LAUNCH_PROPHETS_MIGRATION_REQUEST`;
export const LAUNCH_PROPHETS_MIGRATION_SUCCESS = `${prefix}/LAUNCH_PROPHETS_MIGRATION_SUCCESS`;
export const LAUNCH_PROPHETS_MIGRATION_FAILURE = `${prefix}/LAUNCH_PROPHETS_MIGRATION_FAILURE`;

export const launchSefariaProphetsMigration = params => ({
  types: [
    LAUNCH_PROPHETS_MIGRATION_REQUEST,
    LAUNCH_PROPHETS_MIGRATION_SUCCESS,
    LAUNCH_PROPHETS_MIGRATION_FAILURE,
  ],
  promise: api => api.settings.launchSefariaProphetsMigration(params),
});

export const LAUNCH_WRITINGS_MIGRATION_REQUEST = `${prefix}/LAUNCH_WRITINGS_MIGRATION_REQUEST`;
export const LAUNCH_WRITINGS_MIGRATION_SUCCESS = `${prefix}/LAUNCH_WRITINGS_MIGRATION_SUCCESS`;
export const LAUNCH_WRITINGS_MIGRATION_FAILURE = `${prefix}/LAUNCH_WRITINGS_MIGRATION_FAILURE`;

export const launchSefariaWritingsMigration = params => ({
  types: [
    LAUNCH_WRITINGS_MIGRATION_REQUEST,
    LAUNCH_WRITINGS_MIGRATION_SUCCESS,
    LAUNCH_WRITINGS_MIGRATION_FAILURE,
  ],
  promise: api => api.settings.launchSefariaWritingsMigration(params),
});

export const LAUNCH_SCHULCHAN_MIGRATION_REQUEST = `${prefix}/LAUNCH_SCHULCHAN_MIGRATION_REQUEST`;
export const LAUNCH_SCHULCHAN_MIGRATION_SUCCESS = `${prefix}/LAUNCH_SCHULCHAN_MIGRATION_SUCCESS`;
export const LAUNCH_SCHULCHAN_MIGRATION_FAILURE = `${prefix}/LAUNCH_SCHULCHAN_MIGRATION_FAILURE`;

export const launchSefariaShulchanMigration = params => ({
  types: [
    LAUNCH_SCHULCHAN_MIGRATION_REQUEST,
    LAUNCH_SCHULCHAN_MIGRATION_SUCCESS,
    LAUNCH_SCHULCHAN_MIGRATION_FAILURE,
  ],
  promise: api => api.settings.launchSefariaShulchanMigration(params),
});

export const GLOBAL_SETTINGS_CHANGE_REQUEST = `${prefix}/GLOBAL_SETTINGS_CHANGE_REQUEST`;
export const GLOBAL_SETTINGS_CHANGE_SUCCESS = `${prefix}/GLOBAL_SETTINGS_CHANGE_SUCCESS`;
export const GLOBAL_SETTINGS_CHANGE_FAILURE = `${prefix}/GLOBAL_SETTINGS_CHANGE_FAILURE`;

export const changeGlobalSettings = data => ({
  types: [
    GLOBAL_SETTINGS_CHANGE_REQUEST,
    GLOBAL_SETTINGS_CHANGE_SUCCESS,
    GLOBAL_SETTINGS_CHANGE_FAILURE,
  ],
  promise: api => api.settings.changeGlobalSettings(data),
});

export const GLOBAL_SETTINGS_GET_REQUEST = `${prefix}/GLOBAL_SETTINGS_GET_REQUEST`;
export const GLOBAL_SETTINGS_GET_SUCCESS = `${prefix}/GLOBAL_SETTINGS_GET_SUCCESS`;
export const GLOBAL_SETTINGS_GET_FAILURE = `${prefix}/GLOBAL_SETTINGS_GET_FAILURE`;

export const getGlobalSettings = data => ({
  types: [
    GLOBAL_SETTINGS_GET_REQUEST,
    GLOBAL_SETTINGS_GET_SUCCESS,
    GLOBAL_SETTINGS_GET_FAILURE,
  ],
  promise: api => api.settings.getGlobalSettings(data),
});
