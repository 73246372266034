import { takeEvery, put } from 'redux-saga/effects';
import {
  REMOVE_SUCCESS,
  ADD_SUCCESS,
  UPDATE_SUCCESS,
  getListAdmin,
} from './actions';

export function* materialsRemovedSuccess() {
  yield takeEvery(REMOVE_SUCCESS, function*() {
    yield put(getListAdmin());
  });
}
export function* materialsSaveSuccess() {
  yield takeEvery(ADD_SUCCESS, function*() {
    yield put(getListAdmin());
  });
  yield takeEvery(UPDATE_SUCCESS, function*() {
    yield put(getListAdmin());
  });
}
