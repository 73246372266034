import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import FormItem from '../../../components/FormItem';
import { Container, Row, Col, Button } from 'reactstrap';
import AddFormModal from './AddFormModal';
import { defaultFormFields } from '../../../constants/defaultFormFields';

class FormListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addModalIsOpen: false,
      fromProps: { saving: false },
    };

    this.remove = this.remove.bind(this);
    this.save = this.save.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(key, open = false) {
    this.setState({ [key]: open });
  }

  componentWillMount() {
    this.props.getListAdmin();
    this.props.getDocumentList();
  }

  save({ id, active, documentId, lang }) {
    const { add } = this.props;
    add({
      active,
      documentId,
      lang,
      formFields: defaultFormFields,
    });
  }

  remove(id) {
    const yes = window.confirm('Are you sure you want delete this item?');
    if (!yes) return;
    const { remove } = this.props;
    if (id) {
      remove(id);
    }
  }

  render() {
    const { loading, list, defaultLang, documentList, langList } = this.props;
    const { addModalIsOpen } = this.state;

    return (
      <>
        {loading && <Loader type="fixed" />}
        <Container fluid className="py-2 border-bottom">
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="display-2 mb-0">Form list</h2>
            </Col>
            <Col className="text-right">
              <Button
                color="primary"
                onClick={() => this.toggleModal('addModalIsOpen', true)}
                className="btn btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text">New</span>
              </Button>
            </Col>
          </Row>
        </Container>
        <Container className="py-4">
          <Row>
            {list.map(item => (
              <Col key={`form-${item.id}`} sm={6}>
                <FormItem
                  item={item}
                  isAdmin
                  lang={defaultLang}
                  onRemove={this.remove}
                />
              </Col>
            ))}
          </Row>
        </Container>

        <AddFormModal
          isOpened={addModalIsOpen}
          defaultLang={defaultLang}
          documentList={documentList}
          langList={langList}
          save={this.save}
          close={() => this.toggleModal('addModalIsOpen')}
        />
      </>
    );
  }
}

export default FormListPage;
