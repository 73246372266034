import { connect } from 'react-redux';

import ContactPage from './ContactPage';

const selector = null;

const actions = {};

export { ContactPage };

export default connect(
  selector,
  actions,
)(ContactPage);
