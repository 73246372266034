import Base from './base';

export default class Category extends Base {
  list(query) {
    return this.apiClient.get('category', query);
  }

  get(id) {
    return this.apiClient.get(`category/${id}`);
  }

  listAdmin(query) {
    return this.apiClient.get('category/admin', query);
  }

  parentsByType(query) {
    return this.apiClient.get('category/parents', query);
  }

  childrenById(query) {
    return this.apiClient.get('category/children', query);
  }

  listByDoctype(query) {
    return this.apiClient.get('category/doctype', query);
  }

  itemAdmin(id) {
    return this.apiClient.get(`category/admin/${id}`);
  }

  add(data) {
    return this.apiClient.post('category/new', { data });
  }

  update(data) {
    return this.apiClient.post('category', { data });
  }

  remove(id) {
    return this.apiClient.delete(`category/${id}`);
  }

  typeList(query) {
    return this.apiClient.get('category-type', query);
  }

  typeListAdmin(query) {
    return this.apiClient.get('category-type/admin', query);
  }

  typeItemAdmin(id) {
    return this.apiClient.get(`category-type/admin/${id}`);
  }

  addType(data) {
    return this.apiClient.post('category-type/new', { data });
  }

  updateType(data) {
    return this.apiClient.post('category-type', { data });
  }

  removeType(id) {
    return this.apiClient.delete(`category-type/${id}`);
  }

  relations() {
    return this.apiClient.get(`category-type-materials`);
  }

  relationItem(id) {
    return this.apiClient.get(`category-type-materials/${id}`);
  }

  addRelation(data) {
    return this.apiClient.post(`category-type-materials/new`, { data });
  }

  updateRelation(data) {
    return this.apiClient.post(`category-type-materials`, { data });
  }

  removeRelation(data) {
    return this.apiClient.delete(`category-type-materials`, data);
  }
}
