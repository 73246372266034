import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

import styles from '../../Categories.module.scss';
import 'react-sortable-tree/style.css';
import { ROUTES } from '../../../../../constants/routes';

class Categories extends Component {
  render() {
    const { categoryTypes, history } = this.props;

    return (
      <Container fluid className="py-4">
        <Row className="mb-4">
          <Col>
            <p className="display-4">Choose category type</p>
          </Col>
        </Row>
        <Row>
          {categoryTypes.map(item => (
            <Col key={`category-type-${item.id}`} sm={2}>
              <Card
                className={styles.categoryType}
                onClick={() =>
                  history.push(`${ROUTES.ADMIN_CATEGORIES}/${item.id}`)
                }
              >
                <CardBody>{item.label}</CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

export default Categories;
