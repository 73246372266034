import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { compose } from '../../../utils';
import { contactUs } from '../../../redux/users/actions';
import { contactUsSent, sending } from '../../../redux/users/selectors';

import ContactForm from './ContactForm';

const reduxConect = connect(
  createStructuredSelector({
    contactUsSent,
    sending,
  }),
  { onSubmit: contactUs },
);

const formConect = reduxForm({
  form: 'contact',
});

export { ContactForm };

export default compose(
  reduxConect,
  formConect,
)(ContactForm);
