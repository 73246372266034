import { library } from '@fortawesome/fontawesome-svg-core';
// import { fas } from '@fortawesome/pro-solid-svg-icons'; // adds all
// import { far } from '@fortawesome/pro-regular-svg-icons'; // adds all
// import { fal } from '@fortawesome/pro-light-svg-icons'; // adds all
// import { fad } from '@fortawesome/pro-duotone-svg-icons'; // adds all

import {
  faPhone,
  faSynagogue,
  faHandshakeAlt,
  faClipboardCheck,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
library.add(
  faPhone,
  faSynagogue,
  faHandshakeAlt,
  faClipboardCheck,
  faChevronLeft,
  faChevronRight,
);
