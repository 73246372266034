import React, { Component } from 'react';
import { Container, Row, Col, Button, Table, Card } from 'reactstrap';
import Paginator from '../../../components/Paginator';
import Loader from '../../../components/Loader';

import classes from './CustomEmailPage.module.scss';

import CustomEmailModal from './CustomEmailModal';
import CustomEmailRow from './CustomEmailRow';
class CustomEmailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendModalIsOpen: false,
      data: {},
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.send = this.send.bind(this);
    this.schedule = this.schedule.bind(this);
    this.rowHandleClick = this.rowHandleClick.bind(this);
    this.clearData = this.clearData.bind(this);
    this.removeCustomEmail = this.removeCustomEmail.bind(this);
    this.getList = this.getList.bind(this);
  }

  componentDidMount() {
    this.props.getListsPage();
    this.props.getListLimit();
  }

  toggleModal() {
    this.setState({ sendModalIsOpen: !this.state.sendModalIsOpen });
  }

  rowHandleClick(data) {
    this.toggleModal();
    this.setState({ data });
  }

  removeCustomEmail(e, id) {
    e.stopPropagation();
    const yes = window.confirm('Are you sure you want delete this item?');
    if (!yes) return;

    this.props.removeCustomEmail(id);
    if (this.state.sendModalIsOpen) {
      this.toggleModal();
    }
  }

  clearData() {
    this.setState({ data: {} });
  }

  prepareForm(data) {
    const formData = new FormData();
    Object.entries(data).forEach(([field, value]) => {
      if (field === 'filesToSend') {
        value.forEach((item, i) => {
          if (item.id) {
            formData.append(`exFiles.${i}`, item.id);
          } else {
            formData.append(`filesToSave.${i}`, item, item.name);
          }
        });
      } else {
        formData.append(field, value);
      }
    });
    return formData;
  }

  send(data) {
    const { prepareCustomEmail } = this.props;

    prepareCustomEmail(this.prepareForm(data));
    this.toggleModal();
  }

  schedule(data) {
    const { scheduleCustomEmail } = this.props;

    scheduleCustomEmail(this.prepareForm(data));
    this.toggleModal();
  }

  getList({ page }) {
    this.props.getListLimit({ page });
  }

  render() {
    const { sendModalIsOpen, data } = this.state;
    const { listsPage, langList, listLimit, listCount, loading } = this.props;

    return (
      <div>
        {loading && <Loader type="fixed" />}
        <Container fluid className="py-2 border-bottom">
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="display-2 mb-0">Custom email</h2>
            </Col>
            <Col className="text-right">
              <Button
                onClick={() => this.toggleModal()}
                color="primary"
                className="btn btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-send" />
                </span>
                <span className="btn-inner--text">New email</span>
              </Button>
            </Col>
          </Row>
        </Container>
        <Container className="py-4">
          <Row>
            <Col>
              <Card>
                <Table
                  className="align-items-center table-flush"
                  hover
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th>#</th>
                      <th>Email</th>
                      <th>ID</th>
                      <th>Subject</th>
                      <th>Status</th>
                      <th>Emailed at</th>
                      <th>Scheduled at</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listLimit &&
                      listLimit.map((email, i) => (
                        <tr
                          key={`emails-${email.emailData.mailingList}-${i + 1}`}
                          onClick={() => this.rowHandleClick(email)}
                          className={classes.customRow}
                        >
                          <CustomEmailRow
                            number={i + 1}
                            name={
                              email.emailData.mailingName ||
                              email.emailData.mailingList
                            }
                            subject={email.emailData.subject}
                            status={email.status}
                            emailedAt={email.emailedAt}
                            scheduled={email.emailDate}
                            id={email.id}
                            removeCustomEmail={this.removeCustomEmail}
                          />
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
          <Paginator getItems={this.getList} amount={listCount} />
        </Container>
        <CustomEmailModal
          data={data}
          listsPage={listsPage}
          langList={langList}
          isOpened={sendModalIsOpen}
          send={this.send}
          schedule={this.schedule}
          remove={this.removeCustomEmail}
          close={() => {
            this.toggleModal();
            this.clearData();
          }}
        />
      </div>
    );
  }
}

export default CustomEmailPage;
