import Base from './base';

export default class CustomEmail extends Base {
  listsPage() {
    return this.apiClient.get('custom-email');
  }
  scheduleCustomEmail(data) {
    return this.apiClient.post('custom-email/schedule', data);
  }
  sendCustomEmail(id) {
    return this.apiClient.post('custom-email/send', id);
  }
  list() {
    return this.apiClient.get('custom-email/list');
  }
  remove(id) {
    return this.apiClient.delete(`custom-email/remove/${id}`);
  }
  listLimit(params) {
    return this.apiClient.get('custom-email/list-limit', params);
  }
}
