export const defaultFormFields = [
  {
    label: 'First Name',
    name: 'first_name',
    type: 'text',
    order: 1,
    lang: 'en',
  },
  {
    label: 'First Name',
    name: 'first_name',
    type: 'text',
    order: 1,
    lang: 'he',
  },
  {
    label: 'Last Name',
    name: 'last_name',
    type: 'text',
    order: 2,
    lang: 'en',
  },
  {
    label: 'Last Name',
    name: 'last_name',
    type: 'text',
    order: 2,
    lang: 'he',
  },
];
