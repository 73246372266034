import React, { Component } from 'react';
import CategoryTypeForm from '../../../../../components/CategoryTypeForm';
import { Card, CardHeader, Modal, CardBody } from 'reactstrap';

class CategoryTypeModal extends Component {
  constructor(props) {
    super(props);

    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
  }

  save({ id, active, parentId, lang }) {
    const { add, update, close } = this.props;
    if (id) {
      update({ id, active, parentId, lang });
    } else {
      add({ active, parentId, lang });
    }
    close();
  }

  remove(id) {
    const yes = window.confirm(
      'Are you sure you want delete this category type?',
    );
    if (!yes) return;
    const { remove } = this.props;
    if (id) {
      remove(id);
    }
  }

  render() {
    const {
      isOpened,
      defaultLang,
      close,
      initialValues = {},
      isNew,
      isFromList,
    } = this.props;

    return (
      <Modal
        zIndex={1000}
        size="lg"
        className="modal-dialog-centered"
        isOpen={isOpened}
        toggle={() => close()}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0 mb-0">
            <CardHeader className="bg-transparent position-relative">
              <div className="text-muted text-center mt-2 mb-2">
                <small>Category Type</small>
              </div>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  close();
                }}
                className="position-absolute right-4 top-4 display-4"
              >
                <i className="ni ni-fat-remove text-default" />
              </a>
            </CardHeader>
            <CardBody className="px-lg-5 pt-0">
              <CategoryTypeForm
                isNew={isNew || !initialValues.id}
                onSubmit={this.save}
                onDelete={() => this.remove(initialValues.id)}
                defaultLang={defaultLang}
                disabled={
                  initialValues.categoryTypes &&
                  initialValues.categoryTypes.length > 0
                }
                initialValues={{
                  active: false,
                  ...initialValues,
                }}
                isFromList={isFromList}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default CategoryTypeModal;
