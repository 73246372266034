import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  list(state = [], action) {
    switch (action.type) {
      case actions.LIST_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  listAdmin(state = [], action) {
    switch (action.type) {
      case actions.LIST_ADMIN_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  listSubscribe(state = [], action) {
    switch (action.type) {
      case actions.LIST_SUBSCRIBE_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  lang(state = '', action) {
    switch (action.type) {
      case actions.SET:
        return action.payload.lang;
      default:
        return state;
    }
  },
  defaultLang(state = '', action) {
    switch (action.type) {
      case actions.LIST_SUCCESS:
        if (action.data && action.data[0]) {
          return action.data[0].identifier;
        }
        return state;
      default:
        return state;
    }
  },
});
