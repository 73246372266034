import React from 'react';
import { Form, Row, Col, FormGroup, Button } from 'reactstrap';

import Input from '../Input/Input';

const AUTO_SEARCH_TYPES = ['select', 'react-select', 'switcher'];

function TableFilter(props) {
  const { filterValues, filterShape, onChange, onSearch } = props;

  const fields = Object.keys(filterShape).reduce((acc, fieldName) => {
    acc.push({ ...filterShape[fieldName], name: fieldName });
    return acc;
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    onSearch({});
  }

  function handleChange(event, options = {}) {
    let name = null;
    let value = null;

    if (options.handleType === 'react-select') {
      name = options.name;
      value = event.value;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    onChange(name, value).then(() => {
      if (AUTO_SEARCH_TYPES.includes(options.handleType)) onSearch({});
    });
  }

  if (fields.length === 0) return null;

  return (
    <Form onSubmit={handleSubmit} role="form">
      <Row className="align-items-center">
        {fields.map(field => (
          <Col key={field.name}>
            <FormGroup>
              <label className="form-control-label" htmlFor={field.name}>
                {field.label}
              </label>
              <Input
                id={field.name}
                name={field.name}
                placeholder={field.placeholder || field.label}
                type={field.type}
                value={filterValues[field.name]}
                options={field.options}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        ))}

        <Col md="1">
          <Button className="btn-icon mt-2" color="primary" type="submit">
            <span className="btn-inner--icon">
              <i className="fas fa-search" />
            </span>
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default TableFilter;
