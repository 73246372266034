import React, { Component } from 'react';

import SubscribeForm from '../../components/SubscribeForm';
import AuthHeader from '../../components/Headers/AuthHeader';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import { OPTION_SEPARATOR } from '../../constants/fieldTypes';

const LANG_URL_TITLES = {
  businessweekly: 'Business Weekly',
  masaumatan: 'Masa Umatan',
  businessweeklyfrench: 'Business Weekly French',
};

const LANG_URL_LANG_IDENTIFIERS = {
  businessweekly: 'en',
  masaumatan: 'he',
  businessweeklyfrench: 'fr',
};

export default class SubscribePage extends Component {
  render() {
    const {
      match: {
        params: { langTitle },
      },
    } = this.props;
    return (
      <div className="bg-default">
        <AuthHeader title={LANG_URL_TITLES[langTitle]} lead="" />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <SubscribeForm
                    initialValues={{
                      lang: [LANG_URL_LANG_IDENTIFIERS[langTitle]],
                      [`lang${OPTION_SEPARATOR}${
                        LANG_URL_LANG_IDENTIFIERS[langTitle]
                      }`]: true,
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
