import React from 'react';

const withTableFilter = options => InitialComponent => {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        filter: this.getInitialFilter(),
      };
    }

    onChange = (name, value) => {
      return new Promise(resolve => {
        this.setState(
          state => ({
            filter: {
              ...state.filter,
              [name]: value,
            },
          }),
          () => {
            resolve();
          },
        );
      });
    };

    getInitialFilter() {
      const { shape } = options;

      if (!shape) return {};

      const initialFilter = Object.keys(shape).reduce((acc, fieldName) => {
        acc[fieldName] = shape[fieldName].initialValue;
        return acc;
      }, {});

      return initialFilter;
    }

    render() {
      const { filter } = this.state;

      return (
        <InitialComponent
          {...this.props}
          filter={filter}
          onFilterChange={this.onChange}
        />
      );
    }
  };
};

export default withTableFilter;
