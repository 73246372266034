import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AudiosPage from './AudiosPage';

import { lang } from '../../redux/lang/selectors';
import { all, allCount, loading } from '../../redux/audio/selectors';
import { list } from '../../redux/audio/actions';

const selector = createStructuredSelector({
  items: all,
  allCount,
  lang,
  loading,
});

const actions = { list };

export { AudiosPage };

export default connect(
  selector,
  actions,
)(AudiosPage);
