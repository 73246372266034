import React, { Component } from 'react';

import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LocalizedLink from '../../components/LocalizedLink';
import Hr from '../../components/Hr';
import IndexHeader from '../../components/Headers/IndexHeader';
import BusinessWeekly from './components/BusinessWeekly';
import Articles from './components/Articles';
import Audios from './components/Audios';
import Documents from './components/Documents';
import ContactUs from '../../components/ContactUs';

import classes from './HomePage.module.scss';
import { TRANSLATIONS } from '../../constants/translations';
import { OUR_SERVICES } from '../../constants/ourServices';
import { getLangFromPath, getLabelFromLang } from '../../utils';

export default class HomePage extends Component {
  render() {
    const {
      location: { pathname },
    } = this.props;

    const lang = getLangFromPath(pathname).toUpperCase();
    const isHE = lang === 'HE';

    return (
      <>
        <IndexHeader />
        {/* OUR SERVICES */}
        <section className="py-6 pb-9 bg-default">
          <Container>
            <Hr color="white" justify={isHE ? 'end' : 'start'} />
            <Row className="justify-content-start">
              <Col>
                <h2
                  className={`display-3 text-white
                    ${isHE ? 'text-right' : ''}`.trim()}
                >
                  {TRANSLATIONS.OUR_SERVICES[lang]}
                </h2>
              </Col>
            </Row>
          </Container>
        </section>
        {/* OUR SERVICES: CARDS */}
        <section className="section section-lg pt-lg-0 mt--7">
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <Row className={isHE ? 'flex-row-reverse' : ''}>
                  {OUR_SERVICES.map(service => (
                    <Col
                      key={service.title.EN}
                      lg={12 / OUR_SERVICES.length}
                      md={12 / (OUR_SERVICES.length / 2)}
                      dir={`${isHE ? 'rtl' : 'ltr'}`}
                    >
                      <Card
                        className={`${
                          classes.servicesCard
                        } card-lift--hover shadow text-center border-0`}
                      >
                        <CardBody
                          className={`${
                            classes.servicesCardBody
                          } py-5 px-5 px-md-4 px-lg-3`}
                        >
                          <div>
                            <div
                              className={`icon icon-shape bg-gradient-${
                                service.color
                              } text-white rounded-circle mb-4`}
                            >
                              <FontAwesomeIcon icon={service.icon} />
                            </div>
                            <h4
                              className={`${classes.servicesTitle} h3 text-${
                                service.color
                              } text-uppercase`}
                            >
                              {getLabelFromLang(service.title, lang)}
                            </h4>
                            <p
                              className={`description mt-3 ${
                                classes.description
                              }`}
                            >
                              {getLabelFromLang(service.description, lang)}
                            </p>
                          </div>
                          {service.isDirectLink ? (
                            <a
                              className={`btn btn-lg btn-${service.color}`}
                              href={service.link}
                            >
                              {getLabelFromLang(service.btnText, lang)}
                            </a>
                          ) : (
                            <LocalizedLink
                              className={`btn btn-lg btn-${service.color}`}
                              to={service.link}
                            >
                              {getLabelFromLang(service.btnText, lang)}
                            </LocalizedLink>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        {/* BW AND ARITCLES */}
        <section className="py-6 position-relative">
          {/* BUSINESS WEEKLY */}
          <Container className={`position-relative ${classes.frontContainer}`}>
            <BusinessWeekly />
          </Container>
          {/* ARTICLES */}
          <Container
            className={`position-relative mt-6 ${classes.frontContainer}`}
          >
            <Articles />
          </Container>
          <Row
            noGutters
            className={`position-absolute top-9 mt-6 w-100 ${
              classes.backgroundContainer
            }`}
          >
            <Col lg={4} md={6} xs={12} className="bg-gradient-info rounded" />
            <Col lg={8} md={6} xs={12} />
          </Row>
        </section>
        {/* AUDIO AND DOCUMENTS */}
        <section>
          <Container>
            <Row>
              <Col lg={6}>
                <Audios />
              </Col>
              <Col lg={6}>
                <Documents />
              </Col>
            </Row>
          </Container>
        </section>
        <ContactUs />
      </>
    );
  }
}
