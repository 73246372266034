import React, { Component } from 'react';

import Hr from '../../components/Hr';
import DonationForm from '../../components/DonationForm';

import { Container } from 'reactstrap';

export default class DonatePage extends Component {
  render() {
    return (
      <>
        <section className="header bg-info pt-5 pb-7">
          <h1 className="display-1 text-white text-center">Donate</h1>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section className="py-6 pb-9 bg-default">
          <Container>
            <Hr color="white" />
          </Container>
        </section>
        <section className="section section-lg pt-lg-0 mt--7 pb-6">
          <Container>
            <DonationForm />
          </Container>
        </section>
      </>
    );
  }
}
