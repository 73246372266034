import Base from './base';
import { ANALYTICS_EVENTS } from '../constants/analyticsEvents';

export default class Users extends Base {
  list(query) {
    return this.apiClient.get('users', query);
  }

  show(id) {
    return this.apiClient.get(`users/${id}`);
  }

  contactUs(data) {
    window.analytics.track(ANALYTICS_EVENTS.CONTACT_US, data);
    return this.apiClient.post(`contact-us`, data);
  }

  consultation(data) {
    window.analytics.track(ANALYTICS_EVENTS.CONSULTATION, data);
    return this.apiClient.post(`consultation`, data);
  }

  donate(data) {
    return this.apiClient.post(`users/donate`, data);
  }

  subscribe(data) {
    window.analytics.track(ANALYTICS_EVENTS.SUBSCRIBE, data);
    return this.apiClient.post(`users/subscribe`, data);
  }

  subscribers(params) {
    return this.apiClient.get('subscribers', params);
  }

  sendWeekly(data) {
    return this.apiClient.post('subscribers/send-weekly', data);
  }

  scheduleWeekly(data) {
    return this.apiClient.post('subscribers/schedule-weekly', data);
  }

  setSubscribed(data) {
    return this.apiClient.post('subscribers/set-subscribed', data);
  }

  exportSubscribers(lang) {
    return this.apiClient.get('subscribers/export', { lang });
  }

  importSubscribers(data) {
    return this.apiClient.post('subscribers/import', data);
  }

  addSubscriber(data) {
    return this.apiClient.post('subscribers', data);
  }
}
