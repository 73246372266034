import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import InputRedux from '../Input/InputRedux';
import FieldFormGroup from '../FieldFormGroup';
import FormActions from '../FormActions';
import LanguageTabs from '../LanguageTabs';
import { Form, Row, Col, TabContent, TabPane } from 'reactstrap';

import { fillHierarchyOptions } from '../../utils';

const KEY_FORM_OPTIONS = {
  label: {
    label: 'Name',
    type: 'text',
    icon: 'collection',
  },
  parentId: {
    label: 'Parent Category',
    type: 'select',
    notWrap: true,
  },
  active: {
    label: 'Active',
    type: 'checkbox',
    notWrap: true,
  },
};

export default class CategoryTypeForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedLang: props.defaultLang,
      fromProps: {},
    };

    this.langChanged = this.langChanged.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { defaultLang } = nextProps;
    const newState = { fromProps: state.fromProps };
    if (state.fromProps.defaultLang !== defaultLang) {
      newState.selectedLang = defaultLang;
      newState.fromProps.defaultLang = defaultLang;
    }

    return newState;
  }

  langChanged(lang) {
    this.setState({ selectedLang: lang });
  }

  renderField(key, lang = null) {
    const { saving, categoryTypes, isNew, isFromList } = this.props;
    const { selectedLang } = this.state;
    const name = lang ? `lang.${lang}.` + key : key;
    const { icon, type, label, notWrap, ...rest } = KEY_FORM_OPTIONS[key];
    if (key === 'parentId' && (!rest.options || !rest.options.length)) {
      rest.options = [{ label: 'None', value: '' }];
      fillHierarchyOptions(
        rest.options,
        categoryTypes,
        selectedLang,
        'categoryTypes',
      );
      if (!isNew || isFromList) rest.disabled = true;
    }
    return (
      <FieldFormGroup icon={icon} label={label} lang={lang} wrap={!notWrap}>
        <Field
          name={name}
          type={type}
          disabled={saving}
          onFocus={() => this.setState({ [`focused_${key}`]: true })}
          onBlur={() => this.setState({ [`focused_${key}`]: false })}
          component={InputRedux}
          {...rest}
        />
      </FieldFormGroup>
    );
  }

  render() {
    const {
      saving,
      handleSubmit,
      onDelete,
      langList,
      isNew,
      disabled,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit} role="form">
        <Row className="justify-content-between py-4 mb-4 border-bottom">
          <Col sm={4}>
            <LanguageTabs onChange={this.langChanged} />
          </Col>
          <Col className="text-right">
            <FormActions
              saving={saving}
              isNew={isNew}
              onDelete={onDelete}
              disabled={disabled}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <TabContent activeTab={'selectedLang-' + this.state.selectedLang}>
              {langList.map(lang => {
                return (
                  <TabPane
                    key={`tab-${lang.identifier}`}
                    tabId={`selectedLang-${lang.identifier}`}
                  >
                    <Row>
                      <Col>{this.renderField('label', lang.identifier)}</Col>
                    </Row>
                  </TabPane>
                );
              })}
            </TabContent>
            {this.renderField('parentId')}
          </Col>
          <Col sm={6}>
            <Row>
              <Col md={6} />
              <Col md={6}>{this.renderField('active')}</Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="text-right mt-4">
            <FormActions
              saving={saving}
              isNew={isNew}
              onDelete={onDelete}
              disabled={disabled}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}
