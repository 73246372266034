import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  InputGroup,
} from 'reactstrap';
import { Field } from 'redux-form';
import InputRedux from '../../../components/Input/InputRedux';

class SettingsPage extends Component {
  componentDidMount() {
    const { getGlobalSettings } = this.props;
    getGlobalSettings();
  }

  render() {
    const {
      loading,
      processing,
      launchDataMigration,
      launchAudioHide,
      launchSefariaMigration,
      launchTagsMigration,
      launchSefariaTorahMigration,
      launchSefariaProphetsMigration,
      launchSefariaWritingsMigration,
      launchSefariaShulchanMigration,
      changeGlobalSettings,
      handleSubmit,
    } = this.props;

    return (
      <>
        {loading && <Loader type="fixed" />}
        {processing && <Loader type="fixed" />}
        <Container fluid className="py-2 border-bottom">
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="display-2 mb-0">Settings</h2>
            </Col>
          </Row>
        </Container>
        <Container fluid className="py-2 border-bottom">
          <h4 className="display-4 mb-2">Global settings</h4>
          <Row className="justify-content-between align-items-center">
            <Col sm={4}>
              <Card>
                <CardBody className="d-flex justify-content-between align-items-center">
                  <Form
                    role="form"
                    onSubmit={handleSubmit(changeGlobalSettings)}
                  >
                    <FormGroup className="mb-3">
                      <label className="form-control-label">
                        Show Related Items
                      </label>
                      <InputGroup className="mb-3 pt-2">
                        <Field
                          name="showRelated"
                          type="checkbox"
                          component={InputRedux}
                        />
                      </InputGroup>
                    </FormGroup>
                    <Button color="primary" type="submit">
                      Save changes
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container fluid className="py-4">
          <h2 className="display-4 mb-2">Migrations</h2>
          <Row>
            <Col sm={4}>
              <Card>
                <CardBody className="d-flex justify-content-between align-items-center">
                  <h2 className="mb-0">Data Migration</h2>
                  <Button
                    size="lg"
                    color="primary"
                    onClick={() => launchDataMigration()}
                  >
                    Launch
                  </Button>
                </CardBody>
              </Card>
            </Col>

            <Col sm={4}>
              <Card>
                <CardBody className="d-flex justify-content-between align-items-center">
                  <h2 className="mb-0">Hide Audio Without File</h2>
                  <Button
                    size="lg"
                    color="primary"
                    onClick={() => launchAudioHide()}
                  >
                    Launch
                  </Button>
                </CardBody>
              </Card>
            </Col>

            <Col sm={4}>
              <Card>
                <CardBody className="d-flex justify-content-between align-items-center">
                  <h2 className="mb-0">Tags Import</h2>
                  <Button
                    size="lg"
                    color="primary"
                    onClick={() => launchTagsMigration()}
                  >
                    Launch
                  </Button>
                </CardBody>
              </Card>
            </Col>

            <Col sm={4}>
              <Card>
                <CardBody className="d-flex justify-content-between align-items-center">
                  <h2 className="mb-0">Sefaria Talmud</h2>
                  <Button
                    size="lg"
                    color="primary"
                    onClick={() => launchSefariaMigration()}
                  >
                    Launch
                  </Button>
                </CardBody>
              </Card>
            </Col>

            <Col sm={4}>
              <Card>
                <CardBody className="d-flex justify-content-between align-items-center">
                  <h2 className="mb-0">Sefaria Torah</h2>
                  <Button
                    size="lg"
                    color="primary"
                    onClick={() => launchSefariaTorahMigration()}
                  >
                    Launch
                  </Button>
                </CardBody>
              </Card>
            </Col>

            <Col sm={4}>
              <Card>
                <CardBody className="d-flex justify-content-between align-items-center">
                  <h2 className="mb-0">Sefaria Prophets</h2>
                  <Button
                    size="lg"
                    color="primary"
                    onClick={() => launchSefariaProphetsMigration()}
                  >
                    Launch
                  </Button>
                </CardBody>
              </Card>
            </Col>

            <Col sm={4}>
              <Card>
                <CardBody className="d-flex justify-content-between align-items-center">
                  <h2 className="mb-0">Sefaria Writings</h2>
                  <Button
                    size="lg"
                    color="primary"
                    onClick={() => launchSefariaWritingsMigration()}
                  >
                    Launch
                  </Button>
                </CardBody>
              </Card>
            </Col>

            <Col sm={4}>
              <Card>
                <CardBody className="d-flex justify-content-between align-items-center">
                  <h2 className="mb-0">Sefaria Shulchan Arukh</h2>
                  <Button
                    size="lg"
                    color="primary"
                    onClick={() => launchSefariaShulchanMigration()}
                  >
                    Launch
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default SettingsPage;
