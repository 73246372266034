import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import {
  Form,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import InputRedux from '../Input/InputRedux';
import classnames from 'classnames';

export default class PasswordRecoveryForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  state = {};

  render() {
    return (
      <Form onSubmit={this.props.handleSubmit} role="form">
        <FormGroup
          className={classnames('mb-3', {
            focused: this.state.focusedEmail,
          })}
        >
          <InputGroup className="input-group-merge input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-email-83" />
              </InputGroupText>
            </InputGroupAddon>
            <Field
              name="email"
              type="text"
              label="Email"
              placeholder="email"
              onFocus={() => this.setState({ focusedName: true })}
              onBlur={() => this.setState({ focusedName: false })}
              component={InputRedux}
            />
          </InputGroup>
        </FormGroup>
        <div className="text-center">
          <Button className="my-4" color="info" type="submit">
            Reset password
          </Button>
        </div>
      </Form>
    );
  }
}
