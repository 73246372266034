import React, { Component } from 'react';
import { Col, Container, Row, Button, Card, CardBody } from 'reactstrap';
import LocalizedLink from '../../components/LocalizedLink';
import SearchFilters from '../../components/SearchFilters';
import { ROUTES } from '../../constants/routes';
import { TRANSLATIONS } from '../../constants/translations';
import { typesLabels, bwLabels, types } from '../../constants/document';
import { getSearchDocumentLink, getLabelFromLang } from '../../utils';

const resultsPerPage = 50;

export default class SearchPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      searchedStr: props.searchedStr,
      isAdvancedSearch: props.match.params.type === 'advanced',
      filter: {
        type: '',
        title: '',
        category: '',
        content: '',
      },
    };

    this.nextResults = this.nextResults.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount() {
    const { searchedStr, search } = this.props;
    if (searchedStr) {
      search({ searching: searchedStr, page: 1, reset: true });
    }
  }

  onSearch({ searching, ...restParams }) {
    const { search } = this.props;
    const { filter, isAdvancedSearch } = this.state;

    this.setState({ page: 1 });

    if (isAdvancedSearch) {
      search({ searching: null, page: 1, filter, reset: true, ...restParams });
      return;
    }

    this.setState({ searchedStr: searching });

    search({
      searching,
      page: 1,
      filter: { type: filter.type },
      reset: true,
      ...restParams,
    });
  }

  onFilterChange = (name, value) => {
    const { searchedStr } = this.state;

    this.setState(
      state => {
        return { filter: { ...state.filter, [name]: value } };
      },
      () => {
        if (name === 'type') {
          this.onSearch({ searching: searchedStr });
        }
      },
    );
  };

  nextResults() {
    const { search, searchedStr } = this.props;
    let { page } = this.state;
    page++;
    this.setState({ page });
    search({ searching: searchedStr, page });
  }

  setIsAdvancedSearch = isAdvanced => {
    const { searchedStr, filter } = this.state;

    const nextState = { isAdvancedSearch: isAdvanced };

    if (!isAdvanced) {
      nextState.filter = {
        ...filter,
        title: '',
        category: '',
        content: '',
      };
    }

    this.setState(nextState, () => {
      if (!isAdvanced && searchedStr) this.onSearch({ searching: searchedStr });
    });
  };

  componentWillUnmount() {
    this.props.reset();
  }

  renderSearchResult(item) {
    const { selectedLang } = this.props;
    let link;
    if (item.Document) {
      link = getSearchDocumentLink(item.Document);
      return (
        <LocalizedLink to={link}>
          <p className="my-0 h4">{item.title}</p>
          <div className="mt-1 text-muted text-sm">
            {item.Document.type === types.BUSINESS_WEEKLY
              ? bwLabels[selectedLang]
              : typesLabels[item.Document.type]}
          </div>
        </LocalizedLink>
      );
    }

    if (item.BusinessWeekly) {
      return (
        <LocalizedLink
          to={`${ROUTES.BUSINESS_WEEKLY_ITEM_PARAM}${item.BusinessWeekly.id}`}
        >
          <p className="my-0 h4">{item.title}</p>
          <div className="mt-1 text-muted text-sm">
            {bwLabels[selectedLang]}
          </div>
        </LocalizedLink>
      );
    }

    if (item.categoryId) {
      return (
        <LocalizedLink to={getSearchDocumentLink(item)}>
          <p className="my-0 h4">{item.lang[selectedLang].title}</p>
          <div className="mt-1 text-muted text-sm">
            {typesLabels[item.type]}
          </div>
          <div className="text-muted text-sm">
            found by <span className="text-default">Category</span>:&nbsp;
            {item.categoryLabel}
          </div>
        </LocalizedLink>
      );
    }
  }

  render() {
    const { selectedLang, results, resultsAmount, loading } = this.props;
    const { filter, page, isAdvancedSearch } = this.state;
    const showLoadMore = resultsPerPage * page < resultsAmount;

    return (
      <>
        <SearchFilters
          values={filter}
          isAdvancedSearch={isAdvancedSearch}
          setIsAdvancedSearch={this.setIsAdvancedSearch}
          onChange={this.onFilterChange}
          onSearch={this.onSearch}
        />
        <section className="pt-4 pb-6 ">
          <Container>
            <Row>
              {loading && (
                <Col className="pt-5 display-4 text-muted text-center">
                  {getLabelFromLang(TRANSLATIONS.SEARCHING, selectedLang)}
                </Col>
              )}
              {!loading && (!results || !results.length) && (
                <Col className="py-8 display-4 text-muted text-center">
                  {getLabelFromLang(TRANSLATIONS.NO_RESULTS, selectedLang)}
                </Col>
              )}
              {results.map((result, i) => (
                <Col sm={6} key={genElKey(result)}>
                  <Card>
                    <CardBody>{this.renderSearchResult(result)}</CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row className="justify-content-center">
              {showLoadMore && !loading && (
                <Button onClick={this.nextResults} color="info">
                  {getLabelFromLang(TRANSLATIONS.LOAD_MORE, selectedLang)}
                </Button>
              )}
              {loading && (
                <Col className="text-center py-2">
                  <i className="text-default fas fa-spinner fa-pulse" />
                </Col>
              )}
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

function genElKey(searchResult) {
  let ident = '';
  if (searchResult.Document) {
    ident = `doc-${searchResult.Document.type}-${searchResult.Document.id}`;
  }
  if (searchResult.BusinessWeekly) {
    ident = `bw-${searchResult.BusinessWeekly.id}`;
  }
  if (searchResult.categoryId) {
    ident = `byCat-${searchResult.type}-${searchResult.id}`;
  }
  return `search-result-${ident}`;
}
