import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import BWArchive from './BWArchive';

import { lang } from '../../redux/lang/selectors';
import {
  list,
  listCount,
  loading,
} from '../../redux/business-weekly/selectors';
import { getList } from '../../redux/business-weekly/actions';
const selector = createStructuredSelector({
  list,
  listCount,
  loading,
  selectedLang: lang,
});

const actions = { getList };

export { BWArchive };

export default connect(
  selector,
  actions,
)(BWArchive);
