import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import BusinessWeeklyForm from '../../../components/BusinessWeeklyForm';
import { Container, Row, Col, Button } from 'reactstrap';
import { ROUTES } from '../../../constants/routes';
import { statuses, bwTypes } from '../../../constants/document';
import { Link } from 'react-router-dom';

const initialData = {
  status: statuses.DRAFT,
  lang: {
    en: {},
    he: {},
    fr: {},
  },
  storyLine: {
    categories: [],
  },
  hotline: {
    categories: [],
  },
  moneyMatters: {
    lang: {
      en: {},
      he: {},
      fr: {},
    },
    categories: [],
  },
};

class BusinessWeeklyItemPage extends Component {
  constructor(props) {
    super(props);

    this.state = { fromProps: {} };

    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const {
      match: {
        params: { id },
      },
    } = nextProps;
    const newState = { fromProps: state.fromProps };

    if (id !== state.fromProps.id) {
      newState.fromProps.id = id;
    }

    return newState;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fromProps: { id },
    } = this.state;
    if (prevState.fromProps.id !== id) {
      if (id && id !== 'new') {
        this.props.fetch(id);
      }
    }
  }

  componentDidMount() {
    const {
      listAdmin,
      getListAdmin,
      fetch,
      getRecent,
      match: {
        params: { id },
      },
    } = this.props;
    if (id && id !== 'new') {
      fetch(id);
    }
    if (id === 'new' && !listAdmin.length) {
      getListAdmin();
    }
    getRecent();
  }

  save({ id, file, ...rest }) {
    const { add, update, setFile } = this.props;
    setFile(file || {});
    if (id) {
      update({ id, ...rest });
    } else {
      add({ ...rest });
    }
  }

  remove() {
    const yes = window.confirm('Are you sure you want delete this item?');
    if (!yes) return;
    const { bwItem, remove } = this.props;
    if (bwItem.id) {
      remove(bwItem.id);
    }
  }

  cancel() {
    const yes = window.confirm('Are you sure?');
    if (!yes) return;
    this.props.history.push(ROUTES.ADMIN_BUSINESS_WEEKLY);
  }

  getDefaultValues() {
    const { recent, langList } = this.props;
    const data = initialData;
    if (recent.lang && recent.lang.en) {
      langList.forEach(item => {
        const number = recent.lang[item.identifier].number
          ? recent.lang[item.identifier].number + 1
          : 1;
        data.lang[item.identifier].number = number;
      });
    }
    const moneyMatters = recent.publications.find(
      item => item && item.subType === bwTypes.MONEY_MATTERS,
    );
    if (moneyMatters) {
      langList.forEach(item => {
        const serialNumber = moneyMatters.lang[item.identifier].serialNumber
          ? moneyMatters.lang[item.identifier].serialNumber + 1
          : 1;
        const category = moneyMatters.lang[item.identifier].category
          ? moneyMatters.lang[item.identifier].category
          : '';
        data.moneyMatters.lang[item.identifier].serialNumber = serialNumber;
        data.moneyMatters.lang[item.identifier].category = category;
      });
    }

    return data;
  }

  getItemForForm() {
    const { bwItem } = this.props;

    if (bwItem.id) {
      return bwItem;
    }
    return null;
  }

  render() {
    const {
      loading,
      saving,
      bwItem,
      match: {
        params: { id },
      },
    } = this.props;
    const isNew = id === 'new';

    return (
      <>
        {(loading || saving) && <Loader type="fixed" />}
        <Container fluid className="py-2 border-bottom">
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="display-2 mb-0">
                {isNew ? 'New' : 'Edit'} business weekly
              </h2>
            </Col>
            <Col className="text-right">
              <Link
                to={`${ROUTES.ADMIN_BUSINESS_WEEKLY_ITEM_PARAM}new`}
                className="btn btn-primary btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text">New</span>
              </Link>
              <Button
                onClick={this.cancel}
                className="btn btn-warning btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-remove" />
                </span>
                <span className="btn-inner--text">Cancel</span>
              </Button>
            </Col>
          </Row>
        </Container>

        {isNew || !bwItem || !bwItem.id ? (
          <BusinessWeeklyForm
            isNew={isNew}
            onSubmit={this.save}
            onDelete={this.remove}
            files={{}}
            initialValues={this.getDefaultValues()}
          />
        ) : (
          <BusinessWeeklyForm
            isNew={false}
            onSubmit={this.save}
            onDelete={this.remove}
            files={bwItem.files}
            initialValues={this.getItemForForm()}
          />
        )}
      </>
    );
  }
}

export default BusinessWeeklyItemPage;
