import React from 'react';
import classnames from 'classnames';

import {
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from 'reactstrap';

import { TRANSLATIONS } from '../../constants/translations';
import { Field } from 'redux-form';
import InputRedux from '../Input/InputRedux';
import PropTypes from 'prop-types';

class SubscribeFormAdmin extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };
  state = {};

  handleLangChange = (e, value) => {
    this.props.handleLangChange(value);
  };

  render() {
    const { saving, defaultLang, langList, handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit} role="form">
        <FormGroup
          className={classnames('mb-3', {
            focused: this.state.focusedEmail,
          })}
        >
          <InputGroup className="input-group-merge input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-email-83" />
              </InputGroupText>
            </InputGroupAddon>
            <Field
              name="email"
              type="email"
              placeholder="Email"
              disabled={saving}
              required
              onFocus={() => this.setState({ focusedEmail: true })}
              onBlur={() => this.setState({ focusedEmail: false })}
              component={InputRedux}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup className="mb-3">
          <InputGroup className="input-group-merge input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-world-2" />
              </InputGroupText>
            </InputGroupAddon>
            <Field
              name="lang"
              type="select"
              disabled={saving}
              required
              onChange={this.handleLangChange}
              options={langList.map(l => ({
                label: l.fullName,
                value: l.identifier,
              }))}
              component={InputRedux}
            />
          </InputGroup>
        </FormGroup>
        <Button
          className="mb-4 w-100"
          color="default"
          type="submit"
          disabled={saving}
        >
          {saving ? (
            <i className="text-white fas fa-spinner fa-pulse" />
          ) : (
            TRANSLATIONS.SUBSCRIBE[defaultLang.toUpperCase()]
          )}
        </Button>
      </Form>
    );
  }
}

export default SubscribeFormAdmin;
