import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, Modal, CardBody } from 'reactstrap';
import CustomEmailForm from '../../../../components/CustomEmailForm';
import CustomEmailPreview from '../CustomEmailPreview';

class CustomEmailModal extends Component {
  render() {
    const {
      isOpened,
      send,
      schedule,
      close,
      data,
      listsPage,
      langList,
      remove,
    } = this.props;

    return (
      <Modal
        zIndex={1050}
        size="lg"
        className="modal-dialog-centered"
        isOpen={isOpened}
        toggle={() => close()}
        style={{ maxWidth: '90%' }}
        data={data}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0 mb-0">
            <CardHeader className="bg-transparent position-relative">
              <div className="text-muted text-center my-0">
                <p className="mb-0">Send email</p>
              </div>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  close();
                }}
                className="position-absolute right-4 top-3 display-4"
              >
                <i className="ni ni-fat-remove text-default" />
              </a>
            </CardHeader>
            <CardBody className="px-lg-5">
              <Row>
                <Col>
                  <CustomEmailForm
                    handleSend={send}
                    handleSchedule={schedule}
                    data={data}
                    listsPage={listsPage}
                    langList={langList}
                    remove={remove}
                  />
                </Col>
                <Col>
                  <CustomEmailPreview />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default CustomEmailModal;
