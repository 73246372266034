import React from 'react';

import LocalizedLink from '../LocalizedLink';

import { Row, Col, Card, CardTitle, CardBody } from 'reactstrap';

import classes from './RelatedPublications.module.scss';

import { TRANSLATIONS } from '../../constants/translations';
import { ROUTES } from '../../constants/routes';
import { types } from '../../constants/document';
import { IMG_URL, FILE_URL } from '../../constants/files';
import AuthorAndDateLabel from '../AuthorAndDateLabel';
import defaultBg from '../../assets/images/newsletter-default.jpg';

class RelatedPublications extends React.Component {
  componentDidMount() {
    const { getRecent } = this.props;
    getRecent();
  }

  render() {
    const { related = [], recent, lang, defaultLang } = this.props;

    const data = [
      ...related.filter(
        item =>
          item.type === types.ARTICLE || item.type === types.BUSINESS_WEEKLY,
      ),
      ...recent,
    ].slice(0, 3);

    if (!data.length) return null;
    return (
      <>
        <Row className="mb-4">
          <Col>
            <h2 className="display-3 mb-0">
              {TRANSLATIONS.RELATED.EN} {TRANSLATIONS.PUBLICATIONS.EN}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {data.map(item => {
              const langData = item.lang[lang] || {};
              const defaultLangData = item.lang[defaultLang] || {};
              return (
                <Card
                  key={`related-publication-${item.id}`}
                  className="overflow-hidden"
                >
                  <Row noGutters>
                    <Col md={6}>
                      <LocalizedLink to={`${ROUTES.ARTICLE_PARAM}${item.id}`}>
                        <div
                          className={classes.image}
                          style={{
                            backgroundImage: `url(${
                              item.file && item.file.url
                                ? `${IMG_URL}${item.file.url}`
                                : defaultBg
                            })`,
                          }}
                        />
                      </LocalizedLink>
                      {item.pdf && item.pdf.id && (
                        <a
                          href={`${FILE_URL}${item.pdf.watermarkUrl ||
                            item.pdf.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="p-3 lh-100 bg-default position-absolute top-0 right-0 mr-0 display-4 text-white"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-cloud-download-95" />
                          </span>
                        </a>
                      )}
                      {item.files && item.files[lang] && item.files[lang].id && (
                        <a
                          href={`${FILE_URL}${item.files[lang].watermarkUrl ||
                            item.files[lang].url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="p-3 lh-100 bg-default position-absolute top-0 right-0 mr-0 display-4 text-white"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-cloud-download-95" />
                          </span>
                        </a>
                      )}
                    </Col>
                    <Col md={6}>
                      <CardBody>
                        <LocalizedLink
                          to={`${
                            item.type === types.ARTICLE
                              ? ROUTES.ARTICLE_PARAM
                              : ROUTES.BUSINESS_WEEKLY_ITEM_PARAM
                          }${item.id}`}
                        >
                          <CardTitle className="h2 mb-0">
                            {langData.title || defaultLangData.title}
                          </CardTitle>
                        </LocalizedLink>

                        <AuthorAndDateLabel
                          selectedLang={lang}
                          item={item}
                          createdAt={item.createdAt}
                        />
                        <p className="mt-2 h4 card-text">
                          {langData.description || defaultLangData.description}
                        </p>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              );
            })}
          </Col>
          <Col xs={12}>
            <LocalizedLink
              to={ROUTES.ARTICLES}
              className="btn btn-outline-primary btn-sm mt-3 mb-3"
            >
              <span className="btn-inner--text mr-1">
                {TRANSLATIONS.SEE_ALL.EN}
              </span>
              <span className="btn-inner--icon">
                <i className="ni ni-bold-right" />
              </span>
            </LocalizedLink>
          </Col>
        </Row>
      </>
    );
  }
}

export default RelatedPublications;
