import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formValueSelector, reduxForm } from 'redux-form';

import { compose } from '../../utils';
import { lang, listSubscribe } from '../../redux/lang/selectors';
import {
  loading,
  subscribed,
  subscribedError,
} from '../../redux/users/selectors';
import { subscribe } from '../../redux/users/actions';

import SubscribeForm from './SubscribeForm';

const formConect = reduxForm({
  form: 'subscribe',
});

const subscribeValues = formValueSelector('subscribe');

const reduxConect = connect(
  createStructuredSelector({
    loading,
    subscribed,
    subscribedError,
    lang,
    langList: listSubscribe,
    checkedLang: state => subscribeValues(state, 'lang'),
  }),
  { onSubmit: subscribe },
);

export default compose(
  reduxConect,
  formConect,
)(SubscribeForm);
