import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import BannerOrderPanel from './BannerOrderPanel';

import { loading, listAdmin, saving } from '../../../redux/banner/selectors';
import { saveOrder } from '../../../redux/banner/actions';

const selector = createStructuredSelector({
  loading,
  saving,
  banners: listAdmin,
});

export default connect(
  selector,
  { saveOrder },
)(BannerOrderPanel);
