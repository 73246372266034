import Base from './base';

export default class BusinessWeekly extends Base {
  list(query) {
    return this.apiClient.get('business-weekly', query);
  }

  recent() {
    return this.apiClient.get(`business-weekly/recent`);
  }

  get(id) {
    return this.apiClient.get(`business-weekly/${id}`);
  }

  listAdmin(params) {
    return this.apiClient.get('business-weekly/admin', params);
  }

  itemAdmin(id) {
    return this.apiClient.get(`business-weekly/admin/${id}`);
  }

  add(data) {
    return this.apiClient.post('business-weekly/new', { data });
  }

  update(data) {
    return this.apiClient.post('business-weekly', { data });
  }

  remove(id) {
    return this.apiClient.delete(`business-weekly/${id}`);
  }

  saveFile(data) {
    return this.apiClient.post('business-weekly/file/save', data);
  }
}
