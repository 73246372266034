import React from 'react';
import { LANG } from '../../constants/lang';

export const beisDin = {
  title: {
    [LANG.EN]: 'BEIS DIN',
  },
  text: {
    [LANG.EN]: (
      <>
        <p className="h2 text-white">
          BHI maintains a Beis Din of professional dayanim with a thorough
          understanding of business. This Beis Din has successfully arbitrated
          complex monetary disputes worth millions of dollars. This effective &
          efficient arbitration has helped parties avoid costly and
          time-consuming litigation while ensuring halachic compliance.
        </p>
      </>
    ),
  },
};

export const beisDinProcess = {
  title: {
    [LANG.EN]: 'PROCESS',
  },
  text: {
    [LANG.EN]: (
      <>
        <p className="font-weight-normal">
          Once parties mutually agree to adjudicate at our Beis Din, we send
          them our arbitration agreement. The terms of the arbitration agreement
          are standard and non-negotiable, and the scope of the adjudication
          must be agreed upon by both parties.
        </p>
        <p className="font-weight-normal">
          Discovery begins at the Beis Din in the presence of both parties. The
          dayanim do not review any information in advance, which ensures
          impartiality when the proceedings begin and efficiency throughout the
          discovery stage. Any claims either need to be confirmed by both
          parties or substantiated by evidence. While many cases can be resolved
          in one or two sessions, more complex cases may require a few
          additional sessions.
        </p>
        <p className="font-weight-normal">
          Beis Din is meticulous in enforcing appropriate decorum, ensuring a
          respectful attitude from all parties throughout the proceedings.
          Maintain decorum is imperative in saving time and completing all
          proceedings as efficiently as possible.
        </p>
        <p className="font-weight-normal">
          After all claims and counterclaims are submitted and all necessary
          documentation is reviewed, the Beis Din panel adjourns the
          proceedings. At this point, the dayanim discuss the factual and
          halachic dimensions of the case among themselves in order to deliver a
          psak.
        </p>
        <p className="font-weight-normal">
          The psak is typically issued within two weeks, though the occasional
          complex case may result in further delays. The psak is delivered by
          email in a PDF and original copies may be requested.
        </p>
        <p className="h3 text-white font-weight-bold">
          All Beis Din Proceedings are confidential.
        </p>
      </>
    ),
  },
};

export const cost = {
  title: {
    [LANG.EN]: 'COST',
  },
  text: {
    [LANG.EN]: (
      <>
        <p className="font-weight-normal">
          Each party is charged $250 per hour spent with the dayanim, invoiced
          in 30-minute increments. Funds are paid to BHI to help defray the
          costs of maintaining the Beis Din, and dayanim are paid a monthly
          salary irrespective of the number and size of the cases they
          adjudicated.
        </p>
        <p className="font-weight-normal">
          Typically, the Beis Din does not charge for back end time such as
          deliberation between the dayanim, fact finding or research after the
          parties leave, except for unique cases. If the situation requires, the
          Beis Din may request a 3rd party expert evaluate information at the
          parties' expense.
        </p>
      </>
    ),
  },
};

export const faq = {
  title: {
    [LANG.EN]: 'FAQs',
  },
  subtitle: {
    [LANG.EN]: 'Click on a question below to reveal the answer',
  },
  items: [
    {
      id: 'dayanim',
      q: {
        [LANG.EN]: 'Who will be the dayanim in our case?',
      },
      a: {
        [LANG.EN]:
          'The Beis Din has a staff of dayanim and three of them will be assigned based on availability and expertise. We do not allow for the parties or their representatives (lawyer or toen) to request particular dayanim in order to maintain transparency and prevent collusion or conflicts of interest. If a party has a reasonable concern, this can be taken into account and other dayanim will be assigned.',
      },
    },
    {
      id: 'toanim-allowed',
      q: {
        [LANG.EN]: 'Does the Beis Din allow toanim? What about lawyers?',
      },
      a: {
        [LANG.EN]:
          'The Beis Din allows toanim, lawyers, or any additional third-party assistance that either party wants to include though it is appropriate for this information to be disclosed to the other party in advance. Our dayanim are experienced and competent and will prevent the toen/lawyer from controlling the proceedings.',
      },
    },
    {
      id: 'hire-toen',
      q: {
        [LANG.EN]: 'Q: Do I need to hire a toen?',
      },
      a: {
        [LANG.EN]:
          'One does not need to hire a toen. At times a toen or third party can be helpful in helping a litigant stay focused and not let emotions distract him from presenting his actual claims. A toen or lawyer must understand his role and should never attempt to assist his client in distorting the truth.',
      },
    },
    {
      id: 'one-dayan',
      q: {
        [LANG.EN]: 'Can we have just one dayan?',
      },
      a: {
        [LANG.EN]:
          'Our beis din panel prefers three dayanim adjudicate each case, as three dayanim are practically & halachically preferable in reaching factual and halachic outcomes. When exceptions are made and a single arbitrator is assigned to a case, the price remains the same.',
      },
    },
    {
      id: 'pshara',
      q: {
        [LANG.EN]:
          'I noticed a provision in your arbitration agreement for pshara, does that mean the beis din will attempt a pshara even if I am right?',
      },
      a: {
        [LANG.EN]:
          'The provision is there only for when this is the most appropriate halachic approach. Some examples would be: when certain key points are deemed impossible to determine with enough precision; when there is a halachic basis for the tovea’s (claimant’s) side; or when it is in the best interest of both parties, such as when the pshara will allow for a better agreement that will protect both parties’ interests. In some cases, the dayanim will give an option of pshara or din, in others they will impose the ruling as they deem more halachically appropriate. In all cases, the provision for pshara is never taken lightly.',
      },
    },
    {
      id: 'toeva-pays',
      q: {
        [LANG.EN]:
          'Why do I have to pay if the other party summoned me, shouldn’t the tovea (plaintiff) pay for both of us?',
      },
      a: {
        [LANG.EN]:
          'One is halachically obligated to adjudicate his disagreements with a rav or beis din, if one cannot come to an agreement on his own. Therefore, one is responsible to pay the costs. Additionally, for transparency and fairness each side should be invoiced equally.',
      },
    },
    {
      id: 'email',
      q: {
        [LANG.EN]: 'Can I send my response via email?',
      },
      a: {
        [LANG.EN]:
          'The Beis Din allows the parties to respond or submit documents in an email CC’d to the other party. However, a din Torah cannot be conducted by email. If an email conversation between the parties ensues, the Beis Din may request another hearing to discuss these concerns in person.',
      },
    },
    {
      id: 'remote-din-torah',
      q: {
        [LANG.EN]:
          'Can we do the din Torah remotely via Skype or phone conference?',
      },
      a: {
        [LANG.EN]:
          'A very important part of a din Torah is discovery, which is most accurately and efficiently accomplished in person. On occasion, when one party cannot make it in person, he can sign a harsha’ah (power of attorney) and be available by phone to participate. This is strongly discouraged, though occasionally used for a follow up session once the main discovery is already done.',
      },
    },
    {
      id: 'hasmana',
      q: {
        [LANG.EN]:
          'I have a dispute with a partner. Can you send him a hazmana (summons) to appear before your Beis Din?',
      },
      a: {
        [LANG.EN]:
          'As a policy, our Beis Din does not send hazmanos. We only accept cases where both parties already agreed to appear before our beis din and accept our adjudication.',
      },
    },
  ],
};

export const sections = [
  {
    section: 'beis-din',
    label: 'BEIS DIN',
  },
  {
    section: 'beis-din-process',
    label: 'PROCESS',
  },
  {
    section: 'cost',
    label: 'COST',
  },
  {
    section: 'faq',
    label: 'FAQs',
  },
];

export const getItemText = (obj, key, lang, defaultLang = 'EN') => {
  return obj[key] && (obj[key][lang.toUpperCase()] || obj[key][defaultLang]);
};
