import { connect } from 'react-redux';
import AdminNavbar from './AdminNavbar';
import { isAdmin, user } from '../../../redux/profile/selectors';
import { logout } from '../../../redux/profile/actions';
import { createStructuredSelector } from 'reselect';

const selector = createStructuredSelector({
  isAdmin,
  user,
});

export default connect(
  selector,
  { logout },
)(AdminNavbar);
