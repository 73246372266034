import React from 'react';
import classnames from 'classnames';

import { Field } from 'redux-form';
import InputRedux from '../../Input/InputRedux';
import FieldFormGroup from '../../FieldFormGroup';
import Dropzone from '../../Dropzone';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  Button,
  Row,
  Col,
} from 'reactstrap';
import LinkAutoComplete from '../../LinkAutoComplete';

const KEY_FORM_OPTIONS = {
  active: {
    label: 'Published',
    type: 'checkbox',
  },
  order: {
    label: 'Order',
    type: 'number',
    icon: 'folder-17',
  },
  title: {
    label: 'Title',
    type: 'text',
    icon: 'collection',
  },
  description: {
    label: 'Description',
    type: 'textarea',
    icon: 'align-left-2',
  },
  btnLabel: {
    label: 'Button Label',
    type: 'text',
    icon: 'active-40',
  },
  btnLink: {
    label: 'Button Link',
    type: 'text',
    icon: 'active-40',
  },
  caption: {
    label: 'Caption Label',
    type: 'text',
    icon: 'tag',
  },
  captionLink: {
    label: 'Caption Link',
    type: 'text',
    icon: 'tag',
  },
};

class BannerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: props.defaultLang,
    };
  }

  linkSelectorOnChange = name => link => {
    this.props.change(name, link);
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  renderField(key, path = '') {
    const name = path + key;
    const isHE = path === 'lang.he.';

    if (KEY_FORM_OPTIONS[key].type === 'checkbox') {
      return (
        <FormGroup className="mb-3">
          <label className="form-control-label">
            {KEY_FORM_OPTIONS[key].label}
          </label>

          <InputGroup className="mb-3 pt-2">
            <Field
              name={name}
              type={KEY_FORM_OPTIONS[key].type}
              component={InputRedux}
            />
          </InputGroup>
        </FormGroup>
      );
    }

    return (
      <FieldFormGroup
        icon={KEY_FORM_OPTIONS[key].icon}
        label={KEY_FORM_OPTIONS[key].label}
      >
        <Field
          name={name}
          type={KEY_FORM_OPTIONS[key].type}
          onFocus={() => this.setState({ [`focused_${key}`]: true })}
          onBlur={() => this.setState({ [`focused_${key}`]: false })}
          component={InputRedux}
          dir={`${isHE ? 'rtl' : 'ltr'}`}
        />
      </FieldFormGroup>
    );
  }

  render() {
    const { handleSubmit, langList, cancel, saving } = this.props;

    return (
      <Form onSubmit={handleSubmit} role="form">
        <Row>
          <Col sm={6}>
            <label className="form-control-label mt-2">Image</label>
            <Field
              name="img"
              type="image"
              accept="image/*"
              component={Dropzone}
            />
          </Col>
          <Col sm={4} />
          <Col sm={2}>{this.renderField('active')}</Col>
        </Row>
        <hr />
        <Nav
          className="nav-fill flex-column flex-md-row mb-3"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          {langList.map(lang => {
            return (
              <NavItem key={`lang-${lang.identifier}`}>
                <NavLink
                  aria-selected={this.state.tabs === lang.identifier}
                  className={classnames('mb-sm-3 mb-md-0', {
                    active: this.state.tabs === lang.identifier,
                  })}
                  onClick={e => this.toggleNavs(e, 'tabs', lang.identifier)}
                  href="#"
                  role="tab"
                >
                  {lang.label}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        <Card className="shadow mb-0">
          <CardBody>
            <TabContent activeTab={'tabs-' + this.state.tabs}>
              {langList.map(lang => {
                return (
                  <TabPane
                    key={`tab-${lang.identifier}`}
                    tabId={`tabs-${lang.identifier}`}
                  >
                    {this.renderField('title', `lang.${lang.identifier}.`)}
                    {this.renderField(
                      'description',
                      `lang.${lang.identifier}.`,
                    )}

                    <Row>
                      <Col sm={4}>
                        {this.renderField(
                          'btnLabel',
                          `lang.${lang.identifier}.`,
                        )}
                      </Col>
                      <Col sm={4}>{this.renderField('btnLink')}</Col>
                      <Col sm={4}>
                        <label className="form-control-label">Search</label>
                        <LinkAutoComplete
                          onChange={this.linkSelectorOnChange(`btnLink`)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        {this.renderField(
                          'caption',
                          `lang.${lang.identifier}.`,
                        )}
                      </Col>
                      <Col sm={4}>{this.renderField('captionLink')}</Col>
                      <Col sm={4}>
                        <label className="form-control-label">Search</label>
                        <LinkAutoComplete
                          onChange={this.linkSelectorOnChange(`captionLink`)}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                );
              })}
            </TabContent>
          </CardBody>
        </Card>

        <div className="modal-footer px-0 pb-0">
          <Button
            disabled={saving}
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => cancel()}
          >
            Close
          </Button>
          <Button
            disabled={saving}
            color="primary"
            type="submit"
            className="ml-auto"
          >
            {saving ? (
              <i className="text-white fas fa-spinner fa-pulse" />
            ) : (
              'Save changes'
            )}
          </Button>
        </div>
      </Form>
    );
  }
}

export default BannerModal;
