import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader';
import ArticleItem from '../../../components/ArticleItem';
import { Container, Row, Col } from 'reactstrap';
import { ROUTES } from '../../../constants/routes';
import AdminDocWrapper from '../AdminDocWrapper';
import { statusOptions } from '../../../constants/document';
import { articleTypeOptions } from '../../../constants/article';

class ArticleListPage extends Component {
  componentWillMount() {
    this.props.getListAdmin();
    this.props.getTypes();
  }

  render() {
    const { loading, list, remove, allCount } = this.props;

    return (
      <>
        {loading && <Loader type="fixed" />}
        <Container fluid className="py-2 border-bottom">
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="display-2 mb-0">Article list</h2>
            </Col>
            <Col className="text-right">
              <Link
                to={`${ROUTES.ADMIN_ARTICLE_ITEM_PARAM}new`}
                className="btn btn-primary btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text">New</span>
              </Link>
            </Col>
          </Row>
        </Container>
        <Container className="py-4">
          <AdminDocWrapper
            getListAdmin={this.props.getListAdmin}
            allCount={allCount}
            remove={remove}
            filterShape={{
              status: {
                label: 'Status',
                type: 'switcher',
                initialValue: '',
                options: statusOptions,
              },
              type: {
                label: 'Type',
                type: 'select',
                initialValue: '',
                options: articleTypeOptions,
              },
              search: {
                label: 'General search',
                type: 'text',
                initialValue: '',
              },
            }}
            headerList={[
              { title: '#', colStyle: { width: '5%' } },
              {
                title: 'name',
                isSortable: true,
                isAssociated: true,
                colStyle: { width: '33%' },
                dbName: 'title',
              },
              {
                title: 'type',
                isSortable: true,
                dbName: 'subType',
                colStyle: { width: '15%' },
              },
              {
                title: 'status',
                isSortable: true,
                colStyle: { width: '15%' },
              },
              {
                title: 'date',
                isSortable: true,
                dbName: 'createdAt',
                colStyle: { width: '15%' },
              },
              {
                title: 'action',
                centered: true,
                colStyle: { width: '15%' },
              },
            ]}
            rowsList={list.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <ArticleItem key={i} item={item} isAdmin index={i + 1} />
            ))}
          />
        </Container>
      </>
    );
  }
}

export default ArticleListPage;
