import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getItemAdmin,
  add,
  update,
  remove,
  saveField,
  removeField,
  saveFieldsOrder,
} from '../../../redux/form/actions';
import { getListFullAdmin as getDocumentList } from '../../../redux/document/actions';
import {
  loading,
  saving,
  itemAdmin,
  editModalOpened,
} from '../../../redux/form/selectors';
import { list, defaultLang } from '../../../redux/lang/selectors';
import { listFullAdmin as documentList } from '../../../redux/document/selectors';
import FormItemPage from './FormItemPage';

const selector = createStructuredSelector({
  loading,
  saving,
  formItem: itemAdmin,
  langList: list,
  documentList,
  defaultLang,
  editModalOpened,
});

const actions = {
  fetch: getItemAdmin,
  getDocumentList,
  add,
  update,
  remove,
  saveField,
  removeField,
  saveOrder: saveFieldsOrder,
};

export default connect(
  selector,
  actions,
)(FormItemPage);
