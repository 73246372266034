import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AdminLayout from './layouts/Admin';
import MainLayout from './layouts/Main';
import RootLayout from './components/RootLayout';
import ConfirmEmail from './pages/ConfirmEmail';
import AuthContainer from './components/AuthContainer';
import PrivateRoute from './components/PrivateRoute';
import ProfilePage from './pages/ProfilePage';
import { ROUTES } from './constants/routes';

const Routes = () => (
  <RootLayout>
    <Switch>
      <PrivateRoute path={ROUTES.ADMIN} component={AdminLayout} />
      <Route path={ROUTES.CONFIRM_EMAIL} component={ConfirmEmail} />
      <PrivateRoute path={ROUTES.PROFILE} component={ProfilePage} />
      <Route path={ROUTES.AUTH} component={AuthContainer} />
      <Route path={ROUTES.HOME} render={props => <MainLayout {...props} />} />
    </Switch>
  </RootLayout>
);

export default Routes;
