import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import FormActions from '../FormActions';
import FieldFormGroup from '../FieldFormGroup';
import InputRedux from '../../components/Input/InputRedux';
import LanguageTabs from '../LanguageTabs';
import { Form, Row, Col, TabContent, TabPane } from 'reactstrap';

const KEY_FORM_OPTIONS = {
  active: {
    label: 'Published',
    type: 'checkbox',
    icon: 'button-power',
    noWrap: true,
  },
  name: {
    label: 'Name',
    type: 'text',
    icon: 'collection',
  },
  description: {
    label: 'Description',
    type: 'textarea',
    icon: 'align-left-2',
    rows: 4,
  },
  text: {
    label: 'Text',
    type: 'quill',
    noWrap: true,
  },
  btnText: {
    label: 'Button text',
    type: 'text',
    icon: 'active-40',
    placeholder: 'Request Custom Shtar',
  },
};

export default class CustomForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tabs: props.defaultLang,
      fromProps: {},
    };

    this.langChanged = this.langChanged.bind(this);
  }

  langChanged(lang) {
    this.setState({ tabs: lang });
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { defaultLang } = nextProps;
    const newState = { fromProps: state.fromProps };
    if (state.fromProps.defaultLang !== defaultLang) {
      newState.tabs = defaultLang;
      newState.fromProps.defaultLang = defaultLang;
    }

    return newState;
  }

  handleConfirm = handleWarning => values => {
    if (handleWarning) {
      const confirm = window.confirm(
        'Confirm you want to attach form to a document that already has a form attached',
      );
      if (confirm) {
        this.props.onSubmit(values);
      }
    } else {
      this.props.onSubmit(values);
    }
  };

  renderField(key, lang = null) {
    const { saving } = this.props;
    const name = lang ? `lang.${lang}.` + key : key;
    const { icon, type, label, noWrap, ...rest } = KEY_FORM_OPTIONS[key];
    return (
      <FieldFormGroup icon={icon} label={label} lang={lang} wrap={!noWrap}>
        <Field
          name={name}
          type={type}
          disabled={saving}
          onFocus={() => this.setState({ [`focused_${key}`]: true })}
          onBlur={() => this.setState({ [`focused_${key}`]: false })}
          component={InputRedux}
          {...rest}
        />
      </FieldFormGroup>
    );
  }

  render() {
    const {
      saving,
      handleSubmit,
      defaultLang,
      onDelete,
      langList,
      isNew,
      documents,
      documentId,
      initialValues,
    } = this.props;
    const selectedDocument = documents.find(doc => doc.id === documentId) || {};
    const showWarnMsg =
      selectedDocument.form && documentId !== initialValues.documentId;

    return (
      <Form
        onSubmit={handleSubmit(this.handleConfirm(showWarnMsg))}
        role="form"
      >
        <Row className="justify-content-between py-4 mb-4 border-bottom">
          <Col sm={4}>
            <LanguageTabs onChange={this.langChanged} />
          </Col>
          <Col className="text-right">
            <FormActions saving={saving} isNew={isNew} onDelete={onDelete} />
          </Col>
        </Row>
        <Row>
          <Col className="text-right">{this.renderField('active')}</Col>
        </Row>
        <Row>
          <Col>
            <TabContent activeTab={'tabs-' + this.state.tabs}>
              {langList.map(lang => {
                return (
                  <TabPane
                    key={`tab-${lang.identifier}`}
                    tabId={`tabs-${lang.identifier}`}
                  >
                    {this.renderField('name', lang.identifier)}
                  </TabPane>
                );
              })}
            </TabContent>
          </Col>
          <Col>
            <TabContent activeTab={'tabs-' + this.state.tabs}>
              {langList.map(lang => {
                return (
                  <TabPane
                    key={`tab-${lang.identifier}`}
                    tabId={`tabs-${lang.identifier}`}
                  >
                    {this.renderField('btnText', lang.identifier)}
                  </TabPane>
                );
              })}
            </TabContent>
          </Col>
        </Row>
        <Row>
          <Col>
            <TabContent activeTab={'tabs-' + this.state.tabs}>
              {langList.map(lang => {
                return (
                  <TabPane
                    key={`tab-${lang.identifier}`}
                    tabId={`tabs-${lang.identifier}`}
                  >
                    {this.renderField('description', lang.identifier)}
                  </TabPane>
                );
              })}
            </TabContent>
          </Col>
          <Col>
            <FieldFormGroup label="Document" wrap={false}>
              <Field
                name="documentId"
                type="select"
                disabled={saving}
                component={InputRedux}
                options={[
                  { label: 'None', value: '' },
                  ...documents.map(d => ({
                    label: d.lang[defaultLang].title,
                    value: d.id,
                  })),
                ]}
              />
              {showWarnMsg && (
                <span className="h6 my-1 px-2 text-info">
                  Selected document already has connected form
                </span>
              )}
            </FieldFormGroup>
          </Col>
        </Row>
        <TabContent activeTab={'tabs-' + this.state.tabs}>
          {langList.map(lang => {
            return (
              <TabPane
                key={`tab-${lang.identifier}`}
                tabId={`tabs-${lang.identifier}`}
              >
                <Row>
                  <Col>{this.renderField('text', lang.identifier)}</Col>
                </Row>
              </TabPane>
            );
          })}
        </TabContent>
      </Form>
    );
  }
}
