import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getListAdmin } from '../../../redux/category/actions';
import { getListAdmin as getTypeListAdmin } from '../../../redux/category-type/actions';
import { loading, listAdmin } from '../../../redux/category/selectors';
import {
  loading as typeLoading,
  listAdmin as typeListAdmin,
} from '../../../redux/category-type/selectors';
import { list, defaultLang } from '../../../redux/lang/selectors';
import CategoryListPage from './CategoryListPage';

const selector = createStructuredSelector({
  loading,
  typeLoading,
  list: listAdmin,
  typeList: typeListAdmin,
  langList: list,
  defaultLang,
});

const actions = {
  getListAdmin,
  getTypeListAdmin,
};

export default connect(
  selector,
  actions,
)(CategoryListPage);
