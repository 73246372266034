import React from 'react';
import PropTypes from 'prop-types';

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const itemsPerPage = 10;
const cutPages = 5;

class Paginator extends React.Component {
  static propTypes = {
    getItems: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      current: 1,
    };

    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.goToPage = this.goToPage.bind(this);
  }

  next(e) {
    e.preventDefault();
    const { getItems, amount, pageSize = itemsPerPage } = this.props;
    let { current } = this.state;
    if (Math.ceil(amount / pageSize) >= current + 1) current++;
    getItems({ page: current });
    this.setState({ current });
  }

  prev(e) {
    e.preventDefault();
    const { getItems } = this.props;
    let { current } = this.state;
    if (current > 1) current--;
    getItems({ page: current });
    this.setState({ current });
  }

  goToPage(e) {
    e.preventDefault();
    const page = e.target.attributes['data-page'].value;
    const { getItems } = this.props;
    getItems({ page });
    this.setState({ current: parseInt(page) });
  }

  render() {
    const { current } = this.state;
    const { amount, pageSize = itemsPerPage } = this.props;
    const pages = Math.ceil(amount / pageSize);
    const manyPages = pages > 10;
    let pageAfterFirst = 2;
    if (manyPages) {
      if (current > cutPages) {
        pageAfterFirst = current - 2;
      }

      if (current + cutPages > pages) {
        pageAfterFirst = pages - cutPages;
      }
    }
    if (amount < pageSize) return null;
    return (
      <>
        <nav aria-label="Page navigation example">
          <Pagination
            className="pagination justify-content-center"
            listClassName="justify-content-center"
          >
            {current > 1 && (
              <PaginationItem>
                <PaginationLink
                  aria-label="Previous"
                  href="#p"
                  onClick={this.prev}
                >
                  <i className="fa fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </PaginationLink>
              </PaginationItem>
            )}

            <PaginationItem className={current === 1 ? 'active' : ''}>
              <PaginationLink data-page={1} href="#p" onClick={this.goToPage}>
                1
              </PaginationLink>
            </PaginationItem>
            {manyPages && current - cutPages > 0 && (
              <span className="px-2">...</span>
            )}
            {Array(manyPages ? cutPages : pages)
              .fill(pageAfterFirst)
              .map((c, i) => {
                const thisPage = c + i;
                if (pages <= thisPage) return null;
                return (
                  <PaginationItem
                    key={`page-${thisPage}`}
                    className={current === thisPage ? 'active' : ''}
                  >
                    <PaginationLink
                      data-page={thisPage}
                      href="#p"
                      onClick={this.goToPage}
                    >
                      {thisPage}
                    </PaginationLink>
                  </PaginationItem>
                );
              })}
            {manyPages && current + 4 < pages && (
              <span className="px-2">...</span>
            )}
            {pages > 1 && (
              <PaginationItem className={current === pages ? 'active' : ''}>
                <PaginationLink
                  data-page={pages}
                  href="#p"
                  onClick={this.goToPage}
                >
                  {pages}
                </PaginationLink>
              </PaginationItem>
            )}
            {current !== pages && (
              <PaginationItem>
                <PaginationLink aria-label="Next" href="#p" onClick={this.next}>
                  <i className="fa fa-angle-right" />
                  <span className="sr-only">Next</span>
                </PaginationLink>
              </PaginationItem>
            )}
          </Pagination>
        </nav>
      </>
    );
  }
}

export default Paginator;
