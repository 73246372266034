import React from 'react';
import classnames from 'classnames';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import {
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Col,
  Row,
} from 'reactstrap';

import { TRANSLATIONS } from '../../constants/translations';
import { OPTION_SEPARATOR } from '../../constants/fieldTypes';
import { ERROR_MESSAGES } from '../../constants/errorMessages';
import InputRedux from '../Input/InputRedux';
import FieldFormGroup from '../FieldFormGroup/FieldFormGroup';
import { getLabelFromLang } from '../../utils';

class SubscribeForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };
  state = {};

  constructor(props) {
    super(props);

    this.langChecked = this.langChecked.bind(this);
  }

  langChecked(e) {
    const name = e.target.name.split(OPTION_SEPARATOR)[1];
    const { checkedLang, change } = this.props;
    if (e.target.checked) {
      checkedLang.push(name);
      change('lang', checkedLang);
    } else {
      checkedLang.splice(checkedLang.indexOf(name), 1);
      change('lang', checkedLang);
    }
  }

  render() {
    const {
      loading,
      lang,
      langList,
      handleSubmit,
      subscribed,
      subscribedError,
      horizontal = false,
      rowClassNames = '',
    } = this.props;

    const isHE = lang === 'he';

    if (horizontal) {
      return (
        <Form onSubmit={handleSubmit} role="form">
          <Row
            noGutters
            className={classnames(`mt-3 mb-5 join-us-form ${rowClassNames}`, {
              'flex-row-reverse': isHE,
            })}
          >
            {subscribed ? (
              <Col>
                <p className="text-left text-white">
                  You successfully subscribed!
                </p>
              </Col>
            ) : (
              <>
                <Col sm={9}>
                  <Field
                    className={classnames({
                      'text-right': isHE,
                    })}
                    name="email"
                    type="email"
                    placeholder={getLabelFromLang(
                      TRANSLATIONS.ENTER_EMAIL,
                      lang,
                    )}
                    disabled={loading}
                    required
                    onFocus={() => this.setState({ focusedEmail: true })}
                    onBlur={() => this.setState({ focusedEmail: false })}
                    component={InputRedux}
                  />
                </Col>
                <Col sm={3}>
                  <Button
                    color="primary"
                    className={classnames(
                      'text-uppercase btn-block mt-2 mt-sm-0',
                      {
                        'ml-sm-2': !isHE,
                        'ml-sm--2': isHE,
                      },
                    )}
                  >
                    {loading ? (
                      <i className="text-white fas fa-spinner fa-pulse" />
                    ) : (
                      getLabelFromLang(TRANSLATIONS.SEND, lang)
                    )}
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Form>
      );
    }

    return (
      <Form onSubmit={handleSubmit} role="form">
        <p className="text-center">SUBSCRIBE TO OUR NEWSLETTER:</p>
        {subscribedError.code &&
          Object.keys(subscribedError.fields).map(field => (
            <p className="text-center">
              <small key={`error-in-${field}`} className="text-warning">
                {
                  ERROR_MESSAGES[field.toUpperCase()][
                    subscribedError.fields[field]
                  ]
                }
              </small>
            </p>
          ))}
        {subscribed ? (
          <div className="pt-4 pb-5">
            <p className="text-center display-4">
              You successfully subscribed!
            </p>
          </div>
        ) : (
          <>
            <FormGroup
              className={classnames('mb-3', {
                focused: this.state.focusedEmail,
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Field
                  name="email"
                  type="email"
                  placeholder="Email"
                  disabled={loading}
                  required
                  autoFocus
                  onFocus={() => this.setState({ focusedEmail: true })}
                  onBlur={() => this.setState({ focusedEmail: false })}
                  component={InputRedux}
                />
              </InputGroup>
            </FormGroup>

            <FieldFormGroup wrap={false} hideLabel={true}>
              {langList.map(option => {
                const val = option.identifier;
                const label = option.fullName;
                const key = `checkbox-option-${val.toLowerCase()}`;

                return (
                  <div
                    key={key}
                    className="custom-control custom-checkbox my-1"
                  >
                    <label
                      htmlFor={key}
                      className={classnames('custom-control-label text-right', {
                        'd-none': !isHE,
                      })}
                    >
                      {label}
                    </label>
                    <Field
                      id={key}
                      name={`lang${OPTION_SEPARATOR}${val}`}
                      type="checkbox"
                      disabled={loading}
                      className="custom-control-input"
                      component="input"
                      onChange={this.langChecked}
                    />
                    <label
                      htmlFor={key}
                      className={classnames('custom-control-label text-left', {
                        'd-none': isHE,
                      })}
                    >
                      {label}
                    </label>
                  </div>
                );
              })}
            </FieldFormGroup>
            <Button
              className="mb-4 w-100"
              color="default"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <i className="text-white fas fa-spinner fa-pulse" />
              ) : (
                getLabelFromLang(TRANSLATIONS.SEND, lang)
              )}
            </Button>
          </>
        )}
      </Form>
    );
  }
}

export default SubscribeForm;
