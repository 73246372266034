import { connect } from 'react-redux';

import { set } from '../../redux/lang/actions';
import { getGlobalSettings } from '../../redux/settings/actions';

import Main from './Main';

export default connect(
  null,
  { setLang: set, getGlobalSettings },
)(Main);
