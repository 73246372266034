import React from 'react';
import { Col, Row } from 'reactstrap';

const Loader = ({ size = 'display-4', type = '', className = '' }) => {
  let iconColor = 'text-white';
  if (type === 'fixed') {
    className +=
      ' align-items-center no-gutters position-fixed top-0 left-0 w-100 h-100 zi-100 bg-translucent-secondary text-info';
    iconColor = 'text-info';
    size = 'display-2';
  }

  return (
    <Row className={`custom-loader justify-content-center ${className}`}>
      <Col>
        <div className="text-center">
          <i className={`${size} ${iconColor} fas fa-spinner fa-pulse`} />
        </div>
      </Col>
    </Row>
  );
};

export default Loader;
