import React, { Component } from 'react';

export default class ShareBtn extends Component {
  render() {
    const { title, contentType } = this.props;
    const contentTitle =
      contentType && contentType.replace(/_/g, ' ').toLowerCase();
    return (
      <a
        className="btn btn-block btn-lg btn-outline-primary btn-icon ml-0"
        href={`mailto:?body=Hi! I thought you'd find this ${contentTitle ||
          'article'} post from Business Halacha interesting - ${
          window.location.href
        } - ${title}`}
      >
        <span className="btn-inner--icon">
          <i className="fas fa-share-alt" />
        </span>
        <span className="btn-inner--text">Share by E-mail</span>
      </a>
    );
  }
}
