import React, { Component } from 'react';
import { TRANSLATIONS } from '../../constants/translations';

export default class AuthorAndDateLabel extends Component {
  render() {
    const {
      selectedLang = 'en',
      item: { lang },
      createdAt,
    } = this.props;
    const authorLabel =
      (lang && lang[selectedLang] && lang[selectedLang].author) ||
      TRANSLATIONS.AUTHOR_DEFAULT[selectedLang.toUpperCase()];

    return (
      <>
        {authorLabel && (
          <p className="display-5 font-weight-normal text-muted mb-0">
            {authorLabel}
          </p>
        )}
        {createdAt && (
          <p className="h5 font-weight-normal text-muted mb-0">
            {new Date(createdAt).toDateString()}
          </p>
        )}
      </>
    );
  }
}
