import React, { Component } from 'react';
import CategoryForm from '../../../../../components/CategoryForm';
import { Card, CardHeader, Modal, CardBody } from 'reactstrap';

class CategoryModal extends Component {
  save = ({ id, active, parentId, typeId, lang }) => {
    const { add, update, close } = this.props;
    if (id) {
      update({ id, active, parentId, typeId, lang });
    } else {
      add({ active, parentId, typeId, lang });
    }
    close();
  };

  remove = id => {
    const yes = window.confirm('Are you sure you want delete this category?');
    if (!yes) return;
    const { remove } = this.props;
    if (id) {
      remove(id);
    }
  };

  render() {
    const {
      isOpened,
      defaultLang,
      close,
      defaultCategoryType,
      initialValues = {},
    } = this.props;

    return (
      <Modal
        zIndex={1000}
        size="lg"
        className="modal-dialog-centered"
        isOpen={isOpened}
        toggle={() => close()}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0 mb-0">
            <CardHeader className="bg-transparent position-relative">
              <div className="text-muted text-center mt-2 mb-2">
                {initialValues.id ? (
                  <small>Edit category</small>
                ) : (
                  <small>Add category</small>
                )}
              </div>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  close();
                }}
                className="position-absolute right-4 top-4 display-4"
              >
                <i className="ni ni-fat-remove text-default" />
              </a>
            </CardHeader>
            <CardBody className="px-lg-5 pt-0">
              <CategoryForm
                isNew={!initialValues.id}
                onSubmit={this.save}
                onDelete={() => this.remove(initialValues.id)}
                initialValues={{
                  lang: defaultLang,
                  active: false,
                  typeId: defaultCategoryType ? defaultCategoryType.id : null,
                  ...initialValues,
                }}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default CategoryModal;
