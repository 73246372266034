import React from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import LocalizedLink from '../LocalizedLink';
import { Card, CardBody, CardTitle, CardImg, Button } from 'reactstrap';

import { ROUTES } from '../../constants/routes';
import { TRANSLATIONS } from '../../constants/translations';
import { FILE_URL, IMG_URL } from '../../constants/files';
import AuthorAndDateLabel from '../AuthorAndDateLabel';
import defaultBg from '../../assets/images/newsletter-default.jpg';

import { tableTime } from '../../utils';
import { statuses } from '../../constants/document';

class ArticleItem extends React.Component {
  render() {
    const {
      item,
      isAdmin,
      lang,
      selectedLang,
      defaultLang,
      onRemove,
      history,
      index,
    } = this.props;

    const isHE = selectedLang === 'he';
    const title = (lang && item.lang[lang] && item.lang[lang].title) || 'N/A';

    const userSide = (
      <Card key={`article-${item.id}`} className="overflow-hidden">
        <LocalizedLink to={`${ROUTES.ARTICLE_PARAM}${item.id}`}>
          <CardImg
            src={item.file.url ? `${IMG_URL}${item.file.url}` : defaultBg}
            top
          />
        </LocalizedLink>

        {item.pdf && item.pdf.id && (
          <a
            href={`${FILE_URL}${item.pdf.watermarkUrl || item.pdf.url}`}
            target="_blank"
            rel="noopener noreferrer"
            className="p-3 lh-100 bg-default position-absolute top-0 right-0 mr-0 display-4 text-white"
          >
            <span className="btn-inner--icon">
              <i className="ni ni-cloud-download-95" />
            </span>
          </a>
        )}
        <CardBody
          className={classnames({
            'text-right': isHE,
          })}
        >
          <LocalizedLink to={`${ROUTES.ARTICLE_PARAM}${item.id}`}>
            <CardTitle className="h2 mb-0">
              {item.lang[selectedLang]
                ? item.lang[selectedLang].title
                : item.lang[defaultLang]
                ? item.lang[defaultLang].title
                : TRANSLATIONS.NO_TITLE_DEFAULT[selectedLang.toUpperCase()]}
            </CardTitle>
          </LocalizedLink>

          <AuthorAndDateLabel
            selectedLang={selectedLang}
            item={item}
            createdAt={item.createdAt}
          />

          <div className="mt-4 card-text">
            {item.lang[selectedLang]
              ? item.lang[selectedLang].description
              : item.lang[defaultLang]
              ? item.lang[defaultLang].description
              : TRANSLATIONS.NO_DESC_DEFAULT[selectedLang.toUpperCase()]}
          </div>
        </CardBody>
      </Card>
    );

    const adminSide = (
      <tr
        onClick={e => {
          e.preventDefault();
          history.push(`${ROUTES.ADMIN_ARTICLE_ITEM_PARAM}${item.id}`);
        }}
        style={{ cursor: 'pointer' }}
      >
        <th scope="row">{index}</th>
        <th className="truncatedText">
          <span>{title}</span>
        </th>
        <td>{item.subType}</td>
        <td>
          <span
            className={
              item.status === statuses.PUBLISHED
                ? 'text-success'
                : 'text-warning'
            }
          >
            {item.status}
          </span>
        </td>
        <td>{tableTime(item.createdAt)}</td>
        <td className="text-center">
          <Button
            className="btn btn-icon btn-danger btn-sm"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Delete"
            onClick={e => {
              e.stopPropagation();
              onRemove(item.id);
            }}
          >
            <span className="btn-inner--icon">
              <i className="ni ni-fat-remove" />
            </span>
          </Button>
        </td>
      </tr>
    );

    return isAdmin ? adminSide : userSide;
  }
}

export default withRouter(ArticleItem);
