import { connect } from 'react-redux';

import HomePage from './HomePage';

const selector = null;

const actions = {};

export { HomePage };

export default connect(
  selector,
  actions,
)(HomePage);
