export const prefix = 'users';

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`;
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`;
export const LIST_FAILURE = `${prefix}/LIST_FAILURE`;

export const listUsers = () => ({
  types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: api => api.users.list(),
});

export const SHOW_REQUEST = `${prefix}/SHOW_REQUEST`;
export const SHOW_SUCCESS = `${prefix}/SHOW_SUCCESS`;
export const SHOW_FAILURE = `${prefix}/SHOW_FAILURE`;

export const showUser = id => ({
  types: [SHOW_REQUEST, SHOW_SUCCESS, SHOW_FAILURE],
  promise: api => api.users.show(id),
  id,
});

export const HIDE = `${prefix}/HIDE`;

export const hideUser = () => ({
  type: HIDE,
});

export const CONTACT_US_REQUEST = `${prefix}/CONTACT_US_REQUEST`;
export const CONTACT_US_SUCCESS = `${prefix}/CONTACT_US_SUCCESS`;
export const CONTACT_US_FAILURE = `${prefix}/CONTACT_US_FAILURE`;

export const contactUs = ({ name, email, subject, msg }) => ({
  types: [CONTACT_US_REQUEST, CONTACT_US_SUCCESS, CONTACT_US_FAILURE],
  promise: api => api.users.contactUs({ name, email, subject, msg }),
});

export const CONSULTATION_REQUEST = `${prefix}/CONSULTATION_REQUEST`;
export const CONSULTATION_SUCCESS = `${prefix}/CONSULTATION_SUCCESS`;
export const CONSULTATION_FAILURE = `${prefix}/CONSULTATION_FAILURE`;

export const consultation = ({ name, email, phone, reason }) => ({
  types: [CONSULTATION_REQUEST, CONSULTATION_SUCCESS, CONSULTATION_FAILURE],
  promise: api => api.users.consultation({ name, email, phone, reason }),
});

export const DONATE_REQUEST = `${prefix}/DONATE_REQUEST`;
export const DONATE_SUCCESS = `${prefix}/DONATE_SUCCESS`;
export const DONATE_FAILURE = `${prefix}/DONATE_FAILURE`;

export const donate = data => ({
  types: [DONATE_REQUEST, DONATE_SUCCESS, DONATE_FAILURE],
  promise: api => api.users.donate(data),
});

export const SUBSCRIBE_REQUEST = `${prefix}/SUBSCRIBE_REQUEST`;
export const SUBSCRIBE_SUCCESS = `${prefix}/SUBSCRIBE_SUCCESS`;
export const SUBSCRIBE_FAILURE = `${prefix}/SUBSCRIBE_FAILURE`;

export const subscribe = data => ({
  types: [SUBSCRIBE_REQUEST, SUBSCRIBE_SUCCESS, SUBSCRIBE_FAILURE],
  promise: api => api.users.subscribe(data),
});

export const SUBSCRIBERS_REQUEST = `${prefix}/SUBSCRIBERS_REQUEST`;
export const SUBSCRIBERS_SUCCESS = `${prefix}/SUBSCRIBERS_SUCCESS`;
export const SUBSCRIBERS_FAILURE = `${prefix}/SUBSCRIBERS_FAILURE`;

export const getSubscribers = params => ({
  types: [SUBSCRIBERS_REQUEST, SUBSCRIBERS_SUCCESS, SUBSCRIBERS_FAILURE],
  promise: api => api.users.subscribers(params),
});

export const SEND_WEEKLY_REQUEST = `${prefix}/SEND_WEEKLY_REQUEST`;
export const SEND_WEEKLY_SUCCESS = `${prefix}/SEND_WEEKLY_SUCCESS`;
export const SEND_WEEKLY_FAILURE = `${prefix}/SEND_WEEKLY_FAILURE`;

export const sendWeekly = data => ({
  types: [SEND_WEEKLY_REQUEST, SEND_WEEKLY_SUCCESS, SEND_WEEKLY_FAILURE],
  promise: api => api.users.sendWeekly(data),
});

export const SCHEDULE_WEEKLY_REQUEST = `${prefix}/SCHEDULE_WEEKLY_REQUEST`;
export const SCHEDULE_WEEKLY_SUCCESS = `${prefix}/SCHEDULE_WEEKLY_SUCCESS`;
export const SCHEDULE_WEEKLY_FAILURE = `${prefix}/SCHEDULE_WEEKLY_FAILURE`;

export const scheduleWeekly = data => ({
  types: [
    SCHEDULE_WEEKLY_REQUEST,
    SCHEDULE_WEEKLY_SUCCESS,
    SCHEDULE_WEEKLY_FAILURE,
  ],
  promise: api => api.users.scheduleWeekly(data),
});

export const SET_SUBSCRIBED_REQUEST = `${prefix}/SET_SUBSCRIBED_REQUEST`;
export const SET_SUBSCRIBED_SUCCESS = `${prefix}/SET_SUBSCRIBED_SUCCESS`;
export const SET_SUBSCRIBED_FAILURE = `${prefix}/SET_SUBSCRIBED_FAILURE`;

export const setSubscribed = (address, lang, subscribed) => ({
  types: [
    SET_SUBSCRIBED_REQUEST,
    SET_SUBSCRIBED_SUCCESS,
    SET_SUBSCRIBED_FAILURE,
  ],
  data: { address },
  promise: api => api.users.setSubscribed({ address, lang, subscribed }),
});

export const EXPORT_SUBSCRIBERS_REQUEST = `${prefix}/EXPORT_SUBSCRIBERS_REQUEST`;
export const EXPORT_SUBSCRIBERS_SUCCESS = `${prefix}/EXPORT_SUBSCRIBERS_SUCCESS`;
export const EXPORT_SUBSCRIBERS_FAILURE = `${prefix}/EXPORT_SUBSCRIBERS_FAILURE`;

export const exportSubscribers = lang => ({
  types: [
    EXPORT_SUBSCRIBERS_REQUEST,
    EXPORT_SUBSCRIBERS_SUCCESS,
    EXPORT_SUBSCRIBERS_FAILURE,
  ],
  promise: api => api.users.exportSubscribers(lang),
});

export const IMPORT_SUBSCRIBERS_REQUEST = `${prefix}/IMPORT_SUBSCRIBERS_REQUEST`;
export const IMPORT_SUBSCRIBERS_SUCCESS = `${prefix}/IMPORT_SUBSCRIBERS_SUCCESS`;
export const IMPORT_SUBSCRIBERS_FAILURE = `${prefix}/IMPORT_SUBSCRIBERS_FAILURE`;

export const importSubscribers = (files, lang) => {
  const formData = new FormData();
  formData.append('file', files[0], files[0].name);
  formData.append('lang', lang);
  return {
    types: [
      IMPORT_SUBSCRIBERS_REQUEST,
      IMPORT_SUBSCRIBERS_SUCCESS,
      IMPORT_SUBSCRIBERS_FAILURE,
    ],
    promise: api => api.users.importSubscribers(formData),
  };
};

export const ADD_SUBSCRIBER_REQUEST = `${prefix}/ADD_SUBSCRIBER_REQUEST`;
export const ADD_SUBSCRIBER_SUCCESS = `${prefix}/ADD_SUBSCRIBER_SUCCESS`;
export const ADD_SUBSCRIBER_FAILURE = `${prefix}/ADD_SUBSCRIBER_FAILURE`;

export const addSubscriber = data => ({
  types: [
    ADD_SUBSCRIBER_REQUEST,
    ADD_SUBSCRIBER_SUCCESS,
    ADD_SUBSCRIBER_FAILURE,
  ],
  promise: api => api.users.addSubscriber(data),
});
