import React, { Component } from 'react';
import LocalizedLink from '../../components/LocalizedLink';

import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  Card,
} from 'reactstrap';
import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import { getLabelFromLang } from '../../utils';
import ConsultationForm from '../../components/ConsultationForm';

export default class ConsultationPage extends Component {
  render() {
    const { lang } = this.props;
    return (
      <>
        <section className="bg-gradient-info py-5">
          <Container>
            <Breadcrumb listClassName="breadcrumb-dark breadcrumb-links pb-2">
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.HOME}>
                  {getLabelFromLang(LINK_LABELS.HOME, lang)}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.SERVICES}>
                  {getLabelFromLang(LINK_LABELS.SERVICES, lang)}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem aria-current="page" className="active">
                {getLabelFromLang(LINK_LABELS.CONSULTATION, lang)}
              </BreadcrumbItem>
            </Breadcrumb>
            <Row className="align-items-stretch">
              <Col lg={4} xs={12}>
                <h1 className="display-2 text-white mb-0">
                  {getLabelFromLang(LINK_LABELS.CONSULTATION, lang)}
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-5">
          <Container>
            <Row className="align-items-stretch">
              <Col lg={12} xs={12}>
                <Card className="p-4">
                  <ConsultationForm />
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
