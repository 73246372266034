import React, { Component } from 'react';

import classnames from 'classnames';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';
import { TRANSLATIONS } from '../../constants/translations';

export default class CreditCard extends Component {
  state = {};

  render() {
    return (
      <Card className="bg-gradient-primary">
        <CardBody>
          <CardTitle className="text-white">
            {TRANSLATIONS.PAYMENT_DETAILS.EN}
          </CardTitle>
          <Row className="justify-content-end align-items-center">
            <Col xs={3} sm={2}>
              <img
                alt="..."
                src={require('../../assets/images/icons/cards/mastercard.png')}
              />
            </Col>
            <Col xs={3} sm={2}>
              <img
                alt="..."
                src={require('../../assets/images/icons/cards/visa.png')}
              />
            </Col>
            <Col xs={3} sm={2}>
              <img
                alt="..."
                src={require('../../assets/images/icons/cards/paypal.png')}
              />
            </Col>
          </Row>
          <div className="mt-4">
            <FormGroup>
              <InputGroup
                className={classnames('input-group-alternative mb-3', {
                  focused: this.state.nameOnCard,
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-single-02" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Name on card"
                  type="text"
                  onFocus={e => this.setState({ nameOnCard: true })}
                  onBlur={e => this.setState({ nameOnCard: false })}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup
                className={classnames('input-group-alternative mb-3', {
                  focused: this.state.cardNumber,
                })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-credit-card" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Card number"
                  type="text"
                  onFocus={e => this.setState({ cardNumber: true })}
                  onBlur={e => this.setState({ cardNumber: false })}
                />
              </InputGroup>
            </FormGroup>
            <Row>
              <Col xs="6">
                <FormGroup>
                  <InputGroup
                    className={classnames('input-group-alternative mb-3', {
                      focused: this.state.date,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="MM/YY"
                      type="text"
                      onFocus={e => this.setState({ date: true })}
                      onBlur={e => this.setState({ date: false })}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <InputGroup
                    className={classnames('input-group-alternative', {
                      focused: this.state.ccv,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="CCV"
                      type="text"
                      onFocus={e => this.setState({ ccv: true })}
                      onBlur={e => this.setState({ ccv: false })}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    );
  }
}
