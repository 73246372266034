import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import BusinessWeeklyItem from './BusinessWeeklyItem';

import { lang, defaultLang } from '../../redux/lang/selectors';

const selector = createStructuredSelector({
  selectedLang: lang,
  defaultLang,
});

export default connect(selector)(BusinessWeeklyItem);
