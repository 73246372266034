export const prefix = 'customEmail';

export const LISTS_PAGE_REQUEST = `${prefix}/LISTS_PAGE_REQUEST`;
export const LISTS_PAGE_SUCCESS = `${prefix}/LISTS_PAGE_SUCCESS`;
export const LISTS_PAGE_FAILURE = `${prefix}/LISTS_PAGE_FAILURE`;

export const getListsPage = () => ({
  types: [LISTS_PAGE_REQUEST, LISTS_PAGE_SUCCESS, LISTS_PAGE_FAILURE],
  promise: api => api.customEmail.listsPage(),
});

export const SCHEDULE_CUSTOM_EMAIL_REQUEST = `${prefix}/SCHEDULE_CUSTOM_EMAIL_REQUEST`;
export const SCHEDULE_CUSTOM_EMAIL_SUCCESS = `${prefix}/SCHEDULE_CUSTOM_EMAIL_SUCCESS`;
export const SCHEDULE_CUSTOM_EMAIL_FAILURE = `${prefix}/SCHEDULE_CUSTOM_EMAIL_FAILURE`;

export const scheduleCustomEmail = data => ({
  types: [
    SCHEDULE_CUSTOM_EMAIL_REQUEST,
    SCHEDULE_CUSTOM_EMAIL_SUCCESS,
    SCHEDULE_CUSTOM_EMAIL_FAILURE,
  ],
  promise: api => api.customEmail.scheduleCustomEmail(data),
});

export const PREPARE_CUSTOM_EMAIL_REQUEST = `${prefix}/PREPARE_CUSTOM_EMAIL_REQUEST`;
export const PREPARE_CUSTOM_EMAIL_SUCCESS = `${prefix}/PREPARE_CUSTOM_EMAIL_SUCCESS`;
export const PREPARE_CUSTOM_EMAIL_FAILURE = `${prefix}/PREPARE_CUSTOM_EMAIL_FAILURE`;

export const prepareCustomEmail = data => ({
  types: [
    PREPARE_CUSTOM_EMAIL_REQUEST,
    PREPARE_CUSTOM_EMAIL_SUCCESS,
    PREPARE_CUSTOM_EMAIL_FAILURE,
  ],
  promise: api => api.customEmail.scheduleCustomEmail(data),
});

export const SEND_CUSTOM_EMAIL_REQUEST = `${prefix}/SEND_CUSTOM_EMAIL_REQUEST`;
export const SEND_CUSTOM_EMAIL_SUCCESS = `${prefix}/SEND_CUSTOM_EMAIL_SUCCESS`;
export const SEND_CUSTOM_EMAIL_FAILURE = `${prefix}/SEND_CUSTOM_EMAIL_FAILURE`;

export const sendCustomEmail = id => ({
  types: [
    SEND_CUSTOM_EMAIL_REQUEST,
    SEND_CUSTOM_EMAIL_SUCCESS,
    SEND_CUSTOM_EMAIL_FAILURE,
  ],
  promise: api => api.customEmail.sendCustomEmail({ id }),
});

export const GET_CUSTOM_EMAILS_LIST_REQUEST = `${prefix}/GET_CUSTOM_EMAILS_LIST_REQUEST`;
export const GET_CUSTOM_EMAILS_LIST_SUCCESS = `${prefix}/GET_CUSTOM_EMAILS_LIST_SUCCESS`;
export const GET_CUSTOM_EMAILS_LIST_FAILURE = `${prefix}/GET_CUSTOM_EMAILS_LIST_FAILURE`;

export const getCustomEmailsList = () => ({
  types: [
    GET_CUSTOM_EMAILS_LIST_REQUEST,
    GET_CUSTOM_EMAILS_LIST_SUCCESS,
    GET_CUSTOM_EMAILS_LIST_FAILURE,
  ],
  promise: api => api.customEmail.list(),
});

export const REMOVE_CUSTOM_EMAIL_REQUEST = `${prefix}/REMOVE_CUSTOM_EMAIL_REQUEST`;
export const REMOVE_CUSTOM_EMAIL_SUCCESS = `${prefix}/REMOVE_CUSTOM_EMAIL_SUCCESS`;
export const REMOVE_CUSTOM_EMAIL_FAILURE = `${prefix}/REMOVE_CUSTOM_EMAIL_FAILURE`;

export const removeCustomEmail = id => ({
  types: [
    REMOVE_CUSTOM_EMAIL_REQUEST,
    REMOVE_CUSTOM_EMAIL_SUCCESS,
    REMOVE_CUSTOM_EMAIL_FAILURE,
  ],
  promise: api => api.customEmail.remove(id),
});

export const GET_CUSTOM_EMAIL_LIST_LIMIT_REQUEST = `${prefix}/GET_CUSTOM_EMAIL_LIST_LIMIT_REQUEST`;
export const GET_CUSTOM_EMAIL_LIST_LIMIT_SUCCESS = `${prefix}/GET_CUSTOM_EMAIL_LIST_LIMIT_SUCCESS`;
export const GET_CUSTOM_EMAIL_LIST_LIMIT_FAILURE = `${prefix}/GET_CUSTOM_EMAIL_LIST_LIMIT_FAILURE`;

export const getListLimit = (params = {}) => ({
  types: [
    GET_CUSTOM_EMAIL_LIST_LIMIT_REQUEST,
    GET_CUSTOM_EMAIL_LIST_LIMIT_SUCCESS,
    GET_CUSTOM_EMAIL_LIST_LIMIT_FAILURE,
  ],
  promise: api => api.customEmail.listLimit(params),
});
