import React from 'react';

import LocalizedLink from '../LocalizedLink';

import AudioItem from '../AudioItem';
import { Row, Col } from 'reactstrap';

import { TRANSLATIONS } from '../../constants/translations';
import { ROUTES } from '../../constants/routes';
import { types } from '../../constants/document';

class RelatedAudio extends React.Component {
  componentDidMount() {
    const { getRecent } = this.props;
    getRecent();
  }

  render() {
    const { related = [], recent } = this.props;
    const data = [
      ...related.filter(item => item.type === types.AUDIO),
      ...recent,
    ].slice(0, 3);

    if (!data.length) return null;

    return (
      <>
        <Row className="mb-4">
          <Col sm={9} xs={12}>
            <h2 className="display-3 mb-0">
              {TRANSLATIONS.RELATED.EN} {TRANSLATIONS.AUDIO.EN}
            </h2>
          </Col>
        </Row>

        {data.map(item => (
          <AudioItem key={`related-${item.id}`} item={item} />
        ))}
        <Row>
          <Col>
            <LocalizedLink
              to={ROUTES.AUDIO}
              className="btn btn-outline-primary btn-sm mt-3 mb-3"
            >
              <span className="btn-inner--text mr-1">
                {TRANSLATIONS.SEE_ALL.EN}
              </span>
              <span className="btn-inner--icon">
                <i className="ni ni-bold-right" />
              </span>
            </LocalizedLink>
          </Col>
        </Row>
      </>
    );
  }
}

export default RelatedAudio;
