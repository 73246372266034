import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  options(state = {}, action) {
    switch (action.type) {
      case actions.ALERT:
        return action.payload;
      case actions.RESET:
        return {};
      default:
        return state;
    }
  },
});
