import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { list, defaultLang } from '../../../redux/lang/selectors';
import AdminDocWrapper from './AdminDocWrapper';

const selector = createStructuredSelector({
  langList: list,
  defaultLang,
});

export { AdminDocWrapper };

export default connect(selector)(AdminDocWrapper);
