import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  Form,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from 'reactstrap';

import InputRedux from '../Input/InputRedux';
import Dropzone from '../Dropzone';
import FormActions from '../FormActions';
import LanguageTabs from '../LanguageTabs';
import FieldFormGroup from '../FieldFormGroup';
import RelatedForm from '../RelatedForm';
import CategoryField from '../CategoryField';
import CategoryCopyButtons from '../CategoryCopyButtons';

import { statuses, types } from '../../constants/document';
import { FILE_URL } from '../../constants/files';

const KEY_FORM_OPTIONS = {
  status: {
    label: 'Status',
    type: 'select',
    icon: 'button-power',
    options: [statuses.DRAFT, statuses.PUBLISHED],
  },
  title: {
    label: 'Name',
    type: 'text',
    icon: 'collection',
  },
  description: {
    label: 'Description',
    type: 'textarea',
    icon: 'align-left-2',
    rows: 6,
  },
  content: {
    label: 'Content',
    type: 'quill',
    rows: 8,
  },
  file: {
    label: 'File',
    type: 'file',
  },
  pdf: {
    label: 'PDF',
    type: 'file',
  },
  subType: {
    label: 'Type',
    type: 'select',
    icon: 'single-copy-04',
    options: [],
  },
  categories: {
    type: 'multiSelect',
    icon: 'tag',
    placeholder: 'Select category',
  },
};

export default class ArticleForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tabs: props.defaultLang,
      fromProps: {},
    };

    this.langChanged = this.langChanged.bind(this);
    this.relatedChanged = this.relatedChanged.bind(this);
    this.onCategoryCopy = this.onCategoryCopy.bind(this);
  }

  componentDidMount() {
    const {
      getCategoriesRelations,
      getCategoryTypesList,
      getListByDoctype,
    } = this.props;
    getCategoriesRelations();
    getCategoryTypesList();
    getListByDoctype(types.ARTICLE);
  }

  relatedChanged(related) {
    const { change } = this.props;
    change(`related`, related);
  }

  langChanged(lang) {
    this.setState({ tabs: lang });
  }

  onCategoryCopy(fromLang, toLang) {
    const { change, langData } = this.props;

    const categories = langData[fromLang].categories;

    change(`lang.${toLang}.categories`, categories);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { defaultLang, types } = nextProps;
    const newState = { fromProps: state.fromProps };
    if (state.fromProps.defaultLang !== defaultLang) {
      newState.tabs = defaultLang;
      newState.fromProps.defaultLang = defaultLang;
    }
    if (!state.fromProps.types && types.length) {
      newState.fromProps.types = types;
      KEY_FORM_OPTIONS.subType.options = types;
    }

    return newState;
  }

  renderField(key, lang = null, i) {
    const { saving } = this.props;
    const name = lang ? `lang.${lang}.` + key : key;
    const { icon, type, label, ...rest } = KEY_FORM_OPTIONS[key];
    const isHE = lang === 'he';
    return (
      <FieldFormGroup icon={icon} label={label} lang={lang}>
        <Field
          name={name}
          type={type}
          disabled={saving}
          index={i}
          onFocus={() => this.setState({ [`focused_${key}`]: true })}
          onBlur={() => this.setState({ [`focused_${key}`]: false })}
          component={InputRedux}
          dir={`${isHE ? 'rtl' : 'ltr'}`}
          {...rest}
        />
      </FieldFormGroup>
    );
  }

  removeCategory = (id, docType, docTypeLang) => e => {
    const { change, langData } = this.props;
    const filteredCategories = langData[docTypeLang].categories.filter(
      item => item.id !== id,
    );
    change(`lang.${docTypeLang}.categories`, filteredCategories);
  };

  renderPdf() {
    const { pdf } = this.props;

    if (!pdf || !pdf.url) return <p className="my-2">No file saved</p>;
    return (
      <div className="my-2">
        <a
          href={`${FILE_URL}${pdf.url}`}
          className="btn btn-default btn-sm"
          target="_blank"
          rel="noreferrer noopener"
        >
          Open pdf in new tab
        </a>
      </div>
    );
  }

  render() {
    const {
      saving,
      handleSubmit,
      onDelete,
      langList,
      isNew,
      relatedValues,
      relatedDocument,
      relatedAudio,
      categoriesRelations,
      selectedSubType,
      langData,
      change,
    } = this.props;

    const relatedCategories = categoriesRelations.filter(
      item => item.doctype === selectedSubType,
    );

    return (
      <Form onSubmit={handleSubmit} role="form">
        <Row className="justify-content-between py-4 mb-4 border-bottom">
          <Col sm={4}>
            <LanguageTabs onChange={this.langChanged} />
          </Col>
          <Col className="text-right" sm={4}>
            <FormActions saving={saving} isNew={isNew} onDelete={onDelete} />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={6}>
            <Card className="shadow mb-2">
              <CardBody className="bg-secondary">
                <TabContent activeTab={'tabs-' + this.state.tabs}>
                  {langList.map(lang => {
                    return (
                      <TabPane
                        key={`tab-${lang.identifier}`}
                        tabId={`tabs-${lang.identifier}`}
                      >
                        <Row>
                          <Col>
                            {this.renderField('title', lang.identifier)}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {this.renderField('description', lang.identifier)}
                          </Col>
                        </Row>
                      </TabPane>
                    );
                  })}
                </TabContent>
              </CardBody>
            </Card>
          </Col>
          <Col sm={6}>
            <Row>
              <Col md={6}>{this.renderField('subType')}</Col>
              <Col md={6}>{this.renderField('status')}</Col>
            </Row>
            <RelatedForm
              changeRelated={this.relatedChanged}
              relatedValues={relatedValues}
              relatedDefault={{
                [types.AUDIO]: relatedAudio,
                [types.DOCUMENT]: relatedDocument,
              }}
              relatedFields={[types.AUDIO, types.DOCUMENT]}
            />
            <Col />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={6}>
            <label className="form-control-label">
              {KEY_FORM_OPTIONS.pdf.label}
            </label>
            <Field
              name="pdf.url"
              type="pdf"
              accept="application/pdf"
              component={Dropzone}
            />
          </Col>
          <Col sm={6}>
            <label className="form-control-label">Image</label>
            <Field
              name="file.url"
              type="image"
              accept="image/*"
              component={Dropzone}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="shadow mb-6">
              <CardBody className="bg-secondary">
                <TabContent activeTab={'tabs-' + this.state.tabs}>
                  {langList.map((lang, i) => {
                    return (
                      <TabPane
                        key={`tab-${lang.identifier}`}
                        tabId={`tabs-${lang.identifier}`}
                      >
                        <Row>
                          <Col>
                            {this.renderField('content', lang.identifier, i)}
                          </Col>
                        </Row>
                        <Row key={lang.identifier}>
                          <Col>
                            <Card className="shadow">
                              <CardBody className="bg-secondary">
                                <div className="d-flex">
                                  <h3 className="text-muted mt-2 mr-4">
                                    Categories {lang.identifier.toUpperCase()}
                                  </h3>
                                  <CategoryCopyButtons
                                    langList={langList}
                                    lang={lang.identifier}
                                    onClick={this.onCategoryCopy}
                                  />
                                </div>
                                {relatedCategories.length > 0 &&
                                  relatedCategories.map(item => (
                                    <CategoryField
                                      key={item.id}
                                      data={item}
                                      change={change}
                                      docTypeLang={lang.identifier}
                                      selectedCategories={
                                        (langData &&
                                          langData[lang.identifier] &&
                                          langData[lang.identifier]
                                            .categories) ||
                                        []
                                      }
                                      removeCategory={this.removeCategory}
                                    />
                                  ))}
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                    );
                  })}
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="text-right">
            <FormActions saving={saving} isNew={isNew} onDelete={onDelete} />
          </Col>
        </Row>
      </Form>
    );
  }
}
