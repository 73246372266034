import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm, formValueSelector } from 'redux-form';

import { compose } from '../../utils';
import { saving } from '../../redux/business-weekly/selectors';
import { listAdmin, defaultLang } from '../../redux/lang/selectors';
import { getListAdmin as getCategoriesRelations } from '../../redux/materials-category-type/actions';
import { listAdmin as categoriesRelations } from '../../redux/materials-category-type/selectors';
import { getListAdmin as getCategoryTypesList } from '../../redux/category-type/actions';
import { listAdmin as categoryTypes } from '../../redux/category-type/selectors';
import { getListByDoctype } from '../../redux/category/actions';

import BusinessWeeklyForm from './BusinessWeeklyForm';

const formConect = reduxForm({
  form: 'businessWeekly',
  enableReinitialize: true,
});

const values = formValueSelector('businessWeekly');

const reduxConect = connect(
  createStructuredSelector({
    saving,
    langList: listAdmin,
    defaultLang,
    categoriesRelations,
    categoryTypes,
    langValues: state => values(state, 'lang'),
    storyLineValues: state => values(state, 'storyLine'),
    hotlineValues: state => values(state, 'hotline'),
    moneyMattersValues: state => values(state, 'moneyMatters'),
  }),
  { getCategoriesRelations, getCategoryTypesList, getListByDoctype },
);

export default compose(
  reduxConect,
  formConect,
)(BusinessWeeklyForm);
