import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.LIST_REQUEST:
      case actions.LIST_ADMIN_REQUEST:
      case actions.ITEM_ADMIN_REQUEST:
        return true;
      case actions.LIST_SUCCESS:
      case actions.LIST_FAILURE:
      case actions.LIST_ADMIN_SUCCESS:
      case actions.LIST_ADMIN_FAILURE:
      case actions.ITEM_ADMIN_SUCCESS:
      case actions.ITEM_ADMIN_FAILURE:
        return false;
      default:
        return state;
    }
  },
  saving(state = false, action) {
    switch (action.type) {
      case actions.ADD_REQUEST:
      case actions.UPDATE_REQUEST:
      case actions.REMOVE_REQUEST:
        return true;
      case actions.ADD_FAILURE:
      case actions.ADD_SUCCESS:
      case actions.UPDATE_FAILURE:
      case actions.UPDATE_SUCCESS:
      case actions.REMOVE_FAILURE:
      case actions.REMOVE_SUCCESS:
        return false;
      default:
        return state;
    }
  },
  list(state = [], action) {
    switch (action.type) {
      case actions.LIST_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  listAdmin(state = [], action) {
    switch (action.type) {
      case actions.LIST_ADMIN_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  itemAdmin(state = { fields: {} }, action) {
    switch (action.type) {
      case actions.ITEM_ADMIN_SUCCESS:
      case actions.UPDATE_SUCCESS:
        return action.data;
      case actions.REMOVE_SUCCESS:
      case actions.ADD_SUCCESS:
        return { fields: {} };
      default:
        return state;
    }
  },
  categoryTypeModalOpened(state = false, action) {
    switch (action.type) {
      case actions.ADD_REQUEST:
      case actions.UPDATE_REQUEST:
      case actions.REMOVE_REQUEST:
        return true;
      case actions.ADD_SUCCESS:
      case actions.UPDATE_SUCCESS:
      case actions.REMOVE_SUCCESS:
        return false;
      default:
        return state;
    }
  },
});
