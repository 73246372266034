export const prefix = 'customForm';

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`;
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`;
export const LIST_FAILURE = `${prefix}/LIST_FAILURE`;

export const getList = params => ({
  types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: api => api.form.list(params),
});

export const GET_ITEM_REQUEST = `${prefix}/GET_ITEM_REQUEST`;
export const GET_ITEM_SUCCESS = `${prefix}/GET_ITEM_SUCCESS`;
export const GET_ITEM_FAILURE = `${prefix}/GET_ITEM_FAILURE`;

export const getItem = id => ({
  types: [GET_ITEM_REQUEST, GET_ITEM_SUCCESS, GET_ITEM_FAILURE],
  promise: api => api.form.get(id),
});

export const LIST_ADMIN_REQUEST = `${prefix}/LIST_ADMIN_REQUEST`;
export const LIST_ADMIN_SUCCESS = `${prefix}/LIST_ADMIN_SUCCESS`;
export const LIST_ADMIN_FAILURE = `${prefix}/LIST_ADMIN_FAILURE`;

export const getListAdmin = () => ({
  types: [LIST_ADMIN_REQUEST, LIST_ADMIN_SUCCESS, LIST_ADMIN_FAILURE],
  promise: api => api.form.listAdmin(),
});

export const ITEM_ADMIN_REQUEST = `${prefix}/ITEM_ADMIN_REQUEST`;
export const ITEM_ADMIN_SUCCESS = `${prefix}/ITEM_ADMIN_SUCCESS`;
export const ITEM_ADMIN_FAILURE = `${prefix}/ITEM_ADMIN_FAILURE`;

export const getItemAdmin = id => ({
  types: [ITEM_ADMIN_REQUEST, ITEM_ADMIN_SUCCESS, ITEM_ADMIN_FAILURE],
  promise: api => api.form.itemAdmin(id),
});

export const ADD_REQUEST = `${prefix}/ADD_REQUEST`;
export const ADD_SUCCESS = `${prefix}/ADD_SUCCESS`;
export const ADD_FAILURE = `${prefix}/ADD_FAILURE`;

export const add = form => ({
  types: [ADD_REQUEST, ADD_SUCCESS, ADD_FAILURE],
  promise: api => api.form.add(form),
});

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`;

export const update = form => ({
  types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: api => api.form.update(form),
});

export const REMOVE_REQUEST = `${prefix}/REMOVE_REQUEST`;
export const REMOVE_SUCCESS = `${prefix}/REMOVE_SUCCESS`;
export const REMOVE_FAILURE = `${prefix}/REMOVE_FAILURE`;

export const remove = id => ({
  types: [REMOVE_REQUEST, REMOVE_SUCCESS, REMOVE_FAILURE],
  promise: api => api.form.remove(id),
});

export const SAVE_FIELD_REQUEST = `${prefix}/SAVE_FIELD_REQUEST`;
export const SAVE_FIELD_SUCCESS = `${prefix}/SAVE_FIELD_SUCCESS`;
export const SAVE_FIELD_FAILURE = `${prefix}/SAVE_FIELD_FAILURE`;

export const saveField = field => ({
  types: [SAVE_FIELD_REQUEST, SAVE_FIELD_SUCCESS, SAVE_FIELD_FAILURE],
  promise: api => api.form.saveField(field),
});

export const REMOVE_FIELD_REQUEST = `${prefix}/REMOVE_FIELD_REQUEST`;
export const REMOVE_FIELD_SUCCESS = `${prefix}/REMOVE_FIELD_SUCCESS`;
export const REMOVE_FIELD_FAILURE = `${prefix}/REMOVE_FIELD_FAILURE`;

export const removeField = id => ({
  types: [REMOVE_FIELD_REQUEST, REMOVE_FIELD_SUCCESS, REMOVE_FIELD_FAILURE],
  promise: api => api.form.removeField(id),
});

export const SAVE_FIELDS_ORDER_REQUEST = `${prefix}/SAVE_FIELDS_ORDER_REQUEST`;
export const SAVE_FIELDS_ORDER_SUCCESS = `${prefix}/SAVE_FIELDS_ORDER_SUCCESS`;
export const SAVE_FIELDS_ORDER_FAILURE = `${prefix}/SAVE_FIELDS_ORDER_FAILURE`;

export const saveFieldsOrder = fields => ({
  types: [
    SAVE_FIELDS_ORDER_REQUEST,
    SAVE_FIELDS_ORDER_SUCCESS,
    SAVE_FIELDS_ORDER_FAILURE,
  ],
  promise: api => api.form.saveFieldsOrder(fields),
});
