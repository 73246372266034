import { connect } from 'react-redux';

import HotlineFeedbackPage from './HotlineFeedbackPage';

const selector = null;

const actions = {};

export { HotlineFeedbackPage };

export default connect(
  selector,
  actions,
)(HotlineFeedbackPage);
