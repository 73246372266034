const ROUTES = {
  HOME: '/',
  SHULCHAN: '/shulchan',
  BUSINESS_WEEKLY: '/business-weekly',
  BUSINESS_WEEKLY_ARCHIVE: '/business-weekly/archive',
  BUSINESS_WEEKLY_ITEM: '/business-weekly/archive/:id',
  BUSINESS_WEEKLY_ITEM_PARAM: '/business-weekly/archive/',
  ARBITRATION: '/arbitration',
  SERVICES: '/services',
  CONSULTATION: '/services/consultation/',
  DOCUMENTS: '/services/documents',
  DOCUMENT: '/services/documents/:id',
  DOCUMENT_PARAM: '/services/documents/',
  MATERIALS: '/materials',
  AUDIO: '/materials/audio',
  AUDIO_ITEM: '/materials/audio/:id',
  AUDIO_ITEM_PARAM: '/materials/audio/',
  ARTICLES: '/materials/articles',
  ARTICLE: '/materials/articles/:id',
  ARTICLE_PARAM: '/materials/articles/',
  KUNTRAISIM: '/materials/kuntraisim',
  KUNTRAIS: '/materials/kuntraisim/:id',
  KUNTRAIS_PARAM: '/materials/kuntraisim/',
  FORMS_APPLY: '/forms/:id',
  FORMS_APPLY_PARAM: '/forms/',
  HALACHIC_GUIDANCE: '/halachic-guidance',
  CONTACT_US: '/contact',
  ABOUT_US: '/about',
  DONATE: '/donate',
  DONATE_OLD: 'https://donate.businesshalacha.com/',
  HOTLINE_FEEDBACK: '/hotline-feedback',
  SUBSCRIBE: '/subscribe/:langTitle',
  SEARCH_PAGE: '/search',
  SEARCH_PAGE_ADVANCED: '/search/advanced',

  AUTH: '/auth',
  LOGIN: '/auth/login',
  SIGNUP: '/auth/signup',
  CONFIRM_EMAIL: '/confirmEmail/:hash',
  RESET_PASSWORD: '/auth/reset-password/:hash',
  PASSWORD_RECOVERY: '/auth/password-recovery',
  PROFILE: '/profile',

  ADMIN: '/admin',
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_BANNER: '/admin/banner',

  ADMIN_AUDIO: '/admin/audio',
  ADMIN_AUDIO_ITEM: '/admin/audio/:id',
  ADMIN_AUDIO_ITEM_PARAM: '/admin/audio/',

  ADMIN_DOCUMENTS: '/admin/documents',
  ADMIN_DOCUMENT_ITEM: '/admin/document/:id',
  ADMIN_DOCUMENT_ITEM_PARAM: '/admin/document/',

  ADMIN_ARTICLES: '/admin/articles',
  ADMIN_ARTICLE_ITEM: '/admin/article/:id',
  ADMIN_ARTICLE_ITEM_PARAM: '/admin/article/',

  ADMIN_BUSINESS_WEEKLY: '/admin/business-weekly',
  ADMIN_BUSINESS_WEEKLY_ITEM: '/admin/business-weekly/:id',
  ADMIN_BUSINESS_WEEKLY_ITEM_PARAM: '/admin/business-weekly/',

  ADMIN_FORMS: '/admin/forms',
  ADMIN_FORMS_ITEM: '/admin/forms/:id',
  ADMIN_FORMS_ITEM_PARAM: '/admin/forms/',

  ADMIN_SUBMISSIONS: '/admin/submissions',
  ADMIN_SUBMISSIONS_ITEM: '/admin/submissions/:id',
  ADMIN_SUBMISSIONS_ITEM_PARAM: '/admin/submissions/',

  ADMIN_CATEGORIES: '/admin/categories',
  ADMIN_CATEGORIES_ITEM: '/admin/categories/:id',
  ADMIN_CATEGORIES_ITEM_PARAM: '/admin/categories/',

  ADMIN_USERS: '/admin/users',
  ADMIN_SUBSCRIBERS: '/admin/subscribers',
  ADMIN_SETTINGS: '/admin/settings',

  ADMIN_CUSTOM_EMAIL: '/admin/custom-email',
};

export { ROUTES };
