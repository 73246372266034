import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

import SearchForm from './SearchFormTop';

import { lang } from '../../redux/lang/selectors';
import { results, searchedStr, loading } from '../../redux/search/selectors';
import { search, reset } from '../../redux/search/actions';
import { ROUTES } from '../../constants/routes';

const maxResults = 10;

const resultsSelector = createSelector(
  lang,
  results,
  (lang, results) => {
    let suggestions = [];
    if (results.length > maxResults) {
      suggestions = results.slice(0, maxResults);
      suggestions.push({
        showMore: true,
        link: `/${lang}${ROUTES.SEARCH_PAGE}`,
      });
    } else {
      suggestions = results.slice();
    }

    return suggestions;
  },
);
const selector = createStructuredSelector({
  loading,
  searchedStr,
  results: resultsSelector,
  lang,
});

export default connect(
  selector,
  { search, reset },
)(SearchForm);
