import { prefix } from './actions';

export const recent = state => state[prefix].recent;
export const related = state => state[prefix].related;
export const list = state => state[prefix].list;
export const allCount = state => state[prefix].allCount;
export const item = state => state[prefix].item;
export const loading = state => state[prefix].loading;

export const materials = state => state[prefix].materials;
export const autocompleteResult = state => state[prefix].autocompleteResult;
export const autocompleteLoading = state => state[prefix].autocompleteLoading;
export const listAdmin = state => state[prefix].listAdmin;
export const listFullAdmin = state => state[prefix].listFullAdmin;
export const listAdminCount = state => state[prefix].listAdminCount;
export const itemAdmin = state => state[prefix].itemAdmin;
export const saving = state => state[prefix].saving;
