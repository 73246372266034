import React from 'react';

import { Form, Button } from 'reactstrap';

import { Field } from 'redux-form';
import InputRedux from '../Input/InputRedux';
import PropTypes from 'prop-types';

class ImportSubscribersForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };
  state = {};

  render() {
    const { loading, handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit} role="form">
        <Field
          name="files"
          type="file"
          required
          component={InputRedux}
          accept="text/csv"
          disabled={loading}
        />
        <Button
          className="my-4 w-100"
          color="default"
          type="submit"
          disabled={loading}
        >
          {loading ? (
            <i className="text-white fas fa-spinner fa-pulse" />
          ) : (
            'Import'
          )}
        </Button>
      </Form>
    );
  }
}

export default ImportSubscribersForm;
