import React from 'react';

import { Switch } from 'react-router-dom';

import AdminUsersPage from '../../pages/admin/AdminUsersPage';
import BannersPage from '../../pages/admin/BannersPage';
import AudioListPage from '../../pages/admin/AudioListPage';
import AudioItemPage from '../../pages/admin/AudioItemPage';
import DocumentListPage from '../../pages/admin/DocumentListPage';
import DocumentItemPage from '../../pages/admin/DocumentItemPage';
import ArticleListPage from '../../pages/admin/ArticleListPage';
import ArticleItemPage from '../../pages/admin/ArticleItemPage';
import BusinessWeeklyListPage from '../../pages/admin/BusinessWeeklyListPage';
import BusinessWeeklyItemPage from '../../pages/admin/BusinessWeeklyItemPage';
import DashboardPage from '../../pages/admin/DashboardPage';
import SubscribersPage from '../../pages/admin/SubscribersPage';
import SubmissionListPage from '../../pages/admin/SubmissionListPage';
import SubmissionItemPage from '../../pages/admin/SubmissionItemPage';
import FormItemPage from '../../pages/admin/FormItemPage';
import FormListPage from '../../pages/admin/FormListPage';
import CategoryListPage from '../../pages/admin/CategoryListPage';
import CategoryItemPage from '../../pages/admin/CategoryListPage/components/CategoryList';
import SettingsPage from '../../pages/admin/SettingsPage';
import CustomEmailPage from '../../pages/admin/CustomEmailPage';
import AdminNavbar from '../../components/Navbars/Admin';
import AdminFooter from '../../components/Footers/AdminFooter.jsx';
import Sidebar from '../../components/Sidebar/Sidebar.jsx';
import PrivateRoute from '../../components/PrivateRoute';
import { ROUTES } from '../../constants/routes';
import { ADMIN_SIDEBAR_MENU } from '../../constants/labels';
import logo from '../../assets/images/color-logo.svg';

class Admin extends React.Component {
  state = {
    sidenavOpen: true,
  };
  componentWillMount() {
    this.props.getLangList();
    this.props.getLangListAdmin();
  }

  componentDidMount() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-pinned');
    }
    if (!document.body.classList.contains('g-sidenav-show')) {
      document.body.classList.add('g-sidenav-show');
    }
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getBrandText = path => {
    // for (let i = 0; i < routes.length; i++) {
    //   if (
    //     this.props.location.pathname.indexOf(
    //       routes[i].layout + routes[i].path,
    //     ) !== -1
    //   ) {
    //     return routes[i].name;
    //   }
    // }
    return 'Brand';
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = e => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={ADMIN_SIDEBAR_MENU}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: '/',
            imgSrc: logo,
            imgAlt: '...',
          }}
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            <PrivateRoute
              path={ROUTES.ADMIN_DASHBOARD}
              component={DashboardPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_BANNER}
              component={BannersPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_USERS}
              component={AdminUsersPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_AUDIO}
              component={AudioListPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_AUDIO_ITEM}
              component={AudioItemPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_DOCUMENTS}
              component={DocumentListPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_DOCUMENT_ITEM}
              component={DocumentItemPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_ARTICLES}
              component={ArticleListPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_ARTICLE_ITEM}
              component={ArticleItemPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_BUSINESS_WEEKLY}
              component={BusinessWeeklyListPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_BUSINESS_WEEKLY_ITEM}
              component={BusinessWeeklyItemPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_SUBSCRIBERS}
              component={SubscribersPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_FORMS}
              component={FormListPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_FORMS_ITEM}
              component={FormItemPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_SUBMISSIONS}
              component={SubmissionListPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_SUBMISSIONS_ITEM}
              component={SubmissionItemPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_CATEGORIES}
              component={CategoryListPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_CATEGORIES_ITEM}
              component={CategoryItemPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_SETTINGS}
              component={SettingsPage}
              exact
            />
            <PrivateRoute
              path={ROUTES.ADMIN_CUSTOM_EMAIL}
              component={CustomEmailPage}
              exact
            />
          </Switch>
          <AdminFooter />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

export default Admin;
