import { prefix } from './actions';

export const loading = state => state[prefix].loading;
export const saving = state => state[prefix].saving;
export const savingSubscriber = state => state[prefix].savingSubscriber;
export const list = state => state[prefix].list;
export const selected = state => state[prefix].selected;
export const subscribed = state => state[prefix].subscribed;
export const subscribedError = state => state[prefix].subscribedError;
export const subscribersList = state => state[prefix].subscribersList;
export const subscribersCount = state => state[prefix].subscribersCount;
export const donationLoading = state => state[prefix].donationLoading;
export const contactUsSent = state => state[prefix].contactUsSent;
export const consultationSent = state => state[prefix].consultationSent;
export const sending = state => state[prefix].sending;
