import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { lang } from '../../../redux/lang/selectors';

import MainFooter from './MainFooter';

const selector = createStructuredSelector({ lang });

export default connect(selector)(MainFooter);
