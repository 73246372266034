import React from 'react';
import { Row, Modal, Card, CardHeader, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SubscribeForm from '../SubscribeForm';

import classes from './SubscribeButton.module.scss';
import { TRANSLATIONS } from '../../constants/translations';
import { OPTION_SEPARATOR } from '../../constants/fieldTypes';
import { getLabelFromLang } from '../../utils';

class SubscribeButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(open = false) {
    this.setState({ modalIsOpen: open });
  }

  render() {
    const { lang } = this.props;
    const { modalIsOpen } = this.state;
    const isHE = lang === 'he';

    return (
      <>
        <Row
          className={`m-0 ${classes.subscribe} ${
            isHE ? 'flex-row-reverse' : ''
          }`.trim()}
        >
          <a
            href="/"
            className={`col-10 justify-content-${
              isHE ? 'end' : 'start'
            }`.trim()}
            onClick={e => {
              e.preventDefault();
              this.toggleModal(true);
            }}
          >
            <span className="h3 m-0 text-default text-uppercase">
              {getLabelFromLang(TRANSLATIONS.SUBSCRIBE, lang)}
            </span>
          </a>
          <a
            href="/"
            onClick={e => {
              e.preventDefault();
              this.toggleModal(true);
            }}
            className="col-2 h3 m-0 text-white font-weight-300 bg-default"
          >
            <FontAwesomeIcon icon={`chevron-${isHE ? 'left' : 'right'}`} />
          </a>
        </Row>

        <Modal
          zIndex={1000}
          className="modal-dialog-centered"
          isOpen={modalIsOpen}
          toggle={() => this.toggleModal()}
        >
          <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0 mb-0">
              <CardHeader className="bg-transparent position-relative">
                <div className="text-muted text-center mt-2 mb-2">
                  <small>Subscription form</small>
                </div>
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    this.toggleModal();
                  }}
                  className="position-absolute right-4 top-4 display-4"
                >
                  <i className="ni ni-fat-remove text-default" />
                </a>
              </CardHeader>
              <CardBody className="px-lg-5">
                <SubscribeForm
                  initialValues={{
                    lang: [lang],
                    [`lang${OPTION_SEPARATOR}${lang}`]: true,
                  }}
                />
              </CardBody>
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}

export default SubscribeButton;
