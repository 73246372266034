import React from 'react';
import { Link } from 'react-router-dom';

const LocalizedLink = ({ lang, dispatch, to, children, ...rest }) => {
  return (
    <Link to={`/${lang}${to}`} {...rest}>
      {children}
    </Link>
  );
};

export default LocalizedLink;
