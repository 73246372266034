export const articleTypes = {
  ARTICLE: 'ARTICLE',
  PUBLICATION: 'PUBLICATION',
};

export const articleTypeOptions = [
  { value: '', label: 'All' },
  { value: articleTypes.ARTICLE, label: 'Article' },
  { value: articleTypes.PUBLICATION, label: 'Publication' },
];
