import { connect } from 'react-redux';

import SubscribePage from './SubscribePage';

const selector = null;

const actions = {};

export default connect(
  selector,
  actions,
)(SubscribePage);
