import { takeEvery, put } from 'redux-saga/effects';
import {
  SEND_CUSTOM_EMAIL_SUCCESS,
  SCHEDULE_CUSTOM_EMAIL_SUCCESS,
  REMOVE_CUSTOM_EMAIL_SUCCESS,
  getListLimit,
  PREPARE_CUSTOM_EMAIL_SUCCESS,
  sendCustomEmail,
} from './actions';

export function* customEmailPrepared() {
  yield takeEvery(PREPARE_CUSTOM_EMAIL_SUCCESS, function*(action) {
    yield put(sendCustomEmail(action.data.id));
  });
}

export function* customEmailScheduled() {
  yield takeEvery(SCHEDULE_CUSTOM_EMAIL_SUCCESS, function*(action) {
    yield put(getListLimit());
  });
}

export function* customEmailSent() {
  yield takeEvery(SEND_CUSTOM_EMAIL_SUCCESS, function*(action) {
    yield put(getListLimit());
  });
}

export function* customEmailRemoved() {
  yield takeEvery(REMOVE_CUSTOM_EMAIL_SUCCESS, function*(action) {
    yield put(getListLimit());
  });
}
