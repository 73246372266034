import { connect } from 'react-redux';

import AboutPage from './AboutPage';

const selector = null;

const actions = {};

export { AboutPage };

export default connect(
  selector,
  actions,
)(AboutPage);
