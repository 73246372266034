import Base from './base';

export default class Lang extends Base {
  list() {
    return this.apiClient.get('lang');
  }
  listAdmin() {
    return this.apiClient.get('lang/admin');
  }
  listSubscribe() {
    return this.apiClient.get('lang/subscribe');
  }
}
