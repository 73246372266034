import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Autosuggest from 'react-autosuggest';

export default class DocumentAutoComplete extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    defaultValue: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
      lang: props.defaultLang,
      fromProps: {
        resultList: [],
        defaultValue: null,
      },
    };

    this.onSearch = _.debounce(e => this.onSuggestionsFetchRequested(e), 800);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { resultList, type, defaultValue } = nextProps;
    const newState = {
      fromProps: {
        resultList: state.fromProps.resultList,
        defaultValue: state.fromProps.defaultValue,
      },
    };
    const suggestions = resultList[type];
    if (state.fromProps.resultList !== suggestions) {
      newState.suggestions = suggestions;
      newState.fromProps.resultList = suggestions;
    }
    if (!state.fromProps.defaultValue && defaultValue) {
      newState.value = defaultValue.label;
      newState.fromProps.defaultValue = defaultValue;
    }
    if (state.fromProps.defaultValue && !defaultValue) {
      newState.value = '';
      newState.fromProps.defaultValue = defaultValue;
    }

    return newState;
  }

  onSuggestionSelected = (event, { suggestion }) => {
    const { defaultValue, type } = this.props;
    const relatedItem = { id: suggestion.id, type };
    if (defaultValue && defaultValue.id) {
      relatedItem.prevId = defaultValue.id;
    }
    this.props.onChange(relatedItem);
    this.setState({
      searchedStr: suggestion.id ? suggestion.label : null,
    });
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
    if (!newValue) {
      this.onSuggestionSelected(event, { suggestion: { id: null } });
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { autocompleteSearch, type } = this.props;
    const { searchedStr } = this.state;
    if (searchedStr && searchedStr.toLowerCase() === value.toLowerCase())
      return;
    const searchStr = value.trim().toLowerCase();
    const inputLength = searchStr.length;
    if (inputLength) autocompleteSearch({ type, searchStr });
    this.setState({ searchedStr: searchStr });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { autocompleteLoading } = this.props;
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: 'Type to search',
      value,
      onChange: this.onChange,
    };

    return (
      <div className="position-relative">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSearch}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          onSuggestionSelected={this.onSuggestionSelected}
          renderSuggestion={s => <div>{s.label}</div>}
          inputProps={inputProps}
        />
        {autocompleteLoading && (
          <div className="bg-translucent-neutral text-center position-absolute top-0 left-0 w-100 h-100">
            <i className="mt-3 text-info fas fa-spinner fa-pulse" />
          </div>
        )}
      </div>
    );
  }
}

const getSuggestionValue = suggestion =>
  suggestion.id ? suggestion.label : '';
