import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import FieldFormGroup from '../FieldFormGroup';
import InputRedux from '../../components/Input/InputRedux';
import { Form, Row, Col, Button } from 'reactstrap';

import { FIELD_TYPES, OPTION_SEPARATOR } from '../../constants/fieldTypes';
import { STATUSES } from '../../constants/submissionStatuses';

const KEY_FORM_OPTIONS = {
  email: {
    label: 'Client email',
    name: 'email',
    type: 'email',
    required: true,
  },
  status: {
    label: 'Status',
    name: 'status',
    type: 'select',
    options: [
      STATUSES.INCOMING,
      STATUSES.PROCESSING,
      STATUSES.INPROGRESS,
      STATUSES.COMPLETED,
    ],
    required: true,
  },
};

export default class SubmissionAdminForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  renderField(field) {
    const { saving } = this.props;
    const { name, icon, type, label, options, ...rest } = field;
    if (options && options.options) {
      rest.options = options.options;
    }
    if (options && !options.options) {
      rest.options = options;
    }

    if (type === FIELD_TYPES.radio.value) {
      return (
        <FieldFormGroup key={`field-${name}`} label={label} wrap={false}>
          {rest.options.map(option => {
            let val, label;
            if (typeof option === 'object') {
              val = option.value;
              label = option.label;
            } else {
              val = option;
              label = option;
            }
            const key = `checkbox-option-${val.toLowerCase()}`;
            return (
              <div className="custom-control custom-checkbox my-1" key={key}>
                <Field
                  id={key}
                  name={`${name}${OPTION_SEPARATOR}${val}`}
                  type="checkbox"
                  disabled={saving}
                  className="custom-control-input"
                  component="input"
                />
                <label className="custom-control-label" htmlFor={key}>
                  {label}
                </label>
              </div>
            );
          })}
        </FieldFormGroup>
      );
    }
    return (
      <FieldFormGroup
        key={`field-${name}`}
        icon={icon}
        label={label}
        wrap={type !== 'checkbox'}
      >
        <Field
          name={name}
          type={type}
          disabled={saving}
          component={InputRedux}
          {...rest}
        />
      </FieldFormGroup>
    );
  }

  render() {
    const {
      saving,
      handleSubmit,
      selectedLang,
      formFields,
      onRemove,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit} role="form">
        <Row>
          <Col lg={6}>
            {this.renderField(KEY_FORM_OPTIONS.email)}
            {formFields
              .filter(field => field.lang === selectedLang)
              .sort((f1, f2) => f1.order > f2.order)
              .map(field => this.renderField(field))}
          </Col>
          <Col lg={5} className="pl-lg-5 ml-lg-5">
            <div className="border-left h-100 position-absolute left-0 top-0 d-none d-lg-block" />
            <div className="border-top w-100 d-lg-none my-3" />
            {this.renderField(KEY_FORM_OPTIONS.status)}
            <Button color="default" className="btn-block">
              {saving ? (
                <i className="text-white fas fa-spinner fa-pulse" />
              ) : (
                'Update'
              )}
            </Button>
            <span />
            <Button
              onClick={onRemove}
              color="danger"
              type="button"
              className="btn-block mt-2"
            >
              Remove
            </Button>
          </Col>
        </Row>

        <Row className="mt-4" />
      </Form>
    );
  }
}
