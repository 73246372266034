import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { saving } from '../../redux/document/selectors';
import { defaultLang } from '../../redux/lang/selectors';

import RelatedForm from './RelatedForm';

export default connect(
  createStructuredSelector({
    saving,
    defaultLang,
  }),
)(RelatedForm);
