import React, { Component } from 'react';
import SubscribeFormAdmin from '../../../components/SubscribeFormAdmin';
import { Card, CardHeader, Modal, CardBody } from 'reactstrap';

class AddSubscriberModal extends Component {
  render() {
    const { isOpened, defaultLang, close, handleLangChange } = this.props;

    return (
      <Modal
        zIndex={1000}
        className="modal-dialog-centered"
        isOpen={isOpened}
        toggle={() => close()}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0 mb-0">
            <CardHeader className="bg-transparent position-relative">
              <div className="text-muted text-center mt-2 mb-2">
                <small>Add new subscriber</small>
              </div>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  close();
                }}
                className="position-absolute right-4 top-4 display-4"
              >
                <i className="ni ni-fat-remove text-default" />
              </a>
            </CardHeader>
            <CardBody className="px-lg-5">
              <SubscribeFormAdmin
                initialValues={{ lang: defaultLang }}
                handleLangChange={handleLangChange}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default AddSubscriberModal;
