import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { add as addForm } from '../../../redux/form/actions';
import {
  getItemAdmin,
  add,
  update,
  remove,
  setFile,
} from '../../../redux/document/actions';
import { loading, saving, itemAdmin } from '../../../redux/document/selectors';
import { list, defaultLang } from '../../../redux/lang/selectors';
import DocumentItemPage from './DocumentItemPage';

const selector = createStructuredSelector({
  loading,
  saving,
  documentItem: itemAdmin,
  langList: list,
  defaultLang,
});

const actions = {
  fetch: getItemAdmin,
  add,
  update,
  remove,
  setFile,
  addForm,
};

export { DocumentItemPage };

export default connect(
  selector,
  actions,
)(DocumentItemPage);
