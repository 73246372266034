import React from 'react';
import PropTypes from 'prop-types';
import { Input as InputStrap } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import ReactQuill from 'react-quill';

import Select from '../Select';

class Input extends React.Component {
  handleChange = handleType => (event, options = {}) => {
    const { onChange } = this.props;
    if (handleType) options.handleType = handleType;
    onChange(event, options);
  };

  render() {
    const { type, placeholder = '', ...rest } = this.props;

    if (type === 'checkbox') {
      return (
        <div>
          <label className="custom-toggle">
            <input type={type} {...rest} />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </div>
      );
    }

    if (type === 'select') {
      const { options } = this.props;
      return (
        <InputStrap
          {...rest}
          placeholder={placeholder}
          type={type}
          onChange={this.handleChange('select')}
        >
          {options &&
            options.map(option => {
              let val, label;
              if (typeof option === 'object') {
                val = option.value;
                label = option.label;
              } else {
                val = option;
                label = option;
              }
              return (
                <option key={`select-option-${val.toLowerCase()}`} value={val}>
                  {label}
                </option>
              );
            })}
        </InputStrap>
      );
    }

    if (type === 'react-select') {
      return (
        <Select
          {...this.props}
          value={this.props.options.find(
            option => option.value === this.props.value,
          )}
          onChange={this.handleChange('react-select')}
        />
      );
    }

    if (type === 'file') {
      const { fileid, accept, filechanged, ...fileTypeRest } = this.props;

      return (
        <div className="custom-file">
          <input
            className="custom-file-input"
            id={fileid}
            lang="en"
            type="file"
            accept={accept}
            {...fileTypeRest}
          />
          <label className="custom-file-label" htmlFor={fileid}>
            Select file
          </label>
        </div>
      );
    }

    if (type === 'quill') {
      return (
        <ReactQuill
          theme="snow"
          modules={{
            toolbar: [
              ['bold', 'italic'],
              ['link', 'blockquote', 'code', 'image'],
              [
                {
                  list: 'ordered',
                },
                {
                  list: 'bullet',
                },
              ],
            ],
          }}
          {...rest}
        />
      );
    }

    if (type === 'date') {
      return (
        <ReactDatetime
          inputProps={{
            placeholder: 'Set your date',
          }}
          timeFormat={false}
          onChange={this.onChange}
        />
      );
    }

    if (type === 'time') {
      return (
        <ReactDatetime
          inputProps={{
            placeholder: 'Set your time',
          }}
          dateFormat={false}
          timeFormat={true}
          onChange={this.onChange}
        />
      );
    }

    if (type === 'options') {
      const { options } = this.props;
      return (
        <>
          {options &&
            options.map(option => {
              let val, label;
              if (typeof option === 'object') {
                val = option.value;
                label = option.label;
              } else {
                val = option;
                label = option;
              }
              const key = `checkbox-option-${val.toLowerCase()}`;
              return (
                <div className="custom-control custom-checkbox my-1" key={key}>
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id={key}
                    name={val}
                  />
                  <label className="custom-control-label" htmlFor={key}>
                    {label}
                  </label>
                </div>
              );
            })}
        </>
      );
    }

    if (type === 'switcher') {
      const { options, name, value } = this.props;
      return (
        <div className="custom-switcher">
          {options &&
            options.map(option => {
              let val, label;
              if (typeof option === 'object') {
                val = option.value;
                label = option.label;
              } else {
                val = option;
                label = option;
              }
              const key = `switcher-option-${val.toLowerCase()}`;

              return (
                <label key={key} className="custom-switch-item">
                  <input
                    className="custom-control-input"
                    type="radio"
                    checked={value === val}
                    value={val}
                    name={name}
                    onChange={this.handleChange('switcher')}
                  />
                  <span className="custom-switch-item-label">{label}</span>
                </label>
              );
            })}
        </div>
      );
    }

    return <InputStrap placeholder={placeholder} type={type} {...rest} />;
  }
}

Input.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'number',
    'select',
    'react-select',
    'checkbox',
    'time',
    'date',
    'file',
    'switcher',
    'options',
    'quill',
  ]),
  placeholder: PropTypes.string,
};

export default Input;
