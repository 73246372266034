import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import SubmissionAdminForm from '../../../components/SubmissionAdminForm';
import { Container, Row, Col } from 'reactstrap';
import { ROUTES } from '../../../constants/routes';
import { STATUS_COLORS } from '../../../constants/submissionStatuses';
import { Link } from 'react-router-dom';

class SubmissionItemPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fromProps: {},
    };

    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const {
      match: {
        params: { id },
      },
    } = nextProps;
    const newState = { fromProps: { id: state.fromProps.id } };

    if (id !== state.fromProps.id) {
      newState.fromProps.id = id;
    }

    return newState;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fromProps: { id },
    } = this.state;
    if (prevState.fromProps.id !== id) {
      this.props.fetch(id);
    }
  }

  componentDidMount() {
    const {
      fetch,
      match: {
        params: { id },
      },
    } = this.props;
    fetch(id);
  }

  save(data) {
    const { update, submission } = this.props;
    const { email, status, ...rest } = data;
    update({ id: submission.id, email, status, fields: rest });
  }

  remove() {
    const yes = window.confirm('Are you sure you want delete the submission?');
    if (!yes) return;
    const { remove, submission } = this.props;
    if (submission.id) {
      remove(submission.id);
    }
  }

  render() {
    const { loading, saving, submission, defaultLang } = this.props;

    if (!submission.id) return <Loader type="fixed" />;

    return (
      <>
        {(loading || saving) && <Loader type="fixed" />}
        <Container fluid className="py-2 border-bottom">
          <Row className="justify-content-between align-items-center">
            <Col md={8}>
              <h2 className="display-2 mb-0">
                Submission
                <p className={`text-${STATUS_COLORS[submission.status]}`}>
                  ({submission.status})
                </p>
              </h2>
            </Col>
            <Col className="text-right">
              <Link
                to={ROUTES.ADMIN_SUBMISSIONS}
                className="btn btn-warning btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-remove" />
                </span>
                <span className="btn-inner--text">Cancel</span>
              </Link>
            </Col>
          </Row>
        </Container>
        <Container className="py-5">
          <Row>
            <Col>
              <span className="display-4">
                {submission.form.lang && submission.form.lang[defaultLang].name}
              </span>
              {submission.form.deletedAt && (
                <span className="ml-2">(form was deleted)</span>
              )}
              &nbsp;
              {submission.trello && submission.trello.shortUrl ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={submission.trello.shortUrl}
                >
                  (view on Trello)
                </a>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <SubmissionAdminForm
                selectedLang={submission.submittedLang}
                formFields={submission.form.fields || []}
                initialValues={{
                  email: submission.email,
                  status: submission.status,
                  ...submission.fields,
                }}
                onSubmit={this.save}
                onRemove={this.remove}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default SubmissionItemPage;
