import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ServicesPage from './ServicesPage';
import { lang } from '../../redux/lang/selectors';

const selector = createStructuredSelector({
  lang,
});

const actions = {};

export { ServicesPage };

export default connect(
  selector,
  actions,
)(ServicesPage);
