import React from 'react';
import classnames from 'classnames';

import LocalizedLink from '../../LocalizedLink';
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  NavbarBrand,
} from 'reactstrap';
import { ROUTES } from '../../../constants/routes';
import { LINK_LABELS } from '../../../constants/labels';
import { TRANSLATIONS } from '../../../constants/translations';
import { EMAIL, PHONE } from '../../../constants/contactInfo';
import SubscribeForm from '../../SubscribeForm';
import logo from '../../../assets/images/color-logo.svg';

import { getLabelFromLang } from '../../../utils';

class Login extends React.Component {
  render() {
    const { lang } = this.props;
    const isHE = lang === 'he';

    return (
      <>
        <footer
          className="py-5 bg-default position-relative navbar-dark"
          id="footer-main"
        >
          <Container>
            <NavbarBrand
              className="position-absolute top--1 mt-3 ml--3 d-none d-lg-block"
              to="/"
              tag={LocalizedLink}
            >
              <img alt="..." src={logo} />
            </NavbarBrand>
            <Row className="align-items-center justify-content-lg-between mt-0 mt-lg-5">
              <Col md={4} lg={2} className="justify-content-sm-between">
                <Row className="align-items-center mb-md-0 mb-4">
                  <Col xs={6} md={12}>
                    <NavbarBrand
                      className="d-lg-none"
                      to="/"
                      tag={LocalizedLink}
                    >
                      <img alt="..." src={logo} />
                    </NavbarBrand>
                  </Col>
                  <Col xs={6} md={12}>
                    {/* <LocalizedLink
                      className="btn btn-icon btn-success mt-3 mb-3 mt-lg-6 mb-lg-5"
                      to={ROUTES.DONATE}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-diamond" />
                      </span>
                      <span className="btn-inner--text">Donate</span>
                    </LocalizedLink> */}
                    <a
                      className="btn btn-success btn-icon"
                      color="success"
                      href={ROUTES.DONATE_OLD}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-diamond" />
                      </span>
                      <span className="btn-inner--text">
                        {TRANSLATIONS.DONATE.EN}
                      </span>
                    </a>
                  </Col>
                </Row>

                <div className="copyright text-muted d-none d-md-block mt-4">
                  ©&nbsp;
                  <LocalizedLink
                    className="font-weight-bold text-white ml-1"
                    to="/"
                    rel="noopener noreferrer"
                  >
                    BHI.
                  </LocalizedLink>
                  &nbsp;
                  {new Date().getFullYear()}
                </div>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={2}
                className="footer-nav text-center text-md-left"
              >
                <Nav className="navbar-nav">
                  <NavItem>
                    <NavLink to={ROUTES.BUSINESS_WEEKLY} tag={LocalizedLink}>
                      {getLabelFromLang(LINK_LABELS.BUSINESS_WEEKLY, lang)}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to={ROUTES.ARBITRATION} tag={LocalizedLink}>
                      {getLabelFromLang(LINK_LABELS.ARBITRATION, lang)}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to={ROUTES.DOCUMENTS} tag={LocalizedLink}>
                      {getLabelFromLang(LINK_LABELS.DOCUMENTS, lang)}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to={ROUTES.MATERIALS} tag={LocalizedLink}>
                      {getLabelFromLang(LINK_LABELS.MATERIALS, lang)}
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col
                xs={6}
                md={4}
                lg={3}
                className="footer-nav text-center text-md-left"
              >
                <Nav className="navbar-nav">
                  <NavItem>
                    <NavLink to={ROUTES.ARTICLES} tag={LocalizedLink}>
                      {getLabelFromLang(LINK_LABELS.ARTICLES, lang)}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to={ROUTES.AUDIO} tag={LocalizedLink}>
                      {getLabelFromLang(LINK_LABELS.AUDIO, lang)}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to={ROUTES.ABOUT_US}
                      rel="noopener noreferrer"
                      tag={LocalizedLink}
                    >
                      {getLabelFromLang(LINK_LABELS.ABOUT_US, lang)}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to={ROUTES.CONTACT_US}
                      tag={LocalizedLink}
                      rel="noopener noreferrer"
                    >
                      {getLabelFromLang(LINK_LABELS.CONTACT_US, lang)}
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg={5} className="mt-lg-0 mt-4">
                <h2
                  className={classnames('display-4 text-white', {
                    'text-right': isHE,
                  })}
                >
                  {getLabelFromLang(TRANSLATIONS.JOIN_NL, lang)}
                </h2>
                <SubscribeForm
                  form="subscribeHorizontal"
                  horizontal
                  initialValues={{ lang: [lang.toLowerCase()] }}
                />
                <Row
                  noGutters
                  className={classnames('text-center', {
                    'text-md-left': !isHE,
                    'text-md-right': isHE,
                  })}
                >
                  <Col sm={7}>
                    <p className="h4 text-uppercase text-white mb-1 mb-md-2">
                      {getLabelFromLang(TRANSLATIONS.CONTACT_US, lang)}
                    </p>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`mailto:${EMAIL}`}
                      className="text-muted"
                    >
                      {EMAIL}
                    </a>
                  </Col>
                  <Col sm={5} className="mt-3 mt-sm-0">
                    <p className="h4 text-uppercase text-white mb-1 mb-md-2">
                      {getLabelFromLang(TRANSLATIONS.CALL_US, lang)}
                    </p>
                    <a href={`tel:${PHONE}`} className="text-muted">
                      {PHONE}
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className="d-block d-md-none text-center mt-4">
                <div className="copyright text-muted">
                  ©&nbsp;
                  <LocalizedLink
                    className="font-weight-bold text-white ml-1"
                    to="/"
                    rel="noopener noreferrer"
                  >
                    BHI.
                  </LocalizedLink>
                  &nbsp;
                  {new Date().getFullYear()}
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
