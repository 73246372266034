import { takeEvery, put } from 'redux-saga/effects';
import {
  ADD_SUCCESS,
  UPDATE_SUCCESS,
  REMOVE_SUCCESS,
  getListAdmin,
  getListByDoctype,
} from './actions';

import { types } from '../../constants/document';
import { ROUTES } from '../../constants/routes';

const typePath = history => {
  const checkType = type => {
    const regexp = new RegExp(type, 'i');
    return regexp.test(history.location.pathname);
  };
  const isBW = checkType(ROUTES.BUSINESS_WEEKLY);
  const isArticle = checkType(ROUTES.ADMIN_ARTICLE_ITEM.replace('/:id', ''));
  return isBW ? types.BUSINESS_WEEKLY : isArticle ? types.ARTICLE : null;
};

export function* categoryRemovedSuccess(history) {
  yield takeEvery(REMOVE_SUCCESS, function*() {
    const id = history.location.pathname.split('/').pop();
    yield put(getListAdmin({ id }));
  });
}
export function* categorySaveSuccess(history) {
  yield takeEvery(ADD_SUCCESS, function*(action) {
    const id = history.location.pathname.split('/').pop();
    const type = typePath(history);
    if (type) {
      yield put(getListByDoctype(type));
    } else {
      yield put(getListAdmin({ id }));
    }
  });
  yield takeEvery(UPDATE_SUCCESS, function*(action) {
    const id = history.location.pathname.split('/').pop();
    yield put(getListAdmin({ id }));
  });
}
