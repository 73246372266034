import Base from './base';

export default class Settings extends Base {
  launchDataMigration(params) {
    return this.apiClient.post('/data-migration/import', params);
  }
  launchAudioHide(params) {
    return this.apiClient.post('/data-migration/audio-hide', params);
  }
  launchSefariaMigration(params) {
    return this.apiClient.get('/data-migration/sefaria');
  }

  launchTagsMigration(params) {
    return this.apiClient.post('/data-migration/tags', params);
  }
  launchSefariaTorahMigration(params) {
    return this.apiClient.post('/data-migration/torah', params);
  }
  launchSefariaProphetsMigration(params) {
    return this.apiClient.post('/data-migration/prophets', params);
  }
  launchSefariaWritingsMigration(params) {
    return this.apiClient.post('/data-migration/writings', params);
  }
  launchSefariaShulchanMigration(params) {
    return this.apiClient.post('/data-migration/shulchan', params);
  }
  changeGlobalSettings(data) {
    return this.apiClient.post('/global-settings', { data });
  }
  getGlobalSettings(data) {
    return this.apiClient.get('/global-settings', { data });
  }
}
