export const prefix = 'notify';

export const ALERT = `${prefix}/ALERT`;

export const alert = (title, msg, autoDismiss, type) => ({
  type: ALERT,
  payload: { title, msg, type, autoDismiss },
});
export const RESET = `${prefix}/RESET`;

export const reset = () => ({
  type: RESET,
});
