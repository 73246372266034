import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AudioItem from './AudioItem';

import { lang, defaultLang } from '../../redux/lang/selectors';

const selector = createStructuredSelector({
  selectedLang: lang,
  defaultLang,
});

export default connect(selector)(AudioItem);
