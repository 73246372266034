import React, { Component } from 'react';
import { Button } from 'reactstrap';

class CategoryCopyButtons extends Component {
  render() {
    const { langList, lang, onClick, docType } = this.props;
    return langList.map(langItem =>
      langItem.identifier !== lang ? (
        <Button
          size="sm"
          className="mb-sm-3 mb-md-0 active"
          key={langItem.identifier}
          onClick={e => {
            e.preventDefault();
            const yes = window.confirm(
              `Are you sure you want to copy ${langItem.identifier.toUpperCase()} categories to ${lang.toUpperCase()} categories?`,
            );
            if (!yes) return;

            onClick(langItem.identifier, lang, docType);
          }}
        >
          Copy from {langItem.identifier.toUpperCase()}
        </Button>
      ) : null,
    );
  }
}

export default CategoryCopyButtons;
