import { takeEvery, put } from 'redux-saga/effects';
import { PROFILE_FAILURE } from '../profile/actions';
import {
  ADD_SUCCESS as BANNER_ADD_SUCCESS,
  UPDATE_SUCCESS as BANNER_UPDATE_SUCCESS,
  ADD_FAILURE as BANNER_ADD_FAILURE,
  UPDATE_FAILURE as BANNER_UPDATE_FAILURE,
  REMOVE_SUCCESS as BANNER_REMOVE_SUCCESS,
  SAVE_ORDER_SUCCESS as BANNER_SAVE_ORDER_SUCCESS,
} from '../banner/actions';
import {
  ADD_SUCCESS as AUDIO_ADD_SUCCESS,
  UPDATE_SUCCESS as AUDIO_UPDATE_SUCCESS,
  ADD_FAILURE as AUDIO_ADD_FAILURE,
  UPDATE_FAILURE as AUDIO_UPDATE_FAILURE,
  REMOVE_SUCCESS as AUDIO_REMOVE_SUCCESS,
  SAVE_FILE_SUCCESS as AUDIO_SAVE_FILE_SUCCESS,
} from '../audio/actions';
import {
  ADD_SUCCESS as DOCUMENT_ADD_SUCCESS,
  UPDATE_SUCCESS as DOCUMENT_UPDATE_SUCCESS,
  ADD_FAILURE as DOCUMENT_ADD_FAILURE,
  UPDATE_FAILURE as DOCUMENT_UPDATE_FAILURE,
  REMOVE_SUCCESS as DOCUMENT_REMOVE_SUCCESS,
  SAVE_FILE_SUCCESS as DOCUMENT_SAVE_FILE_SUCCESS,
} from '../document/actions';
import {
  ADD_SUCCESS as ARTICLE_ADD_SUCCESS,
  UPDATE_SUCCESS as ARTICLE_UPDATE_SUCCESS,
  ADD_FAILURE as ARTICLE_ADD_FAILURE,
  UPDATE_FAILURE as ARTICLE_UPDATE_FAILURE,
  REMOVE_SUCCESS as ARTICLE_REMOVE_SUCCESS,
  SAVE_FILE_SUCCESS as ARTICLE_SAVE_FILE_SUCCESS,
} from '../article/actions';
import {
  ADD_SUCCESS as BW_ADD_SUCCESS,
  UPDATE_SUCCESS as BW_UPDATE_SUCCESS,
  ADD_FAILURE as BW_ADD_FAILURE,
  UPDATE_FAILURE as BW_UPDATE_FAILURE,
  REMOVE_SUCCESS as BW_REMOVE_SUCCESS,
  SAVE_FILE_SUCCESS as BW_SAVE_FILE_SUCCESS,
} from '../business-weekly/actions';
import { alert } from './actions';
import {
  ERROR_MESSAGES,
  ERROR_MESSAGE_CODES,
} from '../../constants/errorMessages';
import {
  SUBSCRIBE_FAILURE,
  SET_SUBSCRIBED_SUCCESS,
  ADD_SUBSCRIBER_SUCCESS,
  SEND_WEEKLY_SUCCESS,
  SCHEDULE_WEEKLY_SUCCESS,
} from '../users/actions';
import {
  SAVE_FIELD_SUCCESS,
  REMOVE_FIELD_SUCCESS,
  ADD_SUCCESS as FORM_ADD_SUCCESS,
  UPDATE_SUCCESS as FORM_UPDATE_SUCCESS,
  ADD_FAILURE as FORM_ADD_FAILURE,
  UPDATE_FAILURE as FORM_UPDATE_FAILURE,
  REMOVE_SUCCESS as FORM_REMOVE_SUCCESS,
} from '../form/actions';
import {
  CHANGE_STATUS_SUCCESS as SUBMISSION_CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_FAILURE as SUBMISSION_CHANGE_STATUS_FAILURE,
  UPDATE_SUCCESS as SUBMISSION_UPDATE_SUCCESS,
  UPDATE_FAILURE as SUBMISSION_UPDATE_FAILURE,
  ADD_SUCCESS as SUBMISSION_ADD_SUCCESS,
  ADD_FAILURE as SUBMISSION_ADD_FAILURE,
  REMOVE_SUCCESS as SUBMISSION_REMOVE_SUCCESS,
} from '../submission/actions';
import {
  UPDATE_SUCCESS as CATEGORY_UPDATE_SUCCESS,
  UPDATE_FAILURE as CATEGORY_UPDATE_FAILURE,
  ADD_SUCCESS as CATEGORY_ADD_SUCCESS,
  ADD_FAILURE as CATEGORY_ADD_FAILURE,
  REMOVE_SUCCESS as CATEGORY_REMOVE_SUCCESS,
} from '../category/actions';
import {
  UPDATE_SUCCESS as CATEGORY_TYPE_UPDATE_SUCCESS,
  UPDATE_FAILURE as CATEGORY_TYPE_UPDATE_FAILURE,
  ADD_SUCCESS as CATEGORY_TYPE_ADD_SUCCESS,
  ADD_FAILURE as CATEGORY_TYPE_ADD_FAILURE,
  REMOVE_SUCCESS as CATEGORY_TYPE_REMOVE_SUCCESS,
} from '../category-type/actions';

import {
  SCHEDULE_CUSTOM_EMAIL_SUCCESS,
  SCHEDULE_CUSTOM_EMAIL_FAILURE,
  SEND_CUSTOM_EMAIL_SUCCESS,
  SEND_CUSTOM_EMAIL_FAILURE,
  REMOVE_CUSTOM_EMAIL_SUCCESS,
  REMOVE_CUSTOM_EMAIL_FAILURE,
} from '../customEmail/actions';

import { LAUNCH_AUDIO_HIDE_SUCCESS } from '../settings/actions';

const NOTIFY_TIMEOUT = 7;
const toString = obj =>
  Object.entries(obj)
    .map(([key, val]) =>
      ERROR_MESSAGES[key.toUpperCase()]
        ? ERROR_MESSAGES[key.toUpperCase()][val]
        : `${key}: ${val}`,
    )
    .join(', ');

export function* notify() {
  yield takeEvery('*', function*(action) {
    if (action.type === BANNER_ADD_SUCCESS) {
      yield put(
        alert(
          'Success',
          'Banner successfully added',
          NOTIFY_TIMEOUT,
          'success',
        ),
      );
    }
    if (action.type === BANNER_UPDATE_SUCCESS) {
      yield put(
        alert(
          'Success',
          'Banner successfully saved',
          NOTIFY_TIMEOUT,
          'success',
        ),
      );
    }
    if (action.type === BANNER_SAVE_ORDER_SUCCESS) {
      yield put(
        alert(
          'Success',
          'Banner order successfully saved',
          NOTIFY_TIMEOUT,
          'success',
        ),
      );
    }
    if (action.type === BANNER_REMOVE_SUCCESS) {
      yield put(
        alert(
          'Success',
          'Banner successfully removed',
          NOTIFY_TIMEOUT,
          'warning',
        ),
      );
    }
    if (action.type === AUDIO_ADD_SUCCESS) {
      yield put(
        alert('Success', 'Audio successfully added', NOTIFY_TIMEOUT, 'success'),
      );
    }
    if (action.type === AUDIO_UPDATE_SUCCESS) {
      yield put(
        alert('Success', 'Audio successfully saved', NOTIFY_TIMEOUT, 'success'),
      );
    }
    if (action.type === AUDIO_REMOVE_SUCCESS) {
      yield put(
        alert(
          'Success',
          'Audio successfully removed',
          NOTIFY_TIMEOUT,
          'warning',
        ),
      );
    }
    if (action.type === ARTICLE_ADD_SUCCESS) {
      yield put(
        alert(
          'Success',
          'Article successfully added',
          NOTIFY_TIMEOUT,
          'success',
        ),
      );
    }
    if (action.type === ARTICLE_UPDATE_SUCCESS) {
      yield put(
        alert(
          'Success',
          'Article successfully saved',
          NOTIFY_TIMEOUT,
          'success',
        ),
      );
    }
    if (action.type === ARTICLE_REMOVE_SUCCESS) {
      yield put(
        alert(
          'Success',
          'Article successfully removed',
          NOTIFY_TIMEOUT,
          'warning',
        ),
      );
    }
    if (action.type === DOCUMENT_ADD_SUCCESS) {
      yield put(
        alert(
          'Success',
          'Document successfully added',
          NOTIFY_TIMEOUT,
          'success',
        ),
      );
    }
    if (action.type === DOCUMENT_UPDATE_SUCCESS) {
      yield put(
        alert(
          'Success',
          'Document successfully saved',
          NOTIFY_TIMEOUT,
          'success',
        ),
      );
    }
    if (action.type === DOCUMENT_REMOVE_SUCCESS) {
      yield put(
        alert(
          'Success',
          'Document successfully removed',
          NOTIFY_TIMEOUT,
          'warning',
        ),
      );
    }
    if (action.type === BW_ADD_SUCCESS) {
      yield put(
        alert('Success', 'Item successfully added', NOTIFY_TIMEOUT, 'success'),
      );
    }
    if (action.type === BW_UPDATE_SUCCESS) {
      yield put(
        alert('Success', 'Item successfully saved', NOTIFY_TIMEOUT, 'success'),
      );
    }
    if (action.type === BW_REMOVE_SUCCESS) {
      yield put(
        alert(
          'Success',
          'Item successfully removed',
          NOTIFY_TIMEOUT,
          'warning',
        ),
      );
    }
    if (action.type === SET_SUBSCRIBED_SUCCESS) {
      yield put(alert('Success', 'Saved', NOTIFY_TIMEOUT, 'success'));
    }
    if (action.type === ADD_SUBSCRIBER_SUCCESS) {
      yield put(alert('Success', 'Added', NOTIFY_TIMEOUT, 'success'));
    }
    if (action.type === SEND_WEEKLY_SUCCESS) {
      yield put(
        alert(
          'Business Weekly',
          'Email successfully sent',
          NOTIFY_TIMEOUT,
          'success',
        ),
      );
    }
    if (action.type === SCHEDULE_WEEKLY_SUCCESS) {
      yield put(
        alert(
          'Business Weekly',
          'Emails successfully scheduled',
          NOTIFY_TIMEOUT,
          'success',
        ),
      );
    }
    if (action.type === SAVE_FIELD_SUCCESS) {
      yield put(alert('New field', 'Saved', NOTIFY_TIMEOUT, 'success'));
    }
    if (action.type === FORM_ADD_SUCCESS) {
      yield put(alert('New form', 'Saved', NOTIFY_TIMEOUT, 'success'));
    }
    if (action.type === FORM_UPDATE_SUCCESS) {
      yield put(alert('Editing form', 'Updated', NOTIFY_TIMEOUT, 'success'));
    }
    if (action.type === FORM_REMOVE_SUCCESS) {
      yield put(alert('Removing form', 'Removed', NOTIFY_TIMEOUT, 'warning'));
    }
    if (action.type === REMOVE_FIELD_SUCCESS) {
      yield put(alert('Removing field', 'Removed', NOTIFY_TIMEOUT, 'warning'));
    }

    if (action.type === SUBMISSION_CHANGE_STATUS_SUCCESS) {
      yield put(alert('Changing status', 'Saved', NOTIFY_TIMEOUT, 'success'));
    }
    if (action.type === SUBMISSION_UPDATE_SUCCESS) {
      yield put(alert('Saving changes', 'Done', NOTIFY_TIMEOUT, 'success'));
    }
    if (action.type === SUBMISSION_ADD_SUCCESS) {
      yield put(
        alert('Saving submission', 'Submitted', NOTIFY_TIMEOUT, 'success'),
      );
    }
    if (action.type === SUBMISSION_REMOVE_SUCCESS) {
      yield put(
        alert('Removing submission', 'Removed', NOTIFY_TIMEOUT, 'warning'),
      );
    }

    if (action.type === CATEGORY_UPDATE_SUCCESS) {
      yield put(alert('Saving changes', 'Done', NOTIFY_TIMEOUT, 'success'));
    }
    if (action.type === CATEGORY_ADD_SUCCESS) {
      yield put(alert('Saving category', 'Saved', NOTIFY_TIMEOUT, 'success'));
    }
    if (action.type === CATEGORY_REMOVE_SUCCESS) {
      yield put(
        alert('Removing category', 'Removed', NOTIFY_TIMEOUT, 'warning'),
      );
    }

    if (action.type === CATEGORY_TYPE_UPDATE_SUCCESS) {
      yield put(alert('Saving changes', 'Done', NOTIFY_TIMEOUT, 'success'));
    }
    if (action.type === CATEGORY_TYPE_ADD_SUCCESS) {
      yield put(
        alert('Saving category type', 'Saved', NOTIFY_TIMEOUT, 'success'),
      );
    }
    if (action.type === CATEGORY_TYPE_REMOVE_SUCCESS) {
      yield put(
        alert('Removing category type', 'Removed', NOTIFY_TIMEOUT, 'warning'),
      );
    }
    if (action.type === SCHEDULE_CUSTOM_EMAIL_SUCCESS) {
      yield put(
        alert(
          'Custom email',
          'Succsessfully scheduled',
          NOTIFY_TIMEOUT,
          'success',
        ),
      );
    }
    if (action.type === SEND_CUSTOM_EMAIL_SUCCESS) {
      yield put(
        alert('Custom email', 'Succsessfully sent', NOTIFY_TIMEOUT, 'success'),
      );
    }
    if (action.type === REMOVE_CUSTOM_EMAIL_SUCCESS) {
      yield put(
        alert(
          'Custom email',
          'Succsessfully removed',
          NOTIFY_TIMEOUT,
          'warning',
        ),
      );
    }
    if (
      action.type === AUDIO_SAVE_FILE_SUCCESS ||
      action.type === DOCUMENT_SAVE_FILE_SUCCESS ||
      action.type === BW_SAVE_FILE_SUCCESS ||
      action.type === ARTICLE_SAVE_FILE_SUCCESS
    ) {
      yield put(alert('Success', 'File saved', NOTIFY_TIMEOUT, 'success'));
    }
    if (
      action.type === BANNER_ADD_FAILURE ||
      action.type === BANNER_UPDATE_FAILURE ||
      action.type === AUDIO_ADD_FAILURE ||
      action.type === AUDIO_UPDATE_FAILURE ||
      action.type === DOCUMENT_ADD_FAILURE ||
      action.type === DOCUMENT_UPDATE_FAILURE ||
      action.type === ARTICLE_ADD_FAILURE ||
      action.type === ARTICLE_UPDATE_FAILURE ||
      action.type === BW_ADD_FAILURE ||
      action.type === BW_UPDATE_FAILURE ||
      action.type === FORM_UPDATE_FAILURE ||
      action.type === FORM_ADD_FAILURE ||
      action.type === SUBMISSION_CHANGE_STATUS_FAILURE ||
      action.type === SUBMISSION_UPDATE_FAILURE ||
      action.type === SUBMISSION_ADD_FAILURE ||
      action.type === CATEGORY_UPDATE_FAILURE ||
      action.type === CATEGORY_ADD_FAILURE ||
      action.type === CATEGORY_TYPE_UPDATE_FAILURE ||
      action.type === CATEGORY_TYPE_ADD_FAILURE ||
      action.type === SCHEDULE_CUSTOM_EMAIL_FAILURE ||
      action.type === SEND_CUSTOM_EMAIL_FAILURE ||
      action.type === REMOVE_CUSTOM_EMAIL_FAILURE
    ) {
      return yield put(
        alert('Error', 'Error during saving', NOTIFY_TIMEOUT, 'danger'),
      );
    }
    if (
      action.error &&
      action.type !== PROFILE_FAILURE &&
      action.type !== SUBSCRIBE_FAILURE
    ) {
      yield put(
        alert(
          ERROR_MESSAGE_CODES[action.error.code] || action.error.code,
          action.error.message ||
            (action.error &&
              action.error.fields &&
              toString(action.error.fields)),
          NOTIFY_TIMEOUT,
          'danger',
        ),
      );
    }
    if (action.type === LAUNCH_AUDIO_HIDE_SUCCESS) {
      yield put(
        alert('Success', 'Audios updated as DRAFT', NOTIFY_TIMEOUT, 'success'),
      );
    }
  });
}
