import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Paginator from './Paginator';

import { lang } from '../../redux/lang/selectors';

const selector = createStructuredSelector({
  selectedLang: lang,
});

export default connect(selector)(Paginator);
