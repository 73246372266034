import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import Categories from './components/Categories';
import TypeList from './components/Types';
import Relations from './components/Relations';

const categoryTabs = {
  list: {
    label: 'List',
    value: 'list',
  },
  types: {
    label: 'Types',
    value: 'types',
  },
  relations: {
    label: 'Materials relations',
    value: 'relations',
  },
};

class CategoryListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: categoryTabs.list.value,
      fromProps: { saving: false },
    };
  }

  toggleTabs(tab) {
    this.setState({ tab });
  }

  componentWillMount() {
    this.props.getTypeListAdmin();
  }

  render() {
    const { typeLoading, history } = this.props;

    return (
      <>
        {typeLoading && <Loader type="fixed" />}
        <Container fluid className="py-2 border-bottom">
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="display-2 mb-0">Categories</h2>
            </Col>
            <Col>
              <Nav
                className="nav-fill flex-column flex-md-row justify-content-end"
                id="lang-tabs-icons-text"
                pills
                role="tablist"
              >
                {[
                  categoryTabs.list,
                  categoryTabs.types,
                  categoryTabs.relations,
                ].map(tab => {
                  return (
                    <NavItem key={`tab-${tab.value}`} className="flex-grow-0">
                      <NavLink
                        aria-selected={this.state.tab === tab.value}
                        className={`mb-sm-3 mb-md-0 ${this.state.tab ===
                          tab.value && 'active'}`}
                        onClick={e => this.toggleTabs(tab.value)}
                        href="#"
                        role="tab"
                      >
                        {tab.label}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>
            </Col>
          </Row>
        </Container>

        <TabContent activeTab={'tab-' + this.state.tab}>
          <TabPane tabId={`tab-${categoryTabs.list.value}`}>
            <Categories history={history} />
          </TabPane>
          <TabPane tabId={`tab-${categoryTabs.types.value}`}>
            <TypeList />
          </TabPane>
          <TabPane tabId={`tab-${categoryTabs.relations.value}`}>
            <Relations />
          </TabPane>
        </TabContent>
      </>
    );
  }
}

export default CategoryListPage;
