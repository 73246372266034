import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import InputRedux from '../Input/InputRedux';
import FieldFormGroup from '../FieldFormGroup';
import FormActions from '../FormActions';
import { Form, Row, Col } from 'reactstrap';

import { fillHierarchyOptions } from '../../utils';

const KEY_FORM_OPTIONS = {
  doctype: {
    label: 'Type Name',
    type: 'text',
    disabled: true,
  },
  categoryTypeId: {
    label: 'Category Type',
    type: 'select',
    notWrap: true,
  },
};

export default class MaterialsCategoryTypeForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      fromProps: {},
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { defaultLang } = nextProps;
    const newState = { fromProps: state.fromProps };
    if (state.fromProps.defaultLang !== defaultLang) {
      newState.fromProps.defaultLang = defaultLang;
    }

    return newState;
  }

  renderField(key, lang = null) {
    const { saving, categoryTypes, defaultLang } = this.props;
    const name = lang ? `lang.${lang}.` + key : key;
    const { icon, type, label, notWrap, ...rest } = KEY_FORM_OPTIONS[key];
    if (key === 'categoryTypeId' && (!rest.options || !rest.options.length)) {
      rest.options = [{ label: 'None', value: '' }];
      fillHierarchyOptions(
        rest.options,
        categoryTypes,
        defaultLang,
        'categoryTypes',
      );
    }
    return (
      <FieldFormGroup icon={icon} label={label} lang={lang} wrap={!notWrap}>
        <Field
          name={name}
          type={type}
          disabled={saving}
          onFocus={() => this.setState({ [`focused_${key}`]: true })}
          onBlur={() => this.setState({ [`focused_${key}`]: false })}
          component={InputRedux}
          {...rest}
        />
      </FieldFormGroup>
    );
  }

  render() {
    const { saving, handleSubmit, onDelete, isNew } = this.props;

    return (
      <Form onSubmit={handleSubmit} role="form">
        <Row>
          <Col sm={6}>{this.renderField('doctype')}</Col>
          <Col sm={6}>{this.renderField('categoryTypeId')}</Col>
        </Row>
        <Row>
          <Col className="text-right mt-4">
            <FormActions saving={saving} isNew={isNew} onDelete={onDelete} />
          </Col>
        </Row>
      </Form>
    );
  }
}
