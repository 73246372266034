import React, { Component } from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';

import LocalizedLink from '../../components/LocalizedLink';
import Hr from '../../components/Hr';
import { scrollTo } from '../../utils';

import {
  missionStatement,
  services,
  profiles,
  branches,
  sections,
  getSectionLabel,
} from './consts';

export default class AboutPage extends Component {
  scrollTo(e) {
    e.preventDefault();
    const sectionEl = document.getElementById(
      `section-${e.target.attributes['data-section'].value}`,
    );
    const rect = sectionEl.getBoundingClientRect();
    scrollTo(rect.top + window.scrollY);
  }

  renderService(service, i) {
    const { title, text, link } = service;
    return (
      <div
        key={`service-${i}`}
        className="mt-2 mb-5 z-index-3 position-relative"
      >
        <h2 className="text-white">{title}</h2>
        <p className="text-white font-weight-normal">{text}</p>
        {link.isDirectLink ? (
          <a className="btn btn-sm btn-outline-white" href={link.path}>
            {link.text}
          </a>
        ) : (
          <LocalizedLink
            className="btn btn-sm btn-outline-white"
            to={link.path}
          >
            {link.text}
          </LocalizedLink>
        )}
      </div>
    );
  }

  renderProfile(profile) {
    const { id, img, name, position, text } = profile;
    return (
      <Card key={`profile-${id}`}>
        <CardBody>
          <a href={img} onClick={e => e.preventDefault()}>
            <img
              alt="..."
              className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
              src={img}
              style={{ width: '140px' }}
            />
          </a>
          <div className="pt-4 text-center">
            <h5 className="h2 title">
              <span className="d-block mb-1">{name}</span>
              <small className="h4 font-weight-light text-muted">
                {position}
              </small>
            </h5>
            <div className="mt-3 text-left">{text}</div>
          </div>
        </CardBody>
      </Card>
    );
  }

  renderBranch(branch) {
    const { id, title, employees = [], contact = {} } = branch;
    return (
      <Card key={`branch-${id}`}>
        <CardBody>
          <h2>BHI {title}</h2>
          <ul>
            {employees.map(employee => (
              <li key={`employee-${employee.id}`}>{employee.name}</li>
            ))}
          </ul>
          <h3>Contact</h3>
          <div className="d-flex align-items-center mb-2">
            <h4 className="mb-0 mr-2">Email:</h4>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${contact.email}`}
            >
              {contact.email}
            </a>
          </div>
          <div className="d-flex align-items-center">
            <h4 className="mb-0 mr-2">Phone:</h4>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`tel:${contact.phoneNum}`}
            >
              {contact.phoneDisplay}
            </a>
          </div>
        </CardBody>
      </Card>
    );
  }

  render() {
    return (
      <>
        <Container
          className="position-sticky mt-5 top-4 left-0 d-none d-lg-block"
          style={{ zIndex: 2, marginBottom: '-22rem', height: '19rem' }}
        >
          <Row className="justify-content-start">
            <Col lg={3}>
              <Card className="bg-gradient-primary">
                <CardBody>
                  {sections.map(s => (
                    <a
                      key={`section-link-${s.section}`}
                      href="/"
                      data-section={s.section}
                      className="btn-block text-white"
                      onClick={this.scrollTo}
                    >
                      {s.label}
                    </a>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* Mission Statement */}
        <section
          id="section-mission"
          className="header bg-gradient-info pt-5 pb-7"
        >
          <Container>
            <Row className="justify-content-end">
              <Col lg={8} style={{ zIndex: 10 }}>
                <h2 className="display-3 text-white mb-0">
                  {getSectionLabel('mission')}
                </h2>
                <p className="h3 text-white font-weight-normal mb-3">
                  {missionStatement.title}
                </p>
                <Hr color="white" margin="mt-2" size="2" />
                <p className="h2 mt-0 text-white">{missionStatement.text}</p>
              </Col>
            </Row>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        {/* Services */}
        <section id="section-services" className="pt-6 pb-7 bg-default">
          <Container>
            <Row className="justify-content-end">
              <Col lg={8}>
                <Hr color="white" />
                <h2 className="display-3 text-white">
                  {getSectionLabel('services')}
                </h2>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg pt-lg-0 mt--6 pb-4 bg-default">
          <Container>
            <Row className="justify-content-end">
              <Col lg={8}>{services.map(this.renderService)}</Col>
            </Row>
          </Container>
        </section>
        {/* Profiles */}
        <section id="section-profiles" className="pt-6 pb-9 bg-default">
          <Container>
            <Row className="justify-content-end">
              <Col lg={8}>
                <Hr color="white" />
                <h2 className="display-3 text-white">
                  {getSectionLabel('profiles')}
                </h2>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg pt-lg-0 mt--7 pb-4">
          <Container>
            <Row className="justify-content-end">
              <Col lg={8}>{profiles.map(this.renderProfile)}</Col>
            </Row>
          </Container>
        </section>
        {/* Case Studies */}
        {/*<section id="section-case-studies" className="section py-6">
          <Container>
            <Row className="justify-content-end">
              <Col lg={8}>
                <Hr color="default" />
                <h2 className="display-3">CASE STUDIES</h2>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col lg={8}>-</Col>
            </Row>
          </Container>
        </section>*/}
        {/* Branches */}
        <section id="section-branches" className="section py-6">
          <Container>
            <Row className="justify-content-end mb-5">
              <Col lg={8}>
                <Hr color="default" />
                <h2 className="display-3">{getSectionLabel('branches')}</h2>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col lg={8}>{branches.map(this.renderBranch)}</Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
