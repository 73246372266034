import React from 'react';

import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

const FieldFormGroup = ({
  label,
  icon,
  wrap = true,
  hideLabel = false,
  lang = null,
  children,
}) => {
  if (wrap)
    return (
      <FormGroup className="mb-3">
        {hideLabel ? null : (
          <label className="form-control-label">
            {label} {lang ? `(${lang.toUpperCase()})` : ''}
          </label>
        )}
        <InputGroup className="input-group-merge input-group-alternative mb-3">
          {icon && (
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className={`ni ni-${icon}`} />
              </InputGroupText>
            </InputGroupAddon>
          )}
          {children}
        </InputGroup>
      </FormGroup>
    );

  return (
    <FormGroup className="mb-3">
      {hideLabel ? null : (
        <label className="form-control-label">
          {label} {lang ? `(${lang.toUpperCase()})` : ''}
        </label>
      )}
      {children}
    </FormGroup>
  );
};

export default FieldFormGroup;
