import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import TableFilter from '../../../components/TableFilter';
import SubmissionRow from './SubmissionRow';
import { Container, Row, Col, Table, Card, CardBody } from 'reactstrap';
import { STATUS_OPTIONS } from '../../../constants/submissionStatuses';

class SubmissionListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {
        status: '',
        search: '',
      },
    };

    this.setStatus = this.setStatus.bind(this);
    this.statusFilterChanged = this.statusFilterChanged.bind(this);
    this.remove = this.remove.bind(this);
  }

  componentWillMount() {
    this.getList();
  }

  getList = () => {
    this.props.getListAdmin({ filter: this.state.filter });
  };

  statusFilterChanged(e) {
    this.getList();
  }

  onFilterChange = (name, value) => {
    return new Promise(resolve => {
      this.setState(
        state => ({
          filter: {
            ...state.filter,
            [name]: value,
          },
        }),
        () => resolve(),
      );
    });
  };

  setStatus(status, id) {
    const { changeStatus } = this.props;
    if (id) {
      changeStatus(id, status);
    }
  }

  remove(id) {
    const yes = window.confirm('Are you sure you want delete the submission?');
    if (!yes) return;
    const { remove } = this.props;
    if (id) {
      remove(id);
    }
  }

  render() {
    const { loading, list, defaultLang } = this.props;
    const { filter } = this.state;

    return (
      <>
        {loading && <Loader type="fixed" />}
        <Container fluid className="py-2 border-bottom">
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="display-2 mb-0">Submissions</h2>
            </Col>
          </Row>
        </Container>
        <Container className="pt-4">
          <TableFilter
            filterValues={filter}
            filterShape={{
              status: {
                label: 'Status',
                type: 'select',
                initialValue: '',
                options: STATUS_OPTIONS,
              },
              search: {
                label: 'General search',
                type: 'text',
                initialValue: '',
              },
            }}
            onChange={this.onFilterChange}
            onSearch={this.getList}
          />
        </Container>
        <Container className="py-4">
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Table
                    className="align-items-center table-flush"
                    hover
                    responsive
                  >
                    <thead className="thead-light">
                      <tr>
                        <th>Email</th>
                        <th>Form</th>
                        <th>Submit Date</th>
                        <th>Status</th>
                        <th width="100" />
                      </tr>
                    </thead>
                    <tbody>
                      {list.map(submission => (
                        <SubmissionRow
                          key={`submission-${submission.id}`}
                          submission={submission}
                          setStatus={this.setStatus}
                          defaultLang={defaultLang}
                          onRemove={this.remove}
                        />
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default SubmissionListPage;
