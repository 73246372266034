export const prefix = 'banner';

export const BANNERS_REQUEST = `${prefix}/BANNERS_REQUEST`;
export const BANNERS_SUCCESS = `${prefix}/BANNERS_SUCCESS`;
export const BANNERS_FAILURE = `${prefix}/BANNERS_FAILURE`;

export const getBanners = () => ({
  types: [BANNERS_REQUEST, BANNERS_SUCCESS, BANNERS_FAILURE],
  promise: api => api.banner.list(),
});

export const LIST_ADMIN_REQUEST = `${prefix}/LIST_ADMIN_REQUEST`;
export const LIST_ADMIN_SUCCESS = `${prefix}/LIST_ADMIN_SUCCESS`;
export const LIST_ADMIN_FAILURE = `${prefix}/LIST_ADMIN_FAILURE`;

export const getListAdmin = () => ({
  types: [LIST_ADMIN_REQUEST, LIST_ADMIN_SUCCESS, LIST_ADMIN_FAILURE],
  promise: api => api.banner.listAdmin(),
});

export const ADD_REQUEST = `${prefix}/ADD_REQUEST`;
export const ADD_SUCCESS = `${prefix}/ADD_SUCCESS`;
export const ADD_FAILURE = `${prefix}/ADD_FAILURE`;

export const add = banner => ({
  types: [ADD_REQUEST, ADD_SUCCESS, ADD_FAILURE],
  promise: api => api.banner.add(banner),
});

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`;

export const update = banner => ({
  types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: api => api.banner.update(banner),
});

export const REMOVE_REQUEST = `${prefix}/REMOVE_REQUEST`;
export const REMOVE_SUCCESS = `${prefix}/REMOVE_SUCCESS`;
export const REMOVE_FAILURE = `${prefix}/REMOVE_FAILURE`;

export const remove = id => ({
  types: [REMOVE_REQUEST, REMOVE_SUCCESS, REMOVE_FAILURE],
  promise: api => api.banner.remove(id),
});

export const SAVE_ORDER_REQUEST = `${prefix}/SAVE_ORDER_REQUEST`;
export const SAVE_ORDER_SUCCESS = `${prefix}/SAVE_ORDER_SUCCESS`;
export const SAVE_ORDER_FAILURE = `${prefix}/SAVE_ORDER_FAILURE`;

export const saveOrder = banners => ({
  types: [SAVE_ORDER_REQUEST, SAVE_ORDER_SUCCESS, SAVE_ORDER_FAILURE],
  promise: api => api.banner.saveOrder(banners),
});

export const RESET_SAVED_BANNER = `${prefix}/RESET_SAVED_BANNER`;

export const resetSavedBanner = () => ({
  type: RESET_SAVED_BANNER,
});
