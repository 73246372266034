import { prefix } from './actions';

export const loading = state => state[prefix].loading;
export const categoryTypeModalOpened = state =>
  state[prefix].categoryTypeModalOpened;

export const list = state => state[prefix].list;
export const listAdmin = state => state[prefix].listAdmin;
export const itemAdmin = state => state[prefix].itemAdmin;
export const saving = state => state[prefix].saving;
