import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import BannerBuilder from '../../../components/BannerBuilder';

class BannersPage extends Component {
  componentWillMount() {
    this.props.getListAdmin();
    this.props.fetchMaterials();
  }

  render() {
    const { loading } = this.props;

    return (
      <>
        {loading && (
          <Loader className="position-absolute py-9 my-5 w-100 zi-100" />
        )}
        <div className="header bg-info">
          <BannerBuilder />
        </div>
      </>
    );
  }
}

export default BannersPage;
