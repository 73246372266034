import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { lang } from '../../redux/lang/selectors';

import LocalizedLink from './LocalizedLink';

const selector = createStructuredSelector({ lang });

export default connect(selector)(LocalizedLink);
