import React, { Component } from 'react';
import { Modal, Collapse, Card, CardBody, Button } from 'reactstrap';

class Info extends Component {
  render() {
    const {
      fullName,
      phone,
      companyName,
      position,
      birthDate,
      email,
    } = this.props.user;
    return (
      <Collapse>
        <Card header="User" key="1">
          <CardBody>
            <p>full name: {fullName}</p>
            <p>email: {email}</p>
            <p>phone: {phone}</p>
            <p>company name: {companyName}</p>
            <p>position: {position}</p>
            <p>birth date: {birthDate}</p>
          </CardBody>
        </Card>
      </Collapse>
    );
  }
}

class UserInfo extends Component {
  render() {
    const { user, hideUser } = this.props;

    return (
      <Modal isOpen={!!user} onCancel={hideUser} onOk={hideUser}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            User Info
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={hideUser}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">{user && <Info user={user} />}</div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={hideUser}
          >
            Cancel
          </Button>
          <Button onClick={hideUser} color="primary" type="button">
            Ok
          </Button>
        </div>
      </Modal>
    );
  }
}

export default UserInfo;
