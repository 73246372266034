import { connect } from 'react-redux';
import { compose } from '../../utils';
import { createStructuredSelector } from 'reselect';
import CategoryField from './CategoryField';
import { listAdmin as categories } from '../../redux/category/selectors';
import { getChildrenById } from '../../redux/category/actions';
import { listAdmin as categoryTypes } from '../../redux/category-type/selectors';
import { defaultLang } from '../../redux/lang/selectors';

const reduxConect = connect(
  createStructuredSelector({
    categoryTypes,
    defaultLang,
    categories,
  }),
  { getChildrenById },
);

export { CategoryField };

export default compose(reduxConect)(CategoryField);
