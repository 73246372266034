import React from 'react';

import { Link } from 'react-router-dom';
// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';
import { ROUTES } from '../../constants/routes';

class Calendar extends React.Component {
  render() {
    return (
      <>
        <Container fluid>
          <footer className="footer py-5">
            <Row className="align-items-center justify-content-lg-between">
              <Col lg="6">
                <div className="copyright text-center text-lg-left text-muted">
                  © {new Date().getFullYear()}{' '}
                  <Link
                    to={ROUTES.ADMIN_DASHBOARD}
                    className="font-weight-bold ml-1"
                  >
                    BHI
                  </Link>
                </div>
              </Col>
              <Col lg="6">
                <Nav className="nav-footer justify-content-center justify-content-lg-end">
                  <NavItem>
                    <NavLink to={ROUTES.HOME} tag={Link}>
                      Home page
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }
}

export default Calendar;
