import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';

import { compose } from '../../utils';
import { consultation } from '../../redux/users/actions';
import { consultationSent, sending } from '../../redux/users/selectors';

import ConsultationForm from './ConsultationForm';

const reduxConect = connect(
  createStructuredSelector({
    consultationSent,
    sending,
  }),
  { onSubmit: consultation },
);

const formConect = reduxForm({
  form: 'consultation',
});

export { ConsultationForm };

export default compose(
  reduxConect,
  formConect,
)(ConsultationForm);
