import { prefix } from './actions';

export const loading = state => state[prefix].loading;
export const loadingChildren = state => state[prefix].loadingChildren;
export const categoryModalOpened = state => state[prefix].categoryModalOpened;

export const listAdmin = state => state[prefix].listAdmin;
export const parentsByType = state => state[prefix].parentsByType;
export const childrenById = state => state[prefix].childrenById;
export const itemAdmin = state => state[prefix].itemAdmin;
export const saving = state => state[prefix].saving;
