import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';

import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import { getLabelFromLang } from '../../utils';
import LocalizedLink from '../../components/LocalizedLink';

import { services } from '../AboutPage/consts';

export default class ServicesPage extends Component {
  renderService(service, i) {
    const { title, text, link } = service;
    return (
      <div
        key={`service-${i}`}
        className="mt-2 mb-5 z-index-3 position-relative"
      >
        <h2 className="text-default">{title}</h2>
        <p className="text-default font-weight-normal">{text}</p>
        {link.isDirectLink ? (
          <a className="btn btn-sm btn-outline-default" href={link.path}>
            {link.text}
          </a>
        ) : (
          <LocalizedLink
            className="btn btn-sm btn-outline-default"
            to={link.path}
          >
            {link.text}
          </LocalizedLink>
        )}
      </div>
    );
  }

  render() {
    const { lang } = this.props;
    return (
      <>
        <section className="bg-gradient-info py-5">
          <Container>
            <Breadcrumb listClassName="breadcrumb-dark breadcrumb-links pb-2">
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.HOME}>
                  {getLabelFromLang(LINK_LABELS.HOME, lang)}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem aria-current="page" className="active">
                {getLabelFromLang(LINK_LABELS.SERVICES, lang)}
              </BreadcrumbItem>
            </Breadcrumb>
            <Row className="align-items-stretch">
              <Col lg={4} xs={12}>
                <h1 className="display-2 text-white mb-0">
                  {getLabelFromLang(LINK_LABELS.SERVICES, lang)}
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-5">
          <Container>
            <Row className="align-items-stretch">
              <Col lg={12} xs={12}>
                {services.map(this.renderService)}
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
