import { takeEvery, put } from 'redux-saga/effects';
import {
  LIST_FAILURE,
  ADD_SUBSCRIBER_SUCCESS,
  IMPORT_SUBSCRIBERS_SUCCESS,
  getSubscribers,
} from './actions';

import { ROUTES } from '../../constants/routes';

export function* listFailure(history) {
  yield takeEvery(LIST_FAILURE, function*(action) {
    if (action.error && action.error.code === 'PERMISSION_DENIED') {
      yield history.push(ROUTES.HOME);
    }
  });
}

export function* subscriberAdded() {
  yield takeEvery(ADD_SUBSCRIBER_SUCCESS, function*(action) {
    yield put(getSubscribers({ lang: action.data.lang }));
  });
}

export function* subscribersImported() {
  yield takeEvery(IMPORT_SUBSCRIBERS_SUCCESS, function*(action) {
    yield put(getSubscribers({ lang: action.data.lang }));
  });
}
