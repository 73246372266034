import { statuses } from '../../constants/document';

export const KEY_FORM_OPTIONS = {
  status: {
    label: 'Status',
    type: 'select',
    icon: 'button-power',
    options: [statuses.DRAFT, statuses.PUBLISHED],
  },
  description: {
    label: 'Description',
    type: 'textarea',
    icon: 'align-left-2',
    rows: 4,
  },
  number: {
    label: 'Issue',
    type: 'number',
    icon: 'tag',
  },
  publishedAt: {
    label: 'Publish Date',
    type: 'date',
    icon: 'calendar-grid-58',
  },
  file: {
    label: 'File',
    type: 'file',
  },
  title: {
    label: 'Title',
    type: 'text',
    icon: 'collection',
  },
  // author: {
  //   label: 'Author',
  //   type: 'text',
  //   icon: 'circle-08',
  //   disabled: true,
  // },
  serialNumber: {
    label: 'Serial Number',
    type: 'number',
    icon: 'tag',
  },
  category: {
    label: 'Category',
    type: 'text',
    icon: 'tag',
  },
  subcategory: {
    label: 'Subcategory',
    type: 'text',
    icon: 'tag',
  },
  content: {
    label: 'Content',
    type: 'quill',
    rows: 8,
    notWrap: true,
  },
  categories: {
    type: 'multiSelect',
    icon: 'tag',
    placeholder: 'Select category',
  },
};
