import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DocumentAutoComplete from '../DocumentAutoComplete';
import { Row, Col } from 'reactstrap';
import { types } from '../../constants/document';

const titles = {
  [types.AUDIO]: 'audio',
  [types.ARTICLE]: 'article',
  [types.DOCUMENT]: 'document',
  [types.BUSINESS_WEEKLY]: 'Business Weekly',
};

export default class RelatedForm extends Component {
  static propTypes = {
    changeRelated: PropTypes.func.isRequired,
    relatedFields: PropTypes.array.isRequired,
    relatedValues: PropTypes.array,
    relatedDefault: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.relatedChanged = this.relatedChanged.bind(this);
  }

  relatedChanged(relatedItem) {
    const { changeRelated, relatedValues } = this.props;
    const relatedObj = relatedValues
      ? relatedValues.reduce((acc, rItem) => {
          acc[rItem.type] = rItem;
          return acc;
        }, {})
      : {};
    relatedObj[relatedItem.type] = relatedItem;

    changeRelated(Object.keys(relatedObj).map(docType => relatedObj[docType]));
  }

  render() {
    const { defaultLang, relatedFields, relatedDefault } = this.props;

    return (
      <Row>
        {relatedFields.map(docType => {
          return (
            <Col key={`related-field-${docType}`} md={6} className="mb-3">
              <label className="form-control-label">
                Related {titles[docType]}
              </label>
              <DocumentAutoComplete
                type={docType}
                defaultValue={
                  relatedDefault[docType]
                    ? {
                        id: relatedDefault[docType].id,
                        label: relatedDefault[docType].lang[defaultLang].title,
                      }
                    : null
                }
                onChange={this.relatedChanged}
              />
            </Col>
          );
        })}
      </Row>
    );
  }
}
