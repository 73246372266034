import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      default:
        return state;
    }
  },
  processing(state = false, action) {
    switch (action.type) {
      case actions.LAUNCH_DATA_MIGRATION_REQUEST:
      case actions.LAUNCH_AUDIO_HIDE_REQUEST:
      case actions.GLOBAL_SETTINGS_CHANGE_REQUEST:
      case actions.GLOBAL_SETTINGS_GET_REQUEST:
        return true;
      case actions.LAUNCH_DATA_MIGRATION_SUCCESS:
      case actions.LAUNCH_DATA_MIGRATION_FAILURE:
      case actions.LAUNCH_AUDIO_HIDE_SUCCESS:
      case actions.LAUNCH_AUDIO_HIDE_FAILURE:
      case actions.GLOBAL_SETTINGS_CHANGE_SUCCESS:
      case actions.GLOBAL_SETTINGS_GET_SUCCESS:
      case actions.GLOBAL_SETTINGS_GET_FAILURE:
      case actions.GLOBAL_SETTINGS_CHANGE_FAILURE:
        return false;
      default:
        return state;
    }
  },
  globalSettings(state = {}, action) {
    switch (action.type) {
      case actions.GLOBAL_SETTINGS_CHANGE_SUCCESS:
      case actions.GLOBAL_SETTINGS_GET_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
});
