import React, { Component } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import NotificationAlert from 'react-notification-alert';
import classes from './RootLayout.module.scss';
import { ROUTES } from '../../constants/routes';

const darkBgPages = [ROUTES.LOGIN, ROUTES.SIGNUP, ROUTES.PASSWORD_RECOVERY];

export default class RootLayout extends Component {
  static propTypes = exact({
    children: PropTypes.element,
    pathname: PropTypes.string.isRequired,
    user: PropTypes.object,
    notifyOptions: PropTypes.object,

    init: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    resetNotify: PropTypes.func,
    getLangList: PropTypes.func,
    getLangListSubscribe: PropTypes.func,
  });

  componentDidUpdate(prevProps, prevState) {
    const { notifyOptions, resetNotify } = this.props;
    if (Object.keys(notifyOptions).length) {
      this.notify(notifyOptions);
      resetNotify();
    }
  }

  componentWillMount() {
    this.props.init();
    this.props.getLangList();
    this.props.getLangListSubscribe();
  }

  notify({ msg, title, type, autoDismiss }) {
    const options = {
      place: 'tr',
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {title}
          </span>
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type,
      icon: 'ni ni-bell-55',
      autoDismiss,
    };
    this.refs.notify.notificationAlert(options);
  }

  render() {
    // const { user, pathname, logout, children } = this.props;
    const { pathname, children } = this.props;
    const isHomePage = pathname === '/';
    return (
      <div
        className={`${classes.root} ${classes.content} ${isHomePage &&
          classes.home} ${darkBgPages.indexOf(pathname) > -1 && 'bg-default'}`}
      >
        <div className="rna-wrapper notification-container">
          <NotificationAlert className="not-class-ssss" ref="notify" />
        </div>
        {children}
      </div>
    );
  }
}
