import React from 'react';

import LocalizedLink from '../../../../components/LocalizedLink';

import Hr from '../../../../components/Hr';
import {
  Row,
  Col,
  Carousel,
  CarouselItem,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
} from 'reactstrap';

import { TRANSLATIONS } from '../../../../constants/translations';
import { bwTypesMap, labels } from '../../../../constants/document';

import classes from './BusinessWeekly.module.scss';
import { ROUTES } from '../../../../constants/routes';
import SubscribeButton from '../../../../components/SubscribeBtn';
import { getLabelFromLang, getTextAlign } from '../../../../utils';

const bannerInterval = 10000;

class BusinessWeekly extends React.Component {
  constructor(props) {
    super(props);
    this.animating = false;
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    this.props.getRecent();
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const {
      recent: { publications },
    } = this.props;
    let { activeIndex } = this.state;
    this.setState({
      activeIndex: publications.length < ++activeIndex + 1 ? 0 : activeIndex,
    });
  }

  prev(e) {
    if (this.animating) return;
    const {
      recent: { publications },
    } = this.props;
    let { activeIndex } = this.state;
    this.setState({
      activeIndex: 0 > --activeIndex ? publications.length - 1 : activeIndex,
    });
  }

  goToIndex(activeIndex) {
    if (this.animating) return;
    this.setState({ activeIndex });
  }

  renderItem(item, index) {
    const { recent, selectedLang } = this.props;
    return (
      <CarouselItem
        onExiting={this.onExiting}
        onExited={this.onExited}
        key={`nl-${item.subType.toLowerCase()}`}
        className={classes.item}
      >
        <CardBody className={`${getTextAlign(selectedLang)}`}>
          <CardTitle className="h2 mb-0">
            {item.lang[selectedLang].title}
          </CardTitle>
          {recent.author && (
            <span className="text-muted h6">by {recent.author}</span>
          )}
          {recent.publishedAt && (
            <span className="text-muted h6">
              on {new Date(recent.publishedAt).toDateString()}
            </span>
          )}
          <div
            className={`mt-4 card-text ${classes.previewText}`}
            dangerouslySetInnerHTML={{
              __html: item.lang[selectedLang].content,
            }}
          />
          <div>...</div>
          <LocalizedLink
            className="px-0 btn-link btn"
            to={`${ROUTES.BUSINESS_WEEKLY}?selected=${index}`}
          >
            Read more
          </LocalizedLink>
        </CardBody>
      </CarouselItem>
    );
  }

  render() {
    const {
      selectedLang,
      recent,
      recent: { publications = [] },
    } = this.props;

    const { activeIndex } = this.state;
    const isHE = selectedLang === 'he';

    if (!publications.length) return null;

    return (
      <>
        <Hr color="default" />
        <Row className="align-items-center">
          <Col lg={4} xs={12}>
            <h2 className="display-3">Business weekly</h2>
          </Col>
          <Col xs={6}>
            <span className="text-primary display-3 pl-lg-4">
              #
              {recent.lang[selectedLang]
                ? recent.lang[selectedLang].number
                : recent.number}
            </span>
          </Col>
          <Col lg={2} xs={6} className="text-right">
            <LocalizedLink
              to={ROUTES.BUSINESS_WEEKLY_ARCHIVE}
              className="btn btn-outline-primary btn-sm"
            >
              <span className="btn-inner--text mr-1">
                {TRANSLATIONS.ALL_ARCHIVE.EN}
              </span>
              <span className="btn-inner--icon">
                <i className="ni ni-bold-right" />
              </span>
            </LocalizedLink>
          </Col>
        </Row>
        <Row className="row-grid align-items-center mt-5">
          <Col lg={4} xs={12}>
            <div>
              <Card className="overflow-hidden">
                <CardBody className="p-0 mb-lg-8 d-flex d-lg-block">
                  {publications.map((item, i) => (
                    <div
                      key={`nl-indicator-${item.subType.toLowerCase()}`}
                      className={`m-0 ${
                        i === activeIndex
                          ? 'bg-gradient-info'
                          : 'bg-transparent'
                      } ${classes.indicator}`}
                      onClick={() => this.goToIndex(i)}
                    >
                      <h5
                        className={`m-0 h4 text-uppercase ${
                          i === activeIndex ? 'text-white' : ''
                        } ${getTextAlign(selectedLang)}`}
                      >
                        {getLabelFromLang(
                          labels[bwTypesMap[item.subType]],
                          selectedLang,
                        )}
                      </h5>
                    </div>
                  ))}
                </CardBody>
                <CardFooter className="p-0 mt-lg-9">
                  <SubscribeButton />
                </CardFooter>
              </Card>
            </div>
          </Col>
          <Col lg={8} xs={12} className="mt-0" dir={`${isHE ? 'rtl' : 'ltr'}`}>
            <Carousel
              next={this.next}
              previous={this.prev}
              ride="carousel"
              interval={bannerInterval}
              activeIndex={activeIndex}
            >
              {publications.map(this.renderItem)}
            </Carousel>
          </Col>
        </Row>
      </>
    );
  }
}

export default BusinessWeekly;
