import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Table } from 'reactstrap';
import classnames from './ProfilePage.module.scss';

const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Username',
    dataIndex: 'username',
  },
];

export default class ProfilePage extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };

  render() {
    const { user } = this.props;
    return (
      <div className={classnames.container}>
        <Card title="My profile" className={classnames.card}>
          <Table responsive rowKey="email" className={classnames.table}>
            <thead className="thead-light">
              <tr>
                {columns.map(({ title, dataIndex }) => (
                  <th key={dataIndex} scope="col">
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {columns.map(({ dataIndex }) => (
                  <td key={dataIndex}>{user[dataIndex]}</td>
                ))}
              </tr>
            </tbody>
          </Table>
        </Card>
      </div>
    );
  }
}
