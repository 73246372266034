import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formValueSelector, reduxForm } from 'redux-form';

import { compose } from '../../utils';
import { saving } from '../../redux/form/selectors';
import { list, defaultLang } from '../../redux/lang/selectors';

import CustomFormFieldForm from './CustomFormFieldForm';

const formConect = reduxForm({
  form: 'customFormField',
  enableReinitialize: true,
});
const customFormFieldValues = formValueSelector('customFormField');
const reduxConect = connect(
  createStructuredSelector({
    saving,
    langList: list,
    defaultLang,
    selectedType: state => customFormFieldValues(state, 'type'),
    fieldOptions: state => customFormFieldValues(state, 'options'),
  }),
);

export { CustomFormFieldForm };

export default compose(
  reduxConect,
  formConect,
)(CustomFormFieldForm);
