import { takeEvery, put } from 'redux-saga/effects';
import {
  ADD_SUCCESS,
  REMOVE_SUCCESS,
  REMOVE_FIELD_SUCCESS,
  getListAdmin,
  getItemAdmin,
} from './actions';

import { ROUTES } from '../../constants/routes';

export function* createFormSuccess(history) {
  yield takeEvery(ADD_SUCCESS, function*(action) {
    yield history.push(`${ROUTES.ADMIN_FORMS_ITEM_PARAM}${action.data.id}`);
  });
}
export function* removeFieldSuccess(history) {
  yield takeEvery(REMOVE_FIELD_SUCCESS, function*(action) {
    yield put(getItemAdmin(action.data.formId));
  });
}

export function* formRemovedSuccess(history) {
  yield takeEvery(REMOVE_SUCCESS, function*() {
    yield put(getListAdmin());
    yield history.push(`${ROUTES.ADMIN_FORMS}`);
  });
}
