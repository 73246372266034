export const FIELD_TYPES = {
  text: {
    label: 'Text',
    value: 'text',
  },
  email: {
    label: 'Email',
    value: 'email',
  },
  tel: {
    label: 'Phone',
    value: 'tel',
  },
  file: {
    label: 'Attachment',
    value: 'file',
  },
  textarea: {
    label: 'Textarea',
    value: 'textarea',
  },
  quill: {
    label: 'Editor',
    value: 'quill',
  },
  select: {
    label: 'Selectbox',
    value: 'select',
  },
  checkbox: {
    label: 'Toggle',
    value: 'checkbox',
  },
  radio: {
    label: 'Options',
    value: 'options',
  },
  date: {
    label: 'Date',
    value: 'date',
  },
  time: {
    label: 'Time',
    value: 'time',
  },
};

export const FIELD_TYPES_ARR = [
  FIELD_TYPES.text,
  FIELD_TYPES.email,
  FIELD_TYPES.tel,
  FIELD_TYPES.file,
  FIELD_TYPES.textarea,
  FIELD_TYPES.quill,
  FIELD_TYPES.select,
  FIELD_TYPES.checkbox,
  FIELD_TYPES.radio,
  FIELD_TYPES.date,
  FIELD_TYPES.time,
];

export const OPTION_SEPARATOR = '-v-';
