import React, { Component } from 'react';
import moment from 'moment';
import { Button } from 'reactstrap';

class CustomEmailRow extends Component {
  render() {
    const {
      number,
      name,
      subject,
      status,
      emailedAt,
      scheduled,
      removeCustomEmail,
      id,
    } = this.props;

    return (
      <>
        <th>{number}</th>
        <th>{name}</th>
        <th>{id}</th>
        <th>{subject}</th>
        <th>
          <span
            className={
              status === 'SENT' ? 'text-success mr-2' : 'text-warning mr-3'
            }
          >
            {status}
          </span>
        </th>
        <td>
          <span className="text-muted">
            {emailedAt ? moment(emailedAt).format('MMMM Do YYYY, h:mm a') : '-'}
          </span>
        </td>
        <td>
          <span className="text-muted">
            {scheduled ? moment(scheduled).format('MMMM Do YYYY, h:mm a') : '-'}
          </span>
        </td>
        <th className="text-center">
          <Button
            className="btn btn-icon btn-danger btn-sm"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Delete"
            onClick={e => removeCustomEmail(e, id)}
          >
            <span className="btn-inner--icon">
              <i className="ni ni-fat-remove" />
            </span>
          </Button>
        </th>
      </>
    );
  }
}

export default CustomEmailRow;
