import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import DocumentForm from '../../../components/DocumentForm';
import { Container, Row, Col, Button } from 'reactstrap';
import { ROUTES } from '../../../constants/routes';
import { statuses, types } from '../../../constants/document';
import { Link } from 'react-router-dom';
import AddFormModal from '../FormListPage/AddFormModal';

class DocumentItemPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addModalIsOpen: false,
      fromProps: {},
    };

    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.cancel = this.cancel.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.addForm = this.addForm.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const {
      match: {
        params: { id },
      },
    } = nextProps;
    const newState = { fromProps: state.fromProps };

    if (id !== state.fromProps.id) {
      newState.fromProps.id = id;
    }

    return newState;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fromProps: { id },
    } = this.state;
    if (prevState.fromProps.id !== id) {
      if (id && id !== 'new') {
        this.props.fetch(id);
      }
    }
  }

  componentDidMount() {
    const {
      fetch,
      match: {
        params: { id },
      },
    } = this.props;
    if (id && id !== 'new') {
      fetch(id);
    }
  }

  toggleModal(open = false) {
    this.setState({ addModalIsOpen: open });
  }

  addForm({ id, active, documentId, lang }) {
    const { addForm } = this.props;
    addForm({ active, documentId, lang });
  }

  save({
    id,
    status,
    publishedAt,
    subType,
    lang,
    file,
    watermark,
    related,
    categories,
  }) {
    const { add, update, setFile } = this.props;
    if (file && file[0]) {
      file[0].watermark = watermark;
    } else {
      file = [{ watermark }];
    }
    const categoriesIds = categories.map(item => item.id);

    setFile(file || []);

    if (id) {
      update({
        id,
        status,
        publishedAt,
        subType,
        lang,
        related,
        categories: categoriesIds,
      });
    } else {
      add({
        status,
        subType,
        publishedAt,
        lang,
        related,
        categories: categoriesIds,
      });
    }
  }

  remove() {
    const yes = window.confirm('Are you sure you want delete this item?');
    if (!yes) return;
    const { documentItem, remove } = this.props;
    if (documentItem.id) {
      remove(documentItem.id);
    }
  }

  cancel() {
    const yes = window.confirm('Are you sure?');
    if (!yes) return;
    this.props.history.push(ROUTES.ADMIN_DOCUMENTS);
  }

  getDefaultValues() {
    return {
      status: statuses.DRAFT,
      watermark: false,
      categories: [],
    };
  }

  getItemForForm() {
    const { documentItem } = this.props;

    if (documentItem.id) {
      documentItem.watermark = !!documentItem.file.watermarkUrl;
      if (!documentItem.categories) {
        documentItem.categories = [];
      }
      return documentItem;
    }
    return null;
  }

  render() {
    const { addModalIsOpen } = this.state;
    const {
      loading,
      saving,
      documentItem,
      langList,
      match: {
        params: { id },
      },
      defaultLang,
    } = this.props;
    const isNew = id === 'new';

    return (
      <>
        {(loading || saving) && <Loader type="fixed" />}
        <Container fluid className="py-2 border-bottom">
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="display-2 mb-0">
                {isNew ? 'New' : 'Edit'} document
              </h2>
            </Col>
            <Col className="text-right">
              <Link
                to={`${ROUTES.ADMIN_DOCUMENT_ITEM_PARAM}new`}
                className="btn btn-primary btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text">New</span>
              </Link>
              <Button
                onClick={this.cancel}
                className="btn btn-warning btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-remove" />
                </span>
                <span className="btn-inner--text">Cancel</span>
              </Button>
            </Col>
          </Row>
        </Container>
        <Container className="pb-5">
          <Row>
            <Col>
              {isNew || !documentItem || !documentItem.id ? (
                <DocumentForm
                  isNew={isNew}
                  onSubmit={this.save}
                  onDelete={this.remove}
                  file={null}
                  initialValues={this.getDefaultValues()}
                  addForm={() => this.toggleModal(true)}
                  relatedAudio={null}
                  relatedArticle={null}
                />
              ) : (
                <DocumentForm
                  isNew={false}
                  onSubmit={this.save}
                  onDelete={this.remove}
                  file={documentItem.file}
                  formId={documentItem.form ? documentItem.form.id : null}
                  initialValues={this.getItemForForm()}
                  addForm={() => this.toggleModal(true)}
                  relatedAudio={documentItem.related.find(
                    rItem => rItem.type === types.AUDIO,
                  )}
                  relatedArticle={documentItem.related.find(
                    rItem => rItem.type === types.ARTICLE,
                  )}
                  relatedBw={documentItem.related.find(
                    rItem => rItem.type === types.BUSINESS_WEEKLY,
                  )}
                />
              )}
            </Col>
          </Row>
        </Container>

        <AddFormModal
          isOpened={addModalIsOpen}
          defaultLang={defaultLang}
          langList={langList}
          initialValues={
            documentItem.id
              ? {
                  documentId: documentItem.id,
                  lang: defaultLang
                    ? {
                        [defaultLang]: {
                          name: documentItem.lang[defaultLang].title,
                        },
                      }
                    : {},
                }
              : {}
          }
          documentList={[documentItem]}
          save={this.addForm}
          close={() => this.toggleModal()}
        />
      </>
    );
  }
}

export default DocumentItemPage;
