import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Nav, NavItem, NavLink } from 'reactstrap';

export default class LanguageTabs extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    fullList: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      lang: props.defaultLang,
      fromProps: {
        defaultLang: '',
      },
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { defaultLang, isCategory, dafalutCatLang } = nextProps;
    const newState = { fromProps: state.fromProps };
    const initLang = isCategory ? dafalutCatLang : defaultLang;
    if (state.fromProps.defaultLang !== initLang) {
      newState.lang = initLang;
      newState.fromProps.defaultLang = initLang;
    }

    return newState;
  }

  componentDidMount() {
    const { lang } = this.state;
    if (lang) {
      this.props.onChange(lang);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fromProps: { defaultLang },
    } = this.state;
    if (defaultLang && !prevProps.defaultLang) {
      this.props.onChange(defaultLang);
    }
    if (prevProps.lang !== this.props.lang) {
      this.setState({
        lang: this.props.lang,
      });
    }
  }

  toggleNavs = (e, state, lang) => {
    const { onChange } = this.props;
    e.preventDefault();
    this.setState({
      [state]: lang,
    });
    onChange(lang);
  };

  render() {
    const {
      langList,
      langFullList,
      fullList,
      vertical = false,
      smLinks = false,
      forceRow = false,
    } = this.props;
    const list = fullList ? langFullList : langList;

    return (
      <Nav
        className={`nav-fill ${
          forceRow
            ? 'flex-row justify-content-end justify-content-md-start'
            : 'flex-column flex-md-row'
        }`}
        id="lang-tabs-icons-text"
        pills
        role="tablist"
      >
        {list.map(lang => {
          return (
            <NavItem
              key={`lang-${lang.identifier}`}
              className={`flex-grow-0 ${vertical ? 'mb-2' : ''} ${
                forceRow ? 'pl-3 pl-md-0' : ''
              }`}
            >
              <NavLink
                aria-selected={this.state.lang === lang.identifier}
                className={classnames('mb-sm-3 mb-md-0', {
                  active: this.state.lang === lang.identifier,
                  'py-1': !!smLinks,
                })}
                onClick={e => this.toggleNavs(e, 'lang', lang.identifier)}
                href="#"
                role="tab"
              >
                {lang.label}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    );
  }
}
