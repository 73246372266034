import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  list(state = [], action) {
    switch (action.type) {
      case actions.LIST_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  item(state = { fields: [] }, action) {
    switch (action.type) {
      case actions.GET_ITEM_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  saving(state = false, action) {
    switch (action.type) {
      case actions.ADD_REQUEST:
      case actions.UPDATE_REQUEST:
      case actions.SAVE_FIELD_REQUEST:
        return true;
      case actions.ADD_FAILURE:
      case actions.ADD_SUCCESS:
      case actions.UPDATE_FAILURE:
      case actions.UPDATE_SUCCESS:
      case actions.SAVE_FIELD_FAILURE:
      case actions.SAVE_FIELD_SUCCESS:
        return false;
      default:
        return state;
    }
  },
  listAdmin(state = [], action) {
    switch (action.type) {
      case actions.LIST_ADMIN_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  itemAdmin(state = { lang: {}, fields: [] }, action) {
    switch (action.type) {
      case actions.ITEM_ADMIN_SUCCESS:
        return action.data;
      case actions.ITEM_ADMIN_REQUEST:
      case actions.REMOVE_SUCCESS:
      case actions.ADD_SUCCESS:
        return { lang: {}, fields: [] };
      default:
        return state;
    }
  },
  editModalOpened(state = false, action) {
    switch (action.type) {
      case actions.ADD_REQUEST:
      case actions.UPDATE_REQUEST:
      case actions.REMOVE_REQUEST:
      case actions.SAVE_FIELD_REQUEST:
      case actions.REMOVE_FIELD_REQUEST:
        return true;
      case actions.ADD_SUCCESS:
      case actions.UPDATE_SUCCESS:
      case actions.REMOVE_SUCCESS:
      case actions.SAVE_FIELD_SUCCESS:
      case actions.REMOVE_FIELD_SUCCESS:
        return false;
      default:
        return state;
    }
  },
});
