export const hebcalLg = {
  en: 'a',
  he: 'h',
  fr: 'fr',
};

export const hebcalCategories = {
  parashat: 'parashat',
  hebdate: 'hebdate',
};

export function getHebCalDate(date) {
  const dateObj = new Date(date);
  return dateObj.toISOString().split('T')[0];
}

export function hebcalParams(lg = hebcalLg.en) {
  const today = new Date();
  return { year: today.getFullYear(), month: today.getMonth() + 1, lg };
}

export function getHebCalItem(hebcal) {
  const today = new Date().getTime();
  const week = hebcal.items.findIndex(
    item =>
      new Date(item.date).getTime() >= today &&
      item.category === hebcalCategories.parashat,
  );

  return week > -1 ? hebcal.items[week] : hebcal.items[0];
}

export const PARSHAS = [
  'Vaera',
  'Bo',
  'Beshalach',
  'Yisro',
  'Mishpatim',
  'Terumah',
  'Tetzaveh',
  'Ki Sisa',
  'Vayakhel',
  'Pekudei',
  'Vayikra',
  'Tzav',
  'Shmini',
  'Sazria',
  'Metzora',
  'Achrei Mos',
  'Kedoshim',
  'Emor',
  'Behar',
  'Bechukosai',
  'Bamidbar',
  'Nasso',
  "Beha'aloscha",
  "Sh'lach",
  'Korach',
  'Chukas',
  'Balak',
  'Pinchas',
  'Matos-Masei',
  'Devarim',
  'Vaeschanan',
  'Eikev',
  "Re'eh",
  'Shoftim',
  'Ki Seitzei',
  'Ki Savo',
  'Nitzavim',
  'Vayeilech',
  "Ha'Azinu",
  'Bereshis',
  'Noach',
  'Lech-Lecha',
  'Vayera',
  'Chayei Sara',
  'Toldos',
  'Vayetzei',
  'Vayishlach',
  'Vayeshev',
  'Miketz',
];
