import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import {
  remove,
  update,
  add,
  getParentsByType,
} from '../../../../../redux/category/actions';
import { loading } from '../../../../../redux/category/selectors';
import { listAdmin as categoryTypes } from '../../../../../redux/category-type/selectors';
import CategoryModal from './CategoryModal';

const selector = createStructuredSelector({
  loading,
  defaultCategoryType: createSelector(
    categoryTypes,
    types => types[0],
  ),
});

const actions = {
  add,
  update,
  remove,
  getParentsByType,
};

export default connect(
  selector,
  actions,
)(CategoryModal);
