export const prefix = 'materialsCategoryType';

export const LIST_ADMIN_REQUEST = `${prefix}/LIST_ADMIN_REQUEST`;
export const LIST_ADMIN_SUCCESS = `${prefix}/LIST_ADMIN_SUCCESS`;
export const LIST_ADMIN_FAILURE = `${prefix}/LIST_ADMIN_FAILURE`;

export const getListAdmin = query => ({
  types: [LIST_ADMIN_REQUEST, LIST_ADMIN_SUCCESS, LIST_ADMIN_FAILURE],
  promise: api => api.category.relations(query),
});

export const ITEM_ADMIN_REQUEST = `${prefix}/ITEM_ADMIN_REQUEST`;
export const ITEM_ADMIN_SUCCESS = `${prefix}/ITEM_ADMIN_SUCCESS`;
export const ITEM_ADMIN_FAILURE = `${prefix}/ITEM_ADMIN_FAILURE`;

export const getItemAdmin = data => ({
  types: [ITEM_ADMIN_REQUEST, ITEM_ADMIN_SUCCESS, ITEM_ADMIN_FAILURE],
  promise: api => api.category.relationItem(data),
});

export const ADD_REQUEST = `${prefix}/ADD_REQUEST`;
export const ADD_SUCCESS = `${prefix}/ADD_SUCCESS`;
export const ADD_FAILURE = `${prefix}/ADD_FAILURE`;

export const add = data => ({
  types: [ADD_REQUEST, ADD_SUCCESS, ADD_FAILURE],
  promise: api => api.category.addRelation(data),
});

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`;

export const update = data => ({
  types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: api => api.category.updateRelation(data),
});

export const REMOVE_REQUEST = `${prefix}/REMOVE_REQUEST`;
export const REMOVE_SUCCESS = `${prefix}/REMOVE_SUCCESS`;
export const REMOVE_FAILURE = `${prefix}/REMOVE_FAILURE`;

export const remove = data => ({
  types: [REMOVE_REQUEST, REMOVE_SUCCESS, REMOVE_FAILURE],
  promise: api => api.category.removeRelation(data),
});
