import React from 'react';
import isEmpty from 'lodash/isEmpty';

import {
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  TabContent,
  TabPane,
} from 'reactstrap';

import { Field } from 'redux-form';
import InputRedux from '../Input/InputRedux';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  hebcalLg,
  hebcalCategories,
  getHebCalItem,
  hebcalParams,
} from '../../constants/hebcal';
import DatePicker from '../DatePicker/DatePicker';

class SendSubscriptionForm extends React.Component {
  static propTypes = {
    handleSend: PropTypes.func.isRequired,
    handleSchedule: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedBWId,
      list,
      hebcalLoading,
      selectedLang,
      hebcal,
    } = this.props;
    if (
      prevProps.selectedBWId !== selectedBWId &&
      list.length > 0 &&
      !hebcalLoading
    ) {
      this.updateEmailData(selectedBWId, selectedLang);
    }
    if (prevProps.list !== list && list.length > 0 && !hebcalLoading) {
      this.updateEmailData(selectedBWId, selectedLang);
    }
    if (prevProps.hebcalLoading && !hebcalLoading && hebcal) {
      this.updateEmailData(selectedBWId, selectedLang);
    }
  }

  componentDidMount() {
    this.props.getRecent();
    this.props.getList({ limit: 6, includeFull: true });
    this.props.getHebcal(hebcalParams());
  }

  send = values => {
    const { handleSend, selectedBWId, list, selectedLang } = this.props;
    const selectedBW = list.find(item => item.id === selectedBWId);
    if (!values.bwId) {
      this.props.alert('BW is required', 'Required', 7, 'warning');
      return;
    }

    if (values.subject && values.subject[selectedLang] === 'other') {
      values.subject = values.subjectCustom;
    }
    if (!values.subject || !values.subject[selectedLang]) {
      this.props.alert('Subject is required', 'Required', 7, 'warning');
      return;
    }

    if (!values.headline) {
      this.props.alert('Headline is required', 'Required', 7, 'warning');
      return;
    }

    if (isEmpty(values.subjectCustom)) {
      if (!values.title) {
        this.props.alert('Title is required', 'Required', 7, 'warning');
        return;
      }
      if (!values.author) {
        this.props.alert('Author is required', 'Required', 7, 'warning');
        return;
      }
    }

    if (!values.text) {
      this.props.alert('Text is required', 'Required', 7, 'warning');
      return;
    }

    if (!values.lang) {
      this.props.alert('Language is required', 'Required', 7, 'warning');
      return;
    }

    if (
      selectedBW &&
      selectedBW.lang &&
      selectedBW.lang[selectedLang] &&
      selectedBW.lang[selectedLang].emailedAt
    ) {
      const yes = window.confirm('Are you sure you want send emails again?');
      if (yes) return handleSend(values);
      return;
    }
    return handleSend(values);
  };

  schedule = values => {
    const { handleSchedule, selectedBWId, list, selectedLang } = this.props;
    const selectedBW = list.find(item => item.id === selectedBWId);
    if (!values.bwId) {
      this.props.alert('BW is required', 'Required', 7, 'warning');
      return;
    }

    if (values.subject && values.subject[selectedLang] === 'other') {
      values.subject = values.subjectCustom;
    }
    if (!values.subject || !values.subject[selectedLang]) {
      this.props.alert('Subject is required', 'Required', 7, 'warning');
      return;
    }

    if (!values.headline) {
      this.props.alert('Headline is required', 'Required', 7, 'warning');
      return;
    }

    if (isEmpty(values.subjectCustom)) {
      if (!values.title) {
        this.props.alert('Title is required', 'Required', 7, 'warning');
        return;
      }
      if (!values.author) {
        this.props.alert('Author is required', 'Required', 7, 'warning');
        return;
      }
    }

    if (!values.text) {
      this.props.alert('Text is required', 'Required', 7, 'warning');
      return;
    }

    if (!values.lang) {
      this.props.alert('Language is required', 'Required', 7, 'warning');
      return;
    }

    if (
      selectedBW &&
      selectedBW.lang &&
      selectedBW.lang[selectedLang].emailDate
    ) {
      const yes = window.confirm(
        'Are you sure you want reschedule emails again?',
      );
      if (yes) return handleSchedule(values);
      return;
    }
    return handleSchedule(values);
  };

  toggleNavs = (state, index) => {
    this.setState({
      [state]: index,
    });
  };

  langChanged = (e, val) => {
    const { selectedBWId, getHebcal } = this.props;
    this.toggleNavs('selectedLang', val);
    getHebcal(hebcalParams(hebcalLg[val]));
    this.updateEmailData(selectedBWId, val);
  };

  sbjChanged = (e, val) => {
    const { hebcal, change } = this.props;
    const hcItem = hebcal.items.find(
      item => item.title === val && item.category === hebcalCategories.parashat,
    );
    if (hcItem && hcItem.date) {
      change(`date`, new Date(hcItem.date).toDateString());
      change(`hDate`, hcItem.hDate);
    } else {
      change(`date`, '');
      change(`hDate`, '');
    }
  };

  handleChangeEmailDate = value => {
    const { change } = this.props;
    change(
      `emailDate`,
      value
        .utc()
        .minute(0)
        .format(),
    );
  };

  updateEmailData = (BWId, lang) => {
    const { change, list, hebcal } = this.props;
    const BW = list.find(item => item.id === BWId);
    if (!BW || !BW.lang[lang]) return;
    const emailData = BW && BW.lang[lang] && BW.lang[lang].emailData;

    if (emailData) {
      change(`headline.${lang}`, emailData.headline);
      change(`author.${lang}`, emailData.author);
      change(`text.${lang}`, emailData.text);
      change(`title.${lang}`, emailData.title);
    } else {
      change(`headline.${lang}`, '');
      change(`author.${lang}`, BW.storyLine.lang[lang].author);
      change(`text.${lang}`, BW.storyLine.lang[lang].content);
      change(`title.${lang}`, BW.storyLine.lang[lang].title);
    }
    if (emailData && emailData.subject) {
      const hcItem = this.getSeletedHebcalItem(emailData.subject);
      if (hcItem.date) {
        change(`subject.${lang}`, emailData.subject);
        change(`date`, new Date(hcItem.date).toDateString());
        change(`hDate`, hcItem.hDate);
      } else {
        change(`subject.${lang}`, 'other');
        change(`subjectCustom.${lang}`, emailData.subject);
        change(`date`, '');
        change(`hDate`, '');
      }
    } else {
      const hcItem = getHebCalItem(hebcal);
      change(
        `subject.${lang}`,
        lang === 'he' ? hcItem.hebrew || hcItem.title : hcItem.title,
      );
      change(`date`, new Date(hcItem.date).toDateString());
      change(`hDate`, hcItem.hDate);
    }
    const emailDate =
      (BW.lang[lang] && BW.lang[lang].emailDate) || this.getDefaultDate();
    change('emailDate', emailDate);
  };

  handleBwChange = (e, value) => {
    const { selectedLang } = this.props;
    this.updateEmailData(value, selectedLang);
  };

  getDefaultDate = () => {
    const dayINeed = 4; // for Thursday
    const timeNeeded = 14;
    const today = moment().isoWeekday();

    if (today <= dayINeed) {
      return moment()
        .isoWeekday(dayINeed)
        .hour(timeNeeded)
        .minute(0);
    } else {
      return moment()
        .add(1, 'weeks')
        .isoWeekday(dayINeed)
        .hour(timeNeeded)
        .minute(0);
    }
  };

  getSeletedHebcalItem = title => {
    const { hebcal } = this.props;
    const selectedItem = hebcal.items.find(
      item =>
        (item.title === title || item.hebrew === title) &&
        item.category === hebcalCategories.parashat,
    );
    return selectedItem || {};
  };

  render() {
    const {
      sending,
      hebcalLoading,
      defaultLang,
      langList,
      list,
      hebcal,
      selectedBWId,
      emailDate,
      handleSubmit,
      selectedLang = defaultLang,
      date,
      sbjValues,
    } = this.props;

    const selectedBW = list.find(item => item.id === selectedBWId) || {};
    const selectedBWData = selectedBW.lang && selectedBW.lang[selectedLang];
    const isHE = selectedLang === 'he';

    return (
      <Form role="form">
        {selectedBWData ? (
          <p className="display-4">
            {selectedBWData.title} #{selectedBWData.number}
            {selectedBWData.emailedAt && (
              <span className="h5 text-warning">
                &nbsp;(This week's email has already been sent)
              </span>
            )}
            {selectedBWData.emailDate && !selectedBWData.emailedAt && (
              <span className="h5  text-warning">
                &nbsp;Emails scheduled for{' '}
                {moment(selectedBWData.emailDate).calendar()}
              </span>
            )}
          </p>
        ) : (
          '-'
        )}

        <Row>
          <Col>
            <FormGroup className="mb-3">
              <Field
                name="bwId"
                type="select"
                disabled={sending || hebcalLoading}
                required
                options={list.map(item => {
                  const bwItem =
                    (item.lang && item.lang[selectedLang]) ||
                    (item.lang && item.lang[defaultLang]) ||
                    {};
                  return {
                    label: `${bwItem.title} #${bwItem.number}`,
                    value: item.id,
                  };
                })}
                onChange={this.handleBwChange}
                component={InputRedux}
                dir={`${isHE ? 'rtl' : 'ltr'}`}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-world-2" />
                  </InputGroupText>
                </InputGroupAddon>
                <Field
                  name="lang"
                  type="select"
                  disabled={sending || hebcalLoading}
                  onChange={this.langChanged}
                  required
                  options={langList.map(l => ({
                    label: l.fullName,
                    value: l.identifier,
                  }))}
                  component={InputRedux}
                  dir={`${isHE ? 'rtl' : 'ltr'}`}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <TabContent activeTab={'selectedLang-' + selectedLang}>
          {langList.map(lang => {
            return (
              <TabPane
                key={`tab-${lang.identifier}`}
                tabId={`selectedLang-${lang.identifier}`}
              >
                <Row>
                  <Col>
                    <FormGroup>
                      <label className="form-control-label">
                        Email subject&nbsp;
                        {hebcalLoading && (
                          <i className="text-info fas fa-spinner fa-pulse ml-3" />
                        )}
                        {!hebcalLoading && date && `(${date})`}
                      </label>
                      <Field
                        name={`subject.${lang.identifier}`}
                        type="select"
                        disabled={sending || hebcalLoading}
                        onChange={this.sbjChanged}
                        options={[
                          ...hebcal.items
                            .filter(
                              item =>
                                item.category === hebcalCategories.parashat,
                            )
                            .map(p => {
                              const optionObjValue = isHE ? p.hebrew : p.title;
                              return {
                                value: optionObjValue,
                                label: optionObjValue,
                              };
                            }),
                          {
                            value: 'other',
                            label: 'Other',
                          },
                        ]}
                        component={InputRedux}
                        dir={`${isHE ? 'rtl' : 'ltr'}`}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    {sbjValues && sbjValues[selectedLang] === 'other' && (
                      <FormGroup>
                        <label className="form-control-label">
                          Custom email subject&nbsp;
                          {hebcalLoading && (
                            <i className="text-info fas fa-spinner fa-pulse ml-3" />
                          )}
                        </label>
                        <Field
                          name={`subjectCustom.${lang.identifier}`}
                          type="text"
                          disabled={
                            sending ||
                            hebcalLoading ||
                            (sbjValues && sbjValues[selectedLang] !== 'other')
                          }
                          component={InputRedux}
                          dir={`${isHE ? 'rtl' : 'ltr'}`}
                        />
                      </FormGroup>
                    )}
                  </Col>
                </Row>

                <FormGroup>
                  <label className="form-control-label">
                    Headline
                    {hebcalLoading && (
                      <i className="text-info fas fa-spinner fa-pulse ml-3" />
                    )}
                  </label>
                  <Field
                    name={`headline.${lang.identifier}`}
                    type="textarea"
                    disabled={sending || hebcalLoading}
                    rows={2}
                    component={InputRedux}
                    dir={`${isHE ? 'rtl' : 'ltr'}`}
                  />
                </FormGroup>
                <Row>
                  <Col>
                    <FormGroup>
                      <label className="form-control-label">
                        Title
                        {hebcalLoading && (
                          <i className="text-info fas fa-spinner fa-pulse ml-3" />
                        )}
                      </label>
                      <Field
                        name={`title.${lang.identifier}`}
                        type="text"
                        disabled={sending || hebcalLoading}
                        component={InputRedux}
                        dir={`${isHE ? 'rtl' : 'ltr'}`}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <label className="form-control-label">
                        Author
                        {hebcalLoading && (
                          <i className="text-info fas fa-spinner fa-pulse ml-3" />
                        )}
                      </label>
                      <Field
                        name={`author.${lang.identifier}`}
                        type="text"
                        disabled={sending || hebcalLoading}
                        component={InputRedux}
                        dir={`${isHE ? 'rtl' : 'ltr'}`}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <label className="form-control-label">
                    Text
                    {hebcalLoading && (
                      <i className="text-info fas fa-spinner fa-pulse ml-3" />
                    )}
                  </label>
                  <Field
                    name={`text.${lang.identifier}`}
                    type="quill"
                    disabled={sending || hebcalLoading}
                    required
                    rows={20}
                    component={InputRedux}
                    dir={`${isHE ? 'rtl' : 'ltr'}`}
                    index={lang.identifier}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label">
                    Schedule email date
                    {hebcalLoading && (
                      <i className="text-info fas fa-spinner fa-pulse ml-3" />
                    )}
                  </label>

                  <DatePicker
                    onChange={this.handleChangeEmailDate}
                    value={emailDate}
                  />
                </FormGroup>
              </TabPane>
            );
          })}
        </TabContent>

        <Button
          disabled={sending}
          onClick={handleSubmit(this.schedule)}
          color="primary"
          className={
            isHE ? 'btn btn-icon mt-5 isHeButton' : 'btn btn-icon mt-5'
          }
        >
          <span className="btn-inner--icon">
            {sending ? (
              <i className="text-white fas fa-spinner fa-pulse" />
            ) : (
              <i className="ni ni-calendar-grid-58" />
            )}
          </span>
          <span className="btn-inner--text">
            {selectedBWData && selectedBWData.emailDate
              ? 'Reschedule'
              : 'Schedule'}
          </span>
        </Button>
        <Button
          disabled={sending}
          onClick={handleSubmit(this.send)}
          color="info"
          className={
            isHE ? 'btn btn-icon mt-5 isHeButton' : 'btn btn-icon mt-5'
          }
        >
          <span className="btn-inner--icon">
            {sending ? (
              <i className="text-white fas fa-spinner fa-pulse" />
            ) : (
              <i className="ni ni-send" />
            )}
          </span>
          <span className="btn-inner--text">
            {selectedBWData && selectedBWData.emailedAt ? 'Resend' : 'Send now'}
          </span>
        </Button>
      </Form>
    );
  }
}

export default SendSubscriptionForm;
