import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import SearchPage from './SearchPage';

import { lang } from '../../redux/lang/selectors';
import {
  loading,
  resultsComposed,
  resultsAmount,
  searchedStr,
} from '../../redux/search/selectors';
import { search, reset } from '../../redux/search/actions';

const selector = createStructuredSelector({
  selectedLang: lang,
  results: resultsComposed,
  resultsAmount,
  searchedStr,
  loading,
});

const actions = { search, reset };

export { SearchPage };

export default connect(
  selector,
  actions,
)(SearchPage);
