import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthNavbar from '../Navbars/AuthNavbar';
import AuthFooter from '../Footers/MainFooter';
import LoginPage from '../../pages/LoginPage';
import ResetPasswordPage from '../../pages/ResetPasswordPage';
import PasswordRecoveryPage from '../../pages/PasswordRecoveryPage';
import { ROUTES } from '../../constants/routes';
import PropTypes from 'prop-types';

export default class AuthContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  render() {
    const { isAuthenticated, loading } = this.props;
    return (
      <>
        <div className="main-content" ref="mainContent">
          <AuthNavbar />
          {isAuthenticated && !loading && <Redirect to="/" />}
          {!isAuthenticated && (
            <Switch>
              <Route path={ROUTES.LOGIN} component={LoginPage} exact />
              <Route
                path={ROUTES.RESET_PASSWORD}
                component={ResetPasswordPage}
                exact
              />
              <Route
                path={ROUTES.PASSWORD_RECOVERY}
                component={PasswordRecoveryPage}
                exact
              />
            </Switch>
          )}
        </div>
        <AuthFooter />
      </>
    );
  }
}
