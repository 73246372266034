import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.SEARCH_REQUEST:
        return true;
      case actions.SEARCH_SUCCESS:
      case actions.SEARCH_FAILURE:
        return false;
      default:
        return state;
    }
  },
  results(state = [], action) {
    switch (action.type) {
      case actions.SEARCH_SUCCESS:
        return action.data.results;
      case actions.RESET:
        return [];
      default:
        return state;
    }
  },
  resultsComposed(state = [], action) {
    switch (action.type) {
      case actions.SEARCH_REQUEST:
        if (action.reset) {
          return [];
        }
        return state;
      case actions.SEARCH_SUCCESS:
        return [...state, ...action.data.results];
      case actions.RESET:
        return [];
      default:
        return state;
    }
  },
  resultsAmount(state = 0, action) {
    switch (action.type) {
      case actions.SEARCH_SUCCESS:
        return action.data.amount || 0;
      case actions.RESET:
        return 0;
      default:
        return state;
    }
  },
  searchedStr(state = '', action) {
    switch (action.type) {
      case actions.SEARCH_REQUEST:
        return action.searchingStr;
      case actions.RESET:
        return '';
      default:
        return state;
    }
  },
  filter(state = { type: null, isAdvancedSearch: false }, action) {
    switch (action.type) {
      case actions.CHANGE_FILTER:
        return { ...state, [action.payload.name]: action.payload.value };
      case actions.RESET:
        return { type: null };
      default:
        return state;
    }
  },
});
