import React from 'react';

import LocalizedLink from '../../../../components/LocalizedLink';
import ArticleItem from '../../../../components/ArticleItem';

import Hr from '../../../../components/Hr';
import { Row, Col, CardTitle, CardBody, CardFooter } from 'reactstrap';

import { TRANSLATIONS } from '../../../../constants/translations';
import { ROUTES } from '../../../../constants/routes';
import AuthorAndDateLabel from '../../../../components/AuthorAndDateLabel';
import { getLabelFromLang } from '../../../../utils';
import { FILE_URL } from '../../../../constants/files';

class Articles extends React.Component {
  componentDidMount() {
    this.props.getRecent();
  }

  render() {
    const {
      recent,
      lang,
      defaultLang,
      selectedLang,
      darkTitle = false,
    } = this.props;

    const color = darkTitle ? 'default' : 'white';
    if (!recent.length) return null;

    const main = recent[0];
    const side = recent.slice(1);

    return (
      <>
        <Hr color={color} />
        <Row>
          <Col md={4}>
            <h2 className={`text-${color} display-3`}>
              Articles & publications
            </h2>
            <LocalizedLink
              to={ROUTES.ARTICLES}
              className={`btn btn-outline-${color} btn-sm mt-3 mb-3`}
            >
              <span className="btn-inner--text mr-1">
                {getLabelFromLang(TRANSLATIONS.SEE_ALL.EN, lang)}
              </span>
              <span className="btn-inner--icon">
                <i className="ni ni-bold-right" />
              </span>
            </LocalizedLink>
          </Col>
          <Col md={8}>
            <Row>
              <Col md={7}>
                <ArticleItem item={main} />
              </Col>
              <Col md={5} className="d-none d-md-block">
                {side.map(item => (
                  <CardBody key={`recent-${item.id}`} className="p-0">
                    <LocalizedLink to={`${ROUTES.ARTICLE_PARAM}${item.id}`}>
                      <CardTitle className="h2 mb-0">
                        {item.lang[lang]
                          ? item.lang[lang].title
                          : item.lang[defaultLang].title}
                      </CardTitle>
                    </LocalizedLink>
                    <AuthorAndDateLabel
                      selectedLang={selectedLang}
                      item={item}
                      createdAt={item.createdAt}
                    />
                    <div>
                      <LocalizedLink
                        to={`${ROUTES.ARTICLE_PARAM}${item.id}`}
                        className="btn btn-outline-default mt-4 mb-4"
                      >
                        <span className="btn-inner--icon mr-2">
                          <i className="fas fa-eye" />
                        </span>
                        <span className="btn-inner--text">
                          {getLabelFromLang(TRANSLATIONS.VIEW.EN, lang)}
                        </span>
                      </LocalizedLink>
                      {item.pdf && item.pdf.id && (
                        <a
                          href={`${FILE_URL}${item.pdf.watermarkUrl ||
                            item.pdf.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-outline-default mt-4 mb-4"
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-cloud-download-95" />
                          </span>
                        </a>
                      )}
                    </div>
                    <CardFooter className="bg-transparent" />
                  </CardBody>
                ))}
              </Col>
              <Col className="d-md-none">
                {side.map(item => (
                  <ArticleItem key={`recent-${item.id}`} item={item} />
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default Articles;
