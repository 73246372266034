export const prefix = 'search';

export const SEARCH_REQUEST = `${prefix}/SEARCH_REQUEST`;
export const SEARCH_SUCCESS = `${prefix}/SEARCH_SUCCESS`;
export const SEARCH_FAILURE = `${prefix}/SEARCH_FAILURE`;
export const CHANGE_FILTER = `${prefix}/CHANGE_FILTER`;
export const RESET = `${prefix}/RESET`;

export const search = ({
  searching,
  isTopResults,
  page,
  reset = false,
  filter,
}) => ({
  types: [SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_FAILURE],
  promise: api => {
    return api.common.search({
      searching,
      isTopResults,
      page,
      filter,
    });
  },
  searchingStr: searching,
  reset,
});

export const reset = () => ({ type: RESET });
