import { takeEvery, put } from 'redux-saga/effects';
import {
  ADD_SUCCESS,
  UPDATE_SUCCESS,
  SAVE_FILE_SUCCESS,
  REMOVE_SUCCESS,
  saveFile,
  getItemAdmin,
  getListAdmin,
} from './actions';

import { ROUTES } from '../../constants/routes';

export function* createDocumentSuccess(history) {
  yield takeEvery(ADD_SUCCESS, function*(action) {
    yield history.push(`${ROUTES.ADMIN_DOCUMENT_ITEM_PARAM}${action.data.id}`);
    yield put(saveFile(action.data.id));
  });
}

export function* updateDocumentSuccess() {
  yield takeEvery(UPDATE_SUCCESS, function*(action) {
    yield put(saveFile(action.data.id, action.data.file.id));
  });
}

export function* documentSaveFileSuccess() {
  yield takeEvery(SAVE_FILE_SUCCESS, function*(action) {
    yield put(getItemAdmin(action.data.itemId));
  });
}

export function* documentRemovedSuccess(history) {
  yield takeEvery(REMOVE_SUCCESS, function*() {
    yield put(getListAdmin());
    yield history.push(`${ROUTES.ADMIN_DOCUMENTS}`);
  });
}
