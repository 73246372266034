import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Input, Button } from 'reactstrap';

import { ROUTES } from '../../../constants/routes';
import {
  STATUSES,
  STATUS_LABELS,
  STATUS_COLORS,
  STATUS_TEXT_COLORS,
} from '../../../constants/submissionStatuses';

class SubmissionRow extends Component {
  render() {
    const { submission, setStatus, defaultLang, onRemove } = this.props;

    return (
      <tr className={`bg-translucent-${STATUS_COLORS[submission.status]}`}>
        <td>
          <span
            className={
              submission.status === STATUSES.COMPLETED
                ? 'text-muted'
                : 'text-info'
            }
          >
            {submission.email}
          </span>
        </td>
        <td>
          {submission.form.deletedAt === null ? (
            <Link
              className={
                submission.status === STATUSES.COMPLETED ? 'text-muted' : ''
              }
              to={`${ROUTES.ADMIN_FORMS_ITEM_PARAM}${submission.form.id}`}
            >
              {submission.form.lang[defaultLang].name}
            </Link>
          ) : (
            <div
              className={
                submission.status === STATUSES.COMPLETED ? 'text-muted' : ''
              }
            >
              {submission.form.lang[defaultLang].name}

              <i className="ml-1 text-warning fas fa-minus-circle" />
            </div>
          )}
        </td>
        <td
          className={
            submission.status === STATUSES.COMPLETED ? 'text-muted' : ''
          }
        >
          {new Date(submission.createdAt).toDateString()}
        </td>
        <td>
          <Input
            className={`form-control-sm text-${
              STATUS_TEXT_COLORS[submission.status]
            }`}
            type="select"
            onChange={e => setStatus(e.target.value, submission.id)}
            defaultValue={submission.status}
          >
            {Object.values(STATUSES).map(status => (
              <option key={status} value={status}>
                {STATUS_LABELS[status]}
              </option>
            ))}
          </Input>
        </td>
        <td>
          <Link
            className="btn btn-icon btn-secondary btn-sm"
            to={`${ROUTES.ADMIN_SUBMISSIONS_ITEM_PARAM}${submission.id}`}
          >
            <i className="fas fa-edit" />
          </Link>
          <Button
            className="btn btn-icon btn-danger btn-sm"
            type="button"
            onClick={() => onRemove(submission.id)}
          >
            <span className="btn-inner--icon">
              <i className="ni ni-fat-remove" />
            </span>
          </Button>
        </td>
      </tr>
    );
  }
}

export default SubmissionRow;
