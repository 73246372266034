import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Articles from './Articles';

import { lang, defaultLang } from '../../../../redux/lang/selectors';
import { recent } from '../../../../redux/article/selectors';
import { getRecent } from '../../../../redux/article/actions';

const selector = createStructuredSelector({
  lang,
  recent,
  defaultLang,
});

export default connect(
  selector,
  { getRecent },
)(Articles);
