import { connect } from 'react-redux';

import HalachicGuidancePage from './HalachicGuidancePage';

const selector = null;

const actions = {};

export { HalachicGuidancePage };

export default connect(
  selector,
  actions,
)(HalachicGuidancePage);
