import React from 'react';

import LocalizedLink from '../../../../components/LocalizedLink';

import Hr from '../../../../components/Hr';
import AudioItem from '../../../../components/AudioItem';
import { Row, Col } from 'reactstrap';
// import { Search } from 'react-bootstrap-table2-toolkit';

import { TRANSLATIONS } from '../../../../constants/translations';
import { ROUTES } from '../../../../constants/routes';

class Audios extends React.Component {
  componentDidMount() {
    this.props.getRecent();
  }

  render() {
    const { recent } = this.props;
    if (!recent.length) return null;

    return (
      <>
        <Hr />
        <Row className="mb-4">
          <Col sm={9} xs={12}>
            <h2 className="display-3 mb-0">{TRANSLATIONS.AUDIO_CLASSES.EN}</h2>
            <small className="text-muted">Shiurim, Chaburos and Seminars</small>
          </Col>
          <Col sm={3} xs={12} className="text-left text-sm-right">
            <LocalizedLink
              to={ROUTES.AUDIO}
              className="btn btn-outline-primary btn-sm mt-3 mb-3"
            >
              <span className="btn-inner--text mr-1">
                {TRANSLATIONS.SEE_ALL.EN}
              </span>
              <span className="btn-inner--icon">
                <i className="ni ni-bold-right" />
              </span>
            </LocalizedLink>
          </Col>
        </Row>
        {/* <Search.SearchBar
          className="mb-4"
          placeholder={TRANSLATIONS.AUDIO_SEARCH_PH.EN}
          onSearch={this.props.onSearch}
        /> */}

        {recent.map(item => (
          <AudioItem key={`recent-${item.id}`} item={item} />
        ))}
      </>
    );
  }
}

export default Audios;
