import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Cookie from 'js-cookie';

import classes from './WelcomeModal.module.scss';

export default class WelcomeModal extends Component {
  constructor(props) {
    super(props);

    // Cookie.remove('showedWelcomeAlert'); //uncomment to test
    const showedCookie = Cookie.get('showedWelcomeAlert');

    this.state = {
      open: null,
      showWelcomeAlert: !showedCookie || showedCookie === 'false',
    };
  }

  componentDidMount() {
    if (this.state.showWelcomeAlert) {
      Cookie.set('showedWelcomeAlert', true, { expires: 999 });
      this.showAlert();
    }
  }

  showAlert() {
    this.setState({
      alert: this.renderSweetAlert(),
    });
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  renderSweetAlert() {
    const swalStyle = {
      top: '30%',
      paddingLeft: '2rem',
      paddingRight: '2rem',
    };

    return (
      <SweetAlert
        title={false}
        confirmBtnText="Great!"
        onConfirm={this.hideAlert.bind(this)}
        style={swalStyle}
      >
        <h2 className={classes.title}>
          Welcome to the new Business Halacha website!
        </h2>
        <p className={`h3 text-gray font-weight-normal ${classes.subtitle}`}>
          We hope you like the redesign, focused on ease of use, better
          navigation, and a more pleasing design.
        </p>

        <hr />

        <h3 className="text-info mb-3">
          See the gif below on how to switch languages
        </h3>
        <img
          alt="Language toggle instructions"
          src={require('../../assets/images/instructionals/language_toggle.gif')}
          className={`rounded mb-3 ${classes.imgStyle}`}
        />
      </SweetAlert>
    );
  }

  render() {
    return <>{this.state.alert}</>;
  }
}
