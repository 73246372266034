import React from 'react';

import { Modal, Card, CardHeader, CardBody } from 'reactstrap';
import BannerEditForm from './BannerEditForm';

export default class BannerEditModal extends React.Component {
  render() {
    const { index, isOpen, save, cancel, banner, disabled } = this.props;

    return (
      <Modal
        zIndex={1000}
        size="lg"
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={() => !disabled && cancel()}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0 mb-0">
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center mt-2 mb-2">
                <small>Edit slide #{index}</small>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 pt-lg-5">
              <BannerEditForm
                onSubmit={save}
                initialValues={banner}
                cancel={cancel}
              />
            </CardBody>
          </Card>
        </div>
      </Modal>
    );
  }
}
