import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.BANNERS_REQUEST:
      case actions.LIST_ADMIN_REQUEST:
        return true;
      case actions.BANNERS_SUCCESS:
      case actions.BANNERS_FAILURE:
      case actions.LIST_ADMIN_SUCCESS:
      case actions.LIST_ADMIN_FAILURE:
        return false;
      default:
        return state;
    }
  },
  saving(state = false, action) {
    switch (action.type) {
      case actions.ADD_REQUEST:
      case actions.UPDATE_REQUEST:
      case actions.SAVE_ORDER_REQUEST:
        return true;
      case actions.ADD_FAILURE:
      case actions.ADD_SUCCESS:
      case actions.UPDATE_FAILURE:
      case actions.UPDATE_SUCCESS:
      case actions.SAVE_ORDER_FAILURE:
      case actions.SAVE_ORDER_SUCCESS:
        return false;
      default:
        return state;
    }
  },
  banners(state = [], action) {
    switch (action.type) {
      case actions.BANNERS_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  listAdmin(state = [], action) {
    switch (action.type) {
      case actions.LIST_ADMIN_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
  savedBanner(state = null, action) {
    switch (action.type) {
      case actions.ADD_SUCCESS:
      case actions.UPDATE_SUCCESS:
      case actions.REMOVE_SUCCESS:
        return action.data;
      case actions.RESET_SAVED_BANNER:
        return null;
      default:
        return state;
    }
  },
});
