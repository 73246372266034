import React, { Component } from 'react';
import classnames from 'classnames';

import { Field } from 'redux-form';

import InputRedux from '../../components/Input/InputRedux';
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

export default class DonatePage extends Component {
  state = {};

  renderRow(name, placeholder, type, focused, icon, attr = {}) {
    return (
      <Col xs={12} md={6} lg={12} xl={6}>
        <FormGroup
          className={classnames('mb-3', {
            focused: this.state[focused],
          })}
        >
          <InputGroup className="input-group-merge input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className={`${icon}`} />
              </InputGroupText>
            </InputGroupAddon>
            <Field
              name={name}
              type={type}
              placeholder={placeholder}
              onFocus={() => this.setState({ [focused]: true })}
              onBlur={() => this.setState({ [focused]: false })}
              component={InputRedux}
            />
          </InputGroup>
        </FormGroup>
      </Col>
    );
  }

  render() {
    return (
      <>
        <Row>
          {this.renderRow(
            'name',
            'Full name',
            'text',
            'focusedName',
            'ni ni-circle-08',
          )}
          {this.renderRow(
            'email',
            'Email',
            'email',
            'focusedEmail',
            'ni ni-email-83',
          )}
          {this.renderRow(
            'address',
            'Address',
            'text',
            'focusedAddress',
            'ni ni-pin-3',
          )}
          {this.renderRow(
            'city',
            'City',
            'text',
            'focusedCity',
            'ni ni-building',
          )}
          {this.renderRow(
            'state',
            'State',
            'text',
            'focusedState',
            'ni ni-map-big',
          )}
          {this.renderRow(
            'zip',
            'Zip',
            'text',
            'focusedZip',
            'ni ni-square-pin',
          )}
          {this.renderRow(
            'phone',
            'Phone Number',
            'tel',
            'focusedPhone',
            'fas fa-phone',
          )}
        </Row>
      </>
    );
  }
}
