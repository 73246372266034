import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import { list, defaultLang } from '../../redux/lang/selectors';
import { materials } from '../../redux/document/selectors';

import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import { types } from '../../constants/document';
import { LANG } from '../../constants/lang';

import LinkAutoComplete from './LinkAutoComplete';

const defaultLinks = [];
Object.keys(ROUTES).forEach(routeKey => {
  if (LINK_LABELS[routeKey])
    defaultLinks.push({
      link: ROUTES[routeKey],
      label: LINK_LABELS[routeKey][LANG.EN],
    });
});

const linksSelector = createSelector(
  materials,
  materials => {
    const links = [];
    materials.forEach(m => {
      let link, label;
      if (m.type) {
        if (m.type === types.AUDIO) {
          link = `${ROUTES.AUDIO_ITEM_PARAM}${m.id}`;
          label = m['Langs.DocumentLang.title'];
        }
        if (m.type === types.DOCUMENT) {
          link = `${ROUTES.DOCUMENT_PARAM}${m.id}`;
          label = m['Langs.DocumentLang.title'];
        }
        if (m.type === types.ARTICLE) {
          link = `${ROUTES.ARTICLE_PARAM}${m.id}`;
          label = m['Langs.DocumentLang.title'];
        }
      } else {
        link = `${ROUTES.BUSINESS_WEEKLY_ITEM_PARAM}${m.id}`;
        label = m['Langs.BusinessWeeklyLang.title'];
      }
      links.push({
        link,
        label,
      });
    });
    return [...defaultLinks, ...links];
  },
);

export default connect(
  createStructuredSelector({
    langList: list,
    defaultLang,
    links: linksSelector,
  }),
  null,
)(LinkAutoComplete);
