import { types } from '../../constants/document';

export const prefix = 'document';

export const RECENT_REQUEST = `${prefix}/RECENT_REQUEST`;
export const RECENT_SUCCESS = `${prefix}/RECENT_SUCCESS`;
export const RECENT_FAILURE = `${prefix}/RECENT_FAILURE`;

export const getRecent = () => ({
  types: [RECENT_REQUEST, RECENT_SUCCESS, RECENT_FAILURE],
  promise: api => api.document.recent(),
});

export const RELATED_REQUEST = `${prefix}/RELATED_REQUEST`;
export const RELATED_SUCCESS = `${prefix}/RELATED_SUCCESS`;
export const RELATED_FAILURE = `${prefix}/RELATED_FAILURE`;

export const getRelated = params => ({
  types: [RELATED_REQUEST, RELATED_SUCCESS, RELATED_FAILURE],
  promise: api => api.document.recent(params),
});

export const SEARCH_REQUEST = `${prefix}/SEARCH_REQUEST`;
export const SEARCH_SUCCESS = `${prefix}/SEARCH_SUCCESS`;
export const SEARCH_FAILURE = `${prefix}/SEARCH_FAILURE`;

export const searchHomePage = val => ({
  type: SEARCH_SUCCESS,
  payload: val,
});

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`;
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`;
export const LIST_FAILURE = `${prefix}/LIST_FAILURE`;

export const getList = params => ({
  types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: api => api.document.list(params),
});

export const GET_ITEM_REQUEST = `${prefix}/GET_ITEM_REQUEST`;
export const GET_ITEM_SUCCESS = `${prefix}/GET_ITEM_SUCCESS`;
export const GET_ITEM_FAILURE = `${prefix}/GET_ITEM_FAILURE`;

export const getItem = id => ({
  types: [GET_ITEM_REQUEST, GET_ITEM_SUCCESS, GET_ITEM_FAILURE],
  promise: api => api.document.get(id),
});

export const LIST_ADMIN_REQUEST = `${prefix}/LIST_ADMIN_REQUEST`;
export const LIST_ADMIN_SUCCESS = `${prefix}/LIST_ADMIN_SUCCESS`;
export const LIST_ADMIN_FAILURE = `${prefix}/LIST_ADMIN_FAILURE`;

export const getListAdmin = (params = {}) => ({
  types: [LIST_ADMIN_REQUEST, LIST_ADMIN_SUCCESS, LIST_ADMIN_FAILURE],
  promise: api => api.document.listAdmin(params),
});

export const LIST_FULL_ADMIN_REQUEST = `${prefix}/LIST_FULL_ADMIN_REQUEST`;
export const LIST_FULL_ADMIN_SUCCESS = `${prefix}/LIST_FULL_ADMIN_SUCCESS`;
export const LIST_FULL_ADMIN_FAILURE = `${prefix}/LIST_FULL_ADMIN_FAILURE`;

export const getListFullAdmin = (params = {}) => ({
  types: [
    LIST_FULL_ADMIN_REQUEST,
    LIST_FULL_ADMIN_SUCCESS,
    LIST_FULL_ADMIN_FAILURE,
  ],
  promise: api => api.document.listFullAdmin(params),
});

export const ITEM_ADMIN_REQUEST = `${prefix}/ITEM_ADMIN_REQUEST`;
export const ITEM_ADMIN_SUCCESS = `${prefix}/ITEM_ADMIN_SUCCESS`;
export const ITEM_ADMIN_FAILURE = `${prefix}/ITEM_ADMIN_FAILURE`;

export const getItemAdmin = id => ({
  types: [ITEM_ADMIN_REQUEST, ITEM_ADMIN_SUCCESS, ITEM_ADMIN_FAILURE],
  promise: api => api.document.itemAdmin(id),
});

export const ADD_REQUEST = `${prefix}/ADD_REQUEST`;
export const ADD_SUCCESS = `${prefix}/ADD_SUCCESS`;
export const ADD_FAILURE = `${prefix}/ADD_FAILURE`;

export const add = document => ({
  types: [ADD_REQUEST, ADD_SUCCESS, ADD_FAILURE],
  promise: api => api.document.add(document),
});

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`;

export const update = document => ({
  types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: api => api.document.update(document),
});

export const REMOVE_REQUEST = `${prefix}/REMOVE_REQUEST`;
export const REMOVE_SUCCESS = `${prefix}/REMOVE_SUCCESS`;
export const REMOVE_FAILURE = `${prefix}/REMOVE_FAILURE`;

export const remove = id => ({
  types: [REMOVE_REQUEST, REMOVE_SUCCESS, REMOVE_FAILURE],
  promise: api => api.document.remove(id),
});

export const MATERIALS_REQUEST = `${prefix}/MATERIALS_REQUEST`;
export const MATERIALS_SUCCESS = `${prefix}/MATERIALS_SUCCESS`;
export const MATERIALS_FAILURE = `${prefix}/MATERIALS_FAILURE`;

export const fetchMaterials = () => ({
  types: [MATERIALS_REQUEST, MATERIALS_SUCCESS, MATERIALS_FAILURE],
  promise: api => api.document.fetchAll(),
});

export const SET_FILE = `${prefix}/SET_FILE`;

export const setFile = files => ({
  type: SET_FILE,
  data: files,
});

export const SAVE_FILE_REQUEST = `${prefix}/SAVE_FILE_REQUEST`;
export const SAVE_FILE_SUCCESS = `${prefix}/SAVE_FILE_SUCCESS`;
export const SAVE_FILE_FAILURE = `${prefix}/SAVE_FILE_FAILURE`;

export const saveFile = (id, fileId = null) => select => {
  const files = select(state => state[prefix].fileToSave);
  if (!files.length)
    return {
      type: SAVE_FILE_SUCCESS,
      data: { itemId: id },
    };
  const formData = new FormData();
  formData.append('id', id);
  formData.append('type', types.DOCUMENT);
  formData.append('watermark', files[0].watermark);
  if (fileId) formData.append('fileId', fileId);
  if (files.length && files[0].name)
    formData.append('file', files[0], files[0].name);

  return {
    types: [SAVE_FILE_REQUEST, SAVE_FILE_SUCCESS, SAVE_FILE_FAILURE],
    promise: api => api.document.saveFile(formData),
  };
};

export const SEARCH_AUTOCOMPLETE_REQUEST = `${prefix}/SEARCH_AUTOCOMPLETE_REQUEST`;
export const SEARCH_AUTOCOMPLETE_SUCCESS = `${prefix}/SEARCH_AUTOCOMPLETE_SUCCESS`;
export const SEARCH_AUTOCOMPLETE_FAILURE = `${prefix}/SEARCH_AUTOCOMPLETE_FAILURE`;

export const autocompleteSearch = query => ({
  types: [
    SEARCH_AUTOCOMPLETE_REQUEST,
    SEARCH_AUTOCOMPLETE_SUCCESS,
    SEARCH_AUTOCOMPLETE_FAILURE,
  ],
  promise: api => api.document.autocompleteSearch(query),
  docType: query.type,
});
