import { LANG } from './lang';
import { PHONE } from './contactInfo';

export const OUR_SERVICES = [
  {
    title: {
      [LANG.EN]: 'Halacha Hotline',
      [LANG.HE]: 'קו ההלכה',
    },
    description: {
      [LANG.EN]: 'Connect with BHI Rabbanim for trustworthy halachic guidance',
      [LANG.HE]: 'צור קשר עם רבני המכון לקבלת הכוונה וייעוץ',
    },
    btnText: {
      [LANG.EN]: 'Call now',
      [LANG.HE]: 'צור קשר',
    },
    color: 'info',
    icon: 'phone',
    link: `tel:${PHONE}`,
    isDirectLink: true,
  },
  {
    title: {
      [LANG.EN]: "Bais Hora'ah",
      [LANG.HE]: 'בית הוראה',
    },
    description: {
      [LANG.EN]: 'Cost-effective mediation services & arbitration',
      [LANG.HE]: 'ישוב סכסוכים ובוררויות במחירים נוחים',
    },
    btnText: {
      [LANG.EN]: 'Reach out',
      [LANG.HE]: 'צור קשר',
    },
    color: 'success',
    icon: 'synagogue',
    link: '/contact',
  },
  {
    title: {
      [LANG.EN]: 'Business Services',
      [LANG.HE]: 'שירותי המכון',
    },
    description: {
      [LANG.EN]: 'Personalized guidance & rapid dispute resolution',
      [LANG.HE]: 'הדרכה אישית לפתרון סכסוכים',
    },
    btnText: {
      [LANG.EN]: 'Schedule a call',
      [LANG.HE]: 'לקביעת שיחה',
    },
    color: 'warning',
    icon: 'handshake-alt',
    link: '/contact',
  },
  {
    title: {
      [LANG.EN]: 'How can we help?',
      [LANG.HE]: 'כיצד אנו יכולים לעזור?',
    },
    description: {
      [LANG.EN]:
        'Fast halachic guidance so you can effectively operate your business',
      [LANG.HE]: 'ייעוץ והכוונה הלכתיים בצורה יעילה ומהירה',
    },
    btnText: {
      [LANG.EN]: 'Get in touch',
      [LANG.HE]: 'צור קשר',
    },
    color: 'default',
    icon: 'clipboard-check',
    link: '/contact',
  },
];
