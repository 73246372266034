import React, { Component } from 'react';
import { Col, Row, Button } from 'reactstrap';
import SortableTree from 'react-sortable-tree';
import CategoryModal from '../CategoryModal';
import CategoryTypeModal from '../CategoryTypeModal';
import Loader from '../../../../../components/Loader';
import {
  getTreeFromFlatData,
  findCategoryTypeById,
} from '../../../../../utils';

import 'react-sortable-tree/style.css';
import { ROUTES } from '../../../../../constants/routes';

export default class CategoryList extends Component {
  state = {
    treeData: [],
    categories: [],
    modalIsOpen: false,
    categoryModalIsOpen: false,
    selectedCategory: {},
  };

  componentDidMount() {
    const { match, getListAdmin, getTypeListAdmin } = this.props;
    getListAdmin({ id: match.params.id });
    getTypeListAdmin();
  }

  componentDidUpdate(prevProps, prevState) {
    const { list } = this.props;
    if (prevProps.list && prevProps.list !== list) {
      const treeData = getTreeFromFlatData({
        flatData: list,
      });
      this.setState({
        categories: this.props.list,
        treeData,
      });
    }
  }

  addCategoryType = categoryToEdit => e => {
    const { categoryTypes } = this.props;
    const { typeId } = categoryToEdit;
    const findItemNested = (arr, itemId, nestingKey) =>
      // eslint-disable-next-line array-callback-return
      arr.reduce((a, item) => {
        if (a) return a;
        if (item.id === itemId) return item;
        if (item[nestingKey])
          return findItemNested(item[nestingKey], itemId, nestingKey);
      }, null);
    const res = findItemNested(categoryTypes, typeId, 'categoryTypes');

    this.setState({
      selectedCategory: res,
      categoryModalIsOpen: true,
    });
  };

  editCategory = categoryToEdit => e => {
    const { getItemAdmin } = this.props;
    const { id } = categoryToEdit;
    if (id) {
      getItemAdmin(id);
    }
    this.setState({
      selectedCategory: categoryToEdit,
      modalIsOpen: true,
    });
  };

  remove = id => e => {
    const yes = window.confirm('Are you sure you want delete this category?');
    if (!yes) return;
    const { remove } = this.props;
    if (id) {
      remove(id);
    }
  };

  toggleModal = (open = false) => {
    this.setState({ modalIsOpen: open, categoryModalIsOpen: open });
  };

  handleToggle = ({ node, treeData, expanded }) => {
    const { getChildrenById, list } = this.props;
    if (expanded) {
      getChildrenById({ id: node.id });
    }
    const data = list.map(item => {
      if (item.id === node.id) {
        item.expanded = expanded;
      }
      return item;
    });

    const updatedTreeData = getTreeFromFlatData({
      flatData: data,
    });
    this.setState({
      updatedTreeData,
    });
  };

  renderButtons = (node, categoryType) => {
    if (node.id.includes('noChildren')) return [];
    const addButtons =
      (categoryType != null &&
        categoryType.categoryTypes &&
        categoryType.categoryTypes.map(cType => (
          <Button
            key={`add-category-${cType.id}`}
            color="primary"
            size="sm"
            onClick={this.editCategory({
              typeId: cType.id,
              parentId: node.id,
            })}
            className="btn btn-icon mr-2"
          >
            <span className="btn-inner--icon">
              <i className="ni ni-fat-add" />
            </span>
            <span className="btn-inner--text">Add {cType.label}</span>
          </Button>
        ))) ||
      [];

    return [
      ...addButtons,
      <Button
        className="btn btn-icon btn-info btn-sm ml-4"
        type="button"
        onClick={this.addCategoryType(node)}
      >
        <span className="btn-inner--icon">
          <i className="ni ni-fat-add" />
        </span>
      </Button>,
      <Button
        className="btn btn-icon btn-secondary btn-sm ml-1"
        type="button"
        onClick={this.editCategory(node)}
      >
        <span className="btn-inner--icon">
          <i className="ni ni-settings-gear-65" />
        </span>
      </Button>,

      <Button
        className="btn btn-icon btn-danger btn-sm ml-1"
        type="button"
        onClick={this.remove(node.id)}
      >
        <span className="btn-inner--icon">
          <i className="ni ni-fat-remove" />
        </span>
      </Button>,
    ];
  };

  render() {
    const {
      categoryTypes,
      loading,
      loadingTypes,
      loadingChildren,
      history,
      match,
      defaultLang,
      itemAdmin,
    } = this.props;
    const typeId = match.params.id;
    const {
      treeData,
      modalIsOpen,
      selectedCategory,
      categoryModalIsOpen,
    } = this.state;
    const selectedType = categoryTypes.find(item => item.id === typeId) || {};

    if (loading || loadingTypes) return <Loader type="fixed" />;

    return (
      <>
        <Row className="m-4">
          <Col>
            <p className="display-4">
              List <small>({selectedType.label})</small>
            </p>
          </Col>
          <Col className="text-right">
            <Button
              color="primary"
              onClick={this.editCategory({ typeId: selectedType.id })}
              className="btn btn-icon"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-add" />
              </span>
              <span className="btn-inner--text">New category</span>
            </Button>
            <Button
              color="warning"
              onClick={() => history.push(ROUTES.ADMIN_CATEGORIES)}
              className="btn btn-icon"
            >
              <span className="btn-inner--icon">
                <i className="ni ni-bold-left" />
              </span>
              <span className="btn-inner--text">Back</span>
            </Button>
          </Col>
        </Row>
        <Row className="m-4">
          {loading && <Loader type="fixed" />}

          <SortableTree
            style={{ minHeight: 1000, width: '100%' }}
            treeData={treeData}
            getNodeKey={({ node, treeIndex }) => node.id}
            canDrag={false}
            searchFocusOffset={0}
            onVisibilityToggle={this.handleToggle}
            generateNodeProps={({ node, path }) => {
              const categoryType = findCategoryTypeById(
                node.typeId,
                categoryTypes,
              );
              return {
                buttons: this.renderButtons(node, categoryType),
                subtitle: (
                  <>
                    {node.id.includes('noChildren') ? (
                      loadingChildren ? (
                        <span className="display-5">Loading...</span>
                      ) : (
                        <span>No Children</span>
                      )
                    ) : (
                      categoryType && (
                        <small className="text-muted mx-2">
                          <span>({categoryType.label})</span>
                        </small>
                      )
                    )}
                  </>
                ),
              };
            }}
            onChange={treeData => this.setState({ treeData })}
          />
        </Row>
        <CategoryModal
          isOpened={modalIsOpen}
          defaultLang={defaultLang}
          initialValues={selectedCategory.id ? itemAdmin : selectedCategory}
          close={this.toggleModal}
        />
        <CategoryTypeModal
          isOpened={categoryModalIsOpen}
          defaultLang={defaultLang}
          initialValues={{
            parentId: selectedCategory.id || null,
          }}
          isFromList
          close={this.toggleModal}
        />
      </>
    );
  }
}
