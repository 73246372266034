import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { Form, FormGroup, InputGroup, Input } from 'reactstrap';

const DEBOUNCE_TIME = 1000;

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };

    this.onSearch = _.debounce(e => this.search(e), DEBOUNCE_TIME);
  }

  search() {
    const { value, onSearch } = this.props;

    if (!value) return;

    const searching = value.trim().toLowerCase();
    const inputLength = searching.length;

    if (inputLength && inputLength > 1) onSearch(value);
  }

  onSubmit = e => {
    e.preventDefault();
    this.onSearch.cancel();
    this.search();
  };

  render() {
    const { name, value, label, placeholder, onChange } = this.props;

    return (
      <Form onSubmit={this.onSubmit}>
        <FormGroup className="mb-md-0 mb-4">
          <label className="form-control-label">{label}</label>
          <InputGroup className="input-group-alternative input-group-merge">
            <Input
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
              onKeyUp={this.onSearch}
            />
          </InputGroup>
        </FormGroup>
      </Form>
    );
  }
}

export default withRouter(SearchInput);
