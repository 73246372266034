import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import BannerBuilder from './BannerBuilder';

import { list, defaultLang } from '../../redux/lang/selectors';
import {
  loading,
  listAdmin,
  saving,
  savedBanner,
} from '../../redux/banner/selectors';
import {
  add,
  update,
  remove,
  resetSavedBanner,
  getListAdmin,
} from '../../redux/banner/actions';

const selector = createStructuredSelector({
  loading,
  saving,
  savedBanner,
  langList: list,
  banners: listAdmin,
  defaultLang,
});

export default connect(
  selector,
  {
    add,
    update,
    remove,
    resetSavedBanner,
    getListAdmin,
  },
)(BannerBuilder);
