import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import InputRedux from '../../Input/InputRedux';
import {
  Row,
  Col,
  Form,
  Button,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import classnames from 'classnames';
import { TRANSLATIONS } from '../../../constants/translations';

export default class ContactForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };
  state = {};

  render() {
    const { contactUsSent, sending } = this.props;

    if (contactUsSent)
      return (
        <Row>
          <Col>
            <p className="text-muted mt-8 mb-4 pr-lg-8">
              Message was sent. We'll contact you as soon as possible.
            </p>
            <p className="display-4 mb-8 pr-lg-8">Thank you!</p>
          </Col>
        </Row>
      );

    return (
      <Form onSubmit={this.props.handleSubmit} role="form">
        <Row>
          <Col lg={6}>
            <FormGroup
              className={classnames('mb-3', {
                focused: this.state.focusedName,
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-circle-08" />
                  </InputGroupText>
                </InputGroupAddon>
                <Field
                  name="name"
                  type="text"
                  required
                  disabled={sending}
                  placeholder="Full name"
                  onFocus={() => this.setState({ focusedName: true })}
                  onBlur={() => this.setState({ focusedName: false })}
                  component={InputRedux}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup
              className={classnames('mb-3', {
                focused: this.state.focusedEmail,
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Field
                  name="email"
                  type="email"
                  placeholder="Email"
                  required
                  disabled={sending}
                  onFocus={() => this.setState({ focusedEmail: true })}
                  onBlur={() => this.setState({ focusedEmail: false })}
                  component={InputRedux}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup
              className={classnames({
                focused: this.state.focusedSubject,
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-collection" />
                  </InputGroupText>
                </InputGroupAddon>
                <Field
                  name="subject"
                  type="text"
                  placeholder="Subject"
                  required
                  disabled={sending}
                  onFocus={() => this.setState({ focusedSubject: true })}
                  onBlur={() => this.setState({ focusedSubject: false })}
                  component={InputRedux}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup
              className={classnames({
                focused: this.state.focusedMsg,
              })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <Field
                  name="msg"
                  type="textarea"
                  placeholder="Message"
                  rows={4}
                  disabled={sending}
                  resize="none"
                  onFocus={() => this.setState({ focusedMsg: true })}
                  onBlur={() => this.setState({ focusedMsg: false })}
                  component={InputRedux}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={12} sm={3} md={4} lg={3}>
            <Button
              className="my-4 btn-block text-uppercase"
              color="primary"
              size="lg"
              type="submit"
              disabled={sending}
            >
              {sending ? (
                <i className="text-white fas fa-spinner fa-pulse" />
              ) : (
                TRANSLATIONS.SEND.EN
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
