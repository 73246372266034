import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';

import { list, defaultLang } from '../../redux/lang/selectors';
import {
  autocompleteResult,
  autocompleteLoading,
} from '../../redux/document/selectors';
import { autocompleteSearch } from '../../redux/document/actions';

import { ROUTES } from '../../constants/routes';
import { types } from '../../constants/document';

import DocumentAutoComplete from './DocumentAutoComplete';

const materialsSelector = createSelector(
  autocompleteResult,
  materialsByType => {
    const resultListByType = {};
    Object.keys(materialsByType).forEach(type => {
      const resultList = [{ id: null, label: 'None' }];
      materialsByType[type].forEach(m => {
        let link, label;
        if (m.type === types.AUDIO) {
          link = `${ROUTES.ADMIN_AUDIO_ITEM_PARAM}${m.id}`;
          label = m['Langs.DocumentLang.title'];
        }
        if (m.type === types.DOCUMENT) {
          link = `${ROUTES.ADMIN_DOCUMENT_ITEM_PARAM}${m.id}`;
          label = m['Langs.DocumentLang.title'];
        }
        if (m.type === types.ARTICLE) {
          link = `${ROUTES.ADMIN_ARTICLE_ITEM_PARAM}${m.id}`;
          label = m['Langs.DocumentLang.title'];
        }
        if (!m.type) {
          link = `${ROUTES.BUSINESS_WEEKLY_ITEM_PARAM}${m.id}`;
          label = m['Langs.BusinessWeeklyLang.title'];
        }
        resultList.push({
          id: m.id,
          link,
          label,
        });
      });
      resultListByType[type] = resultList;
    });

    return resultListByType;
  },
);

export default connect(
  createStructuredSelector({
    langList: list,
    defaultLang,
    resultList: materialsSelector,
    autocompleteLoading,
  }),
  {
    autocompleteSearch,
  },
)(DocumentAutoComplete);
