import { combineReducers } from 'redux';

import profile from './profile/reducer';
import form from './redux-forms/reducer';
import customForm from './form/reducer';
import router from './router/reducer';
import users from './users/reducer';
import banner from './banner/reducer';
import article from './article/reducer';
import audio from './audio/reducer';
import document from './document/reducer';
import businessWeekly from './business-weekly/reducer';
import notify from './notify/reducer';
import lang from './lang/reducer';
import submission from './submission/reducer';
import category from './category/reducer';
import categoryType from './category-type/reducer';
import materialsCategoryType from './materials-category-type/reducer';
import settings from './settings/reducer';
import search from './search/reducer';
import customEmail from './customEmail/reducer';

export default combineReducers({
  profile,
  customForm,
  users,
  router,
  form,
  banner,
  article,
  audio,
  document,
  businessWeekly,
  notify,
  lang,
  submission,
  category,
  categoryType,
  materialsCategoryType,
  settings,
  search,
  customEmail,
});
