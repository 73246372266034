import React from 'react';

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';
import SearchFormTop from '../../components/SearchFormTop';
import LocalizedLink from '../../components/LocalizedLink';
import LocaleSelector from '../../components/LocaleSelector';

import { MAIN_MENU } from '../../constants/labels';
import { ROUTES } from '../../constants/routes';
import { TRANSLATIONS } from '../../constants/translations';
import logo from '../../assets/images/color-logo.svg';

import { getLangFromPath, getLabelFromLang } from '../../utils';

class IndexNavbar extends React.Component {
  state = {
    isOpen: false,
  };
  openSearch = () => {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(function() {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(function() {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  };
  closeSearch = () => {
    document.body.classList.remove('g-navbar-search-shown');
    setTimeout(function() {
      document.body.classList.remove('g-navbar-search-show');
      document.body.classList.add('g-navbar-search-hiding');
    }, 150);
    setTimeout(function() {
      document.body.classList.remove('g-navbar-search-hiding');
      document.body.classList.add('g-navbar-search-hidden');
    }, 300);
    setTimeout(function() {
      document.body.classList.remove('g-navbar-search-hidden');
    }, 500);
  };

  isActive(link, pathname) {
    const paths = pathname.split('/');
    return link.replace('/', '') === paths[1];
  }

  toggleNavbar = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  closeNavbar = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const { pathname } = this.props;
    const lang = getLangFromPath(pathname);
    const { isOpen } = this.state;

    const menuOrder = (lang, menuItems = []) =>
      lang === 'he' ? [...menuItems].reverse() : menuItems;

    return (
      <>
        <Navbar
          className="navbar-horizontal navbar-main zi-100 navbar-dark bg-default"
          expand="lg"
          id="navbar-main"
          lang={lang}
        >
          <Container className="overflow-visible">
            <NavbarBrand
              className="search-open-hide mr-0"
              to="/"
              tag={LocalizedLink}
            >
              <img alt="..." src={logo} />
            </NavbarBrand>
            {!pathname.includes(ROUTES.SEARCH_PAGE) && (
              <div className="d-sm-none color-light search-open-hide">
                <NavLink onClick={this.openSearch}>
                  <i className="ni ni-zoom-split-in" />
                </NavLink>
              </div>
            )}

            <div className="navbar-container">
              {!pathname.includes(ROUTES.SEARCH_PAGE) && (
                <Nav className="align-items-center navbar-search-custom" navbar>
                  <NavItem className="col-lg-10 col-12 nav-item-search-form">
                    <SearchFormTop
                      className="navbar-search-custom"
                      theme="dark"
                      closeSearch={this.closeSearch}
                      onSearch={() => {}}
                    />
                  </NavItem>
                </Nav>
              )}
              <Collapse
                className="navbar-custom-collapse pl-3"
                navbar
                isOpen={isOpen}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="8">
                      <LocalizedLink to="/" onClick={this.closeNavbar}>
                        <img
                          alt="..."
                          src={require('../../assets/images/logo.png')}
                        />
                      </LocalizedLink>
                    </Col>
                    <Col xs="2">
                      <LocaleSelector pathname={pathname} />
                    </Col>
                    <Col className="collapse-close" xs="2">
                      <button
                        className="navbar-toggler"
                        type="button"
                        onClick={this.toggleNavbar}
                      >
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav
                  className={`mr-auto col-md-12 col-sm-9 col-12 ${lang ===
                    'he' && 'justify-content-end'}`.trim()}
                  navbar
                >
                  {menuOrder(lang, MAIN_MENU).map(({ link, label, sub }) => (
                    <NavItem
                      className={`${sub ? 'with-sub-menu' : ''}`}
                      key={`main-menu-${getLabelFromLang(label, lang)
                        .toLowerCase()
                        .replace(/ /g, '-')}`}
                      onClick={this.closeNavbar}
                    >
                      <NavLink
                        to={link}
                        tag={LocalizedLink}
                        className={`${
                          this.isActive(link, pathname) ? 'active' : ''
                        }`}
                      >
                        <span>{getLabelFromLang(label, lang)}</span>
                      </NavLink>

                      {sub && (
                        <Nav
                          className="nav-sub-menu bg-primary px-3 py-2 d-none"
                          navbar
                        >
                          {menuOrder(lang, sub).map(
                            ({ link, label, comingSoon }) => (
                              <NavItem
                                key={`main-sub-menu-${getLabelFromLang(
                                  label,
                                  lang,
                                )
                                  .toLowerCase()
                                  .replace(/ /g, '-')}`}
                              >
                                <NavLink
                                  to={link}
                                  tag={LocalizedLink}
                                  className={`${
                                    this.isActive(link, pathname)
                                      ? 'active'
                                      : ''
                                  }`}
                                >
                                  <div>{getLabelFromLang(label, lang)}</div>
                                  {comingSoon && (
                                    <div className="h6 text-white-50 mb-0">
                                      Coming soon
                                    </div>
                                  )}
                                </NavLink>
                              </NavItem>
                            ),
                          )}
                        </Nav>
                      )}
                    </NavItem>
                  ))}
                </Nav>
                {/* <hr className="d-lg-none" />
                TODO DO WE NEED SUBSCRIBE BUTTON???
                <Nav
                  className="align-items-lg-center ml-lg-auto flex-column"
                  navbar
                >
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="/"
                      id="tooltip601201423"
                      target="_blank"
                    >
                      <span className="nav-link-inner--text d-lg-none">
                        Subscribe
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip601201423">
                      Subscribe to our newsletter
                    </UncontrolledTooltip>
                  </NavItem>
                </Nav> */}
              </Collapse>
            </div>
            <button
              className="navbar-toggler search-open-hide"
              type="button"
              onClick={this.toggleNavbar}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <Nav className="navbar-main-right">
              {/* <NavItem className=" my-2 ml-lg-auto d-lg-block search-open-hide">
                  <LocalizedLink
                    className="btn btn-success btn-icon"
                    color="success"
                    to={ROUTES.DONATE}
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-diamond" />
                    </span>
                    <span className="btn-inner--text">
                      {TRANSLATIONS.DONATE.EN}
                    </span>
                  </LocalizedLink>
                </NavItem> */}
              <NavItem className="mb-2 ml-lg-auto d-lg-block search-open-hide">
                <a
                  className="btn btn-success btn-icon"
                  color="success"
                  href={ROUTES.DONATE_OLD}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-diamond" />
                  </span>
                  <span className="btn-inner--text">
                    {TRANSLATIONS.DONATE.EN}
                  </span>
                </a>
              </NavItem>
              <NavItem className="d-none d-lg-block ml-lg-auto">
                <LocaleSelector pathname={pathname} />
              </NavItem>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default IndexNavbar;
