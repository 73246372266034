export const STATUSES = {
  INCOMING: 'INCOMING',
  PROCESSING: 'PROCESSING',
  INPROGRESS: 'INPROGRESS',
  COMPLETED: 'COMPLETED',
};

export const STATUS_COLORS = {
  [STATUSES.INCOMING]: 'none',
  [STATUSES.PROCESSING]: 'none',
  [STATUSES.INPROGRESS]: 'none',
  [STATUSES.COMPLETED]: 'secondary',
};

export const STATUS_TEXT_COLORS = {
  [STATUSES.INCOMING]: 'info',
  [STATUSES.PROCESSING]: 'purple',
  [STATUSES.INPROGRESS]: 'success',
  [STATUSES.COMPLETED]: 'muted',
};

export const STATUS_LABELS = {
  [STATUSES.INCOMING]: 'Incoming',
  [STATUSES.PROCESSING]: 'Processing',
  [STATUSES.INPROGRESS]: 'In progress',
  [STATUSES.COMPLETED]: 'Completed',
};

export const STATUS_OPTIONS = [
  { value: '', label: 'All' },
  { value: STATUSES.INCOMING, label: STATUS_LABELS[STATUSES.INCOMING] },
  { value: STATUSES.PROCESSING, label: STATUS_LABELS[STATUSES.PROCESSING] },
  { value: STATUSES.INPROGRESS, label: STATUS_LABELS[STATUSES.INPROGRESS] },
  { value: STATUSES.COMPLETED, label: STATUS_LABELS[STATUSES.COMPLETED] },
];
