import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';

import * as serviceWorker from './service-worker';

// argon styles
import './assets/scss/argon-dashboard-pro-react.scss?v1.0.0';
import './assets/css/nucleo/css/nucleo.css';
import 'react-notification-alert/dist/animate.css';
import 'react-quill/dist/quill.core.css';
import 'react-autosuggest/dist/';
// app styles
import './assets/styles/global.scss';
// Fontawesome custom imported library
import './assets/fonts/fontawesome';
// Tippy styles
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
