import React, { Component } from 'react';

class SubscriberRow extends Component {
  render() {
    const { subscriber, setSubscribed, savingSubscriber } = this.props;

    return (
      <>
        <th>{subscriber.address}</th>
        <td>
          <span className="text-muted">
            {subscriber.vars.createdAt
              ? new Date(subscriber.vars.createdAt).toDateString()
              : '-'}
          </span>
        </td>
        <td>
          <label className="custom-toggle">
            <input
              defaultChecked={subscriber.subscribed}
              type="checkbox"
              onChange={e => {
                setSubscribed(subscriber.address, e.target.checked);
              }}
            />
            <span
              className="custom-toggle-slider rounded-circle"
              data-label-off="No"
              data-label-on="Yes"
            />
          </label>
        </td>
        <td>
          {savingSubscriber[subscriber.address] && (
            <i className="text-info fas fa-spinner fa-pulse" />
          )}
        </td>
      </>
    );
  }
}

export default SubscriberRow;
