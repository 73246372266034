import React, { Component } from 'react';

import LocalizedLink from '../../components/LocalizedLink';
import RelatedPublications from '../../components/RelatedPublications';
import RelatedDocuments from '../../components/RelatedDocuments';
import {
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Container,
  Row,
  Badge,
} from 'reactstrap';
import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import { TRANSLATIONS } from '../../constants/translations';
import { FILE_URL } from '../../constants/files';
import ShareBtn from '../../components/ShareBtn';
import { getLabelFromLang } from '../../utils';

export default class AudioPage extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    this.props.getItem(id);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { id },
        url,
      },
    } = this.props;

    if (url !== prevProps.match.url) {
      this.props.getItem(id);
    }
  }

  getDownloadLink(url) {
    if (url) {
      const base = `${FILE_URL}${url}`;

      return /\?/.test(url)
        ? `${base}&response-content-disposition=attachment;%20FILENAME.EXT`
        : `${base}?response-content-disposition=attachment;%20FILENAME.EXT`;
    }
  }

  render() {
    const {
      lang: selectedLang,
      audio: { id, lang, tags = [], file = {}, pdf, createdAt, type, related },
      loading,
      showRelated,
    } = this.props;

    const authorLabel =
      (lang && lang[selectedLang].author) ||
      TRANSLATIONS.AUTHOR_DEFAULT[selectedLang.toUpperCase()];

    if (loading)
      return (
        <Container>
          <Row>
            <div className="text-center pb-2">
              <i className="display-3 text-white fas fa-spinner fa-pulse" />
            </div>
          </Row>
        </Container>
      );
    if (!id)
      return (
        <Container>
          <Row>
            <p className="display-2 pt-2">Shiur not found</p>
          </Row>
        </Container>
      );

    return (
      <>
        <section className="bg-gradient-info py-5">
          <Container>
            <Breadcrumb listClassName="breadcrumb-dark breadcrumb-links pb-2">
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.HOME}>
                  {LINK_LABELS.HOME.EN}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.MATERIALS}>
                  {LINK_LABELS.MATERIALS.EN}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.AUDIO}>
                  {LINK_LABELS.AUDIO.EN}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem aria-current="page" className="active">
                {lang[selectedLang].title}
              </BreadcrumbItem>
            </Breadcrumb>
            <Row className="align-items-center">
              <Col lg={8} xs={12}>
                <h1 className="display-2 text-white mb-0">
                  {lang[selectedLang].title}
                </h1>
                <p className="text-white h6 lh-120">
                  {new Date(createdAt).toDateString()} | {authorLabel}
                </p>
              </Col>
              <Col lg={4} xs={12} className="text-right">
                <LocalizedLink
                  to={ROUTES.AUDIO}
                  className="btn btn-outline-white btn-sm mt-3 mb-3"
                >
                  <span className="btn-inner--text mr-1">
                    {TRANSLATIONS.ALL.EN} {TRANSLATIONS.AUDIOS.EN}
                  </span>
                  <span className="btn-inner--icon">
                    <i className="ni ni-bold-right" />
                  </span>
                </LocalizedLink>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pt-4 pb-6">
          <Container>
            <Row className="mb-4">
              <Col lg={9}>
                {tags && tags.length > 0 && (
                  <div>
                    {tags.map(({ label, color }) => (
                      <Badge
                        key={`tag-${label.replace(/ /g, '').toLowerCase()}`}
                        color={color}
                        pill
                      >
                        {label}
                      </Badge>
                    ))}
                  </div>
                )}

                {lang[selectedLang].content && (
                  <div
                    className="py-3"
                    dangerouslySetInnerHTML={{
                      __html: lang[selectedLang].content,
                    }}
                  />
                )}

                {file && file.url ? (
                  <audio
                    id={`audio-${id}`}
                    controls
                    preload="auto"
                    className="w-100"
                  >
                    <source src={`${FILE_URL}${file.url}`} type="audio/mp3" />
                    <span>Your browser does not support HTML5 audio.</span>
                  </audio>
                ) : (
                  <div class="py-1">
                    <h3>There is no audio available for this shiur</h3>
                  </div>
                )}
              </Col>
              <Col lg={3} className="mt-4 mt-lg-0">
                {file && file.url && (
                  <a
                    href={this.getDownloadLink(file.url)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-block btn-lg btn-outline-primary btn-icon ml-0"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-cloud-download-95" />
                    </span>
                    <span className="btn-inner--text">
                      {getLabelFromLang(TRANSLATIONS.DOWNLOAD, selectedLang)}{' '}
                      {getLabelFromLang(TRANSLATIONS.AUDIO, selectedLang)}
                    </span>
                  </a>
                )}

                {pdf && pdf.id && (
                  <a
                    href={`${FILE_URL}${pdf.watermarkUrl || pdf.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-block btn-lg btn-outline-primary btn-icon ml-0 mb-0"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-collection" />
                    </span>
                    <span className="btn-inner--text">Marei Mekomos</span>
                  </a>
                )}

                <ShareBtn contentType={type} title={lang[selectedLang].title} />
              </Col>
            </Row>
          </Container>
        </section>
        {showRelated && (
          <section className="pt-2 pb-6">
            <Container>
              <hr />
              <Row>
                <Col lg={6}>
                  <RelatedDocuments related={related} tags={tags} />
                </Col>
                <Col lg={6}>
                  <RelatedPublications related={related} tags={tags} />
                </Col>
              </Row>
            </Container>
          </section>
        )}
      </>
    );
  }
}
