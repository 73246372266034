import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classnames from 'classnames';
import {
  Container,
  Form,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from 'reactstrap';

import InputRedux from '../Input/InputRedux';
import FieldFormGroup from '../FieldFormGroup';
import LanguageTabs from '../LanguageTabs';
import FormActions from '../FormActions';
import FormFileRender from '../FormFileRender';
import CategoryField from '../CategoryField';
import CategoryCopyButtons from '../CategoryCopyButtons';

import { getFieldName } from '../../utils';

import {
  types,
  subTypes,
  labels,
  bwTypesMapReverse,
} from '../../constants/document';
import { KEY_FORM_OPTIONS } from './formKeys';

const tabs = [
  subTypes[types.BUSINESS_WEEKLY].storyLine,
  subTypes[types.BUSINESS_WEEKLY].hotline,
  subTypes[types.BUSINESS_WEEKLY].moneyMatters,
];

export default class BusinessWeeklyForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tabs: subTypes[types.BUSINESS_WEEKLY].storyLine,
      lang: '',
      fromProps: {},
    };

    this.langChanged = this.langChanged.bind(this);
    this.onCategoryCopy = this.onCategoryCopy.bind(this);
  }

  componentDidMount() {
    const {
      getCategoriesRelations,
      getCategoryTypesList,
      getListByDoctype,
    } = this.props;
    getCategoriesRelations();
    getCategoryTypesList();
    getListByDoctype(types.BUSINESS_WEEKLY);
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  langChanged(lang) {
    this.setState({ lang });
  }

  onCategoryCopy(fromLang, toLang, docType) {
    const {
      change,
      storyLineValues,
      hotlineValues,
      moneyMattersValues,
    } = this.props;

    const data = {
      storyLine: storyLineValues || {},
      hotline: hotlineValues || {},
      moneyMatters: moneyMattersValues || {},
    };

    const categories =
      data[docType] &&
      data[docType].lang &&
      data[docType].lang[fromLang] &&
      data[docType].lang[fromLang].categories &&
      data[docType].lang[fromLang].categories;

    change(`${docType}.lang.${toLang}.categories`, categories);
  }

  renderField(key, lang = null, path = null, i) {
    const { saving } = this.props;
    const name = getFieldName(key, lang, path);
    const { icon, type, label, notWrap, ...rest } = KEY_FORM_OPTIONS[key];
    const isHE = lang === 'he';

    return (
      <FieldFormGroup icon={icon} label={label} lang={lang} wrap={!notWrap}>
        <Field
          name={name}
          type={type}
          disabled={saving}
          index={i}
          onFocus={() => this.setState({ [`focused_${key}`]: true })}
          onBlur={() => this.setState({ [`focused_${key}`]: false })}
          component={InputRedux}
          dir={`${isHE ? 'rtl' : 'ltr'}`}
          {...rest}
        />
      </FieldFormGroup>
    );
  }

  removeCategory = (id, docType, docTypeLang) => e => {
    const {
      change,
      storyLineValues,
      hotlineValues,
      moneyMattersValues,
    } = this.props;

    const data = {
      storyLine: storyLineValues || {},
      hotline: hotlineValues || {},
      moneyMatters: moneyMattersValues || {},
    };
    const filteredCategories =
      data[docType] &&
      data[docType].lang &&
      data[docType].lang[docTypeLang] &&
      data[docType].lang[docTypeLang].categories &&
      data[docType].lang[docTypeLang].categories.filter(item => item.id !== id);

    change(`${docType}.lang.${docTypeLang}.categories`, filteredCategories);
  };

  render() {
    const {
      files,
      saving,
      handleSubmit,
      langList,
      onDelete,
      isNew,
      categoriesRelations,
      change,
      storyLineValues,
      hotlineValues,
      moneyMattersValues,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit} role="form">
        <Container className="form-actions-row mb-4">
          <Row className="justify-content-between py-4 border-bottom">
            <Col sm={4}>
              <LanguageTabs fullList onChange={this.langChanged} />
            </Col>
            <Col className="text-right">
              <FormActions saving={saving} isNew={isNew} onDelete={onDelete} />
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col sm={4}>
              <TabContent activeTab={'langTabs-' + this.state.lang}>
                {langList.map(lang => {
                  return (
                    <TabPane
                      key={`tab-${lang.identifier}`}
                      tabId={`langTabs-${lang.identifier}`}
                    >
                      <Row>
                        <Col>{this.renderField('title', lang.identifier)}</Col>
                      </Row>
                      <Row>
                        <Col>
                          {this.renderField('description', lang.identifier)}
                        </Col>
                      </Row>
                    </TabPane>
                  );
                })}
              </TabContent>
            </Col>
            <Col sm={8}>
              <Row>
                <Col md={6}>{this.renderField('publishedAt')}</Col>
                <Col md={6}>{this.renderField('status')}</Col>
              </Row>
              <TabContent activeTab={'langTabs-' + this.state.lang}>
                {langList.map(lang => {
                  return (
                    <TabPane
                      key={`tab-${lang.identifier}`}
                      tabId={`langTabs-${lang.identifier}`}
                    >
                      <Row>
                        <Col md={6}>
                          {this.renderField('number', lang.identifier)}
                        </Col>

                        <Col md={6}>
                          <label>PDF ({lang.label})</label>
                          <Field
                            name={`file.${lang.identifier}`}
                            type={KEY_FORM_OPTIONS.file.type}
                            component={InputRedux}
                            accept="application/pdf"
                            fileid="bw-file-input"
                            disabled={saving}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  );
                })}
              </TabContent>
              <Row>
                <Col md={12}>
                  <TabContent activeTab={'langTabs-' + this.state.lang}>
                    {langList.map(lang => {
                      return (
                        <TabPane
                          key={`tab-${lang.identifier}`}
                          tabId={`langTabs-${lang.identifier}`}
                        >
                          <FormFileRender file={files[lang.identifier]} />
                        </TabPane>
                      );
                    })}
                  </TabContent>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="my-4 py-4 border-bottom border-top">
            <Col>
              <Nav
                className="nav-fill flex-column flex-md-row mb-3"
                id="lang-tabs-icons-text"
                pills
                role="tablist"
              >
                {tabs.map(tab => {
                  return (
                    <NavItem key={`tab-item-${tab}`}>
                      <NavLink
                        aria-selected={this.state.tabs === tab}
                        className={classnames('mb-sm-3 mb-md-0', {
                          active: this.state.tabs === tab,
                        })}
                        onClick={e => this.toggleNavs(e, 'tabs', tab)}
                        href="#"
                        role="tab"
                      >
                        {labels[tab].EN}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>

              <TabContent activeTab={'tabs-' + this.state.tabs}>
                {tabs.map(tab => {
                  const relatedCategories = categoriesRelations.filter(
                    item => item.doctype === bwTypesMapReverse[tab],
                  );
                  const data = {
                    storyLine: storyLineValues || {},
                    hotline: hotlineValues || {},
                    moneyMatters: moneyMattersValues || {},
                  };

                  return (
                    <TabPane key={`tab-${tab}`} tabId={`tabs-${tab}`}>
                      <TabContent activeTab={'langTabs-' + this.state.lang}>
                        {langList.map((lang, i) => (
                          <TabPane
                            key={`tab-${lang.identifier}`}
                            tabId={`langTabs-${lang.identifier}`}
                          >
                            <Row>
                              <Col sm={4}>
                                {this.renderField(
                                  'title',
                                  lang.identifier,
                                  tab,
                                )}
                              </Col>
                              {tab === 'moneyMatters' && (
                                <Col sm={4}>
                                  {this.renderField(
                                    'serialNumber',
                                    lang.identifier,
                                    tab,
                                  )}
                                </Col>
                              )}
                              {tab === 'moneyMatters' && (
                                <Col sm={4}>
                                  {this.renderField(
                                    'category',
                                    lang.identifier,
                                    tab,
                                  )}
                                </Col>
                              )}
                              {tab === 'moneyMatters' && (
                                <Col sm={4}>
                                  {this.renderField(
                                    'subcategory',
                                    lang.identifier,
                                    tab,
                                  )}
                                </Col>
                              )}
                            </Row>
                            <Row>
                              <Col>
                                {this.renderField(
                                  'content',
                                  lang.identifier,
                                  tab,
                                  i + tab,
                                )}
                              </Col>
                            </Row>
                            <Card className="shadow">
                              <CardBody className="bg-secondary">
                                <div className="d-flex">
                                  <h3 className="text-muted mt-2 mr-4">
                                    Categories {lang.identifier.toUpperCase()}
                                  </h3>
                                  <CategoryCopyButtons
                                    langList={langList}
                                    lang={lang.identifier}
                                    onClick={this.onCategoryCopy}
                                    docType={tab}
                                  />
                                </div>
                                {relatedCategories.length > 0 &&
                                  relatedCategories.map(item => (
                                    <CategoryField
                                      key={item.id}
                                      data={item}
                                      change={change}
                                      docType={tab}
                                      selectedCategories={
                                        (data[tab] &&
                                          data[tab].lang &&
                                          data[tab].lang[lang.identifier] &&
                                          data[tab].lang[lang.identifier]
                                            .categories) ||
                                        []
                                      }
                                      docTypeLang={lang.identifier}
                                      removeCategory={this.removeCategory}
                                    />
                                  ))}
                              </CardBody>
                            </Card>
                          </TabPane>
                        ))}
                      </TabContent>
                    </TabPane>
                  );
                })}
              </TabContent>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="d-flex justify-content-end pb-8">
              <FormActions saving={saving} isNew={isNew} onDelete={onDelete} />
            </Col>
          </Row>
        </Container>
      </Form>
    );
  }
}
