import React, { Component } from 'react';
import classnames from 'classnames';
import BannerCard from './BannerCard';
import { Button, Container, Row, Col } from 'reactstrap';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import classes from '../BannerBuilder.module.scss';

const slideUpDuration = 300;
const slideUpDelay = 50;

class BannerOrderPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
      fromProps: {},
      slideUp: false,
    };
    this.moveCard = this.moveCard.bind(this);
    this.saveOrder = this.saveOrder.bind(this);
    this.close = this.close.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { banners, saving, isOpen, loading } = nextProps;
    const newState = { fromProps: state.fromProps };

    if (
      !loading &&
      state.fromProps.loading &&
      state.updatingList &&
      banners &&
      banners.length
    ) {
      newState.banners = banners;
      newState.updatingList = false;
      newState.fromProps.banners = banners;
    }

    if (!state.fromProps.banners && banners.length) {
      newState.banners = banners.slice();
      newState.fromProps.banners = banners;
    }

    if (saving !== state.fromProps.saving) {
      newState.fromProps.saving = saving;
    }

    if (isOpen !== state.fromProps.isOpen) {
      newState.fromProps.isOpen = isOpen;
      newState.updatingList = isOpen;
    }

    if (loading !== state.fromProps.loading) {
      newState.fromProps.loading = loading;
    }

    return newState;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { saving, isOpen } = prevProps;
    if (saving && !this.state.fromProps.saving) {
      this.close();
      this.props.updateBannersList();
    }
    if (!isOpen && this.state.fromProps.isOpen) {
      setTimeout(() => {
        this.setState({ slideUp: true });
      }, slideUpDelay);
    }
  }

  close() {
    const { close } = this.props;
    this.setState({ slideUp: false });
    setTimeout(() => {
      close();
    }, slideUpDuration);
  }

  saveOrder() {
    const { banners } = this.state;
    const { saveOrder } = this.props;
    banners.forEach((b, i) => {
      b.order = i;
    });

    saveOrder(banners);
  }

  moveCard(dragIndex, hoverIndex) {
    const { banners } = this.state;
    const dragCard = banners[dragIndex];
    banners.splice(dragIndex, 1);
    banners.splice(hoverIndex, 0, dragCard);
    this.setState({ banners });
  }

  render() {
    const { isOpen, saving, lang } = this.props;
    const { banners, slideUp } = this.state;

    return (
      <div
        className={classnames(classes.bannerOrderPanel, {
          [classes.panelIsOpened]: isOpen,
        })}
      >
        <div
          className={classnames(
            'admin-bottom-drawer',
            classes.bannerOrderPanelContent,
            { [classes.panelIsOpened]: slideUp },
          )}
        >
          <Row noGutters className="bg-translucent-info p-4">
            <Col sm={4}>
              <h3 className="display-4 text-white">Banners order</h3>
            </Col>
            <Col className="text-right">
              <Button
                color="primary"
                disabled={saving}
                onClick={this.saveOrder}
              >
                {saving ? (
                  <i className="text-white fas fa-spinner fa-pulse" />
                ) : (
                  'Save Banners Order'
                )}
              </Button>
              <Button color="secondary" disabled={saving} onClick={this.close}>
                Cancel
              </Button>
            </Col>
          </Row>
          <p className="px-4 pt-2 mb-0">Drag & Drop to change order</p>
          <Container
            fluid
            className="p-5 d-flex align-items-center justify-content-start"
          >
            {banners &&
              banners.map((banner, i) => {
                const langKeys = banner.lang[lang];
                return (
                  <BannerCard
                    id={banner.id}
                    order={banner.order}
                    index={i}
                    langKeys={langKeys}
                    img={banner.img}
                    key={`banner-${banner.id}`}
                    moveCard={this.moveCard}
                  />
                );
              })}
          </Container>
        </div>
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(BannerOrderPanel);
