import React from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import LocalizedLink from '../LocalizedLink';
import { Row, Col, Card, CardBody, Badge, Button } from 'reactstrap';

import { typeColors } from '../../constants/audioTypes';
import { ROUTES } from '../../constants/routes';
import { TRANSLATIONS } from '../../constants/translations';
import { FILE_URL } from '../../constants/files';
import AuthorAndDateLabel from '../AuthorAndDateLabel';
import { statuses } from '../../constants/document';
import { tableTime } from '../../utils';

class AudioItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = { playing: false };
    this.toggleAudio = this.toggleAudio.bind(this);
  }

  getDownloadLink(url) {
    if (url) {
      const base = `${FILE_URL}${url}`;

      return /\?/.test(url)
        ? `${base}&response-content-disposition=attachment;%20FILENAME.EXT`
        : `${base}?response-content-disposition=attachment;%20FILENAME.EXT`;
    }
  }

  toggleAudio(e) {
    e.preventDefault();
    const {
      item: { id },
    } = this.props;
    const audioEl = document.getElementById(`audio-${id}`);
    if (audioEl.paused) {
      audioEl.play();
    } else {
      audioEl.pause();
    }
    this.setState({ playing: !audioEl.paused });
  }

  render() {
    const {
      item,
      isAdmin,
      lang,
      index,
      selectedLang,
      defaultLang,
      onRemove,
      history,
    } = this.props;

    const { playing } = this.state;
    const isHE = selectedLang === 'he';

    const title = (lang && item.lang[lang] && item.lang[lang].title) || 'N/A';

    const userSide = (
      <Card>
        <CardBody>
          <Row
            className={classnames(
              'justify-content-between align-items-center',
              {
                'flex-row-reverse': isHE,
              },
            )}
          >
            <Col sm={2} lg={3} className="mb-4 mb-sm-0 text-center">
              <Badge color={typeColors[item.subType]} pill>
                {item.subType && item.subType.toLowerCase()}
              </Badge>
            </Col>
            <Col
              sm={7}
              lg={6}
              className={classnames('mb-3 mb-sm-0 text-center', {
                'text-sm-left': !isHE,
                'text-sm-right': isHE,
              })}
            >
              <LocalizedLink to={`${ROUTES.AUDIO_ITEM_PARAM}${item.id}`}>
                <h2 className="h2 lh-120 mb-1">
                  {item.lang[selectedLang]
                    ? item.lang[selectedLang].title
                    : item.lang[defaultLang]
                    ? item.lang[defaultLang].title
                    : TRANSLATIONS.NO_TITLE_DEFAULT[selectedLang.toUpperCase()]}
                </h2>
              </LocalizedLink>
              <AuthorAndDateLabel
                selectedLang={selectedLang}
                item={item}
                createdAt={item.createdAt}
              />
            </Col>
            <Col
              sm={3}
              className={classnames(
                'd-flex justify-content-around align-items-center',
                {
                  'flex-row-reverse': isHE,
                },
              )}
            >
              {item.file && item.file.url ? (
                <>
                  <a
                    className="h4 btn-inner--icon text-primary mb-0"
                    href="/"
                    onClick={this.toggleAudio}
                  >
                    {playing ? (
                      <i className="ni ni-button-pause" />
                    ) : (
                      <i className="ni ni-button-play" />
                    )}
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    // href={`${FILE_URL}${item.file.url}`}
                    href={this.getDownloadLink(item.file.url)}
                    className="h2 btn-inner--icon text-primary mb-0 pt-1"
                  >
                    <i className="ni ni-cloud-download-95" />
                  </a>
                  <audio id={`audio-${item.id}`}>
                    <source
                      src={`${FILE_URL}${item.file.url}`}
                      type="audio/mp3"
                    />
                    <span>
                      Your browser does not support the audio element.
                    </span>
                  </audio>
                </>
              ) : null}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );

    const adminSide = (
      <tr
        onClick={e => {
          e.preventDefault();
          history.push(`${ROUTES.ADMIN_AUDIO_ITEM_PARAM}${item.id}`);
        }}
        style={{ cursor: 'pointer' }}
      >
        <th scope="row">{index}</th>
        <th className="truncatedText">
          <span>{title}</span>
        </th>
        <td>{item.subType}</td>
        <td>
          <span
            className={
              item.status === statuses.PUBLISHED
                ? 'text-success'
                : 'text-warning'
            }
          >
            {item.status}
          </span>
        </td>
        <td>{tableTime(item.createdAt)}</td>
        <td className="text-center">
          <Button
            className="btn btn-icon btn-danger btn-sm"
            type="button"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Delete"
            onClick={e => {
              e.stopPropagation();
              onRemove(item.id);
            }}
          >
            <span className="btn-inner--icon">
              <i className="ni ni-fat-remove" />
            </span>
          </Button>
        </td>
      </tr>
    );

    return isAdmin ? adminSide : userSide;
  }
}

export default withRouter(AudioItem);
