import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getListAdmin, remove } from '../../../redux/document/actions';
import {
  loading,
  listAdmin,
  listAdminCount,
} from '../../../redux/document/selectors';
import { list, defaultLang } from '../../../redux/lang/selectors';
import DocumentListPage from './DocumentListPage';

const selector = createStructuredSelector({
  loading,
  list: listAdmin,
  allCount: listAdminCount,
  langList: list,
  defaultLang,
});

const actions = {
  getListAdmin,
  remove,
};

export { DocumentListPage };

export default connect(
  selector,
  actions,
)(DocumentListPage);
