export const types = {
  SEMINARS: 'SEMINARS',
  CHABUROS: 'CHABUROS',
  SHIURIM: 'SHIURIM',
};

export const singleTypes = {
  SEMINAR: 'SEMINAR',
  CHABURA: 'CHABURA',
  SHIUR: 'SHIUR',
};

export const singleTypeOptions = [
  { value: '', label: 'All' },
  { value: singleTypes.SEMINAR, label: 'Seminar' },
  { value: singleTypes.CHABURA, label: 'Chabura' },
  { value: singleTypes.SHIUR, label: 'Shiur' },
];

export const typeColors = {
  [types.SEMINARS]: 'default',
  [types.CHABUROS]: 'info',
  [types.SHIURIM]: 'success',

  [singleTypes.SEMINAR]: 'default',
  [singleTypes.CHABURA]: 'info',
  [singleTypes.SHIUR]: 'success',
};
