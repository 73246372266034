import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import LanguageTabs from '../../../components/LanguageTabs';
import AddSubscriberModal from './AddSubscriberModal';
import SendSubscriptionModal from './SendSubscriptionModal';
import ImportSubscribersModal from './ImportSubscribersModal';
import SubscriberRow from './SubscriberRow';
import Paginator from '../../../components/Paginator';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';

class SubscribersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addModalIsOpen: false,
      sendModalIsOpen: false,
      importModalIsOpen: false,
      selectedLang: '',
      fromProps: { saving: false },
    };

    this.send = this.send.bind(this);
    this.setSubscribed = this.setSubscribed.bind(this);
    this.import = this.import.bind(this);
    this.export = this.export.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.langChanged = this.langChanged.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { saving, defaultLang } = nextProps;
    const newState = {
      fromProps: {
        saving: state.fromProps.saving,
        defaultLang: state.fromProps.defaultLang,
      },
    };

    if (saving !== state.fromProps.saving) {
      newState.fromProps.saving = saving;
    }

    if (defaultLang !== state.fromProps.defaultLang) {
      newState.fromProps.defaultLang = defaultLang;
      newState.selectedLang = defaultLang;
    }

    return newState;
  }

  componentDidMount() {
    const { defaultLang, getSubscribers } = this.props;
    if (defaultLang) {
      getSubscribers({ lang: defaultLang });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { saving: prevSaving } = prevState.fromProps;
    const { saving } = this.state.fromProps;
    if (!saving && prevSaving) {
      this.toggleModal('addModalIsOpen');
      this.toggleModal('sendModalIsOpen');
    }
  }

  langChanged(selectedLang) {
    this.setState({ selectedLang });
    this.props.getSubscribers({ lang: selectedLang });
  }

  getList = ({ page }) => {
    const { selectedLang: lang } = this.state;
    this.props.getSubscribers({ lang, page });
  };

  toggleModal(key, open = false) {
    this.setState({ [key]: open });
  }

  send(data) {
    this.props.sendWeekly(data);
  }

  schedule = data => {
    this.props.scheduleWeekly(data);
  };

  setSubscribed(address, subscribed) {
    const { selectedLang } = this.state;
    this.props.setSubscribed(
      address,
      selectedLang || this.props.defaultLang,
      subscribed,
    );
  }

  export() {
    this.props.export(this.state.selectedLang || this.props.defaultLang);
  }

  import({ files }) {
    this.props.import(files, this.state.selectedLang || this.props.defaultLang);
  }

  render() {
    const {
      loading,
      saving,
      savingSubscriber,
      subscribersList,
      subscribersCount,
      langList,
      defaultLang,
      resetHebcal,
    } = this.props;
    const {
      addModalIsOpen,
      sendModalIsOpen,
      importModalIsOpen,
      selectedLang,
    } = this.state;

    return (
      <>
        {(loading || saving) && <Loader type="fixed" />}
        <Container fluid className="py-2 border-bottom">
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="display-2 mb-0">Subscribers</h2>
            </Col>
            <Col className="text-right">
              <Button
                onClick={() => this.toggleModal('sendModalIsOpen', true)}
                color="primary"
                className="btn btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-send" />
                </span>
                <span className="btn-inner--text">Send weekly</span>
              </Button>
            </Col>
          </Row>
        </Container>
        <Container className="py-4">
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="6" md="2">
                      <h3 className="mb-0">List</h3>
                    </Col>
                    <Col xs="6" md="4">
                      <LanguageTabs
                        fullList
                        smLinks
                        forceRow
                        lang={selectedLang}
                        onChange={this.langChanged}
                      />
                    </Col>
                    <Col className="text-right" xs="12" md="6">
                      <Button
                        className="btn-round btn-icon"
                        color="success"
                        onClick={() => this.toggleModal('addModalIsOpen', true)}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Add</span>
                      </Button>
                      <Button
                        className="btn-round btn-icon"
                        color="default"
                        onClick={() =>
                          this.toggleModal('importModalIsOpen', true)
                        }
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-users" />
                        </span>
                        <span className="btn-inner--text">Import</span>
                      </Button>
                      <Button
                        className="btn-neutral btn-round btn-icon"
                        id="tooltip-subscr-export"
                        onClick={this.export}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                        </span>
                        <span className="btn-inner--text">Export</span>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="tooltip-subscr-export"
                      >
                        Export csv
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                </CardHeader>

                <Table
                  className="align-items-center table-flush"
                  hover
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th>Email</th>
                      <th>Subscribed Date</th>
                      <th>Subscribed</th>
                      <th width="70" />
                    </tr>
                  </thead>
                  <tbody>
                    {subscribersList.map(subscriber => (
                      <tr key={`subscribers-${subscriber.address}`}>
                        <SubscriberRow
                          subscriber={subscriber}
                          setSubscribed={this.setSubscribed}
                          savingSubscriber={savingSubscriber}
                          langList={langList}
                        />
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Paginator
                  getItems={this.getList}
                  pageSize={50}
                  amount={subscribersCount}
                />
              </Card>
            </Col>
          </Row>
        </Container>
        <AddSubscriberModal
          isOpened={addModalIsOpen}
          defaultLang={selectedLang}
          handleLangChange={this.langChanged}
          close={() => this.toggleModal('addModalIsOpen')}
        />
        <SendSubscriptionModal
          isOpened={sendModalIsOpen}
          send={this.send}
          schedule={this.schedule}
          close={() => {
            resetHebcal();
            this.toggleModal('sendModalIsOpen');
          }}
        />
        <ImportSubscribersModal
          isOpened={importModalIsOpen}
          defaultLang={defaultLang}
          submit={this.import}
          close={() => this.toggleModal('importModalIsOpen')}
        />
      </>
    );
  }
}

export default SubscribersPage;
