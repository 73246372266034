import React from 'react';

import CreditCard from './CreditCard';
import BillingForm from './BillingForm';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import { TRANSLATIONS } from '../../constants/translations';

class DonationForm extends React.Component {
  render() {
    const { donationLoading, handleSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit} role="form">
        {donationLoading && (
          <div className="text-center pb-2">
            <i className="display-3 text-white fas fa-spinner fa-pulse" />
          </div>
        )}
        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle>{TRANSLATIONS.BILLING_INFORMATION.EN}</CardTitle>
                <BillingForm />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>{TRANSLATIONS.DONATE_AMOUNT_TITLE.EN}</CardTitle>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-diamond" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Donation amount" type="number" />
                  </InputGroup>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <CreditCard />
          </Col>
        </Row>
        <Row className="justify-content-center text-center">
          <Col xs={6}>
            <Button
              className="my-4 text-uppercase"
              color="primary"
              size="lg"
              type="submit"
            >
              {TRANSLATIONS.DONATE.EN}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default DonationForm;
