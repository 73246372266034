export default {
  container: provided => ({
    ...provided,
    flexGrow: '1',
  }),
  control: provided => ({
    ...provided,
    border: '1px solid #dee2e6',
    padding: 4,
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#5e72e4',
    },
  }),
};
