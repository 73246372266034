import config from '../config';
import ApiClient from './client';

import Auth from './auth';
import Users from './users';
import Audio from './audio';
import Banner from './banner';
import Lang from './lang';
import Document from './document';
import BusinessWeekly from './businessWeekly';
import Article from './article';
import Hebcal from './hebcal';
import Form from './form';
import Submission from './submission';
import Category from './category';
import Settings from './settings';
import Common from './common';
import CustomEmail from './customEmail';

const apiClient = new ApiClient(config.apiPrefix);

export default {
  auth: new Auth(apiClient),
  users: new Users(apiClient),
  audio: new Audio(apiClient),
  document: new Document(apiClient),
  article: new Article(apiClient),
  businessWeekly: new BusinessWeekly(apiClient),
  banner: new Banner(apiClient),
  lang: new Lang(apiClient),
  hebcal: new Hebcal(apiClient),
  form: new Form(apiClient),
  submission: new Submission(apiClient),
  category: new Category(apiClient),
  settings: new Settings(apiClient),
  common: new Common(apiClient),
  client: apiClient,
  customEmail: new CustomEmail(apiClient),
};
