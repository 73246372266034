import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getListAdmin } from '../../../redux/banner/actions';
import { fetchMaterials } from '../../../redux/document/actions';
import { loading, listAdmin } from '../../../redux/banner/selectors';
import { list } from '../../../redux/lang/selectors';
import BannersPage from './BannersPage';

const selector = createStructuredSelector({
  loading,
  listAdmin,
  langList: list,
});

const actions = {
  getListAdmin,
  fetchMaterials,
};

export { BannersPage };

export default connect(
  selector,
  actions,
)(BannersPage);
