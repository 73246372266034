export const prefix = 'category';

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`;
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`;
export const LIST_FAILURE = `${prefix}/LIST_FAILURE`;

export const getList = query => ({
  types: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: api => api.category.list(query),
});

export const LIST_ADMIN_REQUEST = `${prefix}/LIST_ADMIN_REQUEST`;
export const LIST_ADMIN_SUCCESS = `${prefix}/LIST_ADMIN_SUCCESS`;
export const LIST_ADMIN_FAILURE = `${prefix}/LIST_ADMIN_FAILURE`;

export const getListAdmin = query => ({
  types: [LIST_ADMIN_REQUEST, LIST_ADMIN_SUCCESS, LIST_ADMIN_FAILURE],
  promise: api => api.category.listAdmin(query),
});

export const LIST_PARENTS_BY_TYPE_REQUEST = `${prefix}/LIST_PARENTS_BY_TYPE_REQUEST`;
export const LIST_PARENTS_BY_TYPE_SUCCESS = `${prefix}/LIST_PARENTS_BY_TYPE_SUCCESS`;
export const LIST_PARENTS_BY_TYPE_FAILURE = `${prefix}/LIST_PARENTS_BY_TYPE_FAILURE`;

export const getParentsByType = typeId => ({
  types: [
    LIST_PARENTS_BY_TYPE_REQUEST,
    LIST_PARENTS_BY_TYPE_SUCCESS,
    LIST_PARENTS_BY_TYPE_FAILURE,
  ],
  promise: api => api.category.parentsByType({ typeId }),
});

export const LIST_CHILDREN_BY_ID_REQUEST = `${prefix}/LIST_CHILDREN_BY_ID_REQUEST`;
export const LIST_CHILDREN_BY_ID_SUCCESS = `${prefix}/LIST_CHILDREN_BY_ID_SUCCESS`;
export const LIST_CHILDREN_BY_ID_FAILURE = `${prefix}/LIST_CHILDREN_BY_ID_FAILURE`;

export const getChildrenById = ({ id, lang }) => ({
  types: [
    LIST_CHILDREN_BY_ID_REQUEST,
    LIST_CHILDREN_BY_ID_SUCCESS,
    LIST_CHILDREN_BY_ID_FAILURE,
  ],
  promise: api => api.category.childrenById({ id, lang }),
});

export const LIST_BY_DOCTYPE_REQUEST = `${prefix}/LIST_BY_DOCTYPE_REQUEST`;
export const LIST_BY_DOCTYPE_SUCCESS = `${prefix}/LIST_BY_DOCTYPE_SUCCESS`;
export const LIST_BY_DOCTYPE_FAILURE = `${prefix}/LIST_BY_DOCTYPE_FAILURE`;

export const getListByDoctype = doctype => ({
  types: [
    LIST_BY_DOCTYPE_REQUEST,
    LIST_BY_DOCTYPE_SUCCESS,
    LIST_BY_DOCTYPE_FAILURE,
  ],
  promise: api => api.category.listByDoctype({ doctype }),
});

export const ITEM_ADMIN_REQUEST = `${prefix}/ITEM_ADMIN_REQUEST`;
export const ITEM_ADMIN_SUCCESS = `${prefix}/ITEM_ADMIN_SUCCESS`;
export const ITEM_ADMIN_FAILURE = `${prefix}/ITEM_ADMIN_FAILURE`;

export const getItemAdmin = id => ({
  types: [ITEM_ADMIN_REQUEST, ITEM_ADMIN_SUCCESS, ITEM_ADMIN_FAILURE],
  promise: api => api.category.itemAdmin(id),
});

export const ADD_REQUEST = `${prefix}/ADD_REQUEST`;
export const ADD_SUCCESS = `${prefix}/ADD_SUCCESS`;
export const ADD_FAILURE = `${prefix}/ADD_FAILURE`;

export const add = category => ({
  types: [ADD_REQUEST, ADD_SUCCESS, ADD_FAILURE],
  promise: api => api.category.add(category),
});

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`;

export const update = category => ({
  types: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: api => api.category.update(category),
});

export const REMOVE_REQUEST = `${prefix}/REMOVE_REQUEST`;
export const REMOVE_SUCCESS = `${prefix}/REMOVE_SUCCESS`;
export const REMOVE_FAILURE = `${prefix}/REMOVE_FAILURE`;

export const remove = id => ({
  types: [REMOVE_REQUEST, REMOVE_SUCCESS, REMOVE_FAILURE],
  promise: api => api.category.remove(id),
});
