import React, { Component } from 'react';

import Hr from '../../components/Hr';
import { Container, Col, Row, Card, CardBody } from 'reactstrap';

import { feedback } from './feedback';

export default class HotlineFeedbackPage extends Component {
  render() {
    return (
      <>
        <section className="header bg-gradient-info pt-5 pb-7">
          <Container>
            <Row className="align-items-stretch">
              <Col xs={12}>
                <h1 className="display-4 text-white mb-0">
                  HERE IS WHAT OTHERS HAVE SAID ABOUT THE HOTLINE
                </h1>
              </Col>
            </Row>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section>
          <section className="py-6 pb-9 bg-default">
            <Container>
              <Row className="justify-content-end">
                <Col>
                  <Hr color="white" />
                </Col>
              </Row>
            </Container>
          </section>
        </section>
        <section className="section section-lg pt-lg-0 mt--6 pb-4">
          <Container>
            <Row className="justify-content-center">
              {feedback.map(({ text, author }, i) => (
                <Col
                  key={`feedback-${author.replace(/ /g, '').toLowerCase()}`}
                  lg={8}
                >
                  <Card>
                    <CardBody>
                      <blockquote className="blockquote">
                        <p className="mb-0">“{text}”</p>
                        <footer className="blockquote-footer">{author}</footer>
                      </blockquote>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
